import React from "react";
import moment from "moment";

// Utility function to check if the scheduled date is 24 hours before
const is24HoursBefore = (scheduledDate) => {
  const scheduleMoment = moment(scheduledDate);
  const currentMoment = moment();
  return scheduleMoment.isBefore(currentMoment.add(24, "hours"));
};

const Patientreschedule = ({
  e,
  schedulingconsult,
  selectedschedule,
  isTabScreen,
}) => {
  // Define the button text based on conditions
  const buttonText =
    e.scheduled_date != null
      ? e.is_confirmed != 1
        ? "Re-Schedule"
        : "ReSchedule"
      : "Schedule Consultation";

  // Define if the button should be disabled
  const isDisabled =
    e.scheduled_date != null && is24HoursBefore(e.scheduled_date);

  // Define button styles based on conditions
  const buttonStyles = {
    width: "81px",
    height: "38px",
    opacity: e.is_confirmed === 1 ? "0.5" : "1",
    fontSize: isTabScreen ? "8px" : "10px",
    fontWeight: "400",
    border: "none",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: e?.id === selectedschedule ? "#F0588B" : "#7C9CBF",
    boxShadow:
      e?.id === selectedschedule
        ? "inset 0px 4px 8px rgba(44, 39, 56, 0.078)"
        : "0px 4px 8px rgba(44, 39, 56, 0.078)",
  };

  if (isDisabled) {
    return null;
  } else {
    return (
      <>
        <button
          onClick={() => schedulingconsult(e)}
          disabled={isDisabled}
          style={buttonStyles}
        >
          {buttonText}
        </button>
      </>
    );
  }
};

export default Patientreschedule;
