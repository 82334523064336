const motivationlist = [
  {
    pregnancy_day: 1,
    info: "A new baby is like the beginning of all things - wonder, hope, a dream of possibilities— Eda LeShan",
  },
  {
    pregnancy_day: 2,
    info: "Envision the future you desire. Create the life of your dreams. See it, feel it believe it. - Jack Canfield",
  },
  {
    pregnancy_day: 3,
    info: "Believe that life is worth living and your belief will help create the fact.William James",
  },
  {
    pregnancy_day: 4,
    info: "When you are joyful, when you say yes to life and have fun and project positivity all around you, you become a sun in the centre of every constellation, and people want to be near you. Shannon L. Alder",
  },
  {
    pregnancy_day: 5,
    info: "You only live once, but if you do it right, once is enough.",
  },
  {
    pregnancy_day: 6,
    info: "Watch your thoughts; they become words. Watch your words they become actions. Watch your actions; they become habits. Watch your habits; they become character. Watch your character; it becomes your destiny.”— Lao-Tze",
  },
  {
    pregnancy_day: 7,
    info: "We should remember that just as a positive outlook on life can promote good health, so can everyday acts of kindness.” —Hillary Clinton",
  },
  {
    pregnancy_day: 8,
    info: "The more man meditates upon good thoughts, the better will be his world and the world at large.” — Confucius",
  },
  {
    pregnancy_day: 9,
    info: "“Life is very interesting... in the end, some of your greatest pains become your greatest strengths.” – Drew Barrymore",
  },
  {
    pregnancy_day: 10,
    info: "“Having a baby is a life changer. It gives you a whole other perspective on why you wake up everyday.”- Taylor Hanson",
  },
  {
    pregnancy_day: 11,
    info: "“That is what compassion does. It challenges our assumptions, our sense of self-limitation, worthlessness, of not having a place in the world, our feelings of loneliness and estrangement. These are narrow, constrictive states of mind. As we develop compassion, our hearts open.” —Sharon Salzberg.",
  },
  {
    pregnancy_day: 12,
    info: "12.Do your little bit of good where you are; it's those little bits of good put together that overwhelm the world.” —Desmond Tutu.",
  },
  {
    pregnancy_day: 13,
    info: "Unexpected kindness is the most powerful, least costly, and most underrated agent of human change.” —Bob Kerrey.",
  },
  {
    pregnancy_day: 14,
    info: "Gratitude is the inward feeling of kindness received. Thankfulness is the natural impulse to express that feeling. Thanksgiving is the following of that impulse.” —Henry Van Dyke.",
  },
  {
    pregnancy_day: 15,
    info: "“To practise five things under all circumstances constitutes perfect virtue these five are gravity, generosity of soul, sincerity, earnestness, and kindness.” —Confucius.",
  },
  {
    pregnancy_day: 16,
    info: "Your whole life is a manifestation of the thoughts that go on in your head. -Lisa Nichols",
  },
  {
    pregnancy_day: 17,
    info: "The more man meditates upon good thoughts, the better will be his world and the world at large.” — Confucius",
  },
  {
    pregnancy_day: 18,
    info: "Stop waiting for somebody to elevate your game. You are already equipped with everything you need to manifest your own greatness. - Germany Kent",
  },
  {
    pregnancy_day: 19,
    info: "A grateful mind is a great mind which will eventually attract to itself great things.",
  },
  {
    pregnancy_day: 20,
    info: "What you think, you create. What you feel, you attract. What you imagine, you become.",
  },
  {
    pregnancy_day: 21,
    info: "A key to manifestation is that when the doors of opportunity open, you have to walk through them. - Russell Eric Dobda",
  },
  {
    pregnancy_day: 22,
    info: "Manifest what you want into existence by opening up to the Universe. Let it be known!",
  },
  {
    pregnancy_day: 23,
    info: "“Having a baby is like falling in love again, both with your husband and your child.” - Tina Brown",
  },
  {
    pregnancy_day: 24,
    info: "“When babies look beyond you and giggle, maybe they're seeing angels.” -Eileen Elias Freeman",
  },
  {
    pregnancy_day: 25,
    info: "The more man meditates upon good thoughts, the better will be his world and the world at large.” — Confucius",
  },
  {
    pregnancy_day: 26,
    info: "“A baby fills a place in your heart that you never knew was empty.” –-Author Unknown",
  },
  {
    pregnancy_day: 27,
    info: "The best and most beautiful things in the world cannot be seen or even touched — they must be felt with the heart. - Helen Keller.",
  },
  {
    pregnancy_day: 28,
    info: "Always remember that you are absolutely unique. Just like everyone else. - Margaret Mead",
  },
  {
    pregnancy_day: 29,
    info: "A baby will make love stronger, days shorter, nights longer, bankroll smaller, home happier, clothes shabbier, the past forgotten, and the future worth living for.” – Anonymous",
  },
  {
    pregnancy_day: 30,
    info: "“Carrying a baby is the most rewarding experience a woman can enjoy.” - Jayne Mansfield",
  },
  {
    pregnancy_day: 31,
    info: "“With every newborn baby, a little sun rises.” - Irmgard Erath",
  },
  {
    pregnancy_day: 32,
    info: "“Every child born into the world is a new thought of God, an ever-fresh and radiant possibility.” – Kate Douglas Wiggin",
  },
  {
    pregnancy_day: 33,
    info: "Babies are like little suns that, in a magical way, bring warmth, happiness, and light into our lives.” – Kartini Diapari-Oengider",
  },
  {
    pregnancy_day: 34,
    info: "Babies are bits of stardust blown from the hand of God. Lucky the woman who knows the pangs of birth for she has held a star.” – Larry Barretto",
  },
  {
    pregnancy_day: 35,
    info: "“For this child, I prayed and the Lord has granted the desires of my heart.” – 1 Samuel 1:27",
  },
  {
    pregnancy_day: 36,
    info: "“Making the decision to have a child – it is momentous. It is to decide forever to have your heart go walking around outside your body.” — Elizabeth Stone",
  },
  {
    pregnancy_day: 37,
    info: "The most important thing she’d learned over the years was that there was no way to be a perfect mother and a million ways to be a good one.” – Jill Churchill",
  },
  {
    pregnancy_day: 38,
    info: "“In giving birth to our babies, we may find that we give birth to new possibilities within ourselves.”– Myla and Jon Kabat-Zinn",
  },
  {
    pregnancy_day: 39,
    info: "“You are the closest I will ever come to magic.” – Suzanne Finnamore",
  },
  {
    pregnancy_day: 40,
    info: "“Part of being a parent is rolling with the punches, so consider an unexpected pregnancy the universe’s way of helping you to learn to do that.” ― Heather Wittenberg",
  },
  {
    pregnancy_day: 41,
    info: "“There is only one pretty child in the world, and every mother has it” -Chinese Proverb",
  },
  {
    pregnancy_day: 42,
    info: "“Every child comes with the message that God is not yet discouraged of man. – Rabindranath Tagore",
  },
  {
    pregnancy_day: 43,
    info: "“Be where you are; otherwise you will miss your life.” — Buddha",
  },
  {
    pregnancy_day: 44,
    info: "“Benjamin Franklin was a humanitarian that dedicated his life to making contributions to all humans. He had a clear purpose for himself: improve the human race.” — Paulo Braga",
  },
  {
    pregnancy_day: 45,
    info: "“It is impossible to live without failing at something, unless you live so cautiously, that you might as well not have lived at all – in which case you fail by default.” — Anonymous",
  },
  {
    pregnancy_day: 46,
    info: "“There is a calmness to a life lived in gratitude, a quiet joy.” — Ralph H. Blum",
  },
  {
    pregnancy_day: 47,
    info: "“When we strive to become better than we are, everything around us becomes better too.” — Paulo Coelho",
  },
  {
    pregnancy_day: 48,
    info: "“Take up one idea. Make that one idea your life — think of it, dream of it, live on that idea. Let the brain, muscles, nerves, every part of your body be full of that idea, and just leave every other idea alone. This is the way to success.” — Swami Vivekananda",
  },
  {
    pregnancy_day: 49,
    info: "“The secret of happiness, you see, is not found in seeking more, but in developing the capacity to enjoy less.” - Socrates",
  },
  {
    pregnancy_day: 50,
    info: "“I believe you make your day. You make your life. So much of it is all perception, and this is the form that I built for myself. I have to accept it and work within those compounds, and it’s up to me.” – Brad Pitt",
  },
  {
    pregnancy_day: 51,
    info: "“As you grow older, you will discover that you have two hands, one for helping yourself, the other for helping others.” — Audrey Hepburn",
  },
  {
    pregnancy_day: 52,
    info: "“Sing like no one’s listening, love like you’ve never been hurt, dance like nobody’s watching, and live like it’s heaven on earth.”",
  },
  {
    pregnancy_day: 53,
    info: "“Life is really simple, but men insist on making it complicated.”",
  },
  {
    pregnancy_day: 54,
    info: "“In order to write about life first you must live it.”– Ernest Hemingway",
  },
  {
    pregnancy_day: 55,
    info: "A positive atmosphere nurtures a positive attitude, which is required to take positive action.",
  },
  {
    pregnancy_day: 56,
    info: "Write it on your heart that every day is the best day of the year.",
  },
  {
    pregnancy_day: 57,
    info: "Whatever your mind clings to will tend to replicate itself in your real world. - Mary Morrissey",
  },
  {
    pregnancy_day: 58,
    info: "All the happiness, joy and abundance you seek in life are all within you. It's up to you to manifest them. - Samuel Zulu",
  },
  {
    pregnancy_day: 59,
    info: "It is the combination of thought and love which forms the irresistible force of the law of attraction. - Charles Hammel",
  },
  {
    pregnancy_day: 60,
    info: "Tomorrow is the day Universe will deliver an amazing blessing to you. Get ready. Your manifestation will be a success.",
  },
  {
    pregnancy_day: 61,
    info: "It's not the words of your intentions that manifest your reality, it's the vibration of the energy of your intentions that attracts.",
  },
  {
    pregnancy_day: 62,
    info: "Never let life impede on your ability to manifest your dreams. - Corin Nemec",
  },
  {
    pregnancy_day: 63,
    info: "“You are the sky. Everything else is just the weather.” — Pema Chodron",
  },
  {
    pregnancy_day: 64,
    info: "“Worry does not empty tomorrow of its sorrows. It empties today of its strength.” — Corrie Ten Boom",
  },
  {
    pregnancy_day: 65,
    info: "“Within you, there is a stillness and sanctuary to which you can retreat at any time and be yourself.” — Hermann Hesse",
  },
  {
    pregnancy_day: 66,
    info: "“Don’t try to force anything. Let life be a deep let-go. God opens millions of flowers every day without forcing their buds.” — Osho",
  },
  {
    pregnancy_day: 67,
    info: "“The greatest weapon against stress is our ability to choose one thought over another.” — William James",
  },
  {
    pregnancy_day: 68,
    info: "“When you realise how perfect everything is, you will tilt your head back and laugh at the sky.” — Buddha",
  },
  {
    pregnancy_day: 69,
    info: "Choosing to see the good even when it's hard will ultimately bring you to where you want to be. - Buddha",
  },
  {
    pregnancy_day: 70,
    info: "Eliminate all doubt and replace it with the full expectation that you will receive what you are asking for. - Rhonda Byrne",
  },
  {
    pregnancy_day: 71,
    info: "What you radiate outward in your thoughts, feelings, mental Pictures and words, you attract into your life. - Catherine Ponder",
  },
  {
    pregnancy_day: 72,
    info: "It is a universal principle that you get more of what you think about, talk about, and feel strongly about. - Jack Canfield",
  },
  {
    pregnancy_day: 73,
    info: "“We have a secret in our culture, and it’s not that birth is painful. It’s that women are strong.” – Laura Stavoe Harm",
  },
  {
    pregnancy_day: 74,
    info: "“A mother is always the beginning. She is how things begin.” – Amy Tan",
  },
  {
    pregnancy_day: 75,
    info: "“Making a decision to have a child–it’s momentous. It is to decide forever to have your heart go walking around outside your body.” – Elizabeth Stone",
  },
  {
    pregnancy_day: 76,
    info: "“Pregnancy is a process that invites you to surrender to the unseen force behind all life.” – Judy Ford",
  },
  {
    pregnancy_day: 77,
    info: "“Feeling fat lasts nine months, but the joy of becoming a mom lasts forever.” – Nikki Dalton",
  },
  {
    pregnancy_day: 78,
    info: "“You are pregnant and you are powerful. You are bold and you are beautiful. Go forward in your boldness, in your beauty and in your contentedness. Trust your body to birth and know that the collective power of women worldwide will be with you.” – Author Unknown",
  },
  {
    pregnancy_day: 79,
    info: "When I stand before God at the end of my life, I would hope that I would not have a single bit of talent left and could say, I used everything you gave me. –Erma Bombeck",
  },
  {
    pregnancy_day: 80,
    info: "“If I had my life to live over, instead of wishing away nine months of pregnancy, I’d have cherished every moment and realized that the wonderment growing inside me was the only chance in life to assist God in a miracle.” – Erma Bombeck",
  },
  {
    pregnancy_day: 81,
    info: "“A baby is something you carry inside you for nine months, in your arms for three years, and in your heart until the day you die.” – Mary Mason",
  },
  {
    pregnancy_day: 82,
    info: "Remember that not getting what you want is sometimes a wonderful stroke of luck. –Dalai Lama",
  },
  {
    pregnancy_day: 83,
    info: "“Giving birth and being born brings us into the essence of creation, where the human spirit is courageous and bold and the body, a miracle of wisdom.” – Harriette Hartigan",
  },
  {
    pregnancy_day: 84,
    info: "“Babies are bits of star-dust blown from the hand of God. Lucky is the woman who knows the pangs of birth for she has held a star.” – Larry Barretto",
  },
  {
    pregnancy_day: 85,
    info: "Happiness is not something readymade. It comes from your own actions. –Dalai Lama",
  },
  {
    pregnancy_day: 86,
    info: "“Truthfully, being pregnant is changing me as a person. Each day is part of this amazing journey that has completely shifted the focus of my life and made me re-evaluate my personal and professional goals.” – Holly Madison",
  },
  {
    pregnancy_day: 87,
    info: "I have been impressed with the urgency of doing. Knowing is not enough; we must apply. Being willing is not enough; we must do. –Leonardo da Vinci",
  },
  {
    pregnancy_day: 88,
    info: "“There is no such thing as a perfect parent, so just be a real one.” — Sue Atkins",
  },
  {
    pregnancy_day: 89,
    info: "“For this child, I prayed and the Lord has granted the desires of my heart.” — 1 Samuel 1:27",
  },
  {
    pregnancy_day: 90,
    info: "It takes courage to create a meaningful life of integrity. It also requires good company. And practice. – Shelley Francis",
  },
  {
    pregnancy_day: 91,
    info: "“Loving a baby is a circular business, a kind of feedback loop. The more you give the more you get and the more you get the more you feel like giving.” — Penelope Leach",
  },
  {
    pregnancy_day: 92,
    info: "“It is the most powerful creation to have life growing inside of you. There is no bigger gift.” — Beyoncé",
  },
  {
    pregnancy_day: 93,
    info: "“A new baby is like the beginning of all things - wonder, hope, a dream of possibilities.” — Eda LeShan",
  },
  {
    pregnancy_day: 94,
    info: "“Family is not an important thing, it's everything.” — Michael J. Fox",
  },
  {
    pregnancy_day: 95,
    info: "“Pregnancy is a process that invites you to surrender to the unseen force behind all life.” — Judy Ford",
  },
  {
    pregnancy_day: 96,
    info: "“You do a lot of growing up when you’re pregnant. It’s suddenly like, ‘Yikes. Here it is, folks. Playtime is over.’” — Connie Fioretto",
  },
  {
    pregnancy_day: 97,
    info: "“No matter how bad my day’s been, it takes one little kick to make everything feel alright.” — Author Unknown",
  },
  {
    pregnancy_day: 98,
    info: "It is never too late to be what you might have been. – George Eliot",
  },
  {
    pregnancy_day: 99,
    info: "“Before you were conceived I wanted you. Before you were born, I loved you. Before you were here an hour, I would die for you. This is the miracle of Mother’s Love.” — Maureen Hawkins",
  },
  {
    pregnancy_day: 100,
    info: "“Children reinvent your world for you.” — Susan Sarandon",
  },
  {
    pregnancy_day: 101,
    info: "“There are no words that can describe the euphoria you feel when your baby recognizes you for the first time and smiles.” — Jared Padalecki",
  },
  {
    pregnancy_day: 102,
    info: "“This is a wonderful day. I have never seen this one before.” — Maya Angelou",
  },
  {
    pregnancy_day: 103,
    info: "The influence of a mother in the lives of her children is beyond calculation. – James E. Faust",
  },
  {
    pregnancy_day: 104,
    info: "“Life doesn’t get more real than having a newborn at home.” — Eric Church",
  },
  {
    pregnancy_day: 105,
    info: "“In life, one has a choice to take one of two paths: to wait for some special day--or to celebrate each special day.” - Rasheed Ogunlaru",
  },
  {
    pregnancy_day: 106,
    info: "“Motherhood is the biggest gamble in the world. It is the glorious life force. It’s huge and scary—it’s an act of infinite optimism.” – Gilda Radner",
  },
  {
    pregnancy_day: 107,
    info: "“What good mothers and fathers instinctively feel like doing for their babies is usually best after all.” — Benjamin Spock",
  },
  {
    pregnancy_day: 108,
    info: "“A baby is God’s opinion that life should go on.” — Carl Sandburg",
  },
  {
    pregnancy_day: 109,
    info: "“If I had my life to live over, instead of wishing away nine months of pregnancy, I’d have cherished every moment and realized that the wonderment growing inside me was the only chance in life to assist God in a miracle.” — Erma Bombeck",
  },
  {
    pregnancy_day: 110,
    info: "“Children are the hands by which we take hold of heaven.”",
  },
  {
    pregnancy_day: 111,
    info: "A mother’s love is the fuel that enables the normal human being to do the impossible",
  },
  {
    pregnancy_day: 112,
    info: "“Children are a gift from the Lord, the fruit of the womb a reward.”",
  },
  {
    pregnancy_day: 113,
    info: "“A baby is a blessing. A gift from heaven above, A precious little angel, to cherish and to love.”",
  },
  {
    pregnancy_day: 114,
    info: "If you relax, it comes. If you relax, it is there. If you relax, you start vibrating with it. Osho",
  },
  {
    pregnancy_day: 115,
    info: "Openness, respect, integrity – these are principles that need to underpin pretty much every other decision that you make. – Justin Trudeau",
  },
  {
    pregnancy_day: 116,
    info: "To a mind that is still, the entire universe surrenders. Zhuangzi",
  },
  {
    pregnancy_day: 117,
    info: "Plant seeds of happiness, hope, success, and love; it will all come back to you in abundance. This is the law of nature. Steve Maraboli",
  },
  {
    pregnancy_day: 118,
    info: "“To hear the laughter of a child. To wake up next to the love of your life. To know that God has placed them on Earth for you. You are Blessed.”",
  },
  {
    pregnancy_day: 119,
    info: "In ordinary life, we hardly realize that we receive a great deal more than we give, and that it is only with gratitude that life becomes rich. Dietrich Bonhoeffer",
  },
  {
    pregnancy_day: 120,
    info: "When you are able to maintain your own highest standards of integrity – regardless of what others may do – you are destined for greatness. – Napoleon Hill",
  },
  {
    pregnancy_day: 121,
    info: "“A baby is God’s opinion that the world should go on.”",
  },
  {
    pregnancy_day: 122,
    info: "“When words are both true and kind, they can change the world.” —Buddha.",
  },
  {
    pregnancy_day: 123,
    info: "Understand: presence of mind is the ability to detach yourself, to see the whole battlefield, the whole picture, with clarity. Robert Greene",
  },
  {
    pregnancy_day: 124,
    info: "Happiness is part of who we are. Joy is the feeling. Tony DeLiso",
  },
  {
    pregnancy_day: 125,
    info: "“A baby is an inestimable blessing. – Mark Twain",
  },
  {
    pregnancy_day: 126,
    info: "The qualities of a great man are vision, integrity, courage, understanding, the power of articulation, and profundity of character. – Dwight D. Eisenhower",
  },
  {
    pregnancy_day: 127,
    info: "“Love and kindness are never wasted. They always make a difference. They bless the one who receives them, and they bless you, the giver.” —Barbara De Angelis.",
  },
  {
    pregnancy_day: 128,
    info: "“Today starts your day with a smile, calmness of mind, coolness of emotions, and a heart filled with gratitude.” —Anonymous",
  },
  {
    pregnancy_day: 129,
    info: "“The mind is like water. When it’s turbulent, it’s difficult to see. When it’s calm, everything becomes clear.” —Prasad Mahes",
  },
  {
    pregnancy_day: 130,
    info: "“There will be calmness, tranquillity when one is free from external objects and is not perturbed.” —Bruce Lee",
  },
  {
    pregnancy_day: 131,
    info: "You can easily judge the character of a man by how he treats those who can do nothing for him. – Johann Wolfgang von Goethe",
  },
  {
    pregnancy_day: 132,
    info: "Ask and it will be given to you; search, and you will find; knock and the door will be opened for you. – Jesus",
  },
  {
    pregnancy_day: 133,
    info: "Go confidently in the direction of your dreams. Live the life you have imagined. – Henry David Thoreau",
  },
  {
    pregnancy_day: 134,
    info: "“Remember there’s no such thing as a small act of kindness. Every act creates a ripple with no logical end.” —Scott Adams.",
  },
  {
    pregnancy_day: 135,
    info: "“Spread love everywhere you go. Let no one ever come to you without leaving happier.” —Mother Teresa.",
  },
  {
    pregnancy_day: 136,
    info: "Tension is who you think you should be. Relaxation is who you are.” — Chinese Proverb",
  },
  {
    pregnancy_day: 137,
    info: "“Breathe. Let go. And remind yourself that this very moment is the only one you know you have for sure.” — Oprah Winfrey",
  },
  {
    pregnancy_day: 138,
    info: "Children are the anchors that hold a mother to life. – Sophocles",
  },
  {
    pregnancy_day: 139,
    info: "“More smiling, less worrying. More compassion, less judgment. More blessed, less stressed. More love, less hate.” — Roy T. Bennett",
  },
  {
    pregnancy_day: 140,
    info: "“The elimination diet: Remove anger, regret, resentment, guilt, blame, and worry. Then watch your health, and your life, improve.” — Charles F. Glassman",
  },
  {
    pregnancy_day: 141,
    info: "As long as you can get what you want, you feel happy. But if you cannot get what you want, unhappiness begins. Krishnamurti",
  },
  {
    pregnancy_day: 142,
    info: "Sometimes the strength of motherhood is greater than natural laws. — Barbara Kingsolver",
  },
  {
    pregnancy_day: 143,
    info: "“You cannot do a kindness too soon because you never know how soon it will be too late.” — Ralph Waldo Emerson",
  },
  {
    pregnancy_day: 144,
    info: "Recognition is not happiness; to know what it is to be happy is not happiness. - Krishnamurti",
  },
  {
    pregnancy_day: 145,
    info: "Either you run the day, or the day runs you. – Jim Rohn",
  },
  {
    pregnancy_day: 146,
    info: "A baby will make love stronger, days shorter, nights longer, bankroll smaller, home happier, clothes shabbier, the past forgotten, and the future worth living for. – Anonymous",
  },
  {
    pregnancy_day: 147,
    info: "To be open to the source of all happiness is the highest religion.—KRISHNAMURTI",
  },
  {
    pregnancy_day: 148,
    info: "A mother’s joy begins when new life is stirring inside...when a tiny heartbeat is heard for the first time, and a playful kick reminds her that she is never alone. –Author unknown",
  },
  {
    pregnancy_day: 149,
    info: "To give real service you must add something which cannot be bought or measured with money, and that is sincerity and integrity. – Douglas Adams",
  },
  {
    pregnancy_day: 150,
    info: "Love will arise in your heart when you have no barrier between yourself and another, when you meet and observe people without judging them. —KRISHNAMURTI",
  },
  {
    pregnancy_day: 151,
    info: "Finding balance as a mother means accepting your imperfections. –Mary Organises",
  },
  {
    pregnancy_day: 152,
    info: "It’s a good thing babies don’t give you a lot of time to think. You fall in love with them and when you realize how much they love you back, life is very simple. –Unknown",
  },
  {
    pregnancy_day: 153,
    info: "Mother’s love is peace. It need not be acquired, it need not be deserved. —Erich Fromm",
  },
  {
    pregnancy_day: 154,
    info: "I know now why God gave us babies. They require constant attention, of course. They make messes and disturb the peace, but their cuteness and smiles are sometimes the only reminder of God we have in the house. –Ann Renaldi",
  },
  {
    pregnancy_day: 155,
    info: "You know what the great thing about babies is? They are like little bundles of hope. Like the future in a basket. –Unknown",
  },
  {
    pregnancy_day: 156,
    info: "Love can do nothing, but without it nothing can be done. —KRISHNAMURTI",
  },
  {
    pregnancy_day: 157,
    info: "I learned what is obvious to a child. That life is simply a collection of little lives, each lived one day at a time. - Nicholas Sparks",
  },
  {
    pregnancy_day: 158,
    info: "There are no words that can describe the euphoria you feel when your baby recognizes you for the first time and smiles. - Jared Padalecki",
  },
  {
    pregnancy_day: 159,
    info: "It is love alone that leads to right action. What brings order in the world is to love and let love do what it will. —KRISHNAMURTI",
  },
  {
    pregnancy_day: 160,
    info: "When he finally was placed into my arms, I looked into his precious eyes and felt an overwhelming, unconditional love....I never felt so complete and empowered in my life. - Gisele Bündchen",
  },
  {
    pregnancy_day: 161,
    info: "Gratitude is a currency that we can mint for ourselves, and spend without fear of bankruptcy. - Fred De Witt Van Amburgh",
  },
  {
    pregnancy_day: 162,
    info: "It’s all kinds of these profound things crashing on you when your child arrives into the world. It’s like you’ve met your reason to live.",
  },
  {
    pregnancy_day: 163,
    info: "The heart of a mother is a deep abyss at the bottom of which you will find forgiveness. –Honore de Balzac",
  },
  {
    pregnancy_day: 164,
    info: "Newborn babies can’t do much on their own. They can’t eat or walk or talk on the phone. But every parent is sure their creation is without a doubt a tremendous sensation.",
  },
  {
    pregnancy_day: 165,
    info: "When you look at your mother, you are looking at the purest love you will ever know. — Mitch Albom",
  },
  {
    pregnancy_day: 166,
    info: "I am not a product of my circumstances. I am a product of my decisions. –Stephen Covey",
  },
  {
    pregnancy_day: 167,
    info: "A baby is wishing well. Everyone puts their hopes, their fears, their pasts, their two cents in. – Elizabeth Bard",
  },
  {
    pregnancy_day: 168,
    info: "It’s hard to feel like much is wrong in the world when you’re looking into the eyes of a happy baby. - Lisa Wingate",
  },
  {
    pregnancy_day: 169,
    info: "Every child is an artist. The problem is how to remain an artist once he grows up. – Pablo Picasso",
  },
  {
    pregnancy_day: 170,
    info: "There is nothing like a newborn baby to renew your spirit and to buttress your resolve to make the world a better place. - Virginia Kelley",
  },
  {
    pregnancy_day: 171,
    info: "There are places in the heart you don’t even know exist until you love a child. - Anne Lamott",
  },
  {
    pregnancy_day: 172,
    info: "A first child is your own best foot forward, and how you do cheer those little feet as they strike out. - Barbara Kingsolver",
  },
  {
    pregnancy_day: 173,
    info: "Youth fades; love droops; the leaves of friendship fall; A mother’s secret hope outlives them all. – Oliver Wendell Holmes",
  },
  {
    pregnancy_day: 174,
    info: "A mother’s arms are made of tenderness and children sleep soundly in them. — Victor Hugo",
  },
  {
    pregnancy_day: 175,
    info: "Fall seven times and stand up eight. – Japanese Proverb",
  },
  {
    pregnancy_day: 176,
    info: "Having children just puts the whole world into perspective. Everything else just disappears. – Kate Winslet",
  },
  {
    pregnancy_day: 177,
    info: "When one door of happiness closes, another opens; but often we look so long at the closed door that we do not see the one which has been opened for us. - Helen Keller",
  },
  {
    pregnancy_day: 178,
    info: "You can’t fall if you don’t climb. But there’s no joy in living your whole life on the ground. – Unknown",
  },
  {
    pregnancy_day: 179,
    info: "Mother – that was the bank where we deposited all our hurts and worries. - T. DeWitt Talmage",
  },
  {
    pregnancy_day: 180,
    info: "Biology is the least of what makes someone a mother. — Oprah Winfrey",
  },
  {
    pregnancy_day: 181,
    info: "Develop an attitude of gratitude, and give thanks for everything that happens to you, knowing that every step forward is a step toward achieving something bigger and better than your current situation. —Brian Tracy",
  },
  {
    pregnancy_day: 182,
    info: "When I let go of what I am, I become what I might be. –Lao Tzu",
  },
  {
    pregnancy_day: 183,
    info: "What good mothers and fathers instinctively feel like doing for their babies is usually best after all. —Benjamin Spock",
  },
  {
    pregnancy_day: 184,
    info: "A mother is someone who can take the place of all others but whose place no one else can take. —Unknown Author",
  },
  {
    pregnancy_day: 185,
    info: "Behind every great child is a mom who’s pretty sure she’s screwing it all up. —Unknown",
  },
  {
    pregnancy_day: 186,
    info: "Parenting is the easiest thing in the world to have an opinion about, but the hardest thing in the world to do. —Matt Walsh",
  },
  {
    pregnancy_day: 187,
    info: "Part of being a parent is rolling with the punches, so consider an unexpected pregnancy the universe's way of helping you to learn to do that. — Heather Wittenberg",
  },
  {
    pregnancy_day: 188,
    info: "There is no way to be a perfect mother, but a million ways to be a good one. —Jill Churchill",
  },
  {
    pregnancy_day: 189,
    info: "Still the most magical day of my life was the day I became a mom. —Linda Becker",
  },
  {
    pregnancy_day: 190,
    info: "There are no words that can describe the euphoria you feel when your baby recognizes you for the first time and smiles. — Jared Padalecki",
  },
  {
    pregnancy_day: 191,
    info: "The miracle of gratitude is that it shifts your perception to such an extent that it changes the world you see. - Dr. Robert Holden",
  },
  {
    pregnancy_day: 192,
    info: "Birth takes a woman’s deepest fears about herself and shows her that she is stronger than them. — Unknown",
  },
  {
    pregnancy_day: 193,
    info: "A new baby is like the beginning of all things—wonder, hope, and dream of possibilities. — Eda J. LeShan",
  },
  {
    pregnancy_day: 194,
    info: "Mother is a verb. It’s something you do. Not just who you are. – Dorothy Canfield Fisher",
  },
  {
    pregnancy_day: 195,
    info: "For a mother is the only person on earth who can divide her love among ten children and each child still have all her love.-Anonymous",
  },
  {
    pregnancy_day: 196,
    info: "Motherhood has a very humanising effect. Everything gets reduced to essentials. – Meryl Streep",
  },
  {
    pregnancy_day: 197,
    info: "You can be a mess and a good mom, you’re allowed to be both.– Unknown",
  },
  {
    pregnancy_day: 198,
    info: "Being a mother is not about what you gave up to have a child, but what you gained by having one.-Sunny Gupta",
  },
  {
    pregnancy_day: 199,
    info: "Motherhood is love in its purest form.” – Revathi Sankaran",
  },
  {
    pregnancy_day: 200,
    info: "I remember my mother’s prayers and they have always followed me. They have clung to me all my life.” – Abraham Lincoln",
  },
  {
    pregnancy_day: 201,
    info: "If you want your children to turn out well, spend twice as much time with them, and half as much money. –Abigail Van Buren",
  },
  {
    pregnancy_day: 202,
    info: "“Love as powerful as your mother’s leaves its own mark ... To have been loved so deeply ... will give you some protection forever.” -J.K. Rowling",
  },
  {
    pregnancy_day: 203,
    info: "“Being thankful is not always experienced as a natural state of existence, we must work at it, akin to a type of strength training for the heart.” —Larissa",
  },
  {
    pregnancy_day: 204,
    info: "“A mother’s love is whole no matter how many times it is divided.” -Unknown",
  },
  {
    pregnancy_day: 205,
    info: "“Kids go where there is excitement. They stay where there is love.” – Zig Ziglar",
  },
  {
    pregnancy_day: 206,
    info: "“The heart of a mother is a deep abyss at the bottom of which you will always find forgiveness.” – Honore de Balzac",
  },
  {
    pregnancy_day: 207,
    info: "“A mother’s love for her child is like nothing else in the world. It knows no law, no pity, it dates all things, and crushes down remorselessly all that stands in its path.” -Agatha Christie",
  },
  {
    pregnancy_day: 208,
    info: "“Motherhood: All love begins and ends there.” – Robert Browning",
  },
  {
    pregnancy_day: 209,
    info: "“When you are looking at your mother, you are looking at the purest love you will ever know.” -Charley Benetto",
  },
  {
    pregnancy_day: 210,
    info: "“A birthing plan doesn't define who you are as a mother. The way you raise your child does.” -Unknown",
  },
  {
    pregnancy_day: 211,
    info: "“Little souls find their way to you whether they’re from your womb or someone else’s.” -Cheryl Crow",
  },
  {
    pregnancy_day: 212,
    info: "It is not what you do for your children, but what you have taught them to do for themselves, that will make them successful human beings. –Ann Landers",
  },
  {
    pregnancy_day: 213,
    info: "“Motherhood is messy. And challenging. And crazy. And sleepless. And giving. And still unbelievably beautiful.” -Rachel Martin",
  },
  {
    pregnancy_day: 214,
    info: "“Gratitude and attitude are not challenges; they are choices. -Robert Braathe",
  },
  {
    pregnancy_day: 215,
    info: "“Mothers hold their children’s hands for a little while, but their hearts forever.” -Unknown",
  },
  {
    pregnancy_day: 216,
    info: "“Gratitude can transform common days into thanksgivings, turn routine jobs into joy, and change ordinary opportunities into blessings. - William Arthur Ward",
  },
  {
    pregnancy_day: 217,
    info: "“There will be many times you feel like you’ve failed. But in the eyes, heart, and mind of your child, you are a super mom.” -Stephanie Precourt",
  },
  {
    pregnancy_day: 218,
    info: "“Successful mothers are not the ones who have never struggled. They are the ones who never give up, despite the struggles.” -Sharon Jaynes",
  },
  {
    pregnancy_day: 219,
    info: "“What separates privilege from entitlement is gratitude. - Brene Brown",
  },
  {
    pregnancy_day: 220,
    info: "Motherhood: the days are long but the years are short.” -Gretchen Rubin",
  },
  {
    pregnancy_day: 221,
    info: "While we try to teach our children all about life, our children teach us what life is all about. -Angela Schwindt",
  },
  {
    pregnancy_day: 222,
    info: "Opening your eyes to more of the world around you can deeply enhance your gratitude practice. —Derrick Carpenter.",
  },
  {
    pregnancy_day: 223,
    info: "I am proud of many things in life. But nothing beats being a mother. -Unknown",
  },
  {
    pregnancy_day: 224,
    info: "Being a parent is like folding a fitted sheet; no one really knows how. -Unknown",
  },
  {
    pregnancy_day: 225,
    info: "Showing gratitude is one of the simplest yet most powerful things humans can do for each other. - Randy Pausch",
  },
  {
    pregnancy_day: 226,
    info: "It’s not the years in your life that count. It’s the life in your years. –Abraham Lincoln",
  },
  {
    pregnancy_day: 227,
    info: "Parenting is 50% feeling like your head might explode and 50% feeling like your heart might. -@chaosandquiet",
  },
  {
    pregnancy_day: 228,
    info: "There is no such thing as a perfect parent. So just be a real one. -Sue Atkins",
  },
  {
    pregnancy_day: 229,
    info: "There are two gifts we should give our children: one is roots, the other is wings. -Unknown",
  },
  {
    pregnancy_day: 230,
    info: "Let motherhood be more about what you do with your children instead of what you do for them. -Mamie L. Pack",
  },
  {
    pregnancy_day: 231,
    info: "Having a parent who listens creates a child who believes he or she has a voice that matters in this world. -Rachel Macy Stafford",
  },
  {
    pregnancy_day: 232,
    info: "In the end, I am the only one who can give my children a happy mother who loves life. -Unknown",
  },
  {
    pregnancy_day: 233,
    info: "A mother is she who can take the place of all others but whose place no one else can take. -Cardinal Mermillod",
  },
  {
    pregnancy_day: 234,
    info: "To the world, you are a mother, but to your family, you are the world. -Unknown",
  },
  {
    pregnancy_day: 235,
    info: "My mother is my root, my foundation. She planted the seed that I base my life on ... the belief that the ability to achieve starts in your mind. -Michael Jordan",
  },
  {
    pregnancy_day: 236,
    info: "There is no greater good in all the world than motherhood. The influence of a mother in the lives of her children is beyond calculation. -James E. Faust",
  },
  {
    pregnancy_day: 237,
    info: "If you want to lift yourself up, lift up someone else. –Booker T. Washington",
  },
  {
    pregnancy_day: 238,
    info: "Motherhood is not a hobby; it is a calling. -Neil L. Andersen",
  },
  {
    pregnancy_day: 239,
    info: "Your greatest contribution to the kingdom of God may not be something you do but someone you raise. -Andy Stanley",
  },
  {
    pregnancy_day: 240,
    info: "As a mother’s prayers for her children are unending, so are the wisdom, grace, and strength they provide to her children. -George W. Bush",
  },
  {
    pregnancy_day: 241,
    info: "Life is what we make it, always has been, always will be. –Grandma Moses",
  },
  {
    pregnancy_day: 242,
    info: "Change your thoughts and you change your world. –Norman Vincent Peale",
  },
  {
    pregnancy_day: 243,
    info: "God gave you a gift of 86,400 seconds today. Have you used one to say thank you? - William A. Ward",
  },
  {
    pregnancy_day: 244,
    info: "Whenever I held my newborn baby in my arms, I used to think that what I said and did to him could have an influence not only on him, but on all whom he met, not only for a day, a month, or a year – but for an eternity – a very challenging and exciting thought for a mother. - Rose Kennedy",
  },
  {
    pregnancy_day: 245,
    info: "Speak to your children as if they are the wisest, kindest, most beautiful and magical humans on earth, for what they believe is what they will become. - Brooke Hampton",
  },
  {
    pregnancy_day: 246,
    info: "If you are a mom, you are a superhero, period. - Rosie Pope",
  },
  {
    pregnancy_day: 247,
    info: "Three things remain with us from paradise: stars, flowers and children. - Dante Alighieri",
  },
  {
    pregnancy_day: 248,
    info: "It is the most powerful creation to have life growing inside of you. There is no bigger gift. - Beyoncé",
  },
  {
    pregnancy_day: 249,
    info: "Pregnancy can be a time when you take tremendous pleasure in eating, not only because you may enjoy food more but also because you know that it is nourishing both you and your baby. - Martha Rose and Jane L. Davis",
  },
  {
    pregnancy_day: 250,
    info: "As we express our gratitude, we must never forget that the highest appreciation is not to utter words but to live by them. - John F. Kennedy",
  },
  {
    pregnancy_day: 251,
    info: "Gratitude turns what we have into enough, and more. It turns denial into acceptance, chaos into order, confusion into clarity...it makes sense of our past, brings peace for today, and creates a vision for tomorrow. - Melody Beattie",
  },
  {
    pregnancy_day: 252,
    info: "Gratitude and attitude are not challenges; they are choices. - Robert Braathe",
  },
  {
    pregnancy_day: 253,
    info: "A mother is one who can take the place of all others but whose place no one else can take. - Cardinal Meymillod",
  },
  {
    pregnancy_day: 254,
    info: "A mother’s love is the fuel that enables a normal human being to do the impossible. - Marion C. Garretty",
  },
  {
    pregnancy_day: 255,
    info: "Youth fades; love droops; the leaves of friendship fall; A mother’s secret hope outlives them all. - Oliver Wendell Holmes",
  },
  {
    pregnancy_day: 256,
    info: "A mother’s love for her child is like nothing else in the world. It knows no law, no pity. It dares all things and crushes down remorselessly all that stands in its path. – Agatha Christie",
  },
  {
    pregnancy_day: 257,
    info: "Mothers are like glue. Even when you can’t see them, they’re still holding the family together. – Susan Gale",
  },
  {
    pregnancy_day: 258,
    info: "Mother is the heartbeat in the home; and without her, there seems to be no heart throb. – Leroy Brownlow",
  },
  {
    pregnancy_day: 259,
    info: "As we express our gratitude, we must never forget that the highest appreciation is not to utter words, but to live by them. – John F. Kennedy",
  },
  {
    pregnancy_day: 260,
    info: "Thankfulness is the beginning of gratitude. Gratitude is the completion of thankfulness. Thankfulness may consist merely of words. Gratitude is shown in acts. – Henri Frederic Amiel",
  },
  {
    pregnancy_day: 261,
    info: "Sometimes the strength of motherhood is greater than natural laws. – Barbara Kingsolver",
  },
  {
    pregnancy_day: 262,
    info: "The heart of a mother is a deep abyss at the bottom of which you will always find forgiveness. – Honore de Balzac",
  },
  {
    pregnancy_day: 263,
    info: "God could not be everywhere, and therefore he made mothers. – Rudyard Kipling",
  },
  {
    pregnancy_day: 264,
    info: "A mother’s arms are more comforting than anyone else’s. – Princess Diana",
  },
  {
    pregnancy_day: 265,
    info: "If you concentrate on finding whatever is good in every situation, you will discover that your life will suddenly be filled with gratitude, a feeling that nurtures the soul. – Rabbi Harold Kushner",
  },
  {
    pregnancy_day: 266,
    info: "Feeling gratitude and not expressing it is like wrapping a present and not giving it. – William Arthur Ward",
  },
  {
    pregnancy_day: 267,
    info: "Reflect upon your present blessings—of which every man has many—not on your past misfortunes, of which all men have some. – Charles Dickens",
  },
  {
    pregnancy_day: 268,
    info: "The only love that I really believe in is a mother’s love for her children. – Karl Lagerfeld",
  },
  {
    pregnancy_day: 269,
    info: "Whether your pregnancy was meticulously planned, medically coaxed, or happened by surprise, one thing is certain—your life will never be the same. – Catherine Jones",
  },
  {
    pregnancy_day: 270,
    info: "Having children just puts the whole world into perspective. Everything else just disappears. – Kate Winslet",
  },
  {
    pregnancy_day: 271,
    info: "You can’t use up creativity. The more you use, the more you have. – Maya Angelou",
  },
  {
    pregnancy_day: 272,
    info: "For my part, I am almost content just now, and very thankful. Gratitude is a divine emotion: it fills the heart, but not to burst; it warms it, but not to fever. – Charlotte Bronte",
  },
  {
    pregnancy_day: 273,
    info: "Being a mother is an attitude, not a biological relation. – Robert A. Heinlein",
  },
];
export default motivationlist;
