import React from "react";
import configData from "../../config.json";
import { useState, useEffect } from "react";
import { useHistory, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import OTPInput from "../Components/OTPInput";
import AlertShow from "../Components/AlertShow";

var createGuest = require("cross-domain-storage/guest");
var createHost = require("cross-domain-storage/host");
var storageHost = createHost([
  {
    origin: "http://43.205.201.90:3000",
    allowedMethods: ["get", "set", "remove"],
  },
  {
    origin: "http://43.205.201.90:3001",
    allowedMethods: ["get"],
  },
]);

let flag = 0;
function Loginform() {
  const navigate = useNavigate();

  const [isPhoneTaken, setIsPhoneTaken] = useState(false);
  const [redphone, setRedPhone] = useState(false);
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });

  const [otpVerify, setOTPVerify] = useState(false);
  const [invalidOTP, setInvalidOTP] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [timer, setTimer] = useState(90); // Initial timer value in seconds
  const [isTimerActive, setIsTimerActive] = useState("");

  const [verifiedno, setVerified] = useState(false);
  const [otp, setOtp] = useState("");
  const [resetKey, setResetKey] = useState(0);

  const [showAlert, setShowAlert] = useState(false);
  const [severity, setSeverity] = useState("");
  const [alertMsg, setAlertMsg] = useState("");

  useEffect(() => {
    let interval;
    if (isTimerActive && timer > 0) {
      interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
    } else {
      clearInterval(interval);
      setIsTimerActive(false);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isTimerActive, timer]);

  const isMobileNumber = (value) => {
    return /^\d{10}$/.test(value);
  };
  const isEmailvalid = (value) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  };
  const generateOTP = () => {
    const digits = "0123456789";
    let OTP = "";
    for (let i = 0; i < 6; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }

    setOtp(OTP);
    setIsTimerActive(true);
    setTimer(90); // Reset timer to initial value when OTP is generated
    return OTP;
  };
  const sendSMS = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${configData.SERVER_API_URL}/users/is_phone_valid`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ phone: loginForm.email }),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        console.log("Response Data:", responseData);

        // Update isPhoneTaken state based on API response
        setIsPhoneTaken(!responseData.user);

        if (responseData.user === false) {
          setRedPhone(true);
        } else {
          var msg = "Phone number is not Registered.";
          const error_color = "error";
          handleAlertMsg(error_color, msg);
          // alert("Phone number is not Registered.");
          setRedPhone(false);
        }
      } else {
        var msg = "Phone number validation failed";
        const error_color = "error";
        handleAlertMsg(error_color, msg);
        // console.log("Phone number validation failed");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
    if (redphone == true) {
      setIsTimerActive(true);
      const otp = generateOTP();
      console.log("Generated OTP:", otp, loginForm.email);

      try {
        const response = await axios.post(
          `${configData.SERVER_API_URL}/users/sendOtp`,
          {
            phone: loginForm.email,
            otp: otp,
          }
        );

        if (response && response.data) {
          if (response.data.success) {
            var msg = "Message sent successfully";
            const error_color = "success";
            handleAlertMsg(error_color, msg);
            // alert("Message sent successfully");
          } else if (response.data.error) {
            console.error("Error in sending message:", response.data.error);
          } else {
            console.error("Unexpected response structure:", response.data);
          }
        } else {
          console.error("Unexpected response:", response);
        }
      } catch (error) {
        console.error("Error fetching details:", error.message);
      }
    }
  };

  const handleOTPComplete = (otp1) => {
    console.log("OTP Entered:", otp1);
    // handleVerifyOTP(otp);
    console.log("OOOOTTTPPP", otp1, otp, flag);
    checkpw(otp1);
  };
  const checkpw = (otpentered) => {
    if (otpentered === otp) {
      flag = 1;
      setVerified(true);
      setInvalidOTP(false);
      //  setTimeout(() => {
      //    alert("Mobile number is verified");
      //  }, 2000);

      // handleLogin();
    } else {
      setVerified(false);
      var msg = "Invalid otp Please try again";
      const error_color = "success";
      handleAlertMsg(error_color, msg);
      // alert("Invalid otp Please try again");
      setInvalidOTP(true);
      resetOtpFields();
    }
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    const bodyData = {};
    if (flag == 1) {
      bodyData.phone = loginForm.email;
      bodyData.role_id = 1;
    } else {
      const { email, password } = loginForm;
      bodyData.email = email;
      bodyData.password = password;
      bodyData.role_id = 1;
    }

    try {
      console.log("{LOGINFORM====}", bodyData);
      const response = await fetch(`${configData.SERVER_API_URL}/users/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyData),
      });

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.success === true) {
          console.log("Patient-ID", responseData.user.id, responseData.user);
          localStorage.setItem("userId", responseData.user.id);
         localStorage.setItem("is_casepaper", responseData.user.case_paper_status);
          var msg = "Login successful...";
          const error_color = "success";
          handleAlertMsg(error_color, msg);
          const userId = responseData.user.id;
          navigate(`/?userId=${userId}`);
        } else {
          var msg = "You have entered incorrect credentials";
          const error_color = "error";
          handleAlertMsg(error_color, msg);
        }
      } else {
        var msg = "You have Entered incorrect credential Please try again";
        const error_color = "error";
        handleAlertMsg(error_color, msg);
        // alert("You have Entered incorrect credential Please try again");
        console.log("Login failed");
      }
    } catch (error) {
      console.log("An error occurred:", error);
    }
  };

  const handleAlertMsg = (error_type, msg) => {
    setShowAlert(true);
    setSeverity(error_type);
    setAlertMsg(msg);
    setTimeout(() => {
      setShowAlert(false);
    }, 2500);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  const resetOtpFields = () => {
    setOtp(["", "", "", "", "", ""]); // Reset the OTP fields
    setResetKey((prevKey) => prevKey + 1);
  };
  const navigatetoweb = () => {
    navigate("/");
  };
  const handleforgetpassword = () => {
    localStorage.setItem("forget-password", "patient");
    navigate("/forget-password");
  };
  return (
    <div
      style={{
        backgroundColor: configData.THEME_COLORS.BACKGROUND,
        width: "100%",
        minHeight: "100vh",
        paddingBottom: "50px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="text-center">
        <div>
          <img
            onClick={navigatetoweb}
            src={"/logo-removebg.png"}
            style={{
              resizeMode: "contain",
              height: "160x",
              width: "160px",
              marginLeft: "20px",
              cursor: "pointer",
            }}
            width={200}
            alt="Removebg"
          />
        </div>
      </div>
      <div
        className="text-center my-auto"
        style={{ top: -30, position: "relative" }}
      >
        <form
          className="w-50 bg-white mx-auto"
          style={{
            borderRadius: "12px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h4 className="fw-bolder py-4">Log in</h4>
          <p className="w-50 mx-auto" style={{ color: "#bac1cb" }}>
            Welcome to Myprega app. Please put your login credentials below to
            start using App.
          </p>
          <input
            type="text"
            className="my-4"
            placeholder="Enter email or mobile number"
            style={{
              width: "50%",
              height: "45px",
              borderRadius: "12px",
              border: "1px solid black",
              padding: 10,
            }}
            value={loginForm.email} // Set the email value from loginForm state
            onChange={(e) =>
              setLoginForm({
                ...loginForm,
                email: e.target.value, // Update the email field
              })
            }
            title="Please enter a valid phone number"
          />

          {isEmailvalid(loginForm.email) && (
            <input
              type="password"
              className="mb-2"
              placeholder="Enter Password"
              style={{
                width: "50%",
                height: "45px",
                borderRadius: "12px",
                border: "1px solid black",
                padding: 10,
              }}
              value={loginForm.password} // Set the password value from loginForm state
              onChange={(e) =>
                setLoginForm({
                  ...loginForm,
                  password: e.target.value, // Update the password field
                })
              }
            />
          )}

          {isEmailvalid(loginForm.email) && (
            <div className="mx-auto w-50">
              <div
                className="text-end"
                style={{
                  color: "#ee5e8f",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "auto",
                }}
              >
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={handleforgetpassword}
                >
                  Forgot password?
                </div>
              </div>
            </div>
          )}
          <div
            style={{
              marginTop: !isMobileNumber(loginForm.email) ? "12px" : -40,
            }}
            id="recaptcha-container"
          ></div>
          <div className="text-end mt-4" style={{ width: "50%" }}>
            {isMobileNumber(loginForm.email) && verifiedno == false ? (
              <>
                <span
                  className="mt-4 mx-auto"
                  style={{
                    fontSize: "13px",
                    cursor: "pointer",
                    color: "black",
                  }}
                  onClick={(e) => {
                    if (loginForm.email === "") {
                      var msg = "Please enter contact number!";
                      const error_color = "error";
                      handleAlertMsg(error_color, msg);
                      // alert("Please enter contact number!");
                      return;
                    }
                    setIsClicked(true);
                    sendSMS(e);
                  }}
                  onMouseOver={(e) => {
                    e.target.style.color = "#ee5e8f"; // Change the color on hover
                  }}
                  onMouseOut={(e) => {
                    e.target.style.color = "black"; // Reset the color when not hovering
                  }}
                >
                  {timer === 0
                    ? "Resend OTP"
                    : invalidOTP == true
                    ? "Resend OTP"
                    : "Send OTP"}
                </span>
                <p className="mt-3" style={{ fontSize: "13px" }}>
                  {isTimerActive == true
                    ? `OTP expires in ${timer} seconds`
                    : isTimerActive == false
                    ? timer == 0
                      ? "OTP expired"
                      : ""
                    : ""}
                </p>
              </>
            ) : verifiedno == true ? (
              <></>
            ) : (
              ""
            )}
            <div style={{ marginTop: "20px", marginBottom: "10px" }}>
              {isMobileNumber(loginForm.email) &&
                isTimerActive == true &&
                verifiedno == false && (
                  <OTPInput
                    onComplete={handleOTPComplete}
                    reset={invalidOTP}
                    resetKey={resetKey}
                  />
                )}
            </div>
          </div>
          {/* {isMobileNumber(loginForm.email) && ( */}
          <div className="d-flex align-items-center justify-content-between w-50 mx-auto">
            <div>
              <input type="checkbox"></input>
              <span className="ps-2">Remember me</span>
            </div>
            <div>
              <button
                className="my-4 login"
                style={{
                  width: "120px",
                  height: "40px",
                  borderRadius: "12px",
                  cursor: otpVerify ? "pointer" : "auto",
                  backgroundColor: "#007bff",
                  color: "white",
                }}
                onClick={handleLogin} // Call the handleLogin function to perform login
                // disabled={
                // loginForm.password == "" || loginForm.email == ""
                // !otpVerify
                // }
              >
                Login
              </button>
            </div>
          </div>
          {/* )} */}
          <div className="py-4" style={{ color: "#bac1cb" }}>
            Don't have an account?
            <span
              className="ps-2"
              style={{ color: "#ee5e8f", cursor: "pointer" }}
              onClick={() => navigate("/patient/Signup")}
            >
              Sign up
            </span>
          </div>
        </form>
      </div>
      <AlertShow
        alertShowHide={showAlert}
        alertClose={handleCloseAlert}
        setalertMsg={alertMsg}
        severity={severity}
      />
    </div>
  );
}

export default Loginform;
