import { useNavigate } from "react-router-dom";
import configData from "../../config.json";
import { useMediaQuery } from "react-responsive";

const DailyActivity = () => {
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const wrapper = {
    marginLeft: isTabScreen ? "20px" : "50px",
    marginRight: isTabScreen ? "20px" : "50px",
    paddingLeft: isTabScreen ? "20px" : "50px",
    paddingBottom: "50px",
    background: "#FBFBFB",
    borderRadius: "12px",
    paddingTop: "30px",
    marginBottom: "50px",
  };
  const navigate = useNavigate();
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={wrapper}>
          <div
            style={{
              fontSize: "12px",
              fontWeight: "600",
              color: configData.THEME_COLORS.PRIMARY,
              cursor: "pointer",
            }}
            onClick={() => {
              navigate({
                pathname: "/patient/Garbha_Sanskar",
                state: 1,
              });
            }}
          >
            Back
          </div>

          <div
            style={{
              marginTop: "10px",
              fontSize: "24px",
              fontWeight: "600",
              color: configData.THEME_COLORS.SECONDARY,
            }}
          >
            Activity of the Day
          </div>
          <div
            style={{
              fontSize: isTabScreen ? "20px" : "24px",
              fontWeight: "600",
              marginTop: "37px",
              marginLeft: "12px",
            }}
          >
            Activity Name
          </div>
          <div
            style={{
              fontSize: "16px",
              fontWeight: "400",
              marginTop: "15px",
              paddingRight: "10px",
              marginLeft: "12px",
            }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </div>

          <img
            src={"/GarbhaSanskar/Youtube-player.png"}
            style={{
              height: "450px",
              width: "80%",
              marginTop: "40px",
              marginBottom: "80px",
              marginLeft: "12px",
            }}
          ></img>
        </div>
      </div>
    </>
  );
};

export default DailyActivity;
