import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Alert } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

const Warningpopup = ({ open, handleclose, message, handleaccept }) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleclose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{
            height: 100,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Alert severity={"warning"}>{message}</Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleclose}>No</Button>
          <Button onClick={handleaccept} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Warningpopup;
