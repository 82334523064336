import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";

const Enterpassword = ({ handlepassowrdinsert, handleprevious }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  // Function to validate the password requirements
  const validatePassword = (password) => {
    const lengthCheck = password.length >= 8;
    const numberCheck = /[0-9]/.test(password);
    const capitalLetterCheck = /[A-Z]/.test(password);
    return lengthCheck && numberCheck && capitalLetterCheck;
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    // Validate the password
    if (!validatePassword(newPassword)) {
      setError(
        "Password must be at least 8 characters, include a number, and a capital letter."
      );
    } else {
      setError("");
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if passwords match
    if (password.trim() == "") {
      setError("Password is required.");
    } else if (password !== confirmPassword) {
      setError("Passwords do not match.");
    } else if (validatePassword(password)) {
      handlepassowrdinsert(password);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 2000);
  }, [error]);

  return (
    <form
      style={{
        borderRadius: "12px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
      onSubmit={handleSubmit}
    >
      <h4 className="fw-bolder py-4">Change Password</h4>

      <input
        type="password"
        className="my-1 form-control"
        placeholder="Enter Password"
        style={{
          width: "50%",
          height: "45px",
          borderRadius: "12px",
          border: "1px solid black",
          padding: 10,
        }}
        value={password}
        onChange={handlePasswordChange}
      />

      <input
        type="password"
        className="my-1 form-control"
        placeholder="Confirm Password"
        style={{
          width: "50%",
          height: "45px",
          borderRadius: "12px",
          border: "1px solid black",
          padding: 10,
        }}
        value={confirmPassword}
        onChange={handleConfirmPasswordChange}
      />

      {error && <div style={{ color: "red", marginTop: "10px" }}>{error}</div>}

      <div className="d-flex align-items-center justify-content-end w-50 mx-auto">
        <div>
          <button
            type="submit"
            className="my-4 login"
            style={{
              padding: "10px 20px ",
              borderRadius: "12px",
              backgroundColor: "#007bff",
              color: "white",
            }}
          >
            Save
          </button>
        </div>
      </div>

      <div className="py-4" style={{ color: "#bac1cb" }}>
        Back to
        <span
          className="ps-2"
          style={{ color: "#ee5e8f", cursor: "pointer" }}
          onClick={handleprevious}
        >
          Login
        </span>
      </div>
    </form>
  );
};

export default Enterpassword;
