import React from "react";
import moment from "moment";

const Wantschedule = ({
  e,
  selectedschedule,
  handlereshedule,
  isTabScreen,
}) => {
  if (!e.is_completed) {
    const scheduleDate = moment(e.scheduled_date);
    const currentDate = moment().startOf("day");
    const oneDayBefore = scheduleDate.clone().subtract(1, "days");
    if (currentDate.isBefore(oneDayBefore)) {
      return (
        <button
          disabled={e?.show_msg === 1}
          style={{
            width: "81px",
            height: "38px",
            marginTop: "10px",
            fontSize: isTabScreen ? "8px" : "10px",
            fontWeight: "400",
            border: "none",
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: e?.id === selectedschedule ? "#F0588B" : "#7C9CBF",
            boxShadow:
              e?.id === selectedschedule
                ? "inset 0px 4px 8px rgba(44, 39, 56, 0.078)"
                : "0px 4px 8px rgba(44, 39, 56, 0.078)",
          }}
          onClick={() => {
            handlereshedule(e);
          }}
        >
          Want Re-Schedule
        </button>
      );
    }
  }

  return null;
};

export default Wantschedule;
