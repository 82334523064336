import "./App.css";
import AppRoutes from "./Routes";
import "./patient/Components/Calendar.css";
import "./patient/assets/style.css";
import "./patient/fonts/Poppins-Regular.ttf";
// import "./fonts.css";
function App() {
  return (
    <div className="">
      <header className="">
        <AppRoutes />
      </header>
    </div>
  );
}

export default App;
