import React, { useState } from "react";
import configData from "../../config.json";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import { downloadFile } from "../../common/utils/fileUtils";
import PrescriptionData from "../../common/Prescriptiondata";

function Rightsidereport({ consultationdata }) {
  const [prescriptiondata, setPrescriptiondata] = useState([]);
  const [showprescriptionmodel, setShowprecriptionmodal] = useState(false);
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const d = moment().format("DD MMMM YYYY");
  console.log("++", consultationdata);
  const headingconsultation = {
    paddingTop: "25px",
    fontSize: isTabScreen ? "20px" : "24px",
    color: "#004AAD",
    fontFamily: "Poppins",
    fontWeight: "500",
    textAlign: "center",
  };
  const date = {
    paddingTop: "20px",
    fontSize: isTabScreen ? "14px" : "18px",
    color: "#004AAD",
    fontWeight: "400",
  };
  const paddingleft = {
    paddingLeft: "20px",
    paddingRight: "20px",
  };
  const p = {
    fontSize: isTabScreen ? "14px" : "20px",
    fontWeight: "600",
    paddingTop: "2px",
    color: "#333333",
  };
  const p1 = {
    fontSize: isTabScreen ? "14px" : "18px",
    fontWeight: "400",
    paddingTop: "3px",
    color: "#333333",
  };
  const pres = {
    fontSize: isTabScreen ? "14px" : "20px",
    fontWeight: "600",
    paddingTop: "40px",
    color: "#333333",
    paddingBottom: "20px",
  };
  const imgreport = {
    width: isTabScreen ? "80px" : "110px",
    height: isTabScreen ? "90px" : "143px",
  };

  const viewreport = () => {
    let fileKey = consultationdata.fileKey;
    if (fileKey) {
      downloadFile(fileKey);
    }
  };
  const viewprescription = () => {
    api
      .getprescriptiondata({
        consultation_id: consultationdata?.id,
      })
      .then((res) => {
        if (res.success) {
          setShowprecriptionmodal(true);
          setPrescriptiondata({
            data: consultationdata,
            medicine: res.Prescrption,
          });
        }
      })
      .catch((rej) => {
        console.log(rej);
      });
  };
  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          paddingTop: "48px",
          fontSize: isTabScreen ? "24px" : "30px",
          color: configData.THEME_COLORS.PRIMARY,
          fontFamily: "Poppins",
          fontWeight: "500",
          color: "#F0588B",
          textAlign: "center",
        }}
      >
        Prescription & Reports
      </div>
      <hr className="w-100"></hr>
      <div style={paddingleft}>
        <div style={headingconsultation}>{consultationdata.title}</div>
        <div style={date}>{d} </div>
        <div style={p}>
          Consulted Dr.{consultationdata?.doctor_firstName}{" "}
          {consultationdata?.doctor_lastName} for gynaec test
        </div>
        <div style={p1}>
          Dr. {consultationdata?.doctor_firstname}{" "}
          {consultationdata?.doctor_lastname} shared 2 records for Mrs.
          {consultationdata.first_name} {consultationdata?.last_name}
        </div>
        <div className="d-flex aling-items-center justify-content-between">
          {consultationdata.is_precription && (
            <div>
              <div style={pres}>Prescription</div>
              <button
                className="btn btn-primary btn-sm"
                onClick={viewprescription}
              >
                View Prescription
              </button>
            </div>
          )}
          {consultationdata.fileKey && (
            <div>
              <div style={pres}>Report</div>
              <button className="btn btn-primary btn-sm" onClick={viewreport}>
                View Report
              </button>
            </div>
          )}
        </div>
      </div>
      {showprescriptionmodel && (
        <PrescriptionData
          open={showprescriptionmodel}
          data={prescriptiondata}
          onClose={() => {
            setShowprecriptionmodal(false);
            setPrescriptiondata([]);
          }}
        />
      )}
    </div>
  );
}

export default Rightsidereport;
