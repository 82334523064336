import React, { useState } from "react";
import configData from "../config.json";
import { useMediaQuery } from "react-responsive";
function EventTypeHeader({ title }) {
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1200px)",
  });

  return (
    <div
      style={{
        fontSize: isTabScreen ? "16px" : "24px",
        marginBottom: "10px",
        fontWeight: "500",
        fontFamily: "Poppins",
        color: configData.THEME_COLORS.SECONDARY,
      }}
    >
      {title}
    </div>
  );
}

export default EventTypeHeader;
