import React, { useState } from "react";
import myreadbooklist from "./myreadbooklist";
import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";
import configData from "../../../config.json";
import axios from "axios";

<link
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
  rel="stylesheet"
  integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
  crossorigin="anonymous"
></link>;

let testdata;
function MyReadBook({ setExpand, setSelected }) {
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const wrapper = {
    marginLeft: isTabScreen ? "20px" : "50px",
    marginRight: isTabScreen ? "20px" : "50px",
    paddingLeft: isTabScreen ? "20px" : "50px",
    paddingBottom: "50px",
    background: "#FBFBFB",
    borderRadius: "12px",
    paddingTop: "30px",
    marginBottom: "50px",
    height: window.innerHeight,
    overflowY: "scroll",
  };

  const back = {
    color: "#004AAD",
    fontSize: "12px",
    fontWeight: "600",
    border: "none",
    backgroundColor: "#FBFBFB",
    borderRadius: "12px",
    cursor: "pointer",
  };
  const heading = {
    color: "#F0588B",
    fontSize: isTabScreen ? "20px" : "24px",
    fontWeight: "600",
    paddingTop: "10px",
  };
  const innercol = {
    marginTop: "30px",
    cursor: "pointer",
  };
  const innerrow = {
    paddingRight: "50px",
  };
  const testnm = {
    fontSize: "14px",
    fontWeight: "400",
    color: "#004AAD",
    height: "90px",
    width: "120px",
    backgroundColor: "#FBFBFB",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const head = {
    paddingTop: "30px",
    paddingBottom: "8px",
    fontSize: "20px",
    fontWeight: "700",
    color: "#333333",
  };
  const head2 = {
    paddingTop: "10px",
    paddingBottom: "15px",
    fontSize: "16px",
    fontWeight: "700",
    color: "#333333",
  };
  const para = {
    paddingBottom: "10px",
    fontFamily: "poppins",
    fontSize: "16px",
    fontWeight: "400",
    color: "#333333",
  };
  const testimg = {
    width: "300px",
    height: "300px",
    margin: "0px auto",
  };
  const [testinfo, settestinfo] = useState(false);
  const [patientdata, setPatientdata] = useState([]);
  const handletestinfo = (r) => {
    settestinfo(true);
    testdata = r;
  };
  function backtotestlist() {
    settestinfo(false);
  }
  useEffect(() => {
    getsinglepatientdetail();
    console.log("<><><><>", patientdata);
  }, []);
  const pregnancyDays = patientdata.pregnancy_days;
  var months = Math.ceil(pregnancyDays / 28);
  console.log("<><><><><>", months);
  const getsinglepatientdetail = async () => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/users/get_single_user`,
        {
          userId: localStorage.getItem("userId"),
        },
      );

      if (response.data.success) {
        console.log("get single user successfully", response.data.user);
        setPatientdata(response.data.user);
      } else {
        console.error(
          "Error adding medical registration details:",
          response.data.error,
        );
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };
  function renderMonthData(monthId) {
    switch (monthId) {
      case 1:
        return (
          <div>
            <h6>First Month of Pregnancy:</h6>
            <ul>
              <li>
                A women becomes aware that she is pregnant when her menstrual
                cycle stops.
              </li>
              <li>
                The foetus first resembles a bubble, then after one month, it
                thickens to resemble a rice grain.
              </li>
              <li>
                On the fourteenth day, the brain neurons start to grow up to 2.5
                lakhs every minute.
              </li>
              <li>
                From the fourth week bones, muscles, intestines etc. are ready
                to develop.
              </li>
              <li>
                Till the second month, the woman does not know that she is
                pregnant but she should start GARBHSANSKAR when she thinks of
                conceiving a child. That is, during this time, reading of good
                books, religious activities, etc should be started in the daily
                life.
              </li>
              <li>
                It is essential to consume just a healthy, home-cooked food and
                to abstain from consuming outside food.
              </li>
              <li>
                The body, mind, emotions, desires of the mind may face some
                small changes, often feel tiredness and panic due to great
                fluctuations in hormones but these are all normal.
              </li>
              <li>
                Now is the time to start your everyday’s garbhsanskar
                activities.
              </li>
            </ul>
          </div>
        );
      case 2:
        return (
          <div>
            <h6>Second Month of Pregnancy :</h6>
            <ul>
              <li>
                The baby's little arms and legs start to form during this month.
                Other organ development starts on a tiny scale.
              </li>
              <li>
                The eye begins to develop but does not open, and the hearing
                power begins to function.
              </li>
              <li>Tissues starts to connect with the brain.</li>
              <li>
                The pregnant lady should avoid bending over or bending forward,
                staying up too long, and using inappropriate or imbalanced
                seated techniques at this time.
              </li>
              <li>Avoid long travel, exercise, heavy weight lifting.</li>
              <li>
                Increase intake of dates, figs, grapes, dry fruits, milk
                products, sheera, etc. in daily diet.
              </li>
              <li>
                Should start Praying, listening to music & foetus conversation.
              </li>
            </ul>
          </div>
        );

      case 3:
        return (
          <div>
            <h6>Third Month of Pregnancy :</h6>
            <ul>
              <li>The embryo becomes a fetus when you’re 3 months pregnant.</li>
              <li>
                The umbilical cord connects the foetus to your placenta and
                uterine wall.
              </li>
              <li>
                In the third month, the child’s sensory and harmonious formation
                is created, the child's sensory system starts working, whatever
                the mother feels, hears, speaks and sees affects the child
                directly.
              </li>
              <li>The eyelids are starting to develop.</li>
              <li>
                Mothers should steer clear of salty and spicy foods during this
                period as the child's taste buds are developing. Additionally,
                consume a pure, healthy & nutritious diet.
              </li>
              <li>Always be happy.</li>
              <li>
                Now you have to nourish yourself and your baby in the womb, so
                you should change your diet and include cow's milk, kheer,
                pudding, moong, coconut water etc.
              </li>
              <li>
                Since hearing ability is also developing at this time, play good
                music or prayer for the infant.
              </li>
              <li>Increase the amount of milk & milk products in the diet.</li>
              <li>Add proteins, vitamins & minerals in the diet.</li>
              <li>
                The mother must pay extra attention to maintaining her mental
                stability because the brain begins to develop at this period.
                She must adopt an optimistic mindset. Keep your tension and fury
                in check.
              </li>
            </ul>
          </div>
        );

      case 4:
        return (
          <div>
            <h6>Fourth Month of Pregnancy :</h6>
            <ul>
              <li>
                Your 2nd trimester begins during your 4th month of pregnancy.
              </li>
              <li>The roof of the mouth is formed.</li>
              <li>
                The head piece is rapidly developed throughout this period. The
                face is more discernible.
              </li>
              <li>
                Skin starts to develop sweat glands and becomes pink as a
                result.
              </li>
              <li>
                The rectilinear also develops, so child knows the taste of the
                food taken by the mother.
              </li>
              <li>
                The taste and aroma of the mother's food is adopted by child
                which lives throughout its life, so the mother should start
                liking the taste and aroma of pure and nutritious food.
              </li>
              <li>
                Include butter, yogurt, rice, green vegetables, pomegranate,
                orange, citrus and grapes in the diet.
              </li>
            </ul>
          </div>
        );

      case 5:
        return (
          <div>
            <h6>Fifth Month of Pregnancy :</h6>
            <ul>
              <li>
                When you’re 5 months pregnant, your foetus develops soft body
                hair and a greasy coating that helps protect the skin.
              </li>
              <li>Liver is ready by the fifth month.</li>
              <li>
                The child's touch glands start to operate properly during the
                fifth month. The mother's touch causes the foetus to react. The
                foetus's movement is also felt by the mother.
              </li>
              <li>Talk to a child while putting your hand on the stomach.</li>
              <li>
                During this time, the kid picks up on all of the mother's
                feelings and responds to them, so that the mother should stay
                happy, sweet heartened, jealousy free and loving.
              </li>
            </ul>
          </div>
        );

      case 6:
        return (
          <div>
            <h6>Sixth Month of Pregnancy :</h6>
            <ul>
              <li>
                During the 6th month, your foetus starts to develop blood cells,
                taste buds, eyebrows and eyelashes.
              </li>
              <li>Bone marrow starts making blood cells.</li>
              <li>Taste buds begin to form.</li>
              <li>In this month the child’s intelligence is developed.</li>
              <li>
                All the muscles and tissues of the body grow and the infant
                becomes more powerful.
              </li>
              <li>
                As the child's intelligence grows at this time, mom should play
                intellectual games, think about math, figure out simple puzzles,
                and become involved in or be amused by art projects.
              </li>
              <li>
                Besides eating food, take fruits like bananas, apples, chikoo,
                oranges, citrus and grapes in the diet.
              </li>
            </ul>
          </div>
        );

      case 7:
        return (
          <div>
            <h6>Seventh Month of Pregnancy :</h6>
            <ul>
              <li>
                The foetus develops more and more fat from now until the end of
                pregnancy.
              </li>
              <li>
                Your uterus continues to expand when you’re 7 months pregnant.
              </li>
              <li>
                Back pain is common. Pregnancy symptoms from earlier months
                continue. Dizziness may lessen.
              </li>
              <li>
                A developing infant begins to use all four senses at this stage,
                including vision, hearing, taste, and touch.
              </li>
              <li>
                During this month, the nerves that allow newborns to regulate
                their breathing and body temperature are developing.
              </li>
              <li>
                In this month, to increase the infant’s weight, start taking
                milk, ghee, dried fruit etc.
              </li>
              <li>
                Think positive, read the life stories of great men, and pray to
                GOD for planting good qualities in a child.
              </li>
            </ul>
          </div>
        );
      case 8:
        return (
          <div>
            <h6>Eighth Month of Pregnancy :</h6>
            <ul>
              <li>Lungs get developed rapidly.</li>
              <li>
                The child's muscles get stronger during this month, and internal
                strengths also rise. The infant's nerves are fully prepared.
              </li>
              <li>
                During this month, the weight of infants is increased to maximum
                extent.
              </li>
              <li>
                The mother should take special care to prevent delivery in this
                period
              </li>
              <li>
                You may feel tired and have a more difficult time breathing as
                your uterus grows upward.
              </li>
              <li>
                Avoid travel, don't keep your legs hanging, do not over-stand,
                do not overwork.
              </li>
              <li>
                Do not take too heavy and extremely cold stuff. Take special
                care to prevent constipation or stomach problems.
              </li>
              <li>
                Do not take potatoes, brinjal, cucumber, cluster beans in your
                diet to avoid gastric problems.
              </li>
              <li>
                Have faith and pray to God for having easy going child delivery.
              </li>
            </ul>
          </div>
        );
      case 9:
        return (
          <div>
            <h6>Ninth Month of Pregnancy :</h6>
            <ul>
              <li>
                Your foetus has better developed eyes, larger pupils, and more
                body fat at 9 months pregnant.
              </li>
              <li>In the ninth month the foetus becomes fully developed.</li>
              <li>Mother is mostly preparing to deliver baby.</li>
              <li>Baby's head falls downwards in the womb.</li>
              <li>
                One teaspoon of castor oil is frequently taken at this period
                with milk or tea for the ease of childbirth.
              </li>
              <li>
                In this month, avoid taking outside food and fermented foods.
              </li>
              <li>
                In the beginning of this month, prepare your hospital &
                pregnancy bag.
              </li>
              <li>
                Do not panic or worry at this last time. Be relaxed and happy.
              </li>
              <li>
                Better to have an experienced woman with you. Your best
                offspring will be playing in your arms in just a few days.
              </li>
            </ul>
          </div>
        );
      case 10:
        return (
          <div>
            <h6>Tenth Month of Pregnancy :</h6>
            <ul>
              <li>
                Your foetus enters its last stage of development at 10 months
                pregnant, at which point you give birth.
              </li>
              <li>
                Your uterus will have grown from your pelvis to the base of your
                rib cage by the end of your pregnancy.
              </li>
              <li>
                Pregnancy symptoms in the tenth month largely depend on when the
                fetus drops down into the lower part of your uterus.
              </li>
              <li>
                When the foetus lowers, shortness of breath, heartburn, and
                constipation typically become better.
              </li>
              <li>
                The placenta and other tissues leave your body after the birth
                of your child. The term "afterbirth" refers to this.
              </li>
              <li>
                In this month, avoid taking outside food and fermented foods.
              </li>
              <li>
                One teaspoon of castor oil is frequently taken at this period
                with milk or tea.
              </li>
            </ul>
          </div>
        );
    }
  }
  return (
    <div style={wrapper}>
      <div>
        {testinfo === true ? (
          <>
            <div style={back} onClick={() => backtotestlist()}>
              BACK
            </div>
            {testdata.id == 1 && (
              <>
                <div style={heading}>My Read Book</div>
                <div style={head}>{testdata.testname}</div>
                <div style={head2}>{testdata.testname2}</div>
                <div style={para}>{testdata.info}</div>
                <div style={para}>{testdata.info1}</div>
                <div style={para}>{testdata.info2}</div>
                <div style={head}>{testdata.subheading}</div>
                <div style={para}>{testdata.infosubheaing}</div>
                <div style={head}>{testdata.subsubheading}</div>
                <div style={para}>{testdata.info3}</div>
                <div style={para}>{testdata.p1}</div>
                <div style={para}>{testdata.p2}</div>
                <div style={para}>{testdata.p3}</div>
                <div className="text-center">
                  <img style={testimg} src={testdata.img} alt="..."></img>
                </div>
              </>
            )}
            {testdata.id == 2 && (
              <>
                <div style={heading}>My Read Book</div>
                <div style={head}>{testdata.testname}</div>
                <div style={para}>{testdata.info1}</div>
                <div style={para}>{testdata.info2}</div>
                <div style={para}>{testdata.info3}</div>
                <div style={para}>{testdata.que}</div>
                <div style={para}>{testdata.ans1}</div>
                <div style={para}>{testdata.ans2}</div>
                <div style={para}>{testdata.ans3}</div>
                <div style={para}>{testdata.ans4}</div>
              </>
            )}
            {testdata.id == 3 && (
              <>
                <div style={heading}>My Read Book</div>
                <div style={head}>{testdata.testname}</div>
                {renderMonthData(months)}
              </>
            )}
          </>
        ) : (
          <>
            <div
              style={back}
              onClick={() => {
                setSelected(false);
                setExpand(true);
              }}
            >
              BACK
            </div>
            <div style={heading}>My Read Book</div>
            <div className="container">
              <div className="row" style={innerrow}>
                {myreadbooklist.map((r) => (
                  <div
                    className="col-md-6 col-lg-4"
                    style={innercol}
                    onClick={() => handletestinfo(r)}
                  >
                    <div className="card text-white" style={{ border: "none" }}>
                      <img
                        src={r.img}
                        className="card-img"
                        style={{ height: "400px", width: "400px" }}
                        alt="..."
                      ></img>
                      <div className="card-img-overlay d-flex justify-content-center align-items-center">
                        <div style={testnm} className="px-2 px-lg-5">
                          <div className="card-title text-center">
                            {r.testname}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default MyReadBook;
