import React, { useState } from "react";
import configData from "../../../config.json";
import { useMediaQuery } from "react-responsive";
function DailyReading({ setExpand, setSelected }) {
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const wrapper = {
    marginLeft: isTabScreen ? "20px" : "50px",
    marginRight: isTabScreen ? "20px" : "50px",
    paddingLeft: isTabScreen ? "20px" : "50px",
    paddingBottom: "50px",
    background: "#FBFBFB",
    borderRadius: "12px",
    paddingTop: "30px",
    marginBottom: "50px",
    height: window.innerHeight,
    overflowY: "scroll",
  };
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={wrapper}>
        <div
          style={{
            fontSize: "12px",
            fontWeight: "600",
            color: configData.THEME_COLORS.PRIMARY,
            marginTop: "30px",
            cursor: "pointer",
          }}
          onClick={() => {
            setSelected(false);
            setExpand(true);
          }}
        >
          Back
        </div>

        <div
          style={{
            marginTop: "10px",
            fontSize: isTabScreen ? "20px" : "24px",
            fontWeight: "600",
            color: configData.THEME_COLORS.SECONDARY,
          }}
        >
          Daily Reading
        </div>

        <div
          style={{
            fontSize: isTabScreen ? "2px" : "32px",
            fontWeight: "600",
            alignSelf: "center",
            marginTop: "30px",
            textAlign: "center",
          }}
        >
          Lorem Ipsum
        </div>

        <div
          style={{
            marginTop: "30px",
            alignSelf: "center",
            fontSize: "20px",
            fontWeight: "400",
            height: window.innerHeight / 2,
            width: "90%",
            overflow: "auto",
          }}
        >
          Rutrum quisque non tellus orci ac auctor. Amet venenatis urna cursus
          eget nunc scelerisque. Amet dictum sit amet justo donec enim diam
          vulputate ut. Ultricies tristique nulla aliquet enim tortor at auctor.
          Libero id faucibus nisl tincidunt eget nullam. Vitae proin sagittis
          nisl rhoncus mattis rhoncus urna neque viverra. Quam vulputate
          dignissim suspendisse in est ante in nibh. Tellus cras adipiscing enim
          eu turpis egestas pretium aenean. Velit euismod in pellentesque massa
          placerat duis ultricies lacus. Tellus in hac habitasse platea dictumst
          vestibulum rhoncus. Ornare massa eget egestas purus. Tristique
          senectus et netus et malesuada fames ac. Cras ornare arcu dui vivamus
          arcu felis bibendum ut.
        </div>
      </div>
    </div>
  );
}

export default DailyReading;
