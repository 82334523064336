import { Alert } from "@mui/material";
import React from "react";

const Pathologypatientstatus = ({ data }) => {
  //   console.log("Govind Kumar", data);
  if (!data?.scheduled_date) {
    return null;
  } else if (data?.is_report_uploaded === 1) {
    return <Alert severity="success">Status: Report Uploaded</Alert>;
  } else if (data.sample_confirmation == 1) {
    return <Alert severity="success">Status: Sample Collected</Alert>;
  } else {
    return null;
  }
};

export default Pathologypatientstatus;
