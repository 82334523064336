import React, { useEffect, useState } from "react";
import configData from "../../config.json";
import { useNavigate } from "react-router-dom";
import Generateopt from "./partials/Generateopt";
import Enterotp from "./partials/Enterotp";
import Enterpassword from "./partials/Enterpassword";
import { Alert } from "@mui/material";

const Forgetpassword = () => {
  const navigate = useNavigate();
  const [currenttab, setCurrenttab] = useState("forgetpass");
  const [errors, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [useid, setUserid] = useState();
  const [emaildata, setEmaildata] = useState();
  const navigatetoweb = () => {
    navigate("/");
  };

  const handlegenerateotp = (data) => {
    api
      .sendotp(data?.value ? data : emaildata)
      .then((res) => {
        if (res.success) {
          setUserid(res?.id);
          setSuccess(res?.message);
          setCurrenttab("enterotp");
        }
      })
      .catch((rej) => {
        setError(rej?.response?.data?.error);
      });
    setEmaildata(data);
  };
  const handleprevious = () => {
    console.log("Back");
  };
  const verifyopt = (data) => {
    api
      .verifyotp({
        id: useid,
        otp: parseInt(data),
      })
      .then((res) => {
        if (res.success) {
          setSuccess(res?.message);
          setCurrenttab("enterpass");
        } else {
          setError(res?.message);
        }
      })
      .catch((rej) => {
        setError(rej?.response?.data?.error);
      });
  };
  const handlepassowrdinsert = (data) => {
    api
      .changepassword({
        id: useid,
        password: data,
        accesskey: "5oi1R3p£M9Q5XJy@%onL*9£",
      })
      .then((res) => {
        if (res.success) {
          setSuccess(res?.message);
          let key = localStorage.getItem("forget-password");
          if (key == "doctor") {
            setTimeout(() => {
              navigate("/doctor/Login");
            }, 2000);
          } else {
            setTimeout(() => {
              navigate("/patient/loginform");
            }, 2000);
          }
        } else {
          setError(res?.message);
        }
      })
      .catch((rej) => {
        setError(rej?.response?.data?.error);
      });
  };
  useEffect(() => {
    setTimeout(() => {
      setError(null);
      setSuccess(null);
    }, 2000);
  }, [errors, success]);
  return (
    <div
      style={{
        backgroundColor: configData.THEME_COLORS.BACKGROUND,
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="text-center">
        <div>
          <img
            onClick={navigatetoweb}
            src={"/logo-removebg.png"}
            style={{
              resizeMode: "contain",
              height: "160px",
              width: "160px",
              marginLeft: "20px",
              cursor: "pointer",
            }}
            width={200}
          />
        </div>

        <div
          className="text-center my-auto mt-3"
          style={{ position: "relative" }}
        >
          <div className="w-50 bg-white mx-auto py-4">
            {errors && (
              <div className="px-3">
                <Alert severity="error">{errors}</Alert>
              </div>
            )}
            {success && (
              <div className="px-3">
                <Alert severity="success">{success}</Alert>
              </div>
            )}

            {currenttab == "forgetpass" && (
              <Generateopt
                handlegenerateotp={handlegenerateotp}
                handleprevious={handleprevious}
              />
            )}
            {currenttab == "enterotp" && (
              <Enterotp
                verifyopt={verifyopt}
                handlegenerateotp={handlegenerateotp}
              />
            )}
            {currenttab == "enterpass" && (
              <Enterpassword handlepassowrdinsert={handlepassowrdinsert} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgetpassword;
