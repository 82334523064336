import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Generateopt = ({ handlegenerateotp, handleprevious }) => {
  const [currentType, setCurrentType] = useState("email");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const changetype = () => {
    setCurrentType(currentType === "email" ? "phone" : "email");
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePhoneChange = (value) => {
    const localPhone = value.replace(/^\+\d{1,3}/, "");
    console.log(localPhone);
    setPhone(localPhone);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (currentType === "email") {
      if (email) {
        let data = {
          type: currentType,
          value: email,
        };
        handlegenerateotp(data);
      } else {
        alert("Please enter a valid email address");
      }
    } else {
      if (phone) {
        let data = {
          type: currentType,
          value: phone,
        };
        handlegenerateotp(data);
      } else {
        alert("Please enter a valid phone number");
      }
    }
  };

  return (
    <form
      style={{
        borderRadius: "12px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
      onSubmit={handleSubmit}
    >
      <h4 className="fw-bolder py-4">Forget Password</h4>

      {currentType === "email" && (
        <input
          type="email"
          className="my-1 form-control"
          placeholder="Enter email"
          style={{
            width: "50%",
            height: "45px",
            borderRadius: "12px",
            border: "1px solid black",
            padding: 10,
          }}
          value={email}
          onChange={handleEmailChange}
        />
      )}

      {currentType === "phone" && (
        <input
          type="number"
          className="my-1 form-control"
          style={{
            width: "50%",
            height: "45px",
            borderRadius: "12px",
            border: "1px solid black",
            padding: 10,
          }}
          max={9999999999}
          value={phone}
          onChange={(e) => {
            handlePhoneChange(e.target.value);
          }}
        />
      )}

      <span
        style={{
          color: "#ee5e8f",
          cursor: "pointer",
          textAlign: "right",
          width: "50%",
        }}
        onClick={changetype}
      >
        {currentType === "email" ? "Use phone number" : "Use email address"}
      </span>

      <div className="d-flex align-items-center justify-content-end w-50 mx-auto">
        <div>
          <button
            type="submit"
            className="my-4 login"
            style={{
              padding: "10px 20px ",
              borderRadius: "12px",
              backgroundColor: "#007bff",
              color: "white",
            }}
          >
            Generate OTP
          </button>
        </div>
      </div>

      <div className="py-4" style={{ color: "#bac1cb" }}>
        Back to
        <span
          className="ps-2"
          style={{ color: "#ee5e8f", cursor: "pointer" }}
          onClick={handleprevious}
        >
          Login
        </span>
      </div>
    </form>
  );
};

export default Generateopt;
