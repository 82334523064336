import React, { useEffect, useState } from "react";
import { useHistory, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
function Dropdown(props) {
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1243px)",
  });
  const navigate = useNavigate();
  const [profilemenu, setProfilemenu] = useState(false);
  const [logoutmenu, setLogoutmenu] = useState(false);
  const [changePassword, setChangePassword] = useState(false);

  const gotoprofile = () => {
    navigate("/doctor/Profile", { state: { profileData: props.profileData } });
  };
  const logout = () => {
    const userConfirmed = window.confirm("Are you sure you want to log out?");
    if (userConfirmed) {
      localStorage.removeItem("userId");
      window.location.href = "/";
      // window.location.reload();
      // navigate("/");
    }
  };

  return (
    <>
      <AccountCircleOutlinedIcon
        onClick={props.onClick}
        sx={{
          marginLeft: "14px",
          width: isTabScreen ? "24px" : "33px",
          height: isTabScreen ? "24px" : "33px",
          color: "#004AAD",
          cursor: "pointer",
        }}
      />
      <div
        className={
          props.submenu == true
            ? props.page != "dashboard"
              ? "displaybox1"
              : "displaybox"
            : "hidebox"
        }
      >
        {" "}
        <div>
          <p
            className="px-1 py-2 mb-0"
            onClick={gotoprofile}
            onMouseEnter={() => setProfilemenu(true)}
            onMouseLeave={() => setProfilemenu(false)}
            style={{
              cursor: "pointer",
              backgroundColor:
                profilemenu == true ? "rgb(143, 200, 242)" : "white",
              display: props.page == "profile" ? "none" : "block",
            }}
          >
            Profile
          </p>
          <p
            className="px-1 py-2 mb-0"
            onClick={() => {
              navigate("/doctor/change-password");
            }}
            onMouseEnter={() => setChangePassword(true)}
            onMouseLeave={() => setChangePassword(false)}
            style={{
              cursor: "pointer",
              backgroundColor:
                changePassword == true ? "rgb(143, 200, 242)" : "white",
              display: props.page == "profile" ? "none" : "block",
            }}
          >
            Change Password
          </p>
          <p
            className="px-1 py-2 mb-0"
            onMouseEnter={() => setLogoutmenu(true)}
            onMouseLeave={() => setLogoutmenu(false)}
            style={{
              cursor: "pointer",
              backgroundColor:
                logoutmenu == true ? "rgb(143, 200, 242)" : "white",
            }}
            onClick={logout}
          >
            Logout
          </p>
        </div>
      </div>
    </>
  );
}

export default Dropdown;
