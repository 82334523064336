import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import AlertShow from "./AlertShow";
import configData from "../../config.json";
function Times1(props) {
  console.log("DDDDDD>>", props, props.info);
  const [age, setAge] = React.useState("");
  const [event, setEvent] = useState(null);
  const [info, setInfo] = useState(false);
  const [schedulingtest, setschedulingtest] = useState();
  const [timings, setTimings] = useState();
  const [timeId, setTimeId] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [severity, setSeverity] = useState("");
  useEffect(() => {
    getTimings();
    setAge("Online");
    if (props.state?.id == 2 && props.dateclick == false) {
      setInfo(true);
      setschedulingtest(true);
    } else {
      setInfo(false);
      setschedulingtest(false);
    }
  }, [props.dateclick]);

  function displayInfo(times) {
    setInfo(true);
    setTimeId(times?.id);
    setEvent(times?.start_time);
    if (props.state?.id == 2) {
      setEvent(props.state.eventDate);
    }
  }
  const handleclick = (props) => {
    UpdateConsultation();
    setschedulingtest(!schedulingtest);
  };
  const setBack = () => {
    setInfo(false);
  };

  const getTimings = async () => {
    try {
      const response = await axios.get(
        `${configData.SERVER_API_URL}/consultation/get_doctor_timings`,
        {},
      );

      if (response.data?.success) {
        console.log("Dr timings-", response.data?.doctor_timing);
        setTimings(response.data?.doctor_timing);
      }
    } catch (error) {
      console.error("Error get timings:", error);
    }
  };

  const UpdateConsultation = async () => {
    var msg = "update consultation and select date";
    const error_color = "error";
    handleAlertMsg(error_color, msg);
    // alert("update consultation nd select date");
    try {
      console.log(
        "CCSCSC",
        timeId,
        props?.info?.patient_id,
        props?.info?.pregnancy_week,
        props?.info?.id,
        props?.formattedDate,
      );
      const response = await axios.post(
        `${configData.SERVER_API_URL}/consultation/update_consultation`,
        {
          consultation_type:
            props?.info?.consultation_type === "Online" ? 1 : 2,
          doctor_timing_id: timeId,
          paitent_id: props?.info?.patient_id,
          week: props?.info?.pregnancy_week,
          consultation_id: props?.info?.id,
          scheduled_date: props?.formattedDate,
        },
      );

      if (response.data?.success) {
        console.log("Updated Consultation-", response.data);
        var msg = "Consultation Scheduled";
        const error_color = "success";
        handleAlertMsg(error_color, msg);
        alert("Consultation Scheduled");
        props.setShowTimes(true);
      }
    } catch (error) {
      console.error("Error in update Consult:", error);
    }
  };

  const handleAlertMsg = (error_type, msg) => {
    setShowAlert(true);
    setSeverity(error_type);
    setAlertMsg(msg);
    setTimeout(() => {
      setShowAlert(false);
    }, 2500);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const checkAvailability = (time) => {
    return props.timings?.some((timing) => timing === time);
  };
  return (
    <>
      {info ? (
        schedulingtest === true ? (
          <>
            <div
              style={{
                paddingTop: "55px",
                textAlign: "center",
                fontSize: "24px",
                fontWeight: "400px",
                color: "#F0588B",
              }}
            >
              {props.state?.id == 2
                ? "Consultation" + " " + props.state?.consultation_no
                : props.title}
            </div>
            <div
              style={{
                border: "0.5px solid #E8E7E7",
                width: "96%",
                margin: "10px auto",
                borderRadius: "12px",
                padding: "4px",
              }}
            >
              <div
                style={{
                  paddingTop: "10px",
                  fontSize: "20px",
                  fontWeight: "500",
                  color: "#004AAD",
                  textAlign: "center",
                }}
              >
                {props.state?.id == 2 ? props.state?.eventDate : props.date}
              </div>
              <div
                className="d-flex justify-content-between px-1"
                style={{ paddingTop: "17px", paddingBottom: "10px" }}
              >
                <div className="text-center pt-3">
                  <img
                    src={"/Pathology/scheduleimg.png"}
                    style={{ width: "37px", height: "56px" }}
                  ></img>
                  <div
                    style={{
                      paddingTop: "10px",
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#464E5F",
                    }}
                  >
                    Dr.{" "}
                    {props.state?.id == 2
                      ? props.state.drname
                      : props.doctorInfo.fname + " " + props.doctorInfo.lname}
                  </div>
                  <div
                    style={{
                      paddingTop: "5px",
                      fontSize: "13px",
                      fontWeight: "500",
                      color: "#B5B5C3",
                    }}
                  >
                    {props.state?.id == 2 ? props.state.drpost : props.drpost}
                  </div>
                </div>
                <div>
                  <div>
                    <div style={{ fontSize: "16px", fontWeight: "500" }}>
                      Consultation ModeP4
                    </div>
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#868686",
                      }}
                    >
                      {props.state?.id == 2
                        ? props.state?.consultation_type
                        : age}
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      paddingTop: "36px",
                    }}
                  >
                    {" "}
                    Timing
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#868686",
                    }}
                  >
                    {" "}
                    {props.state?.id == 2 ? props.state?.timing : event}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className="d-flex align-items-center px-3"
              style={{ paddingTop: "55px" }}
            >
              <img
                src={"/Pathology/Arrow.png"}
                style={{ width: "30px", height: "20px", cursor: "pointer" }}
                onClick={() => setBack()}
              ></img>
              <div
                style={{
                  fontSize: "24px",
                  fontWeight: "400",
                  color: "#F0588B",
                  paddingLeft: "60px",
                }}
              >
                Book Timing
              </div>
            </div>
            <div
              style={{
                marginTOp: "20px",
                border: "0.5px solid #E8E7E7",
                width: "320px",
                margin: "0px auto",
                marginTop: "20px ",
              }}
            >
              <div
                style={{
                  paddingTop: "20px",
                  fontSize: "12px",
                  fontWeight: "500",
                  color: "#004AAD",
                  textAlign: "center",
                }}
              >
                {props.date}
              </div>
              <div
                style={{
                  fontSize: "17px",
                  fontWeight: "500",
                  paddingTop: "20px",
                  paddingLeft: "32px",
                }}
              >
                Consultation Mode
              </div>
              {/* <FormControl fullWidth size={"small"} style={{}}>
                <Select
                  style={{
                    backgroundColor: "#F2F2F2",
                    fontSize: "13px",
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    color: "gray",
                    width: "80%",
                    height: "30px",
                    margin: " 0px auto",
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={age}
                  displayEmpty
                  onChange={handleChange}
                >
                  <MenuItem value="">In-person</MenuItem>
                  {consultTypes.map((item, index) => {
                    return <MenuItem value={item}>{item}</MenuItem>;
                  })}
                </Select>
              </FormControl> */}
              <div
                style={{
                  backgroundColor: "#F2F2F2",
                  fontSize: "13px",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  color: "gray",
                  width: "80%",
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  margin: "0px auto",
                  paddingLeft: "10px",
                }}
              >
                {props?.info?.consultation_type}
              </div>
              <div className="d-flex px-5 pt-4 py-2 align-items-center justify-content-between">
                <div
                  style={{
                    fontSize: "24px",
                    fontWeight: "600",
                    color: "#58CF99",
                  }}
                >
                  {event}
                </div>
                <div>
                  <button
                    style={{
                      width: "100px",
                      height: "32px",
                      backgroundColor: "#004AAD",
                      boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.25)",
                      borderRadius: "6px",
                      border: "none",
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "white",
                    }}
                    onClick={(props) => handleclick(props)}
                  >
                    Schedule
                  </button>
                </div>
              </div>
            </div>
          </>
        )
      ) : (
        <div
          className="mt-5 px-3"
          style={{
            overflowX: "scroll",
            display: "flex",
            height: "400px",
            overflowY: "auto",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              paddingTop: "20px",
              fontSize: "20px",
              fontWeight: "500",
              color: "#004AAD",
              textAlign: "center",
            }}
          >
            Timings on {props.date}
          </div>
          {/* <div style={timecontainer}> */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
              overflowY: "auto",
              height: "auto",
              paddingLeft: "15px",
              paddingRight: "15px",
              marginTop: "10px",
            }}
          >
            <div className="row">
              {timings &&
                timings.length > 0 &&
                timings.map((data) => (
                  <div className="col-lg-6" style={{ paddingTop: "10px" }}>
                    <span style={{ width: "110px" }}>{data.start_time}: </span>
                    <button
                      style={{
                        float: "inline-end",
                        // marginLeft: "20px",
                        width: "90px",
                        height: "40px",
                        borderRadius: "5px",
                        fontSize: "12px",
                        padding: "10px",
                        color: checkAvailability(data.start_time)
                          ? "white"
                          : "#2C2C2C",
                        backgroundColor: checkAvailability(data.start_time)
                          ? "#2EA171"
                          : "#D9D9D9",
                        cursor: checkAvailability(data.start_time)
                          ? "pointer"
                          : "default",
                        border: "none",
                      }}
                      onClick={(e) => {
                        if (checkAvailability(data.start_time))
                          displayInfo(data);
                      }}
                    >
                      {" "}
                      {checkAvailability(data.start_time)
                        ? "Available"
                        : "Unavailable"}
                    </button>
                  </div>
                ))}
            </div>

            {/* { timings && timings.length > 0 && (() => {
             const result = [];
             for(let i = 0; i < timings.length - 1; i += 2){
              result.push(
            // timings?.map((times , i) => {
              
                <div className="d-flex align-items-center">
                  <div
                    className="times my-2 text-center"
                    style={{ width: "110px" }}
                  >
                    <div>{timings[i].start_time}</div>
                  </div>
                  <button
                    className="my-2"
                    onClick={(e) => {
                      if (checkAvailability(timings[i].start_time))
                        displayInfo(timings[i]);
                    }}
                    style={{
                      marginLeft: "20px",
                      width: "90px",
                      height: "40px",
                      borderRadius: "5px",
                      fontSize: "12px",
                      padding: "10px",
                      color: checkAvailability(timings[i].start_time)
                        ? "white"
                        : "#2C2C2C",
                      backgroundColor: checkAvailability(timings[i].start_time)
                        ? "#2EA171"
                        : "#D9D9D9",
                      cursor: checkAvailability(timings[i].start_time)
                        ? "pointer"
                        : "default",
                      border: "none",
                    }}
                  >
                    {checkAvailability(timings[i].start_time)
                      ? "Available"
                      : "Unavailable"}
                  </button>
                  <div
                    className="times my-2 text-center"
                    style={{ width: "110px" }}
                  >
                    <div>{timings[i+1].start_time}</div>
                  </div>
                  <button
                    className="my-2"
                    onClick={(e) => {
                      if (checkAvailability(timings[i+1].start_time))
                        displayInfo(timings[i+1]);
                    }}
                    style={{
                      marginLeft: "20px",
                      width: "90px",
                      height: "40px",
                      borderRadius: "5px",
                      fontSize: "12px",
                      padding: "10px",
                      color: checkAvailability(timings[i+1].start_time)
                        ? "white"
                        : "#2C2C2C",
                      backgroundColor: checkAvailability(timings[i+1].start_time)
                        ? "#2EA171"
                        : "#D9D9D9",
                      cursor: checkAvailability(timings[i+1].start_time)
                        ? "pointer"
                        : "default",
                      border: "none",
                    }}
                  >
                    {checkAvailability(timings[i+1].start_time)
                      ? "Available"
                      : "Unavailable"}
                  </button>
                </div>
              )
              }
              return result;
            })()} */}
          </div>
          {/* </div> */}
          {/* <div style={timecontainer}>
              <div>
                {" "}
                {time1.map((times) => {
                  return (
                    <div className="d-flex align-items-center">
                      <div className="times my-2 text-center">
                        <div onClick={(e) => displayInfo(e)}>
                          {times.timeval} AM
                        </div>
                      </div>
                      <button
                        className="my-2"
                        onClick={(e) => displayInfo(e)}
                        style={{
                          width: "80px",
                          height: "40px",
                          border: "none",
                          borderRadius: "5px",
                          fontSize: "12px",
                          color:
                            times.status === "Available" ? "white" : "#2C2C2C",
                          backgroundColor:
                            times.status === "Available"
                              ? "#2EA171"
                              : "#D9D9D9",
                          cursor:
                            times.status === "Available" ? "pointer" : "none",
                          border:
                            times === "Available"
                              ? "none"
                              : "1px solid #D9D9D9",
                        }}
                      >
                        {times.status}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div> */}
          <AlertShow
            alertShowHide={showAlert}
            alertClose={handleCloseAlert}
            setalertMsg={alertMsg}
            severity={severity}
          />
        </div>
      )}
    </>
  );
}

export default Times1;
