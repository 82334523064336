import React, { useState, useEffect, useRef } from "react";
import AddPrescriptionBox from "../Components/AddPrescriptionBox";
import axios from "axios";
import Chat from "./Chat";
import configData from "../config.json";

function ReportDetailsBox({
  title,
  drid,
  patientid,
  chatData,
  onBackClick,
  showSent,
  startVideoConsultFromChat,
  fromInspect,
  drnm,
  consultationnumber,
  consultItem,
  profileData,
  consultaionId,
  followup,
  setRenderagain,
  audioVedio,
}) {
  console.log("^^", consultItem);

  const [addPrescription, setAddPrescription] = useState(false);
  const [addedprescibe, setaddedprescibe] = useState(false);
  const [endoption, setEndoption] = useState(false);
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     getmsg();
  //   }, 1000);
  // }, [chatData]);

  const [value, onChange] = useState(new Date());

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAdd = () => {
    // setShowCalendar(false);
    setaddedprescibe(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const add = () => {
    setAddPrescription(true);
  };
  const redirectToChat = () => {
    setAddPrescription(false);
    setEndoption(true);
  };
  return (
    <>
      {addPrescription ? (
        <div className="test">
          <AddPrescriptionBox
            title={"Add Prescription"}
            titleendconsult={"End Consultation"}
            handleAdd={handleAdd}
            drnm={drnm}
            consultationnumber={consultationnumber}
            consultaionId={consultaionId}
            title1={title}
            drid={drid}
            patientid={patientid}
            chatData={chatData}
            onBackClick={onBackClick}
            startVideoConsultFromChat={startVideoConsultFromChat}
            fromInspect={fromInspect}
            consultItem={consultItem}
            redirectToChat={redirectToChat}
            profileData={profileData}
            setEndoption={setEndoption}
          />
        </div>
      ) : (
        <Chat
          title={title}
          audioVedio={audioVedio}
          consultItem={consultItem}
          drid={drid}
          showSent={showSent}
          consultaionId={consultaionId}
          patientid={patientid}
          chatData={chatData}
          onBackClick={onBackClick}
          fromInspect={fromInspect}
          startVideoConsultFromChat={startVideoConsultFromChat}
          onClick={add}
          consultationnumber={consultationnumber}
          profileData={profileData}
          followup={followup}
          addPrescription={addPrescription}
          endoption={endoption}
          setRenderagain={setRenderagain}
        />
      )}
    </>
  );
}

export default ReportDetailsBox;
