import React, { useState } from "react";
import configData from "../../../config.json";
import DateBox from "../DateBox";
import { useMediaQuery } from "react-responsive";
const dates = [
  {
    date: 15,
    day: "Mon",
    patientDay: 1,
  },
  {
    date: 16,
    day: "Tue",
    patientDay: 2,
  },
  {
    date: 17,
    day: "Wed",
    patientDay: 3,
  },
  {
    date: 18,
    day: "Mon",
    patientDay: 4,
  },
  {
    date: 19,
    day: "Thu",
    patientDay: 5,
  },
  {
    date: 20,
    day: "Fri",
    patientDay: 6,
  },
  {
    date: 21,
    day: "Sat",
    patientDay: 7,
  },
  {
    date: 22,
    day: "Sun",
    patientDay: 8,
  },
  {
    date: 23,
    day: "Mon",
    patientDay: 9,
  },
  {
    date: 24,
    day: "Tue",
    patientDay: 10,
  },
  {
    date: 25,
    day: "Wed",
    patientDay: 11,
  },
  {
    date: 26,
    day: "Thu",
    patientDay: 12,
  },
  {
    date: 27,
    day: "Fri",
    patientDay: 13,
  },
  {
    date: 28,
    day: "Sat",
    patientDay: 14,
  },
  {
    date: 29,
    day: "Sun",
    patientDay: 15,
  },
  {
    date: 30,
    day: "Mon",
    patientDay: 16,
  },
  {
    date: 31,
    day: "Tue",
    patientDay: 17,
  },
  {
    date: 1,
    day: "Wed",
    patientDay: 18,
  },
  {
    date: 2,
    day: "Thu",
    patientDay: 19,
  },
];

function Embryonic({ weekwisedata, setExpand, setSelected }) {
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const wrapper = {
    marginLeft: isTabScreen ? "20px" : "50px",
    marginRight: isTabScreen ? "20px" : "50px",
    paddingLeft: isTabScreen ? "20px" : "50px",
    paddingBottom: "50px",
    background: "#FBFBFB",
    borderRadius: "12px",
    paddingTop: "30px",
    marginBottom: "50px",
    height: window.innerHeight,
    overflowY: "scroll",
  };
  return (
    <div>
      <div style={wrapper}>
        <div
          style={{
            fontSize: "12px",
            fontWeight: "600",
            color: configData.THEME_COLORS.PRIMARY,
            marginTop: "30px",
            cursor: "pointer",
          }}
          onClick={() => {
            setSelected(false);
            setExpand(true);
          }}
        >
          Back
        </div>

        <div
          style={{
            marginTop: "10px",
            fontSize: "24px",
            fontWeight: "600",
            color: configData.THEME_COLORS.SECONDARY,
          }}
        >
          Embryonic Development
        </div>
        <div className="w-100">
          <div
            style={{
              marginTop: isTabScreen ? "20px" : "40px",
              alignSelf: "center",
              display: "flex",
              overflow: "hidden",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              height: isTabScreen ? "100" : "130",
            }}
          >
            {dates.map((item, index) => (
              <DateBox
                day={item.day}
                date={item.date}
                patientDay={item.patientDay}
                size={"40px"}
                activeDay={4}
              />
            ))}
            {/* <Carousel items={dates} active={0} /> */}
          </div>
        </div>
        <div
          style={{
            marginTop: "30px",
            alignSelf: "center",
            fontSize: "20px",
            fontWeight: "400",
            height: window.innerHeight / 2,
            width: "90%",
            overflow: "auto",
            marginTop: "54px",
          }}
        >
          {weekwisedata?.description}
        </div>
      </div>
    </div>
  );
}

export default Embryonic;
