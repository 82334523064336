import axios from "axios";
import configData from "./../../config.json";

const base = configData.SERVER_API_URL;
const base2 = `https://admin.myprega.com`;

export default {
  // patientconsultantalert(data) {
  //   const promise = axios.post(
  //     `${base}/consultation/get_patient_consultation`,
  //     data
  //   );
  //   return promise
  //     .then((response) => {
  //       return response.data;
  //     })
  //     .catch((error) => {
  //       throw error;
  //     });
  // },
  updatepatientmessagetodoctor(data) {
    const promise = axios.post(`${base}/patient/update_Show_msg_status`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return promise
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
  sendotp(data) {
    const promise = axios.post(`${base}/users/send_Otp_forgotPassword`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return promise
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
  verifyotp(data) {
    const promise = axios.post(`${base}/users/verifyOtp_forgotPassword`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return promise
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
  changepassword(data) {
    const promise = axios.post(`${base}/users/change_forgot_Password`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return promise
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
  reportupload(data) {
    const promise = axios.post(`${base2}/user/upload-files`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return promise
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  sonographypatientvisited(data) {
    const promise = axios.post(`${base}/consultation/patient_visited`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return promise
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
  sonographyscanstatus(data) {
    const promise = axios.post(
      `${base}/consultation/scan_completed_confirmation`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return promise
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
  sonographycompleted(data) {
    const promise = axios.post(
      `${base}/consultation/update_consultation_status`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return promise
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
  downloadfile(data) {
    const promise = axios.post(`${base2}/user/download-file`, data, {
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "arraybuffer",
    });
    return promise
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
  fetchpatientreport(data) {
    const promise = axios.post(`${base}/common/get_uploaded_reports`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return promise
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
  getprescriptiondata(data) {
    const promise = axios.post(
      `${base}/consultation/get_prescription_data`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return promise
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
  getprofiledata(data) {
    const promise = axios.post(`${base}/users/get_single_user`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return promise
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
  deleteconsulationtoken(data) {
    const promise = axios.post(
      `${base}/consultation/delete_consultation_token`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return promise
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
  addmedicineapi(data) {
    const promise = axios.post(`${base}/consultation/add_medicine`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return promise
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
};
