import React, { useEffect, useState } from "react";
import configData from "../config.json";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import { Alert } from "@mui/material";
import Handleclick from "../../common/doctor/Handleclick";
import Handleevent from "../../common/doctor/Handleevent";

function Consultationrightbox(props) {
  const d1 = props.item.weekDates[0];
  const d2 = props.item.weekDates[6];
  const weekstart = moment(d1).format("DD MMM");
  const weekend = moment(d2).format("DD MMM");
  const currentDate = moment();
  const futureDate = moment(d1);
  const lastDate = moment(d2);
  const dateDiff = futureDate.diff(currentDate, "days");
  const lastDateDiff = lastDate.diff(currentDate, "days");
  const newItemData = JSON.stringify(props.item);
  const scheduledDate = moment(props.item?.scheduled_date).format("DD-MM-YYYY");
  const currentDateCompare = moment().format("DD-MM-YYYY");
  const [showbtn, setShowbtn] = useState("changeweek");
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const lastweekday = props?.item?.weekDates[props?.item?.weekDates.length - 1];
  const currentday = moment().format("YYYY-MM-DD");
  useEffect(() => {
    console.log(lastweekday);
    if (props?.item?.scheduled_date) {
      const scheduledDate = moment(props.item.scheduled_date);
      const currentDate = moment();
      const blankStart = scheduledDate.clone().subtract(24, "hours");
      const blankEnd = scheduledDate.clone().endOf("day");
      if (currentDate.isBetween(blankStart, blankEnd)) {
        setShowbtn("");
      } else {
        setShowbtn("reschedule");
      }
    } else {
      let fistweekday = props?.item?.weekDates[0];
      const currentDate = moment().format("YYYY-MM-DD");
      const sevenDaysBefore = moment(fistweekday)
        .subtract(7, "days")
        .format("YYYY-MM-DD");
      if (currentDate > sevenDaysBefore) {
        setShowbtn("schedule");
      } else {
        setShowbtn("changeweek");
      }
    }
  }, [props?.item]);

  return (
    <>
      <div
        style={{
          paddingTop: "20px",
          paddingLeft: "25px",
          marginBottom: "12px",
        }}
      >
        {" "}
        {props?.item?.patient_consultation_name ||
          `Consultation ${props?.item?.consultation_number}`}
        {/* Consultation {props.index + 1} */}
        {/* {props.item.consultation_number}{""} */}
      </div>

      <div
        style={{
          border: "1px solid #CBC6C6",
          backgroundColor: "rgba(240, 250, 248, 0.502)",
          borderRadius: "12px",
          width: "86%",
          margin: "0px auto",
        }}
      >
        {props?.item?.show_msg == 1 && (
          <div
            style={{
              padding: "0px",
            }}
          >
            <Alert
              severity={"warning"}
              style={{
                borderRadius: "12.9744px",
              }}
            >
              Your Patients want to Re-schedule Consultation.
            </Alert>
          </div>
        )}
        <div
          style={{
            paddingTop: "18px",
            paddingBottom: "18px",
            textAlign: "center",
            color:
              props.item.status === "SCHEDULED"
                ? configData.THEME_COLORS.PRIMARY
                : "black",
            fontSize: isTabScreen ? "16px" : "20px",
          }}
        >
          {props.item.scheduled_date ? (
            moment(props.item.scheduled_date)
              .format("DD MMM YYYY")
              .toUpperCase()
          ) : (
            <div>
              {weekstart}-{weekend}
            </div>
          )}
        </div>
        <div
          className="pb-3 text-center fw-bolder"
          style={{ fontSize: isTabScreen ? "13px" : "20px" }}
        >
          {props.isPathology ? (
            <div>In-person</div>
          ) : (
            props.item.consultation_type
          )}
        </div>

        <div className="px-3 d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column justify-content-center">
            <div
              className="pb-1"
              style={{ fontSize: isTabScreen ? "9px" : "16px" }}
            >
              Timing
            </div>
            <div
              className="pt-1"
              style={{
                color: "#868686",
                fontSize: isTabScreen ? "9px" : "16px",
              }}
            >
              {props.item.timing == null ? "00:00" : props.item.timing}
            </div>
          </div>
          <div style={{ fontSize: isTabScreen ? "9px" : "16px" }}>
            Pregnancy week: {props.item.pregnancy_week}
          </div>
        </div>

        <div className="my-3 d-flex justify-content-between align-items-center mx-md-1 mx-lg-3">
          <div
            style={{
              fontSize: isTabScreen ? "11px" : "16px",
              color:
                props.item.doctor_scheduled === 1
                  ? props.item.scheduled_date != null
                    ? "green"
                    : "#F92A2A"
                  : "#004AAD",
              textAlign: "center",
            }}
          >
            {/* {props.scheduledr === props.item.id ? "SCHEDULED" : "NOT SCHEDULED"} */}
            {props.item.doctor_scheduled === 0 ? (
              <div>NOT SCHEDULED</div>
            ) : props.item.doctor_scheduled === 1 &&
              props.item.is_completed === 0 ? (
              <div>SCHEDULED</div>
            ) : props.item.doctor_scheduled === 1 &&
              props.item.is_completed === 1 ? (
              <div>COMPLETED</div>
            ) : null}
          </div>
          {props.update && props.item.is_completed === 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              {!props.isPathology && (
                <>
                  <Handleclick
                    scheduledDate={scheduledDate}
                    currentDateCompare={currentDateCompare}
                    onClick={props.consulationTypeChanege}
                    item={props?.item}
                    button_name={"Change Type"}
                    isTabScreen={isTabScreen}
                  />
                </>
              )}
              {showbtn == "changeweek" && (
                <Handleevent
                  item={props?.item}
                  button_name={"Change Week"}
                  onClick={props.changeWeek}
                  newItemData={newItemData}
                  d2={d2}
                  isTabScreen={isTabScreen}
                />
              )}

              {showbtn == "schedule" && (
                <Handleevent
                  item={props?.item}
                  button_name={"Schedule"}
                  onClick={props.onClick}
                  newItemData={newItemData}
                  d2={d2}
                  isTabScreen={isTabScreen}
                />
              )}
              {showbtn === "reschedule" && (
                <Handleevent
                  item={props?.item}
                  button_name={"Re-schedule"}
                  onClick={
                    currentday >= lastweekday
                      ? props.getNextDates
                      : props.onClick
                  }
                  newItemData={newItemData}
                  d2={d2}
                  isTabScreen={isTabScreen}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Consultationrightbox;
