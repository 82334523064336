import React, { useState, useEffect, useRef } from "react";
import MiniDrawer from "../Components/MiniDrawer";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Typography from "@mui/material/Typography";
import configData from "../../config.json";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ReportBox from "../Components/ReportBox";
import ReportDetailsBox from "../Components/ReportDetailsBox";
import Dropdown from "../Components/Categories/Dropdown";

import axios from "axios";
import { downloadFile } from "../../common/utils/fileUtils";
import Prescriptiondata from "../../common/Prescriptiondata";
const doctorTypes = ["Sonography Centre", "Pathology Lab"];

const consultationNumbers = [
  "Gynaecologist",
  "Doctor Of Medicine",
  "Sonography Lab",
  "Pathalogy Lab",
];
const pregnancyWeeks = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
];

const reports1 = [
  {
    id: 1,
    name: "TEST 2",
    month: "Oct",
    week: 13,
    date: 23,
    dr: "Sunrise Dignostic Center",
    type: "Sonography Center",
    fullDate: "Oct 23 2023 10:00:00 AM",
  },
  {
    id: 2,
    name: "CONSULTATION 2",
    month: "Oct",
    week: 11,
    date: 11,
    dr: "Dr. Jane Doe",
    type: "Doctor of Medicine",
    fullDate: "Oct 1 2023 10:00:00 AM",
  },
  {
    id: 3,
    name: "CONSULTATION 2",
    month: "Sep",
    week: 9,
    date: 28,
    dr: "Dr. Jane Doe",
    type: "Gynaecologist",
    fullDate: "Sep 28 2023 10:00:00 AM",
  },
  {
    id: 4,
    name: "TEST 1",
    month: "Sep",
    week: 7,
    date: 13,
    dr: "Sunrise Dignostic Center",
    type: "Sonography Center",
    fullDate: "Sep 13 2023 10:00:00 AM",
  },
  {
    id: 5,
    name: "CONSULTATION 2",
    month: "Aug",
    week: 5,
    date: 30,
    dr: "Dr. Jane Doe",
    type: "Doctor of Medicine",
    fullDate: "Aug 30 2023 10:00:00 AM",
  },
  {
    id: 6,
    name: "CONSULTATION 2",
    month: "Aug",
    week: 3,
    date: 14,
    dr: "Dr. Jane Doe",
    type: "Gynaecologist",
    fullDate: "Aug 14 2023 10:00:00 AM",
  },
];
function Reports() {
  useEffect(() => {
    getreports();
  }, []);

  const [drType, setDrtype] = useState("");
  const [number, setNumber] = React.useState("");
  const [reports, setReports] = useState([]);
  const [week, setWeek] = React.useState("");
  const [filteredReports, setFilteredreports] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [data, setData] = useState([]);
  const wrapperRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [desc, setDesc] = useState(true);
  const [prescriptiondata, setPrescriptiondata] = useState([]);
  const [showprescriptionmodel, setShowprecriptionmodal] = useState(false);

  const showsubmenu = () => {
    setSubmenu(!submenu);
  };
  const [submenu, setSubmenu] = useState(false);

  const handleChange1 = (event) => {
    setDrtype(event.target.value);
  };
  const handleChange2 = (event) => {
    setNumber(event.target.value);
  };
  const handleChange3 = (event) => {
    setWeek(event.target.value);
  };

  const handleClick = (item) => {
    setIsVisible(!isVisible);
    setClicked(!clicked);
    setData(item);
    console.log("===data", item);
  };

  const handleSort = () => {
    const data = filteredReports;
    data.sort(function (a, b) {
      var c = new Date(a.created_date);
      var d = new Date(b.created_date);
      return desc ? d - c : c - d;
    });
    setFilteredreports(data);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsVisible(false);
    }
  };
  const getreports = async () => {
    const user_id = localStorage.getItem("userId");
    api
      .fetchpatientreport({
        patient_id: user_id,
        doctor_id: null,
      })
      .then((res) => {
        if (res.success) {
          setReports(res.reports);
          const uniqueExperts = [];
          const seenExperts = new Set();

          // Loop through all reports to find unique medical experts by name only
          res.reports.forEach((item) => {
            // If the medical_expert_name hasn't been added to the seenExperts set, add it
            if (!seenExperts.has(item.medical_expert_name)) {
              seenExperts.add(item.medical_expert_name);
              uniqueExperts.push({
                name: item.medical_expert_name,
                value: item.doctor_id, // Optionally add the first doctor_id you encounter
              });
            }
          });

          // Set the unique experts to the state
          setDrtype(uniqueExperts);
        }
      })
      .catch((rej) => {
        console.log(rej);
      });
  };
  useEffect(() => {
    setFilteredreports(reports);
  }, [reports]);

  const viewReorts = (data) => {
    let fileKey = data;
    downloadFile(fileKey);
  };

  const viewprescription = (data) => {
    api
      .getprescriptiondata({
        consultation_id: data?.id,
      })
      .then((res) => {
        if (res.success) {
          setShowprecriptionmodal(true);
          setPrescriptiondata({
            data: data,
            medicine: res.Prescrption,
          });
        }
      })
      .catch((rej) => {
        console.log(rej);
      });
  };

  const filterreports = (id) => {
    if (id == "all") {
      setFilteredreports(reports);
    } else {
      let currentreport = reports.filter((item) => item.doctor_id == id);
      setFilteredreports(currentreport);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: configData.THEME_COLORS.BACKGROUND,
        height: "100%",
        width: "100%",
      }}
    >
      <MiniDrawer />

      <div style={{ width: "100%" }}>
        <div
          style={{
            marginTop: 50,
            // marginLeft: -40,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            paragraph
            sx={{
              color: configData.THEME_COLORS.PRIMARY,
            }}
          >
            <div
              style={{
                fontSize: "32px",
                fontWeight: "600",
                fontFamily: "Poppins",
                marginLeft: "60px",
              }}
            >
              {"Reports"}
            </div>
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "60px",
            }}
          >
            <NotificationsIcon
              sx={{ width: "25px", height: "25px", color: "#004AAD" }}
            />
            <Dropdown
              submenu={submenu}
              onClick={() => showsubmenu()}
              page="report"
            />
          </div>
        </div>
        <div
          style={{
            width: "87%",
            backgroundColor: "white",
            marginBottom: "20px",
            borderRadius: "12px",
            marginLeft: "60px",
          }}
        >
          <div
            style={{
              // marginLeft: '10px',

              borderRadius: "12px",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <div
                style={{
                  marginLeft: "30px",
                  marginTop: "10px",
                  fontWeight: "600",
                  fontSize: "24px",
                  color: configData.THEME_COLORS.SECONDARY,
                }}
              >
                Filters
              </div>
            </div>
            <div style={{}}>
              <Typography
                paragraph
                sx={{
                  color: configData.THEME_COLORS.PRIMARY,
                  marginTop: "21px",
                  alignSelf: "flex-end",
                  display: "flex",
                  marginRight: "20px",
                }}
              >
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setDesc(!desc);
                    handleSort();
                  }}
                >
                  {desc ? "New - Old" : "Old - New"}
                </div>

                <img
                  src={"/HealthTracker/sort.png"}
                  style={{
                    marginTop: "8px",
                    resizeMode: "contain",
                    height: "18px",
                    width: "18px",
                  }}
                  alt="Sort"
                />
              </Typography>
            </div>
          </div>
          <div
            style={{
              marginTop: "12px",
              marginLeft: "20px",
              height: "60px",
              borderRadius: "12px",
              display: "flex",
            }}
          >
            <Box
              sx={{
                width: isVisible && clicked ? "20%" : "18%",
                marginRight: "3%",
              }}
            >
              <FormControl fullWidth size={"small"}>
                <select
                  className="form-select"
                  onChange={(e) => {
                    if (e.target.value == "Select") return;
                    filterreports(e.target.value);
                  }}
                >
                  <option>Select</option>
                  <option value={"all"}>All Reports</option>

                  {drType.length > 0 &&
                    drType.map((item) => {
                      return <option value={item.value}>{item.name}</option>;
                    })}
                </select>
              </FormControl>
            </Box>
          </div>
        </div>
        <div
          style={{
            width: "90%",
            marginLeft: "60px",
          }}
        >
          <div
            className="container"
            style={{ height: "80vh", overflowY: "scroll" }}
          >
            <div className="row">
              {/* <div className="col"> */}
              {filteredReports?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <ReportBox
                      onClick={() => handleClick(item)}
                      item={item}
                      reports1={reports}
                      isVisible={isVisible}
                      viewReorts={viewReorts}
                      viewprescription={viewprescription}
                    />
                  </React.Fragment>
                );
              })}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
      {clicked && isVisible && (
        <ReportDetailsBox title={"Report Details"} data={data} />
      )}
      {showprescriptionmodel && (
        <Prescriptiondata
          open={showprescriptionmodel}
          data={prescriptiondata}
          onClose={() => {
            setShowprecriptionmodal(false);
            setPrescriptiondata([]);
          }}
        />
      )}
    </div>
  );
}

export default Reports;
