import React, { useState, useEffect } from "react";
import configData from "../../config.json";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
var selected;
function ReportBox({ onClick, item, isVisible, viewReorts, viewprescription }) {
  const [clicked, setClicked] = useState(false);
  // const [selected, setSelected] = useState(1)
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  return (
    <div className="col-4  ">
      <div className="bg-white text-center  p-4 m-2">
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <img
              src={"/Reports/perspective_matte.png"}
              style={{
                resizeMode: "contain",
                height: "18px",
                width: "18px",
                marginRight: "10px",
              }}
            />
            <div
              style={{
                fontSize: "16px",
                fontWeight: "500",
                color: configData.THEME_COLORS.PRIMARY,
                marginRight: "23px",
                textTransform: "uppercase",
              }}
            >
              {item?.scheduled_date
                ? moment(item?.scheduled_date).format("DD MMM")
                : "-"}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <img
              src={"/Reports/73956801.png"}
              style={{
                marginLeft: "25px",
                resizeMode: "contain",
                height: "24px",
                width: "24px",
              }}
            />
            <div
              style={{
                fontSize: "16px",
                fontWeight: "500",
                color: configData.THEME_COLORS.PRIMARY,
                marginLeft: "2px",
              }}
            >
              week {item?.week}
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: "15px",
            paddingBottom: "10px",
            fontSize: "20px",
            fontWeight: "500",
            textAlign: "center",
            color: configData.THEME_COLORS.SECONDARY,
          }}
        >
          {item?.patient_consultation_name ||
            `Consultation ${item?.consultation_number}`}
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>
              <div
                className="py-2"
                style={{
                  fontSize: isTabScreen ? "12px" : "14px",
                  color: "#333333",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Doctor's Name:{" "}
                <strong>
                  {item?.doctor_fname}{" "}
                  {item?.doctor_lname && ` ` + item?.doctor_lname}
                </strong>
              </div>
              <div
                className="py-2"
                style={{
                  fontSize: isTabScreen ? "12px" : "14px",
                  color: "#333333",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Expertise: <strong>{item?.medical_expert_name}</strong>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          {item?.is_prescription != 0 && (
            <button
              onClick={() => {
                viewprescription(item);
              }}
              className="btn btn-primary btn-sm border-0 mt-3"
            >
              View Prescription
            </button>
          )}

          <button
            onClick={() => {
              viewReorts(item.filekey);
            }}
            className="btn btn-primary btn-sm border-0 mt-3"
          >
            View Report
          </button>
        </div>
      </div>
    </div>
  );
}

export default ReportBox;
