import React from "react";

import { Modal } from "react-bootstrap";

const Showprecription = ({ data }) => {
  return (
    <Modal size="lg" show={false}>
      <Modal.Body>Helo wofrld</Modal.Body>
    </Modal>
  );
};

export default Showprecription;
