import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import MiniDrawer from "../Components/MiniDrawer";
import { useMediaQuery } from "react-responsive";

function Contact() {
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const [nm, setNm] = useState();
  const [email, setEmail] = useState();
  const [help, setHelp] = useState();
  const outerwrapper = {
    height: isTabScreen ? "100%" : "125vh",
    width: "48vw",
    backgroundColor: "#f6e7ec",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };
  const heading = {
    fontSize: isTabScreen ? "24px" : "32px",
    fontWeight: "600",
    fontFamily: "Poppins",
    color: "#004AAD",
    paddingTop: "100px",
    textAlign: "center",
    paddingBottom: "50px",
  };
  const heading2 = {
    fontSize: isTabScreen ? "18px" : "24px",
    fontWeight: "600",
    fontFamily: "Poppins",
    color: "#004AAD",
    paddingTop: "100px",
  };
  const wrapperright = {
    paddingLeft: "10vw",
    width: "38vw",
    height: "100%",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    // alignItems: 'center',
  };

  const textinp = {
    width: "60%",
    height: "55px",
    background: "rgba(251, 252, 253, 0.94)",
    borderRadius: "10px",
    padding: "0px",
    border: "none",
  };
  const textinp1 = {
    width: "60%",
    height: "150px",
    background: "rgba(251, 252, 253, 0.94)",
    borderRadius: "10px",
    padding: "0px",
  };
  const submit = {
    marginTop: "60px",
    width: "134px",
    height: "57.34px",
    background: "rgba(251, 252, 253, 0.65)",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.25)",
    bordeRadius: "6px",
    border: "none",
  };
  const pt15 = {
    paddingTop: "15px",
  };
  const pt40 = {
    paddingTop: "40px",
  };
  const headingplan = {
    fontSize: isTabScreen ? "18px" : "24px",
    fontWeight: "600",
    fontFamily: "Poppins",
    color: "#004AAD",
    paddingTop: "40px",
  };
  const pt60 = {
    paddingTop: "60px",
  };
  const contact = {
    paddingTop: "32px",
    fontWeight: "600",
    fontSize: isTabScreen ? "20px" : "32px",
    color: "#004AAD",
  };
  const pt10 = {
    paddingTop: "10px",
    display: "flex",
  };
  const pl = {
    paddingLeft: "10px",
  };
  const pl48 = {
    paddingLeft: "48px",
  };
  const pl20 = {
    paddingLeft: "20px",
  };
  const textinp2 = {
    backgroundColor: "white",
    width: "60%",
  };

  const sentMail = () => {
    const mailtoLink = `mailto:info@myprega.com?subject=About&body=
     Let's start by getting to know you better. Hi, I'm ${encodeURIComponent(
       nm,
     )} You can reply to me at  ${encodeURIComponent(
       email,
     )} I need little help  ${encodeURIComponent(help)}`;

    window.location.href = mailtoLink;
  };

  return (
    <div className="d-flex">
      <div>
        <MiniDrawer />
      </div>
      <div>
        <div className="container px-0 mx-0">
          <div style={{ display: "flex" }}>
            <div>
              <div style={outerwrapper}>
                <div style={heading}>Contact Us</div>
                <form style={{ textAlign: "center" }}>
                  <TextField
                    id="outlined-basic"
                    label="Name"
                    variant="outlined"
                    style={textinp}
                    value={nm}
                    onChange={(e) => setNm(e.target.value)}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Email where we can reach you"
                    variant="outlined"
                    style={textinp}
                    className="my-4"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <TextField
                    id="outlined-basic"
                    label="How can we help you?"
                    variant="outlined"
                    multiline
                    rows={4}
                    style={textinp2}
                    className="my-4"
                    value={help}
                    onChange={(e) => setHelp(e.target.value)}
                  />
                </form>
                <div className="text-center">
                  <button onClick={sentMail} style={submit}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
            <div>
              <div style={wrapperright}>
                <div style={heading2}>Your Customer ID</div>
                <div style={pt15}>P1234567890</div>
                <div style={headingplan}>Plan Detail</div>
                <div style={pt15}>cherish care</div>
                <div style={pt40}>
                  Quote these details in any support query.
                </div>
                <div style={pt60}>{/* <hr></hr> */}</div>
                <div style={contact}>Contact Information</div>
                <div style={pt10}>
                  <img src="/Pregnancycare/mobileimg.png" alt="nt found"></img>
                  <div style={pl}>+91 34567 78910</div>
                </div>
                <div className="d-flex" style={pl48}>
                  <div>DAILY</div>
                  <div style={pl20}>9 am - 7 pm</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
