import React from "react";

const Casepaperone = ({ props }) => {
  return (
    <table className="table table-bordered break-table-td">
      <thead>
        <tr>
          <th>Question</th>
          <th>Answer</th>
        </tr>
      </thead>
      <tbody>
        {props?.casepaperdata1st?.height && (
          <tr>
            <td>1. What is your height?</td>
            <td>{props.casepaperdata1st.height} cm</td>
          </tr>
        )}
        {props?.casepaperdata1st?.weight && (
          <tr>
            <td>2. What is your weight?</td>
            <td>{props.casepaperdata1st.weight} kg</td>
          </tr>
        )}
        {props?.casepaperdata1st?.age && (
          <tr>
            <td>3. What is your age?</td>
            <td>{props.casepaperdata1st.age} years</td>
          </tr>
        )}
        {props?.casepaperdata1st?.addiction && (
          <tr>
            <td>4. Do you have any addiction?</td>
            <td>{props.casepaperdata1st.addiction}</td>
          </tr>
        )}
        {props?.casepaperdata1st?.addtiction_consumption && (
          <tr>
            <td>5. How often do you consume your addiction?</td>
            <td>{props.casepaperdata1st.addtiction_consumption}</td>
          </tr>
        )}
        {props?.casepaperdata1st?.exercise_habit && (
          <tr>
            <td>6. What is your exercise habit?</td>
            <td>{props.casepaperdata1st.exercise_habit}</td>
          </tr>
        )}
        {props?.casepaperdata1st?.sexual_life && (
          <tr>
            <td>7. Are you satisfied with your sexual life?</td>
            <td>{props.casepaperdata1st.sexual_life}</td>
          </tr>
        )}
        {props?.casepaperdata1st?.sex_frequency && (
          <tr>
            <td>8. How frequent is your sexual activity?</td>
            <td>{props.casepaperdata1st.sex_frequency}</td>
          </tr>
        )}
        {props?.casepaperdata1st?.dental_care && (
          <tr>
            <td>9. Do you take care of your dental health?</td>
            <td>{props.casepaperdata1st.dental_care}</td>
          </tr>
        )}
        {props?.casepaperdata1st?.vaccinations && (
          <tr>
            <td>10. Have you taken any vaccinations?</td>
            <td>{props.casepaperdata1st.vaccinations}</td>
          </tr>
        )}
        {props?.casepaperdata1st?.general_lifestyle_habits && (
          <tr>
            <td>11. Describe your general lifestyle habits.</td>
            <td>{props.casepaperdata1st.general_lifestyle_habits}</td>
          </tr>
        )}
        {props?.casepaperdata1st?.lifestyle_issues && (
          <tr>
            <td>12. Are there any lifestyle issues?</td>
            <td>{props.casepaperdata1st.lifestyle_issues}</td>
          </tr>
        )}
        {props?.casepaperdata1st?.work_enviornment && (
          <tr>
            <td>13. How would you describe your work environment?</td>
            <td>{props.casepaperdata1st.work_enviornment}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default Casepaperone;
