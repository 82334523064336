import React, { useEffect, useState } from "react";
import configData from "../config.json";
import { useMediaQuery } from "react-responsive";
import moment from "moment";

const Reports = (props) => {
  const [medicalexperts, setMedicalexperts] = useState([]);
  const [allreports, setAllreports] = useState([]);
  const userId = localStorage.getItem("userId");
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const [clr, setclr] = useState(false);
  const isTabScreen1 = useMediaQuery({
    query: "(min-width: 1024px)" && "(max-width: 1240px)",
  });
  const addtofav = () => {
    setclr(!clr);
  };
  function handleChange(e) {
    const { nodeName, value } = e.target;

    if (nodeName === "INPUT") {
      setRadio(value);
    }
  }

  useEffect(() => {
    setAllreports(props.allreports);
    const uniqueExperts = [];
    const seenExperts = new Set();

    // Loop through all reports to find unique medical experts by name only
    props.allreports.forEach((item) => {
      // If the medical_expert_name hasn't been added to the seenExperts set, add it
      if (!seenExperts.has(item.medical_expert_name)) {
        seenExperts.add(item.medical_expert_name);
        uniqueExperts.push({
          name: item.medical_expert_name,
          value: item.doctor_id, // Optionally add the first doctor_id you encounter
        });
      }
    });

    // Set the unique experts to the state
    setMedicalexperts(uniqueExperts);
  }, [props.allreports]);

  const filterreports = (id) => {
    if (id == "all") {
      setAllreports(props.allreports);
    } else {
      let currentreport = props.allreports.filter(
        (item) => item.doctor_id == id
      );
      setAllreports(currentreport);
    }
  };

  return (
    <>
      <div
        className="d-flex justify-content-between px-3 align-items-center "
        style={{ paddingTop: "30px", position: "relative", gap: "12px" }}
        onChange={handleChange}
      >
        <div
          style={{
            fontSize: isTabScreen ? "24px" : "32px",
            textAlign: "left",
            fontSize: isTabScreen ? "24px" : "32px",
            fontWeight: "600",
            fontFamily: "Poppins",
            color: configData.THEME_COLORS.PRIMARY,
          }}
        >
          Reports
        </div>
        <select
          className="form-select"
          onChange={(e) => {
            if (e.target.value == "Select") return;
            filterreports(e.target.value);
          }}
        >
          <option>Select</option>
          <option value={"all"}>All Reports</option>
          <option value={userId}>My Reports</option>
          {medicalexperts.length > 0 &&
            medicalexperts.map((item) => {
              return <option value={item.value}>{item.name}</option>;
            })}
        </select>
      </div>
      {/* Reports */}
      {allreports &&
        allreports?.length > 0 &&
        allreports.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                border: "1px solid #E8E7E7",
                margin: "20px 30px",
                borderRadius: "12px",
                backgroundColor: "#F0FAF8",
                padding: "20px",
              }}
            >
              <div
                className="d-flex justify-content-between pb-3"
                style={{ color: "#004AAD" }}
              >
                <div
                  style={{
                    paddingLeft: isTabScreen ? "10px" : "30px",
                    fontSize: isTabScreen ? "10px" : "14px",
                  }}
                >
                  {/* {date} */}
                  {item?.scheduled_date
                    ? moment(item?.scheduled_date).format("YYYY")
                    : ""}
                </div>
                <div
                  style={{
                    paddingRight: isTabScreen ? "10px" : "30px",
                    fontSize: isTabScreen ? "10px" : "14px",
                  }}
                >
                  {/* {week} */}
                  {`WEEK ${item?.week}`}
                </div>
              </div>
              <div
                className="text-center"
                style={{
                  fontSize: isTabScreen ? "16px" : "20px",
                  color: "#F0588B",
                }}
              >
                {/* {title} */}
                {item?.patient_consultation_name ||
                  `Consultation Number ${item?.consultation_number}`}
              </div>
              <div
                style={{
                  fontSize: isTabScreen ? "30px" : "35px",
                  fontWeight: "600",
                  paddingTop: "16px",
                  textAlign: "center",
                  color: "#004AAD",
                }}
              >
                {item?.scheduled_date
                  ? moment(item?.scheduled_date).format("DD MMM")
                  : ""}
              </div>
              <div
                className="py-2"
                style={{
                  fontSize: isTabScreen ? "12px" : "14px",
                  color: "#333333",
                  width: "100%",
                  textAlign: "left",
                }}
              >
                Doctor's Name:{" "}
                <strong>
                  {item?.doctor_fname} {item?.doctor_fname && ` ` + item?.doctor_lname}
                </strong>
              </div>
              <div
                className="py-2"
                style={{
                  fontSize: isTabScreen ? "12px" : "14px",
                  color: "#333333",
                  width: "100%",
                  textAlign: "left",
                }}
              >
                Expertise: <strong>{item?.medical_expert_name}</strong>
              </div>
              <div
                style={{
                  fontSize: isTabScreen ? "13px" : "16px",
                  fontWeight: "400",
                  textAlign: "center",
                }}
              >
                {item?.summary || item?.description || "No Summary"}
              </div>
              <div className="d-flex align-items-center justify-content-center mr-2">
                {item?.is_prescription != 0 && (
                  <button
                    className="btn btn-primary text-center m-auto btn-sm"
                    onClick={() => {
                      // console.log("Console", item?.filekey);
                      props.viewprescriptions(item);
                    }}
                  >
                    View Prescription
                  </button>
                )}
                {item?.filekey && (
                  <button
                    className="btn btn-primary text-center m-auto btn-sm"
                    onClick={() => {
                      // console.log("Console", item?.filekey);
                      props.downloadfiles(item?.filekey);
                    }}
                  >
                    View Report
                  </button>
                )}
              </div>
              {/* <div
                className="d-flex justify-content-center py-3 align-items-center"
                style={{ fontSize: isTabScreen ? "12px" : "14px" }}
              >
                <div>Prescription</div>
              </div> */}
              {/* <div
                className="d-flex justify-content-center py-3 align-items-center"
                style={{ fontSize: isTabScreen ? "12px" : "14px" }}
              >
                <div>Report</div>
              </div> */}
            </div>
          );
        })}

      {allreports.length == 0 && (
        <div
          style={{
            fontSize: isTabScreen ? "16px" : "20px",
            textAlign: "center",

            fontFamily: "Poppins",
            color: configData.THEME_COLORS.PRIMARY,
          }}
        >
          No Reports Found
        </div>
      )}
    </>
  );
};

export default Reports;
