import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import configData from "../../config.json";
import { useMediaQuery } from "react-responsive";

import axios from "axios";

function PathologyReportDetail({ date, title, week, reportid }) {
  // console.log("tttttt", title);
  const [report, setReport] = useState([]);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [reports, setReports] = useState([]);

  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const isTabScreen1 = useMediaQuery({
    query: "(min-width: 1024px)" && "(max-width: 1240px)",
  });

  useEffect(() => {
    fetchingreport();
  }, []);

  const fetchingreport = async () => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/consultation/get_pathology_report`,
        {
          consultation_id: reportid,
        },
      );
      if (response.data.success) {
        const report = response.data?.report;
        const reportList = response.data?.report;
        if (reportList.length > 0) {
          setReports(reportList);
        }
        // if(report.length>0)
        // {
        //   const lastitem=report[report.length-1];
        //   setReport(lastitem?.file_name);
        //   setFileUrl(lastitem?.file_url);
        //   setFileType(lastitem?.file_type);
        // }
      } else {
        console.error("Error in getting report:", response.data.error);
      }
    } catch (Error) {
      console.log("Error", Error);
    }
  };
  useEffect(() => {
    console.log("report", report, fileUrl, fileType);
  }, [report]);

  // const handleDownload=(fileurl,filename)=>{
  //   const link=document.createElement('a');
  //   link.href=fileurl;
  //   console.log("link%%",link);
  //   // link.download=filename;
  //  link.setAttribute('download',filename);
  //  link.style.display = 'none';
  //  document.body.appendChild(link);
  //  link.click();
  //  console.log("link%%",link);
  //  document.body.removeChild(link);
  // }

  const handleDownload = (fileData, fileName) => {
    const blob = new Blob([fileData]);
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    link.style.display = "none";
    document.body.appendChild(link);

    link.click();

    // Clean up
    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  };

  const renderFile = (file) => {
    const user = localStorage.getItem("userId");
    const fileUrl = `${configData.SERVER_API_URL}/pathology_reports/${user}/${file.file_name}`;
    if (typeof file.file_name === "string") {
      const fileExtension = file.file_name.split(".").pop().toLowerCase();
      if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
        return (
          <>
            <div
              onClick={() => handleDownload(fileUrl, file.file_name)}
              style={{
                border: "1px solid #E8E7E7",
                // height: '345px',
                marginTop: "38px",
                marginRight: isTabScreen
                  ? "15px"
                  : isTabScreen1
                    ? "20px"
                    : "45px",
                marginLeft: isTabScreen
                  ? "15px"
                  : isTabScreen1
                    ? "20px"
                    : "45px",
                borderRadius: "12px",
                backgroundColor: "#F0FAF8",
              }}
            >
              <img
                className="my-3"
                src={fileUrl}
                alt="Pathology report"
                key={file.file_name}
                // style={{ maxWidth: '100%' }}
              />
            </div>
          </>
        );
      } else if (fileExtension === "pdf") {
        return (
          <>
            <div
              onClick={() => handleDownload(fileUrl, file.file_name)}
              style={{
                border: "1px solid #E8E7E7",
                // height: '345px',
                marginTop: "38px",
                marginRight: isTabScreen
                  ? "15px"
                  : isTabScreen1
                    ? "20px"
                    : "45px",
                marginLeft: isTabScreen
                  ? "15px"
                  : isTabScreen1
                    ? "20px"
                    : "45px",
                borderRadius: "12px",
                backgroundColor: "#F0FAF8",
              }}
            >
              <iframe
                src={fileUrl}
                title="Pathology report PDF"
                key={file.file_name}
              />
            </div>
          </>
        );
      } else {
        return (
          <p key={file.file_name}>No preview available for this file type</p>
        );
      }
    } else {
      return <p key={file.file_name}>File name not available</p>;
    }
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      <div style={{ width: "30vw" }}>
        <div
          style={{
            paddingTop: "48px",
            fontSize: isTabScreen ? "24px" : "32px",
            color: configData.THEME_COLORS.PRIMARY,
            fontFamily: "Poppins",
            fontWeight: "500",
            color: "#F0588B",
            textAlign: "center",
          }}
        >
          Report Details
        </div>
        <hr className="mb-0 w-100"></hr>

        {reports && reports.length > 0 ? (
          reports.map((report) => renderFile(report))
        ) : (
          <p>No reports available</p>
        )}

        {/* </div> */}
      </div>
    </div>
  );
}

export default PathologyReportDetail;
