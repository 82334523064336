import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { useMediaQuery } from "react-responsive";

function HealthVital3({
  img,
  color,
  value,
  unit,
  title,
  mode,
  data,
  x,
  y,
  onSubmit3,
  vital,
}) {
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const [submit, setSubmit] = useState(vital?.length > 0 ? false : true);
  const [input3, setInput3] = useState("");

  const [edit, setisEdit] = useState(vital?.length > 0 ? true : false);
  const [pluseBtn, setPluseBtn] = useState(true);

  const [hover, setHover] = useState(false);

  let h1;

  useEffect(() => {
    if (vital === undefined || vital === null || vital.length === 0) {
      setisEdit(false);
      setSubmit(false);
      setPluseBtn(true);
    } else {
      setisEdit(true);
      setSubmit(false);
    }
  }, [vital]);

  return (
    <div
      style={{
        borderRadius: "12px",
        alignSelf: "center",
        display: "flex",
        flexDirection: "column",
        width: "90%",
        backgroundColor: "#FFFFFF",
        border: "1px solid #E8E7E7",
        boxShadow: "0px 1px 30px rgba(0, 0, 0, 0.08)",
        height: "120%",
      }}
    >
      <div
        className="py-1 py-lg-2 px-1"
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: isTabScreen ? "25%" : "35%",
            backgroundColor: color,
            borderRadius: "12px",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            marginLeft:
              title != "Temperature" && mode === "Consultation" ? "5%" : 8,
          }}
        >
          <img
            className="my-2"
            src={img}
            style={{
              resizeMode: "contain",
              height: isTabScreen ? "20px" : "34px",
            }}
          />
        </div>
        <div
          style={{
            width: "50%",
            textAlign: "center",
            fontWeight: "600",
            fontSize: isTabScreen
              ? "9px"
              : title == "Temperature"
                ? "10px"
                : "13px",
            marginLeft: "10px",
          }}
        >
          {title}
        </div>
      </div>

      {(vital === undefined || vital === null || vital.length === 0) &&
        pluseBtn && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "15%",
              cursor: "pointer",
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => {
              setSubmit(true);
              setPluseBtn(false);
            }}
          >
            <img
              src={"/HealthTracker/plus-circle.png"}
              style={{
                resizeMode: "contain",
                height: "8vh",
              }}
            />
          </div>
        )}

      <div style={{ display: "flex", flexDirection: "column" }}>
        {submit && (
          <div
            style={{
              marginLeft: "10px",
              fontSize: isTabScreen ? "13px" : "14px",
            }}
          >
            Enter value
          </div>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "17px",
          }}
        >
          {submit ? (
            <>
              <div className="d-flex align-items-center justify-content-between">
                <TextField
                  value={input3}
                  size="small"
                  sx={{ mt: 1, width: "50%" }}
                  inputProps={{
                    style: { fontSize: "18px", fontWeight: "600" },
                  }}
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  onChange={(e) => setInput3(e.target.value)}
                />
                <div
                  style={{
                    marginLeft: "6px",
                    fontSize: isTabScreen ? "8px" : "18px",
                    // width: "40%",
                  }}
                >
                  {unit}
                </div>
              </div>
            </>
          ) : edit ? (
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "36.9px",
                }}
              >
                <div
                  style={{
                    fontSize: "24px",
                    fontWeight: "500",
                    overflowX: "auto",
                  }}
                >
                  {vital}
                </div>
                <div
                  style={{
                    marginLeft: "10px",
                    color: "#818181",
                    fontSize: isTabScreen ? "8px" : "18px",
                  }}
                >
                  {unit}
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    fontWeight: "400",
                    fontSize: "12px",
                    color: "#3699FF",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSubmit(true);
                    setInput3(vital);
                  }}
                >
                  {edit === false ? "" : "edit"}
                </div>
                <img
                  src={"/HealthTracker/editCircle.png"}
                  style={{
                    marginLeft: "4px",
                    resizeMode: "contain",
                    height: "8px",
                    width: "8px",
                  }}
                />
              </div>
            </div>
          ) : null}
        </div>
        {submit && (
          <div
            style={{
              background: "#004AAD",
              borderRadius: "4px",
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.160784)",
              marginTop: "10px",
              marginBottom: "-10px",
              color: "#FFFFFF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              alignSelf: "center",
              height: isTabScreen ? "22px" : "28px",
              width: isTabScreen ? "59px" : "79px",
              fontSize: isTabScreen ? "10px" : "12px",
            }}
            onClick={() => {
              setSubmit(false);
              setisEdit(true);
              onSubmit3(input3);
            }}
          >
            Submit
          </div>
        )}
      </div>
    </div>
  );
}

export default HealthVital3;
