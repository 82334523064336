import React from "react";
import Times from "./Times";

function Time(props) {
  console.log("my ids----", props);
  return (
    <div>
      {props.showTime ? (
        <Times
          date={props.date}
          //   trimesternm={props.title}
          //   drpost={props.drpost}
          //   drstatus={props.drstatus}
          timingsafter={props.timingsafter}
          timings={props.timings}
          getTime={props.getTime}
          timingid={props.timingid}
          updateSchedule={props.updateSchedule}
          dates={props.dates}
          dateObjects={props.dateObjects}
          isUpdatedDate={props.isUpdatedDate}
          newUpdateSchedule={props.newUpdateSchedule}
          // onClick={props.onClick()}
        />
      ) : null}
    </div>
  );
}

export default Time;
