import * as React from "react";
import Col from "react-bootstrap/Col";
import configData from "../../config.json";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import Sonostatus from "../../common/Sonostatus";
import sonodescriptions from "../../common/sonographystaticdescription";
import Moreinfo from "../../common/Moreinfo";
export function PathTable1(props) {
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  let i = 1;
  console.log("09", props);
  return (
    <table className="spacing-table">
      <thead>
        <tr
          style={{
            fontFamily: "Poppins",
            fontSize: isTabScreen ? "10px" : "12px",
            fontWeight: "600",
            color: configData.THEME_COLORS.SECONDARY,
            backgroundColor: "rgba(243, 246, 249, 1)",
          }}
        >
          <th>NUMBER</th>
          <th>DATE</th>
          <th>STATUS</th>
        </tr>
      </thead>
      {props?.data?.length > 0 ? (
        props?.data?.map((row, i) => (
          <tbody>
            <tr>
              <td
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                {i + 1}
              </td>
              <td
                style={{
                  fontSize: isTabScreen ? "8px" : "12px",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                {row?.scheduled_date
                  ? moment(row?.scheduled_date).format("DD MMM YYYY")
                  : "Not Scheduled"}
              </td>
              <td>
                {" "}
                {row?.is_completed ? (
                  <img
                    alt="nt found"
                    src={"/Pathology/tickmark.png"}
                    style={{
                      width: "28px",
                      height: "25px",
                      margin: "0px auto",
                    }}
                  ></img>
                ) : (
                  <div
                    style={{
                      fontSize: isTabScreen ? "8px" : "12px",
                      fontWeight: "500",
                      textAlign: "center",
                      color: "#F92A2A",
                    }}
                  >
                    {row?.is_completed ? "COMPLETED" : "NOT COMPLETED"}
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        ))
      ) : (
        <tbody>
          <p className="ps-3" style={{ color: "rgb(0, 74, 173)" }}>
            No summary to show yet
          </p>
        </tbody>
      )}
    </table>
  );
  {
    i++;
  }
}

function PathologyTest(props) {
  const [showmore, setShowmore] = useState(false);
  const [currentdescription, setCurrentdescription] = useState(null);
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const isTabScreen1 = useMediaQuery({
    query: "(min-width: 1024px)" && "(max-width: 1194px)",
  });
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [severity, setSeverity] = useState("");
  const s = {
    fontWeight: "500",
    fontSize: "9px",
  };
  const lock = {
    top: "-6px",
    left: "38%",
    position: "absolute",
    height: "40px",
    width: "40px",
    cursor: "not-allowed",
  };
  const is24HoursBefore = (scheduledDate) => {
    const startOf24HoursBefore = moment(scheduledDate)
      .subtract(1, "day")
      .startOf("day");
    const startOfCurrentDay = moment().startOf("day");
    var msg = "You cant reschedule now";
    const error_color = "error";
    handleAlertMsg(error_color, msg);
    return moment(startOfCurrentDay).isAfter(startOf24HoursBefore);
  };
  const handleAlertMsg = (error_type, msg) => {
    setShowAlert(true);
    setSeverity(error_type);
    setAlertMsg(msg);
    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
  };
  console.log("{PP}", props.clickedindex, props.index, props);
  const isstatus = props.teststatus;
  const len = props.title?.length;
  const text = props.msg1;
  const [clicked, setClicked] = useState(false);
  const [clickedschedule, setClickedschedule] = useState(false);
  const [clr, SetClr] = useState();
  const [schedule, setschedule] = useState(false);
  const [clickedindex, setindex] = useState("1000");
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
    alert(isReadMore);
  };
  const dorescheduleconsultation = (data) => {
    const userId = localStorage.getItem("userId");
    api
      .updatepatientmessagetodoctor({
        patient_id: userId,
        doctor_id: currentcunsulationinfo?.doctor_id,
        consultation_number: currentcunsulationinfo?.consultation_number,
        show_msg: 1,
      })
      .then((res) => {
        if (res.success) {
          getDoctorConsultations(currentcunsulationinfo?.doctor_id);
          setReschedulealert({
            title: "Thank You to Re-schedule your consultation",
            description: `Dr. ${currentcunsulationinfo?.doctor_firstName} ${currentcunsulationinfo?.doctor_lastName} will contact you soon.`,
            consultinfo: currentcunsulationinfo,
          });
          setReschedulestatus(false);
          setCurrentconsultationinfo("");
        }
      })
      .catch((rej) => {
        console.log(rej);
      });
  };
  return (
    <>
      <Col md={6} className="px-0">
        <div
          className="bg-white"
          style={{
            marginTop: "20px",
            marginLeft: props.index % 2 != 0 ? "auto" : "",
            margin: props.index % 2 == 0 ? "20px auto" : "none",
            border: "0.5px solid #CBC6C6",
            borderRadius: "12px",
            fontFamily: "poppins",
            width: isTabScreen ? "96%" : "90%",
            height:
              props.content === true && props.clickedindex === props.id
                ? isTabScreen
                  ? "340px"
                  : "420px"
                : isTabScreen
                ? isReadMore === false
                  ? ""
                  : "300px"
                : isReadMore === false
                ? ""
                : "355px",
            marginBottom:
              props.content === true && props.clickedid === props.id
                ? "20px"
                : "0px",
            boxShadow:
              props.clickedindex === props.id &&
              (props.clicked === true || props.clickcomment === true)
                ? "0px 1px 24px rgba(0, 0, 0, 0.1608)"
                : "none",
            // marginLeft:
            //   isTabScreen || isTabScreen1
            //     ? props.index % 2 === 0
            //       ? "10px"
            //       : "10px"
            //     : props.index % 2 === 0
            //     ? "20px"
            //     : "59px",

            // marginRight:
            //   isTabScreen || isTabScreen1
            //     ? props.index % 2 === 0
            //       ? "10px"
            //       : "10px"
            //     : props.index % 2 === 0
            //     ? "30px"
            //     : "20px",
          }}
        >
          <div
            className="px-3 text-center"
            style={{
              paddingTop: "21px",
            }}
          >
            <div
              style={{
                height: isTabScreen ? "30px" : "58px",
                fontSize: isTabScreen
                  ? len <= 23
                    ? "14px"
                    : "12px"
                  : isTabScreen1
                  ? len <= 23
                    ? "15px"
                    : "17px"
                  : "22px",
                fontWeight: "500",
              }}
            >
              {props.title}
            </div>
            <div
              className="pt-2 d-flex justify-content-between px-md-2 px-lg-2 align-items-center"
              style={{ height: "50px" }}
            >
              <div>
                <img
                  style={{
                    height: isTabScreen ? "20px" : "30px",
                    width: isTabScreen ? "20px" : "30px",
                  }}
                  src={"/Pathology/calendar.png"}
                ></img>
                <div
                  style={{
                    fontSize: isTabScreen ? "6px" : "9px",
                    color: "#333333",
                    fontWeight: "500",
                  }}
                >
                  {props.date}
                </div>
                {props.date ? null : (
                  <>
                    {" "}
                    <span
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      {" "}
                      {moment(props.week[0]).format("MMM DD")}
                    </span>
                    <br />
                    {" - "}
                    <span
                      style={{
                        fontSize: "14px",
                        lineHeight: "1",
                      }}
                    >
                      {moment(props.week[props.week.length - 1]).format(
                        "MMM DD"
                      )}
                    </span>
                  </>
                )}
              </div>
              <div
                style={{
                  fontSize: isTabScreen
                    ? props.teststatus === "COMPLETED"
                      ? "2vw"
                      : "1.9vw"
                    : "20px",
                  fontWeight: "500",
                  color:
                    props.teststatus === "COMPLETED" ? "#58CF99" : "#F92A2A",
                }}
              >
                {props.teststatus}
              </div>
            </div>
            {props?.data?.is_completed != 1 && (
              <div
                style={{
                  display: "block",
                  marginTop: "15px",
                }}
              >
                <Sonostatus data={props?.data} />
              </div>
            )}
            <div
              className="px-2 d-flex align-items-center"
              style={{ paddingTop: "15px", justifyContent: "space-between" }}
            >
              <div>
                {isstatus === "COMPLETED" ? (
                  <>
                    {props?.data?.fileKey && (
                      <button
                        style={s}
                        className={`${
                          props.viewindex === props.id
                            ? "patient-consultation-btn-active"
                            : "patient-consultation-btn"
                        }`}
                        onClick={() => props.viewreport()}
                      >
                        Report
                      </button>
                    )}

                    {/* <div
                    onClick={() => props.onclick2()}
                    style={{
                      width: isTabScreen ? "" : "120px",
                      width: window.innerWidth / 11,
                      height: window.innerHeight / 13.5,
                      backgroundColor: "#FFFFFF",
                      border: "1px 1px 1px 0.5px",
                      borderStyle: "solid",
                      borderColor: "#F3EFEF",
                      boxShadow:
                        props.clickedindex === props.id
                          ? "rgba(44, 39, 56, 0.08) 0px 4px 8px inset"
                          : "0px 2px 8px rgba(0, 0, 0, 0.078)",
                      color:
                        props.clickedindex === props.id &&
                        props.clickcomment === true
                          ? "#F0588B"
                          : "black",
                      borderRadius: "0px 0px 12px 12px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={"/Pathology/message.png"}
                      style={{
                        height: isTabScreen ? "10px" : "16px",
                        width: isTabScreen ? "10px" : "16px",
                      }}
                    />
                    <div style={{ fontWeight: "400", fontSize: "0.8vw" }}>
                      Comment
                    </div>
                  </div> */}
                  </>
                ) : props.date != null ? (
                  <>
                    {/* <div
                    onClick={() => props.onClick()}
                    style={{
                      width: window.innerWidth / 11,
                      height: window.innerHeight / 13.5,
                      backgroundColor: "#FFFFFF",
                      border: "1px 1px 1px 0.5px",
                      borderStyle: "solid",
                      borderColor: "#F3EFEF",
                      boxShadow:
                        props.clickedindex === props.id
                          ? "rgba(44, 39, 56, 0.08) 0px 4px 8px inset"
                          : "0px 2px 8px rgba(0, 0, 0, 0.078)",
                      color:
                        props.clickedindex === props.id ? "#F0588B" : "black",
                      borderRadius: "0px 0px 12px 12px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      fontSize: "9px",
                      cursor: "pointer",
                    }}
                  >
                    Re-Schedule
                  </div> */}
                  </>
                ) : props.is_completed === 0 &&
                  props.consulted_by_doctor === true ? (
                  <div
                    style={{
                      width: window.innerWidth / 11,
                      height: window.innerHeight / 13.5,
                      backgroundColor: "#FFFFFF",
                      border: "1px 1px 1px 0.5px",
                      borderStyle: "solid",
                      borderColor: "#F3EFEF",
                      boxShadow:
                        props.clickedindex === props.id
                          ? "rgba(44, 39, 56, 0.08) 0px 4px 8px inset"
                          : "0px 2px 8px rgba(0, 0, 0, 0.078)",
                      color:
                        props.clickedindex === props.id ? "#F0588B" : "black",
                      borderRadius: "0px 0px 12px 12px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    onClick={() => props.onClick()}
                  >
                    <img
                      src={"/Pathology/scheduling.png"}
                      style={{
                        height: isTabScreen ? "10px" : "16px",
                        width: isTabScreen ? "10px" : "16px",
                      }}
                    />
                    <div
                      style={{
                        fontWeight: "400",
                        fontSize: "0.8vw",
                        cursor: "pointer",
                      }}
                    >
                      Schedule
                    </div>
                  </div>
                ) : (
                  <>
                    <div
                      className="d-flex justify-content-center position-relative"
                      style={{
                        marginTop: "15px",
                        opacity: "0.4",
                      }}
                    >
                      <div style={lock}>
                        <img src="/DoctorConsultation/lock.png"></img>
                      </div>
                      <div
                        style={{
                          width: window.innerWidth / 11,
                          height: window.innerHeight / 13.5,
                          backgroundColor: "#FFFFFF",
                          border: "1px 1px 1px 0.5px",
                          borderStyle: "solid",
                          borderColor: "#F3EFEF",
                          boxShadow:
                            props.clickedindex === props.id
                              ? "rgba(44, 39, 56, 0.08) 0px 4px 8px inset"
                              : "0px 2px 8px rgba(0, 0, 0, 0.078)",
                          color:
                            props.clickedindex === props.id
                              ? "#F0588B"
                              : "black",
                          borderRadius: "0px 0px 12px 12px",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                        onClick={() => props.onClick()}
                      >
                        <img
                          src={"/Pathology/scheduling.png"}
                          style={{
                            height: isTabScreen ? "10px" : "16px",
                            width: isTabScreen ? "10px" : "16px",
                          }}
                        />
                        <div
                          style={{
                            fontWeight: "400",
                            fontSize: "0.8vw",
                            cursor: "pointer",
                          }}
                        >
                          Schedule
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {props.date && (
                <>
                  <button
                    style={s}
                    className={`${
                      props.viewindex === props.id
                        ? "patient-consultation-btn-active"
                        : "patient-consultation-btn"
                    }`}
                    onClick={() => props.onClick5()}
                  >
                    View
                  </button>
                </>
              )}

              {props.is_completed == 0 && props?.teststatus == "SCHEDULED" && (
                <>
                  {(() => {
                    console.log(props);
                    const scheduleDate = moment(props?.data?.scheduled_date);
                    const currentDate = moment().startOf("day");
                    const oneDayBefore = scheduleDate
                      .clone()
                      .subtract(1, "days");
                    if (currentDate.isBefore(oneDayBefore)) {
                      return (
                        <button
                          className={`${
                            props.viewindex === props.id
                              ? "patient-consultation-btn-active"
                              : "patient-consultation-btn"
                          }`}
                          onClick={() => {
                            props.handlewantreschedule();
                          }}
                        >
                          Want Re-schedule
                        </button>
                      );
                    }
                    return null;
                  })()}
                </>
              )}
            </div>
          </div>
          {isstatus === "COMPLETED" ? (
            props.clickcomment === true ? (
              <div
                className="px-1 mx-3"
                style={{
                  fontSize: props.content === true ? "1vw" : "0.9vw",
                  fontWeight: "400",
                  marginTop: "22px",
                  paddingBottom: "30px",
                  color: "#333333",
                  overflowY: props.content === true ? "scroll" : "hidden",
                  height:
                    props.content === true && props.clickedid === props.id
                      ? window.innerHeight / 5.5
                      : window.innerHeight / 6.5,
                  marginBottom:
                    props.content === true && props.clickedid === props.id
                      ? "30px"
                      : "0px",
                  backgroundColor: "white",
                  border:
                    props.content === true
                      ? "1px solid #F3EFEF"
                      : "1px solid #F3EFEF",
                  borderRadius:
                    props.content === true
                      ? "14px 0px 14px 14px"
                      : "14px 0px 14px 14px",
                }}
              >
                Lorem ipsum dolor sit amet, consec adipiscing elit, sed do
                eiusmod Lorem ipsum dolor sit amet Dolores eos pariatur aperiam
                nemo porro mollitia
                {props.content === true ? (
                  <span id="moretext">
                    Dolores eos pariatur aperiam nemo porro mollitia ipsam
                    cupiditate nihil quos aliquid minima fugiat ab sint veniam,
                    voluptatem harum, quis amet ad. Lorem, ipsum dolor sit amet
                    consectetur adipisicing elit. Rerum eaque officiis sunt
                    molestias, ipsum iure ex deserunt totam hic ducimus
                    excepturi incidunt possimus sequi voluptatum assumenda, quia
                    ea temporibus dolores?
                  </span>
                ) : (
                  <span
                    id="points"
                    onClick={() => props.onclick3()}
                    style={{ color: "#004AAD", cursor: "pointer" }}
                  >
                    ...READ MORE
                  </span>
                )}
              </div>
            ) : (
              <>
                {props?.data?.summary && (
                  <div
                    className="d-flex align-items-start justify-content-start"
                    style={{
                      gap: "15px",
                      padding: "15px",
                    }}
                  >
                    <div className="">
                      <img
                        className=""
                        style={{
                          height: "20px",
                          width: "20px",
                        }}
                        src={"/Pathology/star.png"}
                      ></img>
                    </div>
                    <div
                      className=""
                      style={{
                        fontSize: isTabScreen ? "14px" : "16px",
                        fontWeight: "400",

                        color: "#333333",
                        overflowY: "hidden",
                        lineHeight: "1",
                      }}
                    >
                      {props?.data?.summary || ""}
                    </div>
                  </div>
                )}
              </>
            )
          ) : (
            <>
              {/* <div className="d-flex align-items-center">
              <div className="w-25">
                <img
                  className="ms-3"
                  style={{
                    height: "25px",
                    width: "25px",
                  }}
                  src={"/Pathology/star.png"}
                ></img>
              </div>
              <div className="px-3 my-3">
                {isReadMore ? (
                  <>
                    <div
                      // className="px-3"
                      style={{
                        fontSize: isTabScreen ? "12px" : "13px",
                        display: "inline",
                      }}
                    >
                      {isTabScreen ? text?.slice(0, 10) : text?.slice(0, 129)}
                    </div>
                    {isTabScreen
                      ? text?.length >= 10
                      : text?.length >= 129 && (
                          <span
                            onClick={toggleReadMore}
                            className="read-or-hide"
                            style={{
                              fontSize: isTabScreen ? "12px" : "13px",
                              color: "#004AAD",
                              cursor: "pointer",
                            }}
                          >
                            {isReadMore === true ? " READ MORE" : "SHOW LESS"}
                          </span>
                        )}
                  </>
                ) : (
                  <div
                    // className="px-3"
                    style={{
                      fontSize: isTabScreen ? "9px" : "11px",
                      fontWeight: "400",
                      paddingTop: "22px",
                      paddingBottom: "30px",
                      color: "#333333",
                      height: isReadMore ? "100px" : "",
                    }}
                  >
                    {props.msg1}
                    <span
                      onClick={toggleReadMore}
                      style={{ color: "#004AAD", cursor: "pointer" }}
                    >
                      {isReadMore === false ? "...show less" : ""}
                    </span>
                  </div>
                )}
              </div>
            </div> */}
            </>
          )}
          {!props?.data?.summary && (
            <div
              className="d-flex align-items-start justify-content-start"
              style={{
                gap: "15px",
                padding: "15px",
              }}
            >
              <div className="">
                <img
                  className=""
                  style={{
                    height: "20px",
                    width: "20px",
                  }}
                  src={"/Pathology/star.png"}
                ></img>
              </div>
              <div
                className=""
                style={{
                  fontSize: isTabScreen ? "14px" : "16px",
                  fontWeight: "400",

                  color: "#333333",
                  overflowY: "hidden",
                  lineHeight: "1",
                }}
              >
                {`${sonodescriptions[props.number]?.slice(0, 90)}... `}{" "}
                <span
                  style={{
                    cursor: "pointer",
                    color: "red",
                  }}
                  onClick={() => {
                    setCurrentdescription(sonodescriptions[props.number]);
                    setShowmore(true);
                  }}
                >
                  Read more
                </span>
              </div>
            </div>
          )}
        </div>
        {showmore && (
          <Moreinfo
            open={showmore}
            message={currentdescription}
            handleclose={() => {
              setShowmore(false);
              setCurrentdescription(null);
            }}
          />
        )}
      </Col>
    </>
  );
}
export default PathologyTest;
