import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import moment from "moment";
import axios from "axios";
import { PieChart, Pie, Cell } from "recharts";
const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

function Consultationcard(props) {
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const chartStyle = {
    position: "absolute",
    // right: "-23%",
    top: "-44%",
  };
  const [isHover, setIsHover] = useState(false);
  const [percentage, setPercentage] = useState();
  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const gaugedata = [
    { label: "Completed Consultations", value: parseInt(props.per) },
    { label: "Incomplete Consultations", value: 100 - props.per },
  ];

  return (
    <>
      <Col
        md={6}
        lg={6}
        className=""
        onClick={() => props.onClick()}
        style={{
          cursor: "pointer",
        }}
      >
        <div
          style={{
            border: "",
          }}
        >
          <div style={{ paddingTop: "0px" }}>
            <div
              style={{
                paddingLeft: "0px",
                border: isHover ? "1px solid #E5739A" : "1px solid #E8E7E7",
                marginLeft: isTabScreen ? "20px" : "59px",
                marginRight: isTabScreen ? "20px" : "50px",
                backgroundColor: "white",
                boxShadow:
                  isHover === true
                    ? "0px 4px 8px rgba(44, 39, 56, 0.078)"
                    : "none",
                borderRadius: "12px",
                marginTop: "40px",
              }}
              onMouseEnter={() => handleMouseEnter()}
              onMouseLeave={() => handleMouseLeave()}
            >
              <div className="d-flex">
                <img
                  style={{
                    marginLeft: "31.5px",
                    marginTop: "28px",
                    height: "60px",
                    width: "60px",
                    borderRadius: "50%",
                  }}
                  src={props.imgdr}
                />
                <div style={{ paddingLeft: "20px", paddingTop: "32px" }}>
                  <div
                    className="mb-0"
                    style={{
                      font: "Poppins",
                      fontWeight: "600",
                      fontSize: isTabScreen ? "18px" : "20px",
                      lineHeight: "30px",
                      color: "#333333",
                    }}
                  >
                    {" "}
                    {props.title}{" "}
                  </div>
                  <div
                    style={{
                      font: "Poppins",
                      fontWeight: "400",
                      fontSize: isTabScreen ? "13px" : "16px",
                      lineHeight: "24px",
                      paddingTop: "12px",
                      color: "#333333",
                    }}
                  >
                    {" "}
                    {props.post}
                  </div>
                  <div
                    style={{
                      font: "Poppins",
                      fontWeight: "400",
                      fontSize: isTabScreen ? "13px" : "16px",
                      lineHeight: "24px",
                      paddingTop: "12px",
                      color: "#333333",
                    }}
                  >
                    {" "}
                    {props.education}
                  </div>
                  <div
                    style={{
                      font: "Poppins",
                      fontWeight: "400",
                      fontSize: isTabScreen ? "13px" : "16px",
                      lineHeight: "24px",
                      paddingTop: "12px",
                      color: "#333333",
                    }}
                  >
                    {" "}
                    Exp -{props.experience}yr
                  </div>
                </div>
              </div>
              <div
                className="d-flex"
                style={{
                  marginTop: isTabScreen ? "15px" : "32px",
                  marginLeft: "29px",
                }}
              >
                <div>
                  <img
                    src={props.imgclinic}
                    style={{
                      height: "30px",
                      width: "30px",
                    }}
                  ></img>
                  <div
                    style={{
                      font: "Poppins",
                      fontWeight: "400",
                      fontSize: "8px",
                      lineHeight: "12px",
                      color: "#707070",
                    }}
                  >
                    clinic
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      font: "Poppins",
                      fontWeight: "100",
                      fontSize: isTabScreen ? "14px" : "16px",
                      lineHeight: "24px",
                      paddingLeft: "22px",
                      color: "#333333",
                      paddingRight: "14px",
                    }}
                  >
                    {props.address}
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginLeft: "55px",
                  // marginTop: isTabScreen ? "16px" : "30px",
                }}
              >
                <Row className="mt-5 me-5" style={{ alignItems: "center" }}>
                  <div
                    className="col-4"
                    style={{
                      font: "Poppins",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: isTabScreen ? "9px" : "11px",
                      color: " #333333",
                      textAlign: "center",
                    }}
                  >
                    {props.info?.upcomingConsultationDate?.scheduled_date ? (
                      moment(
                        props.info?.upcomingConsultationDate?.scheduled_date
                      ).format("MMM DD")
                    ) : (
                      <span>No event</span>
                    )}
                    <div>
                      <img
                        className="mx-auto"
                        style={{
                          height: "30px",
                          width: "30px",
                          marginTop: "10px",
                          marginLeft: "8px",
                        }}
                        src={"/DoctorConsultation/calendar.png"}
                      ></img>
                      <p
                        className="mb-0"
                        style={{
                          font: "Poppins",
                          fontWeight: "400",
                          fontSize: "8px",
                          lineHeight: "12px",
                        }}
                      >
                        Upcoming
                      </p>
                      <p
                        style={{
                          font: "Poppins",
                          fontWeight: "400",
                          fontSize: "8px",
                          lineHeight: "12px",
                        }}
                      >
                        Event
                      </p>
                    </div>
                  </div>
                  <div className="text-center col-4">
                    {props.consultation ? (
                      <div
                        style={{
                          font: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: isTabScreen ? "9px" : "11px",
                          lineHeight: "20px",
                          color: " #333333",
                          textAlign: "center",
                        }}
                      >
                        {props.consultationcompleted}
                        <span>/</span>
                        {props.consultationtotal}
                      </div>
                    ) : (
                      <div
                        style={{
                          font: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: isTabScreen ? "9px" : "11px",
                          color: " #333333",
                          textAlign: "center",
                        }}
                      >
                        0<span>/</span>0
                      </div>
                    )}
                    <div>
                      <img
                        className="mx-auto"
                        style={{
                          height: "23px",
                          width: "23px",
                          marginTop: "10px",
                        }}
                        src={"/DoctorConsultation/tickmark.png"}
                      ></img>
                      <p
                        className="mb-0"
                        style={{
                          font: "Poppins",
                          fontWeight: "400",
                          fontSize: "8px",
                          lineHeight: "12px",
                          paddingTop: "6px",
                        }}
                      >
                        Consultation
                      </p>
                      <p
                        style={{
                          font: "Poppins",
                          fontWeight: "400",
                          fontSize: "8px",
                          lineHeight: "12px",
                        }}
                      >
                        Completed
                      </p>
                    </div>
                  </div>
                  <div className="text-center col-4 position-relative">
                    <div>
                      <PieChart
                        // className="position-absolute"
                        style={chartStyle}
                        // height={isTabScreen ? 100 : 130}
                        // width={isTabScreen ? 100 : 240}
                      >
                        <Pie
                          startAngle={180}
                          endAngle={0}
                          innerRadius="55%"
                          data={gaugedata}
                          dataKey="value"
                          labelLine={false}
                          blendStroke
                          isAnimationActive={true}
                          cy={"70%"}
                        >
                          <Cell fill="#58CF99" />
                          <Cell fill="gray" />
                        </Pie>
                      </PieChart>
                      <div
                        style={{
                          color: "#58CF99",
                          // top: isTabScreen ? -50 : -70,

                          textAlign: "center",
                          fontSize: isTabScreen ? "20px" : "26px",
                          fontWeight: "700",
                        }}
                      >
                        {props.per ? Math.floor(props.per) : 0}
                      </div>
                      <div
                        style={{
                          color: "#707070",

                          textAlign: "center",
                          fontSize: isTabScreen ? "8px" : "8px",
                          fontWeight: "500",
                        }}
                      >
                        Pregnancy Care
                        <br /> Completed
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
              <hr className="mt-3 w-100"></hr>

              <div className="my-2 d-flex" style={{ marginLeft: "29px" }}>
                <div className="text-center">
                  <img
                    src="/DoctorConsultation/star.png"
                    style={{ marginTop: "12px" }}
                  ></img>
                  <p
                    style={{
                      font: "Poppins",
                      fontWeight: "400",
                      fontSize: "8px",
                      lineHeight: "12px",
                      color: "#707070",
                    }}
                  >
                    Importance
                  </p>
                </div>
                <div style={{ paddingLeft: "22px", width: "70%" }}>
                  <p
                    style={{
                      font: "Poppins",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: isTabScreen ? "10px" : "12px",
                      lineHeight: "18px",
                      color: "#333333",
                    }}
                  >
                    {props.tip}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </>
  );
}
export default Consultationcard;
