import React, { useState, useEffect } from "react";
import configData from "../config.json";
import { useMediaQuery } from "react-responsive";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import moment from "moment";

var selected1, selected2;
function ReportBoxYoga({
  onClick,
  onClick2,
  item,
  isVisible,
  clicked,
  isVisibleInspect,
  patientId,
  isPathology,
  isSonography,
}) {
  const [clicked1, setClicked] = useState(false);
  const [clicked2, setClicked2] = useState(false);
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState();
  const [patientData, setPatientData] = useState([]);
  console.log("nmae", item);

  const Toggle = () => setShow(!show);
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });

  useEffect(() => {
    // if (item?.batch_id) {
    fetchPatientList(item?.id);
    // }
  }, []);
  async function fetchPatientList(id) {
    try {
      if (id) {
        const response = await axios.post(
          `${configData.SERVER_API_URL}/consultation/get_patient_name_list_from_yoga_batches_id`,
          {
            batch_id: id,
          }
        );
        if (response.data.success) {
          setPatientData(response.data.patient_list);
        } else {
          console.error(
            "Error adding add user education details:",
            response.data.error
          );
        }
      }
    } catch (error) {
      console.error("Error", error);
    }
  }
  return (
    <>
      <div
        className={clicked ? "col-6" : "col-4"}
        style={{ marginTop: "20px" }}
      >
        <div
          style={
            isVisible && selected === item.id
              ? {
                  alignSelf: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "97.5%",
                  borderRadius: "12px",
                  backgroundColor: "#FFFFFF",
                  border: "0.5px solid #CBC6C6",
                  boxSizing: "border-box",
                  border: "0.5px solid #CBC6C6",
                  boxShadow: "0px 1px 24px rgba(0, 0, 0, 0.1608)",
                  borderRadius: "12px",
                }
              : {
                  alignSelf: "center",
                  display: "flex",
                  width: "97.5%",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "12px",
                  backgroundColor: "#FFFFFF",
                  border: "0.5px solid #CBC6C6",
                  boxSizing: "border-box",
                  border: "0.5px solid #CBC6C6",
                  borderRadius: "12px",
                }
          }
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              paddingTop: "20px",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <div>
              <img
                // src={"/Communication/test1.png"}
                src={
                  item?.profile_pic
                    ? configData.API_URL + item?.profile_pic
                    : "/Profile/Group-1151.png"
                }
                style={{
                  resizeMode: "contain",
                  height: isTabScreen ? "40px" : "60px",
                  width: isTabScreen ? "40px" : "60px",
                  marginRight: "10px",
                  marginLeft: "15%",
                  borderRadius: "50%",
                }}
                alt="Group-1151"
              />
            </div>
            <div style={{ marginRight: "10%" }}>
              <div
                style={{
                  fontSize: isTabScreen ? "15px" : "18px",
                  fontWeight: "600",
                  color: "#333333",
                  marginTop: "13px",
                  marginLeft: "6px",
                }}
              >
                {"Batch No "}
              </div>

              <div
                style={{
                  fontSize: isTabScreen ? "14px" : "17.3px",
                  fontWeight: "500",
                  color: configData.THEME_COLORS.PRIMARY,
                  marginLeft: "6px",
                }}
              >
                {/* {"01 OCT 2022"} */}
                {item?.name}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    fontSize: isTabScreen ? "15px" : "17px",
                    color: "#333333",
                    width: "70px",
                  }}
                >
                  Days :
                </div>
                <div
                  className="d-flex"
                  style={{
                    fontSize: isTabScreen ? "15px" : "15px",
                    fontWeight: "500",
                    color: "#333333",
                    //   marginLeft: "3%",
                    width: "70%",
                  }}
                >
                  <div>{item.days}</div>
                  {/* <div className="ms-1">{item.lname}</div> */}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "2%",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    fontSize: isTabScreen ? "12px" : "14px",
                    color: configData.THEME_COLORS.PRIMARY,
                  }}
                >
                  Session :
                </div>
                <div
                  style={{
                    marginLeft: "2%",
                    width: isTabScreen ? "50%" : "150px",
                  }}
                >
                  <div
                    style={{
                      fontSize: isTabScreen ? "14px" : "16px",
                      fontWeight: "500",
                      color: configData.THEME_COLORS.PRIMARY,
                      // wordBreak: "break-word",
                    }}
                  >
                    {item.session}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "2%",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    fontSize: isTabScreen ? "12px" : "14px",
                    color: configData.THEME_COLORS.PRIMARY,
                  }}
                >
                  Time :
                </div>
                <div
                  style={{
                    marginLeft: "2%",
                    width: isTabScreen ? "50%" : "150px",
                  }}
                >
                  <div
                    style={{
                      fontSize: isTabScreen ? "14px" : "16px",
                      fontWeight: "500",
                      color: configData.THEME_COLORS.PRIMARY,
                      // wordBreak: "break-word",
                    }}
                  >
                    {item.timing}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "80%",
            }}
          >
            <div
              style={{
                marginTop: "40px",
                marginRight: isTabScreen ? "2%" : "5%",
                fontWeight: "400",
                fontSize: isTabScreen ? "14px" : "20px",
                color: "#7C9CBF",
                display: "flex",
                cursor: "pointer",
                height: "44.41px",
                width: isTabScreen ? "80px" : "120px",
                backgroundColor: "white",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "35px",

                boxShadow:
                  isVisibleInspect && selected2 === item.id
                    ? " inset 0px 4px 8px rgba(44, 39, 56, 0.078)"
                    : "0px 4px 8px rgba(44, 39, 56, 0.078)",
                color:
                  isVisibleInspect && selected2 === item.id
                    ? "#7C9CBF"
                    : "#111",
                borderRadius: "10%",
              }}
              onClick={() => Toggle()}
            >
              Patient List
            </div>
            <div
              style={{
                marginTop: "40px",
                marginLeft: isTabScreen ? "5%" : "10%",
                fontWeight: "400",
                fontSize: isTabScreen ? "0.7em" : "0.8em",
                color: "#7C9CBF",
                display: "flex",
                cursor: "pointer",
                height: "44.41px",
                width: isTabScreen ? "70px" : "110px",
                backgroundColor: "white",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "35px",
                boxShadow:
                  isVisibleInspect && selected2 === item.id
                    ? " inset 0px 4px 8px rgba(44, 39, 56, 0.078)"
                    : "0px 4px 8px rgba(44, 39, 56, 0.078)",
                color:
                  isVisibleInspect && selected2 === item.id
                    ? "#F0588B"
                    : "#7C9CBF",
                borderRadius: "10%",
              }}
              onClick={() => {}}
            >
              Start Video Call
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={show}
        onClose={Toggle}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="lg"
      >
        <Box className="modal-container">
          {/* <div
                               className="modal-content text-end w-50"
                               style={{ backgroundColor: "#FFF0F5" }}
                             > */}
          <div
            style={{
              background: "#fff",
              color: "#111",
              width: "400px",
              height: "700px",
              overflowY: "scroll",
              padding: "2em",
              borderRadius: "10px",
              scrollbarWidth: "none",
            }}
          >
            <span
              className="close3"
              onClick={Toggle}
              style={{ color: "white", cursor: "pointer" }}
            >
              &times;
            </span>
            <div
              className="flex justify-center font-semibold"
              style={{
                fontSize: "1.2em",
              }}
            >
              Patient List
            </div>

            {patientData?.map((item, i) => (
              <div
                className="flex justify-start mx-2 my-3"
                key={item?.patient_id}
              >
                <span
                  style={{
                    marginRight: "10px",
                  }}
                >
                  {i + 1}
                </span>{" "}
                {item?.patient_name}
              </div>
            ))}
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ReportBoxYoga;
