import React from "react";

const Handleclick = ({
  scheduledDate,
  currentDateCompare,
  onClick,
  item,
  button_name,
  isTabScreen,
}) => {
  return (
    <button
      style={{
        width: "100px",
        height: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#004AAD",
        border: "1px solid rgba(0, 74, 173, 0.5)",
        fontSize: isTabScreen ? "8px" : "12px",
        color: "white",
        boxShadow: "0px 4px 8px rgba(44, 39, 56, 0.078)",
        borderRadius: "5px",
        cursor:
          scheduledDate === currentDateCompare ? "not-allowed" : "pointer",
        marginBottom: "10px",
      }}
      type="button"
      data-id={item.id}
      onClick={onClick}
      data-type={item.consultation_type}
      data-week={item.pregnancy_week}
      data-props={JSON.stringify(item)}
    >
      {button_name}
    </button>
  );
};

export default Handleclick;
