import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import Casepaperone from "./casepapers/Casepaperone";
import Casepapertwo from "./casepapers/Casepapertwo";
import Casepaperthree from "./casepapers/Casepaperthree";
import { ImEnlarge } from "react-icons/im";

function Casepaper(props) {
  const [tab, setTab] = useState(1);
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });

  return (
    <div>
      <div
        className="d-flex justify-content-between px-3 align-items-center"
        style={{ paddingTop: "30px", paddingBottom: "30px" }}
      >
        <div
          style={{
            textAlign: "center",
            fontSize: isTabScreen ? "24px" : "32px",
            color: "#004AAD",
            fontFamily: "Poppins",
            fontWeight: "600",
            color: "#004AAD",
          }}
        >
          Case Paper
        </div>
        <button
          onClick={() => props.onClick()}
          className="btn btn-primary btn-sm"
        >
          <ImEnlarge />
        </button>
        {/* <div>
          <img src="./Communication/downloadarrow.png"></img>
        </div> */}
      </div>

      <div
        style={{
          width: "100%",
          // background: "rgba(240, 250, 248, 0.502)",
          border: "1px solid #E8E7E7",
          // borderRadius: "12px",
          // margin: "0px auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <div>
          <div className="pt-2 ps-2">
            <select
              className="form-select mb-2"
              onChange={(e) => {
                setTab(e.target.value);
              }}
            >
              <option value={"1"}> General lifestyle habits & History</option>
              <option value={"2"}> Personal Family Medical Profile</option>
              <option value={"3"}>Reproductive Menstrual History</option>
            </select>
            <hr
              className="w-100 mb-2"
              style={{
                borderTop: "3px solid #e66e97",
              }}
            />
            {tab == 1 && <Casepaperone props={props} />}
            {tab == 2 && <Casepapertwo props={props} />}
            {tab == 3 && <Casepaperthree props={props} />}
          </div>
          {/* <img src="./Communication/cam.png"></img> */}

          {/* <div style={{ position: "absolute", right: "6px", bottom: "6px" }}>
            <img
              src="./Communication/zoombtn.png"
              style={{ height: "16px", width: "16px" }}
              onClick={() => props.onClick()}
            ></img>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Casepaper;
