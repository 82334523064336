import React, { useState, useEffect, useRef, useCallback } from "react";
import { useHistory, useLocation, useNavigate } from "react-router-dom";

import moment from "moment";
import Radium, { StyleRoot } from "radium";
import { fadeIn } from "react-animations";
import axios from "axios";
import MiniDrawer from "../Components/MiniDrawer";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import Typography from "@mui/material/Typography";
import Dropdown from "../Components/Dropdown";
import configData from "../config.json";
import {
  followUpDate,
  isTimeEnabledForConsultation,
  JoinDate,
} from "../utils/common";

import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ReportBox from "../Components/ReportBox";
import RightBox from "../Components/RightBox";
import ReportDetailsBox from "../Components/ReportDetailsBox";
import TextField from "@mui/material/TextField";
import Menu from "@mui/material/Menu";
import Modal from "@mui/material/Modal";
import { motion } from "framer-motion";
import Badge from "@mui/material/Badge";

import { styled, alpha } from "@mui/material/styles";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import { useMediaQuery } from "react-responsive";
import AddPrescriptionBox from "../Components/AddPrescriptionBox";
import ReportsBox from "../Components/ReportsBox";
import RightBox2 from "../Components/RightBox2";
import MeetingNew from "./MeetingNew";
import AlertShow from "../Components/AlertShow";
import ReportBoxYoga from "../Components/ReportBoxYoga";
import { toast } from "react-toastify";
import api from "../../app-service/api/api";
import Sonographybtn from "../../common/doctor/Sonographybtn";
import { debounce } from "lodash";

const consultTypes = ["Online", "In Person"];

const consultationNumbers = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
];
const carePercentages = ["0-25 %", "25-50 %", "50-75 %", "75-100 %"];

const styles = {
  width: "30vw",
  fadeIn: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeIn, "fadeIn"),
  },
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "20px",
  width: "25%",
  bgcolor: "background.paper",
};
let reportdata;
let patientid;
function Communication() {
  let navigate = useNavigate();
const consentimageRef = useRef();
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const isScreen2 = useMediaQuery({
    query: "(min-width: 1400px)" && "(max-width: 1920px)",
  });
  const isScreen3 = useMediaQuery({
    query: "(min-width: 2000px)",
  });
  const [drType, setDrtype] = useState("");
  const [number, setNumber] = useState("");
  const [consultations1, setConsultations1] = useState();
  const [week, setWeek] = useState("");
  const [showLock, setShowLock] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [clickedInspect, setClickedInspect] = useState();
  const [isYogaBatchId, setIsYogaBatchId] = useState();
  const [showCalendar, setShowCalendar] = useState(false);
  const [timing, setTiming] = useState();
  const [data, setData] = useState([]);
  const [upload, setUpload] = useState(false);
  const [uploadsono, setUploadsono] = useState(false);
  const wrapperRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);
  const [isVisibleInspect, setIsVisibleInspect] = useState(true);
  const [audioVedio, setAudioVedio] = useState(true);

  const [desc, setDesc] = useState(true);

  const [searched, setSearched] = useState("");
  const [selected, setSelected] = useState();
  const [renderagain, setRenderagain] = useState(false);
  const [date, setDate] = useState();
  const [highlightDates, setHighlightDates] = useState([]);

  const [dateClicked, setDateClicked] = useState(false);
  const [startConsultation, setStartConsultation] = useState(false);
  const [startConsultationCall, setStartConsultationCall] = useState(false);
  const [startConsultationCall2, setStartConsultationCall2] = useState(false);
  const [startConsult, setStartConsult] = useState(false);
  const [followup, setFollowup] = useState(false);
  const [consultNo, setConsultNo] = useState(1);
  const [consultnm, setConsultnm] = useState("");
  const [editTimeSlot, setEditTimeSlot] = useState(false);
  const [clickedNext, setClickedNext] = useState(false);
  const [reports1, setReports1] = useState([]);
  const [reportsBatch, setReportsBatch] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [consultComplete, setConsultComplete] = useState();
  const [consultationfinish, setConsultationfinish] = useState();
  const [addPrescription, setAddPrescription] = useState(false);
  const [addedprescibe, setAddedprescibe] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [online, setOnline] = useState();
  const [weekdate, setWeekdate] = useState([]);
  const location = useLocation();
  const [submenu, setSubmenu] = useState(false);
  const [consultaionId, setConsultaionId] = useState();
  const [profileData, setProfileData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [chatData, setChatData] = useState();
  const [patientId, setPatientId] = useState();

  const [filteredPatients, setFilteredPatients] = useState([]);
  const [defaultSelected, setDefaultSelected] = useState(true);
  const [isPathology, setIsPathology] = useState(false);
  const [isSonography, setIsSonography] = useState(false);
  const [isYogaExperts, setIsYogaExperts] = useState(false);
  const [scanCompleted, setScanCompleted] = useState(false);
  const [reportLink, setReportLink] = useState();
  const [summary, setSummary] = useState("");
  const [confirm, setConfirm] = useState();
  const [assignPhlebo, setAssignPhlebo] = useState();
  const [sampleCollected, setSampleCollected] = useState();

  const [senderId, setSenderId] = useState();
  const [receivedId, setReceiverId] = useState();
  const [phleboName, setPhleboName] = useState();
  const [phleboid, setPhleboid] = useState();
  const [phlebono, setPhlebono] = useState();
  const [file, setFile] = useState([]);
  const [filemulti, setFilemulti] = useState([]);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [showSent, setShowSent] = useState(true);
  const [consultItem, setConsultItem] = useState();

  const [notification, setNotification] = useState([]);
  const [shownotification, setShownotification] = useState(false);
  const [count, setCount] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [severity, setSeverity] = useState("");
  const [isScanCompleted, setIsScanCompleted] = useState("");
  const [isSonographyReport, setIsSonographyReport] = useState();
  const [sonographyreport, setSonographyreport] = useState();
  const [indexval, setIndexval] = useState(0);
  const [mode, setMode] = useState(false);
  const [consentform, setConsentform] = useState();
  const [consentformarr, setConsentformarr] = useState([]);
  const [open3, setOpen3] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const handleClose3 = () => setOpen3(false);
  const [imageurl, setImageUrl] = useState("");
  const doctorTypes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
  const [consultcomplete, setConsultcomplete] = useState(doctorTypes);
  const [callroute, setCallroute] = useState();
  let arr;

  useEffect(() => {
    switch (userData?.medical_expert_name) {
      case "Gynecologist":
        break;
      case "General Physician":
        break;
      case "Pediatrician":
        break;
      case "Yoga Expert": {
        setIsYogaExperts(true);
        break;
      }
      case "Dietitian":
        break;
      case "Psychiatrist":
        break;
      case "Breastfeeding Consultant":
        break;
      case "Pathology Lab": {
        setIsPathology(true);
        break;
      }
      case "Sonography Centre": {
        setIsSonography(true);
        break;
      }
      default:
    }

    fetchNotifications();
  }, [userData]);

  useEffect(() => {
    if (isPathology === true) {
      setConsultcomplete(doctorTypes.slice(0, 5));
    } else if (isSonography === true) {
      setConsultcomplete(doctorTypes.slice(0, 7));
    } else {
      setConsultcomplete(doctorTypes.slice(0, 16));
    }
  }, [isPathology, isSonography]);

  useEffect(() => {
    getapi1();
  }, []);
  const lock = {
    top: "12px",
    left: "46%",
    position: "absolute",
    height: "40px",
    width: "40px",
    cursor: "not-allowed",
  };
  const lock1 = {
    top: "-8px",
    left: "46%",
    position: "absolute",
    height: "40px",
    width: "40px",
    cursor: "not-allowed",
  };
  // useEffect(() => {
  //   if (profileData) {
  //     getpatientlist();
  //   }
  // }, [profileData]);

  useEffect(() => {
    const filteredData = reports1?.filter(
      (patient) =>
        patient.fname.toLowerCase().includes(searched.toLowerCase()) ||
        patient.lname.toLowerCase().includes(searched.toLowerCase())
    );
    setFilteredPatients(filteredData);

    const filteredDataByWeek =
      number !== ""
        ? filteredData?.filter((patient) => patient.week === parseInt(number))
        : filteredData;
    setFilteredPatients(filteredDataByWeek);

    const filteredDatapercentage =
      week !== ""
        ? filteredDataByWeek?.filter((patient) => {
            const percentage = patient.completion_percentage;
            switch (week) {
              case 0:
                return percentage >= 0 && percentage <= 25;
              case 1:
                return percentage > 25 && percentage <= 50;
              case 2:
                return percentage > 50 && percentage <= 75;
              case 3:
                return percentage > 75 && percentage <= 100;
              default:
                return false;
            }
          })
        : filteredDataByWeek;
    setFilteredPatients(filteredDatapercentage);

    const filteredDataconsultcomplete =
      drType !== ""
        ? filteredDatapercentage?.filter(
            (patient) => patient.consultation_completed === parseInt(drType)
          )
        : filteredDatapercentage;
    setFilteredPatients(filteredDataconsultcomplete);
  }, [searched, reports1, number, week, drType]);

  const handleRemoveFile = (indextoremove) => {
    setFile((prevFiles) =>
      prevFiles.filter((file, index) => index !== indextoremove)
    );
  };
  const getapi1 = async () => {
    try {
      const id = localStorage.getItem("userId");
      if (id) {
        const response = await axios.post(
          `${configData.SERVER_API_URL}/users/get_single_user`,
          {
            userId: id,
          }
        );
        if (response.data.success) {
          setProfileData(response.data);
          setUserData(response.data?.user);
          getpatientlist(
            response.data?.user?.area_id,
            response.data?.user?.id,
            response.data?.user?.medical_expert_id
          );
          // getpatientlist();
        } else {
          console.error(
            "Error adding add user education details:",
            response.data.error
          );
        }
      }
    } catch (error) {
      console.error("Error", error);
    }
  };
  const showsubmenu = () => {
    setSubmenu(!submenu);
  };

  useEffect(() => {
    if (location?.state == 1) {
      setClickedInspect(true);
      setEditTimeSlot(true);
      setShowCalendar(true);
    }
  }, []);

  useEffect(() => {
    getYogaConsultations();
  }, [isYogaExperts]);

  useEffect(() => {}, [consultItem]);

  const getYogaConsultations = async () => {
    try {
      const response = await axios.get(
        `${configData.SERVER_API_URL}/consultation/get_yoga_batches_timiing`
      );
      if (response.data.success) {
        setReportsBatch(response.data?.Batches);
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };
  useEffect(() => {
    if (patientid) {
      getConsultations(patientid);
      // const interval = setInterval(() => {
      //   getConsultations(patientid);
      // }, 1000);
      // return () => clearInterval(interval);
    }
  }, [patientid, profileData]);

  const getConsultations = async (id) => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/consultation/get_patient_consultation`,
        {
          patient_id: id,
          doctor_id: profileData?.user?.id,
        }
      );
      if (response.status === 200) {
        setConsultations1(response.data?.patient_consultations);
        setConsentformarr(response.data?.consent_form);
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const getpatientlist = async (area_id, doctorId, medical_expert_id) => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/users/get_paitent_list`,
        {
          area_id: area_id,
          doctor_id: doctorId,
          medical_expert_id: medical_expert_id,
        }
      );
      if (response.status === 200) {
        setReports1(response.data?.patientList);
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };
  const confirmConsult = async (modeId) => {
    setMode(true);
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/consultation/confirm_decline_pathology_consultation`,
        {
          consultation_id: consultaionId,
          sender_id: senderId,
          receiver_id: receivedId,
          is_confirmed: modeId,
        }
      );
      if (response.data.success) {
        if (modeId === 1) {
          var msg = "Consultation Confirmed!";
          const error_color = "success";
          handleAlertMsg(error_color, msg);
          getConsultations(patientid);
        } else {
          var msg = "Consultation Declined!";
          const error_color = "error";
          handleAlertMsg(error_color, msg);
          navigate("/doctor/Patient", {
            state: {
              id: 2,
              patientid: receivedId,
              cardid: consultaionId,
            },
          });
        }
        setConfirm(true);
      } else {
        console.error("Error Confirm API:", response.data.error);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };
  const assignPhlebologist = async () => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/consultation/assign_phlebologist`,
        {
          consultation_id: consultaionId,
          name: phleboName,
          phlebologistId: phleboid,
          mobile_no: phlebono,
        }
      );
      if (response.data.success) {
        var msg = "Phlebologist Assigned!";
        const error_color = "success";
        handleAlertMsg(error_color, msg);
        // alert("Phlebologist Assigned!");
        setAssignPhlebo(true);
        setConfirm(true);
        getConsultations(patientid);
      } else {
        console.error("Error assign API:", response.data.error);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };
  const alertPhlebologist = async () => {
    var msg = "I will be only open one day prior";
    const error_color = "error";
    handleAlertMsg(error_color, msg);
  };
  const sampleCollectConfirm = async () => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/consultation/sample_collected_confirmation`,
        {
          consultation_id: consultaionId,
        }
      );
      if (response.data.success) {
        var msg = "Sample Collected!";
        const error_color = "success";
        handleAlertMsg(error_color, msg);
        // alert("Sample Collected!");
        setSampleCollected(true);
        getConsultations(patientid);
      } else {
        console.error("Error isSampleCollected API:", response.data.error);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };
  const startVideoConsultFromChat = (data) => {
    console.log(data);
    setCallroute(data);
    setStartConsultation(true);
    setStartConsultationCall(true);
    setStartConsultationCall2(true);
    setShowCalendar(false);
    setClicked(false);
  };
  const fetchNotifications = async () => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/common/get_notification`,
        {
          receiver_id: userData.id,
        }
      );

      if (response.status === 200) {
        const l = response.data.notifications?.length;

        setNotification(response.data.notifications);
        let cnt = response.data.notifications[l - 1].notification_count;

        setCount(cnt);
      } else {
        console.log("Error in getting notification");
      }
    } catch (error) {
      console.log("Error in getting catch block", error.message);
    }
  };
  useEffect(() => {
    if (patientid) {
      getConsultations(patientid);
    }
  }, [renderagain, mode]);
  const readnotification = async () => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/common/read_notification`,
        { receiver_id: userData.id }
      );
      if (response.status === 200) {
      } else {
        console.log("Error in reading notification");
      }
    } catch (error) {
      console.log("Error", error.message);
    }
    fetchNotifications();
  };
  const shownotificationofdr = () => {
    setShownotification(!shownotification);
    readnotification();
  };

  const handleClose2 = () => setOpen2(false);

  const handleClickOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen2(true);
  };
  const handleChange1 = (event) => {
    let i = event.target.value;
    setDrtype(i);
  };
  const handleChange2 = (event) => {
    setNumber(event.target.value);
  };

  const handleChange3 = (event) => {
    setWeek(event.target.value);
  };
  const handleClick = (item) => {
    setChatData(item);
    setIsVisible(true);
    setAudioVedio(false);
    setIsVisibleInspect(false);
    setClicked(true);
    setClickedInspect(false);
    setPatientId(item?.id);
  };
  const handleClick2 = (item) => {
    setChatData(item);
    reportdata = item;

    patientid = item?.id;

    getConsultations(item?.id);
    if (clicked) setClicked(false);
    setIsVisible(false);
    setIsVisibleInspect(false);
    setClickedInspect(item.id);
    setShowCalendar(true);

    setIsYogaBatchId(item.id);
  };
  const handleAdd = () => {
    setShowCalendar(false);
    setAddedprescibe(true);
  };
  const handleReschedule = () => {
    navigate("/Doctor_consultation");
  };

  const onClickDay = (item) => {
    setDateClicked(true);
  };

  const handleSort = () => {
    const data = filteredPatients;
    data?.sort((a, b) => {
      let c = new Date(a.created_date);
      let d = new Date(b.created_date);
      return desc ? d - c : c - d;
    });
    setFilteredPatients(data);
  };
  useEffect(() => {}, [file]);

  // const handleFileChange = (e) => {
  //    const selectedFiles = e.target.files;
  //   console.log("++", selectedFiles,e);
  //   setFile((prevFiles) => [...prevFiles, ...selectedFiles]);
  // };
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleFileChangemultiple = (e) => {
    const selectedFile = e.target.files[0]; // Get the first file

    if (selectedFile) {
      setFilemulti(selectedFile); // Save the selected file to state
    } else {
    }
  };

  const handleSonographyCompletedConfirm = async () => {
    try {
      if (consultaionId !== undefined) {
        const response = await axios.post(
          `${configData.SERVER_API_URL}/consultation/scan_completed_confirmation`,
          {
            consultation_id: consultaionId,
          }
        );
        if (response.data.success) {
          setScanCompleted(!scanCompleted);
          getConsultations(patientid);
        }
      } else {
        var msg = "Please select consulations";
        const error_color = "error";
        handleAlertMsg(error_color, msg);
      }
    } catch (error) {
      console.log("Error :", error);
    }
  };

  const handleAddSonographyReport = async () => {
    try {
      let flag = 0;
      if (reportLink === "" || summary === "") {
        flag = 1;
      }
      if (flag === 0) {
        const formData = new FormData();
        formData.append("patient_id", patientId);
        formData.append("consultation_id", consultaionId);
        formData.append("files", file);
        formData.append("Links", reportLink);
        formData.append("summary", summary);

        const response = await axios.post(
          `${configData.SERVER_API_URL}/consultation/add_sonography_report`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.data.success) {
          var msg = "File uploaded successfully!";
          const error_color = "success";
          getConsultations(patientid);
          handleAlertMsg(error_color, msg);

          setFileUploaded(true);
          setScanCompleted(true);
        } else {
        }
      } else {
        var msg = "Please enter all details..";
        const error_color = "error";
        handleAlertMsg(error_color, msg);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleUpdateSonographyStatus = async () => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/consultation/update_consultation_status`,
        {
          consultation_id: consultaionId,
          patient_id: patientId,
        }
      );
      if (response.data.success) {
        getConsultations(patientid);
        var msg = "Your consulation is done";
        const error_color = "success";
        handleAlertMsg(error_color, msg);
      } else {
        console.log("Error : ");
      }
    } catch (error) {
      console.log("Error :", error);
    }
  };
  const handleUpload = async (data) => {
    const formData = new FormData();
    formData.append("patient_id", data?.patient_id);
    formData.append("consultation_id", data?.id);
    formData.append("summary", summary);
    formData.append("file", filemulti);
    formData.append("doctor_id", data?.doctor_id);
    formData.append("consultation_number", data?.consultation_number);
    formData.append(
      "patient_consultation_name",
      data?.patient_consultation_name
    );
    formData.append("medical_expert_name", userData?.medical_expert_name);

    api
      .reportupload(formData)
      .then((res) => {
        if (res.status == "Success") {
          setFileUploaded(true);
          getConsultations(patientid);
        }
      })
      .catch((rej) => {
        console.log(rej);
      });
    //try {

    //   const response = await axios.post(
    //     `${configData.SERVER_API_URL}/consultation/add_pathology_report`,
    //     formData
    //   );

    //   console.log("calling api....");
    //   if (response.status === 200) {
    //     console.log("@", response.data);
    //     setFileUploaded(true);
    //     getConsultations(patientid);
    //   } else {
    //     console.log("Error in adding reports");
    //   }
    //   // formData.files = arr;
    //   // formData.append("files",arr );
    //   // formData.files = 'Ajay';

    //   console.log("formData", formData);
    // } catch (error) {
    //   console.error("Upload error:", error);
    // }
  };

  const markAsDone = async () => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/consultation/update_consultation_status`,
        {
          consultation_id: consultaionId,
          patient_id: patientId,
        }
      );
      if (response.data.success) {
        var msg = "Consultation Marked As Done!";
        const error_color = "success";
        handleAlertMsg(error_color, msg);
        getConsultations(patientid);
        // alert("Consultation Marked As Done!");
      } else {
        console.error("Error markAsdone API:", response.data.error);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const handleAlertMsg = (error_type, msg) => {
    setShowAlert(true);
    setSeverity(error_type);
    setAlertMsg(msg);
    setTimeout(() => {
      setShowAlert(false);
    }, 2500);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsVisible(false);
    }
  };

  const handleClickCommunication = () => {
    getpatientlist();
    setStartConsultationCall(false);
    setStartConsultation(false);
    setShowCalendar(false);
    setClickedInspect(false);
  };

  const handleMeetingLeave = () => {
    alert("Please add prescription now");
    setStartConsultationCall(false);
    setStartConsultationCall2(false);
    setStartConsultation(false);
    setShowCalendar(false);
    setClicked(true);
    const userId = localStorage.getItem("userId");
    api.deleteconsulationtoken({
      consultation_id: consultaionId,
      doctor_id: userId,
    });
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearched(searchTerm);
    setDesc(true);
  };

  useEffect(() => {
    if (consultations1?.length > 0 && defaultSelected) {
      const defaultItem = consultations1[0];

      setConsultaionId(defaultItem?.id);
      setSelected(defaultItem?.id);
      setFollowup(defaultItem?.follow_up);
      setConsultNo(defaultItem?.consultation_number);
      setConsultnm(defaultItem?.patient_consultation_name);
      // setIndexval()
      setDate(defaultItem?.scheduled_date);

      setTiming(defaultItem?.timing);
      setDateClicked(true);
      setOnline(defaultItem.consultation_type);
      setHighlightDates(defaultItem.dates);
      setWeekdate(defaultItem?.weekDates);
      setReceiverId(defaultItem?.patient_id);
      setSenderId(defaultItem?.doctor_id);
      setConsultItem(defaultItem);
      setPatientId(defaultItem?.patient_id);
      setIsScanCompleted(defaultItem?.is_scan_completed);
      const currentDate = new Date();
      const diffInMilliseconds =
        new Date(defaultItem?.scheduled_date) - currentDate;
      const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);

      if (diffInDays <= 1) {
        setShowLock(false);
      } else {
        // if()
        setShowLock(true);
      }
      // setConsultComplete(defaultItem?.is_completed);
      setScanCompleted(false);
      setDefaultSelected(false);
      setConsultationfinish(defaultItem?.is_completed);
      setIsSonographyReport(true);
      // setIsScanComplete
      // if(defaultItem?.sonography_report)
      // {
      //   setSonographyreport(true);
      // }
      // else{
      //   setSonographyreport(false);
      // }
    }
  }, [consultations1, defaultSelected]);
  let defaultItem;
  useEffect(() => {
    if (defaultSelected === false) {
      const defaultItemid = consultItem?.id;
      {
        consultations1.map((item) => {
          if (item?.id === defaultItemid) {
            defaultItem = item;
          }
        });
      }
      setUpload(defaultItem?.pathology_report?.[0]?.filekey ? true : false);
      const currentDate = new Date();
      const diffInMilliseconds =
        new Date(defaultItem?.scheduled_date) - currentDate;

      const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);

      if (diffInDays <= 1) {
        setShowLock(false);
      } else {
        setShowLock(true);
      }
      setConsultaionId(defaultItem?.id);
      setSelected(defaultItem?.id);
      setFollowup(defaultItem?.follow_up);
      setConsultNo(defaultItem?.consultation_number);
      setConsultnm(defaultItem?.patient_consultation_name);
      setDate(defaultItem?.scheduled_date);
      setTiming(defaultItem?.timing);
      setDateClicked(true);
      setOnline(defaultItem?.consultation_type);
      setHighlightDates(defaultItem?.dates);
      setWeekdate(defaultItem?.weekDates);
      setReceiverId(defaultItem?.patient_id);
      setSenderId(defaultItem?.doctor_id);
      setConsultItem(defaultItem);
      setPatientId(defaultItem?.patient_id);
      setIsScanCompleted(defaultItem?.is_scan_completed);
      setConsultComplete(!!parseInt(defaultItem?.is_completed));
      setConsultationfinish(defaultItem?.is_completed);
      setScanCompleted(false);
    }
  }, [consultItem, consultations1, defaultSelected]);

  const showconsentform = () => {
    switch (consultItem.consultation_number) {
      case 21:
        setImageUrl(
          consentformarr[0]?.image_file?.split(", consultation_id")[0]
        );
        break;
      case 22:
        setImageUrl(
          consentformarr[1]?.image_file?.split(", consultation_id")[0]
        );
        break;
      case 23:
        setImageUrl(
          consentformarr[2]?.image_file?.split(", consultation_id")[0]
        );
        break;
      case 24:
        setImageUrl(
          consentformarr[3]?.image_file?.split(", consultation_id")[0]
        );
        break;
      case 25:
        setImageUrl(
          consentformarr[4]?.image_file?.split(", consultation_id")[0]
        );
        break;
      case 26:
        setImageUrl(
          consentformarr[5]?.image_file?.split(", consultation_id")[0]
        );
        break;
      case 27:
        setImageUrl(
          consentformarr[6]?.image_file?.split(", consultation_id")[0]
        );
        break;
      default:
        alert("Something went wrong...");
    }
    // setConsentform(consentform);

    setOpen3(true);
  };
const handleDownload = () => {
  if (consentimageRef.current) {
    const link = document.createElement("a");
    link.href = consentimageRef.current.src; 
    link.download = "image.jpg"; 
    link.click();
  }
}
  // confirm
  const StyledMenu = styled((props) => (
    <Menu sx={{ left: "-7%", top: "5%" }} elevation={0} {...props} />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          backgroundColor: "white",
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: "white",
        },
      },
    },
  }));

  useEffect(() => {
    const interval = setInterval(() => {
      document.getElementById("uploadButton")?.classList.toggle("noop");
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div
      style={{
        display: "flex",
        // height:
        //   startConsultation && !startConsult
        //     ? "100vh"
        //     : clickedInspect &&
        //       !startConsult &&
        //       !addPrescription &&
        //       showCalendar
        //     ? "125vh"
        //     : addPrescription || showReport
        //     ? "115vh"
        //     : "100%",
        width: "100%",
      }}
    >
      <MiniDrawer />
      <StyleRoot
        style={{
          height: "100%",
          width: "100%",
          // width: clicked?"40%":"100%",
          backgroundColor: configData.THEME_COLORS.BACKGROUND,
        }}
      >
        <div className="test" style={styles.fadeIn}>
          {clickedInspect && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "4%",
                  marginLeft: "2%",
                }}
              >
                <img
                  onClick={() => {
                    setClickedInspect(false);
                    setShowCalendar(false);
                    setStartConsultation(false);
                    setStartConsult(false);
                    setEditTimeSlot(false);
                    setIsVisible(false);
                    setAddPrescription(false);
                  }}
                  src={"/Communication/left.png"}
                  style={{
                    resizeMode: "contain",
                    height: isTabScreen ? "21px" : "38px",
                    width: isTabScreen ? "14px" : "28px",
                    cursor: "pointer",
                    marginTop: "12px",
                  }}
                  alt="Communication"
                />
                <div
                  style={{
                    fontSize: isTabScreen ? "22px" : "32px",
                    fontWeight: "600",
                    color: configData.THEME_COLORS.PRIMARY,
                    marginLeft: "2%",
                  }}
                >
                  {reportdata?.fname}
                </div>
              </div>
              <div
                style={{
                  fontSize: isTabScreen ? "14px" : "24px",
                  fontWeight: "600",
                  color: configData.THEME_COLORS.SECONDARY,
                  marginTop: "7px",
                  marginLeft: "6.5%",
                }}
              >
                {startConsult
                  ? "Consultation" + " " + consultNo
                  : "Communication"}
              </div>
              <hr
                style={{
                  marginTop: "18px",
                  width: "80%",
                  marginLeft: "6.5%",
                }}
              ></hr>
              {!startConsultation && consultations1 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: "6.5%",
                      marginTop: "-3%",
                      width: "722px",
                    }}
                  >
                    {consultations1?.map((item, index) => (
                      <div
                        className=""
                        onClick={() => {
                          // index={index}
                          setIndexval(index);
                          setConsultaionId(item?.id);
                          setSelected(item?.id);
                          setFollowup(item?.follow_up);
                          setConsultNo(item?.consultation_number);
                          setIndexval(index);
                          setConsultnm(defaultItem?.patient_consultation_name);
                          setDate(item?.scheduled_date);

                          setTiming(item?.timing);
                          setDateClicked(true);
                          setOnline(item.consultation_type);
                          setHighlightDates(item.dates);
                          setWeekdate(item?.weekDates);
                          setReceiverId(item?.patient_id);
                          setSenderId(item?.doctor_id);
                          setConsultItem(item);
                          setPatientId(item?.patient_id);
                          setIsScanCompleted(item?.is_scan_completed);
                          setConsultComplete(!!parseInt(item?.is_completed));
                          setScanCompleted(false);

                          // consentformarr.map((consent,i)=>{

                          // })
                          // }
                        }}
                        style={{
                          height: isTabScreen ? "90px" : "130px",
                          width: isTabScreen ? "30px" : "55px",
                          backgroundColor:
                            item.id === selected ? "#004AADCC" : "#FFFFFF",
                          clipPath:
                            "polygon(25% 55%, 100% 55%, 78% 100%, 0% 100%)",
                          marginRight: -14,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "1px solid rgba(0, 74, 173, 0.5)",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          style={{
                            color: item.id === selected ? "white" : "#004AADCC",
                            marginTop: "140%",
                            fontSize:
                              isTabScreen && item.id === selected
                                ? "16px"
                                : item.id === selected
                                ? "40px"
                                : isTabScreen
                                ? "14px"
                                : "35px",
                            fontWeight: item.id === selected ? "600" : "500",
                          }}
                        >
                          {index + 1}
                        </div>
                      </div>
                    ))}
                  </div>
                  <motion.div
                    animate={{
                      x:
                        consultNo === 1 || consultNo === 16 || consultNo === 21
                          ? "3%"
                          : consultNo === 2 ||
                            consultNo === 17 ||
                            consultNo === 22
                          ? "7%"
                          : consultNo === 3 ||
                            consultNo === 18 ||
                            consultNo === 23
                          ? "12%"
                          : consultNo === 4 ||
                            consultNo === 19 ||
                            consultNo === 24
                          ? "16%"
                          : consultNo === 5 ||
                            consultNo === 20 ||
                            consultNo === 25
                          ? "20%"
                          : consultNo === 6 || consultNo === 26
                          ? "24%"
                          : consultNo === 7 || consultNo === 27
                          ? "28%"
                          : consultNo === 8
                          ? "32%"
                          : consultNo === 9
                          ? "37%"
                          : consultNo === 10
                          ? "42%"
                          : consultNo === 11
                          ? "47%"
                          : consultNo === 12 && "52%",
                    }}
                    transition={{ delay: 0.2 }}
                    style={{ marginBottom: "20px" }}
                  >
                    <div
                      style={{
                        marginTop: "5%",
                        width: isTabScreen ? "36vw" : "35vw",
                        backgroundColor: "#FFFFFF",
                        border: "1.07143px solid #CBC6C6",
                        borderRadius: isTabScreen
                          ? "52.857px 52.857px 12.7143px 12.7143px"
                          : "102.857px 102.857px 25.7143px 25.7143px",
                        display: "flex",
                        flexDirection: "column",
                        paddingBottom: "12px",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "600",
                          fontSize: isTabScreen ? "15px" : "30px",
                          marginTop: "5%",
                          textAlign: "center",
                        }}
                      >
                        {consultnm
                          ? `${consultnm}`
                          : `Consultation ${consultNo}`}
                      </div>
                      {!editTimeSlot && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: isTabScreen ? "6%" : "8%",
                            width: "100%",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            alt="not fount"
                            src={"/Communication/calendar1.png"}
                            style={{
                              marginTop: "1px",
                              marginRight: "10%",
                              resizeMode: "contain",
                              height: isTabScreen ? "35px" : "70px",
                              width: isTabScreen ? "35px" : "70px",
                            }}
                          />

                          <div style={{}}>
                            <div
                              style={{
                                marginLeft: "10px",
                                fontSize: isTabScreen ? "15px" : "24px",
                                fontWeight: "400",
                              }}
                            >
                              {date
                                ? moment(date).format("Do MMM")
                                : "Not Scheduled"}
                            </div>
                            <div
                              style={{
                                marginLeft: "10px",
                                fontSize: isTabScreen ? "18px" : "26px",
                                fontWeight: "500",
                                color: "#004AAD",
                              }}
                            >
                              {timing}
                            </div>
                          </div>
                        </div>
                      )}
                      {editTimeSlot && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            alignSelf: "center",
                            marginTop: isScreen2 ? "3%" : "1%",
                          }}
                        >
                          <img
                            alt="not found"
                            src={"/Communication/calendar1.png"}
                            style={{
                              marginTop: "8px",
                              marginRight: "12px",
                              resizeMode: "contain",
                              height: isTabScreen
                                ? "30px"
                                : isScreen2
                                ? "80px"
                                : "70px",
                              width: isTabScreen
                                ? "30px"
                                : isScreen2
                                ? "80px"
                                : "70px",
                            }}
                          />

                          <div style={{}}>
                            <div
                              style={{
                                fontSize: isTabScreen
                                  ? "12px"
                                  : isScreen2
                                  ? "24px"
                                  : "26px",
                                fontWeight: "500",
                                color: configData.THEME_COLORS.PRIMARY,
                              }}
                            >
                              {weekdate[0]}
                              <div>-</div>
                              {weekdate[6]}
                            </div>
                          </div>
                          <Box
                            sx={{
                              marginTop: isScreen2 ? "5%" : 0,
                              width: isTabScreen ? "100%" : "68%",
                            }}
                          >
                            <FormControl fullWidth size={"small"}>
                              <Select
                                sx={{
                                  boxShadow: "none",
                                  ".MuiOutlinedInput-notchedOutline": {
                                    border: 0,
                                  },
                                }}
                                style={{
                                  backgroundColor: "#F2F2F2",
                                  fontSize: isTabScreen
                                    ? "10px"
                                    : isTabScreen && clicked
                                    ? "10px"
                                    : "16px",
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={drType}
                                displayEmpty
                                onChange={handleChange1}
                              >
                                <MenuItem
                                  value=""
                                  style={{
                                    fontSize: isTabScreen ? "10px" : "16px",
                                    fontFamily: "Poppins",
                                    fontWeight: "500",
                                    color: "#868686",
                                  }}
                                >
                                  Select Type
                                </MenuItem>
                                {consultTypes?.map((item, index) => {
                                  return (
                                    <MenuItem
                                      value={index}
                                      style={{
                                        fontSize: isTabScreen ? "10px" : "16px",
                                        fontFamily: "Poppins",
                                        fontWeight: "500",
                                        color: "#868686",
                                      }}
                                    >
                                      {item}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Box>
                        </div>
                      )}
                      {!editTimeSlot && (
                        <div
                          style={{
                            fontSize: isTabScreen
                              ? "16px"
                              : isTabScreen && consultComplete
                              ? "15px"
                              : consultComplete
                              ? "18px"
                              : "27px",
                            fontWeight: "600",
                            color: "#868686",
                            alignSelf: !consultComplete
                              ? "center"
                              : "flex-start",
                            marginTop: "5%",
                            marginLeft: !consultComplete ? 0 : "16%",
                          }}
                        >
                          {isPathology
                            ? "Home Sample Collection"
                            : isSonography
                            ? "In-Person"
                            : online}
                        </div>
                      )}
                      {isSonography && consultItem?.scheduled_date && (
                        <div
                          style={{
                            marginTop: "2%",
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "space-around",
                            alignSelf: "center",
                          }}
                        >
                          <Sonographybtn
                            data={consultItem}
                            getConsultations={getConsultations}
                            userData={userData}
                          />
                        </div>
                      )}

                      {!consultComplete && !isPathology && (
                        <div
                          style={{
                            marginTop: "",
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "space-around",
                            alignSelf: "center",
                            marginBottom: "12px",
                          }}
                        >
                          {/* <div
                            onClick={() => setEditTimeSlot(true)}
                            style={{
                              fontSize: isTabScreen ? "12px" : "18px",
                              fontWeight: "400",
                              textAlign: "center",
                              backgroundColor: "#F3EFEF",
                              borderRadius: "15px",
                              height: isTabScreen ? "5vh" : "8vh",
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                              padding: "3%",
                              color: "#7C9CBF",
                            }}
                          >
                            Edit Time Slot
                          </div> */}

                          {!isSonography && (
                            <div
                              style={{
                                fontSize: isTabScreen ? "12px" : "18px",
                                fontWeight: "400",
                                textAlign: "center",
                                backgroundColor: scanCompleted
                                  ? "green"
                                  : "#F3EFEF",
                                height: isTabScreen ? "5vh" : "8vh",
                                display: "flex",
                                alignItems: "center",

                                padding: "3%",
                                borderRadius: "15px",
                                color: "black",
                                // color: scanCompleted ? "white" : "#7C9CBF",
                                // display:isSonography && date!=null && timing!=null && "none"
                              }}
                            >
                              {isSonography &&
                              date != null &&
                              timing != null &&
                              isScanCompleted === 0 &&
                              !isYogaExperts ? (
                                " Patient visited & scan completed"
                              ) : isYogaExperts ? (
                                "Start Video Call"
                              ) : isSonography &&
                                date === null &&
                                timing === null ? (
                                <div
                                  className="position-relative"
                                  style={{ opacity: "0.4" }}
                                >
                                  <div style={lock1}>
                                    <img
                                      src="/DoctorConsultation/lock.png"
                                      alt="nt found"
                                    ></img>
                                  </div>
                                  Patient visited & scan completed
                                </div>
                              ) : (
                                !isSonography && (
                                  <>
                                    {date === null && timing === null ? (
                                      <div>
                                        <div
                                          className="position-relative"
                                          style={{ opacity: "0.4" }}
                                        >
                                          <div style={lock1}>
                                            <img
                                              src="/DoctorConsultation/lock.png"
                                              alt="nt found"
                                            ></img>
                                          </div>
                                          Start Consultation
                                        </div>
                                      </div>
                                    ) : JoinDate(date) ? (
                                      isTimeEnabledForConsultation(timing) ? (
                                        <div
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            if (
                                              !isSonography &&
                                              !isYogaExperts
                                            ) {
                                              setShowCalendar(false);
                                              setAudioVedio(true);
                                              setClicked(true);
                                              setIsVisible(true);
                                            } else if (
                                              isSonography &&
                                              date != null &&
                                              timing != null &&
                                              !isScanCompleted &&
                                              !isYogaExperts
                                            ) {
                                              handleSonographyCompletedConfirm();
                                            } else if (isYogaExperts) {
                                              startVideoConsultFromChat();
                                            } else {
                                              setScanCompleted(!scanCompleted);
                                            }
                                          }}
                                        >
                                          Start Consultation
                                        </div>
                                      ) : (
                                        <div>
                                          <div
                                            className="position-relative"
                                            style={{ opacity: "0.4" }}
                                          >
                                            <div style={lock1}>
                                              <img
                                                src="/DoctorConsultation/lock.png"
                                                alt="nt found"
                                              ></img>
                                            </div>
                                            Start Consultation
                                          </div>
                                        </div>
                                      )
                                    ) : (
                                      <div>
                                        <div
                                          className="position-relative"
                                          style={{ opacity: "0.4" }}
                                        >
                                          <div style={lock1}>
                                            <img
                                              src="/DoctorConsultation/lock.png"
                                              alt="nt found"
                                            ></img>
                                          </div>
                                          Start Consultation
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )
                              )}
                            </div>
                          )}
                        </div>
                      )}
                      {consultaionId &&
                        isPathology &&
                        consultItem?.is_confirmed !== 1 &&
                        consultItem?.is_confirmed !== 2 &&
                        consultItem?.scheduled_date !== null && (
                          <div
                            style={{
                              marginTop: "4%",
                              display: "flex",
                              width: "100%",
                              alignItems: "center",
                              justifyContent: "space-around",
                              alignSelf: "center",
                              marginBottom: "12px",
                            }}
                          >
                            <div
                              onClick={() => {
                                // setConfirm(true);
                                confirmConsult(1);
                              }}
                              style={{
                                fontSize: isTabScreen ? "12px" : "18px",
                                fontWeight: "400",
                                textAlign: "center",
                                backgroundColor: "green",
                                borderRadius: "15px",
                                height: isTabScreen ? "5vh" : "8vh",
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                padding: "3%",
                                color: "white",
                              }}
                            >
                              Confirm
                            </div>
                            <div
                              onClick={() => {
                                confirmConsult(2);
                              }}
                              style={{
                                fontSize: isTabScreen ? "12px" : "18px",
                                fontWeight: "400",
                                textAlign: "center",
                                backgroundColor: "red",
                                height: isTabScreen ? "5vh" : "8vh",
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                padding: "3%",
                                borderRadius: "15px",
                                color: "white",
                              }}
                            >
                              Decline
                            </div>
                          </div>
                        )}

                      {isPathology &&
                        consultItem?.is_confirmed == 2 &&
                        consultItem?.scheduled_date !== null &&
                        consultItem?.timing !== null && (
                          <div
                            onClick={() => {
                              // setConfirm(true);
                              confirmConsult(1);
                            }}
                            style={{
                              fontSize: isTabScreen ? "12px" : "18px",
                              fontWeight: "400",
                              textAlign: "center",
                              backgroundColor: "green",
                              borderRadius: "15px",
                              width: "150px",
                              margin: "0px auto",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                              padding: "3%",
                              color: "white",
                            }}
                          >
                            Confirm
                          </div>
                        )}
                      {consultComplete && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: -20,
                          }}
                        >
                          <div
                            style={{
                              marginTop: "5%",
                              // marginLeft: '10%',
                              fontSize: isTabScreen ? "13px" : "26px",
                              fontWeight: "600",
                              display: "flex",
                              alignSelf: "center",
                              color: "#58CF99",
                            }}
                          >
                            Completed
                          </div>
                          {followUpDate(consultItem?.scheduled_date) && (
                            <div
                              style={{
                                marginTop: "5%",
                                marginLeft: "5%",
                                fontSize: isTabScreen ? "13px" : "26px",
                                fontWeight: "600",
                                display: "flex",
                                alignSelf: "center",
                                color: "#111",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                if (!isSonography && !isYogaExperts) {
                                  setShowCalendar(false);
                                  setClicked(true);
                                  setIsVisible(true);
                                  setAudioVedio(false);
                                } else if (
                                  isSonography &&
                                  date != null &&
                                  timing != null &&
                                  !isScanCompleted &&
                                  !isYogaExperts
                                ) {
                                  handleSonographyCompletedConfirm();
                                } else if (isYogaExperts) {
                                  startVideoConsultFromChat();
                                } else {
                                  setScanCompleted(!scanCompleted);
                                }
                              }}
                            >
                              Followup
                            </div>
                          )}
                          {addedprescibe && (
                            <img
                              src={"/Communication/tick.png"}
                              style={{
                                marginLeft: "3%",
                                top: "10px",
                                position: "relative",
                                resizeMode: "contain",
                                height: "18px",
                                width: "18px",
                              }}
                              alt="tick"
                            />
                          )}
                        </div>
                      )}
                      {consultComplete && !isSonography && (
                        <div
                          style={{
                            color: "#7C9CBF",
                            fontWeight: "400",
                            fontSize: isTabScreen ? "10px" : "20px",
                            display: "flex",
                            alignSelf: "center",
                            marginTop: "3%",
                            cursor: "pointer",
                            marginBottom: "5%",
                          }}
                          onClick={() => {
                            if (!addedprescibe) setAddPrescription(true);
                            else {
                              setAddPrescription(false);
                              setShowCalendar(false);
                              setShowReport(true);
                            }
                          }}
                        >
                          {!addedprescibe ? "" : "Report"}
                        </div>
                      )}
                      {/* {isSonography &&
                        consultItem?.is_scan_completed === 1 &&
                        !consultItem?.sonography_report?.filekey &&
                        !uploadsono && (
                          <button
                            id="uploadbutton"
                            className="uploadbutton"
                            style={{
                              fontSize: isTabScreen ? "12px" : "16px",
                            }}
                            onClick={() => setUploadsono(true)}
                            disabled={
                              !moment().isSameOrAfter(
                                moment(date).subtract(10, "minutes")
                              )
                            }
                          >
                            Upload report
                          </button>
                        )} */}

                      {/* {isSonography &&
                        consultItem?.is_scan_completed === 1 &&
                        !consultItem?.sonography_report?.filekey &&
                        uploadsono && (
                          <div
                            style={{
                              marginTop: "4%",
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              alignItems: "center",
                              justifyContent: "space-around",
                              alignSelf: "center",
                              marginBottom: "12px",
                            }}
                          >
                            <input
                              type="text"
                              value={reportLink}
                              style={{
                                width: "96%",
                                borderRadius: "12px",
                                border: "1px solid gray",
                                marginTop: "10px",
                                padding: 6,
                              }}
                              placeholder="Enter Report Link"
                              onChange={(e) => setReportLink(e.target.value)}
                            />
                            <textarea
                              type="text"
                              value={summary}
                              style={{
                                width: "96%",
                                borderRadius: "12px",
                                border: "1px solid gray",
                                marginTop: "10px",
                                padding: 6,
                              }}
                              placeholder="Add Summary"
                              onChange={(e) => setSummary(e.target.value)}
                            />
                            <div
                              style={{
                                display: "flex",
                                alignSelf: "center",
                                flexDirection: "column",
                                marginTop: "10px",
                              }}
                            >
                              <input
                                style={{
                                  marginBottom: 12,
                                  width: "80%",
                                  display: "flex",
                                  alignSelf: "center",
                                  border: "1px solid gray",
                                  borderRadius: "12px",
                                  padding: 6,
                                }}
                                type="file"
                                id="fileInput"
                                accept=".png, .jpg, .jpeg"
                                onChange={handleFileChange}
                              />
                              <ul>
                                <p>Selected files:</p>
                                {file.length > 0 &&
                                  file.map((file, index) => {
                                    return (
                                      <>
                                        <li>
                                          {file.name}
                                          <span
                                            style={{
                                              cursor: "pointer",
                                              marginLeft: "8px",
                                            }}
                                            onClick={() =>
                                              handleRemoveFile(index)
                                            }
                                          >
                                            &#x2715;
                                          </span>
                                        </li>
                                      </>
                                    );
                                  })}
                              </ul>

                              <div
                                onClick={() => {
                                  if (file) handleAddSonographyReport();
                                  else {
                                    var msg = "Please Select Report File";
                                    const error_color = "error";
                                    handleAlertMsg(error_color, msg);
                                  }
                                }}
                                style={{
                                  fontSize: isTabScreen ? "12px" : "16px",
                                  fontWeight: "400",
                                  textAlign: "center",
                                  backgroundColor: "#004AAD",
                                  height: isTabScreen ? "5vh" : "7vh",
                                  cursor: "pointer",
                                  borderRadius: "15px",
                                  color: "white",
                                  width: "80%",
                                  marginBottom: "12px",
                                  alignSelf: "center",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                Upload
                              </div>
                            </div>
                          </div>
                        )} */}

                      {isSonography &&
                        consultItem?.is_scan_completed === 1 &&
                        consultItem?.sonography_report?.filekey &&
                        !consultComplete && (
                          <div
                            onClick={handleUpdateSonographyStatus}
                            style={{
                              fontSize: isTabScreen ? "12px" : "18px",
                              fontWeight: "400",
                              backgroundColor: fileUploaded
                                ? "lightgreen"
                                : "#e0e0e0",
                              borderRadius: "15px",
                              height: isTabScreen ? "5vh" : "8vh",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                              padding: "3%",
                              color: "#7C9CBF",
                              marginTop: "6px",
                              width: "80%",
                              alignSelf: "center",
                              marginBottom: "20px",
                            }}
                          >
                            Mark as Done
                          </div>
                        )}

                      {isPathology &&
                        consultItem?.is_confirmed === 1 &&
                        !consultItem?.phlebologist_name && (
                          <>
                            <input
                              type="text"
                              value={phleboName}
                              style={{
                                width: "80%",
                                borderRadius: "12px",
                                border: "1px solid gray",
                                marginTop: "10px",
                                padding: 6,
                                alignSelf: "center",
                              }}
                              placeholder="Enter Phlebologist Name"
                              onChange={(e) => setPhleboName(e.target.value)}
                            />
                            <input
                              type="text"
                              value={phlebono}
                              style={{
                                width: "80%",
                                borderRadius: "12px",
                                border: "1px solid gray",
                                marginTop: "10px",
                                padding: 6,
                                alignSelf: "center",
                              }}
                              placeholder="Enter Mobile Number"
                              onChange={(e) => setPhlebono(e.target.value)}
                            />
                            <input
                              type="text"
                              value={phleboid}
                              style={{
                                width: "80%",
                                borderRadius: "12px",
                                border: "1px solid gray",
                                marginTop: "10px",
                                padding: 6,
                                alignSelf: "center",
                              }}
                              placeholder="Enter phlebology ID"
                              onChange={(e) => setPhleboid(e.target.value)}
                            />
                            {showLock === true ? (
                              <div
                                className="position-relative"
                                style={{
                                  opacity: "0.4",
                                }}
                              >
                                <div style={lock}>
                                  <img
                                    src="/DoctorConsultation/lock.png"
                                    alt="nt found"
                                  ></img>
                                </div>
                                <div
                                  onClick={() => {
                                    alertPhlebologist();
                                  }}
                                  style={{
                                    fontSize: isTabScreen ? "12px" : "16px",
                                    fontWeight: "400",
                                    textAlign: "center",
                                    backgroundColor: "#F0588B",
                                    height: isTabScreen ? "5vh" : "7vh",
                                    cursor: "pointer",
                                    borderRadius: "15px",
                                    color: "white",
                                    width: "80%",
                                    margin: "0px auto",
                                    marginBottom: "12px",
                                    alignSelf: "center",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginTop: "18px",
                                  }}
                                >
                                  Assign Phlebologist
                                </div>
                              </div>
                            ) : (
                              <>
                                <div
                                  onClick={() => {
                                    assignPhlebologist();
                                  }}
                                  style={{
                                    fontSize: isTabScreen ? "12px" : "16px",
                                    fontWeight: "400",
                                    textAlign: "center",
                                    backgroundColor: "#F0588B",
                                    height: isTabScreen ? "5vh" : "7vh",
                                    cursor: "pointer",
                                    borderRadius: "15px",
                                    color: "white",
                                    width: "80%",
                                    margin: "0px auto",
                                    marginBottom: "12px",
                                    alignSelf: "center",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginTop: "18px",
                                  }}
                                >
                                  Assign Phlebologist
                                </div>
                              </>
                            )}
                          </>
                        )}
                      {isPathology &&
                        consultItem?.phlebologist_name &&
                        consultItem?.sample_confirmation !== 1 && (
                          <div
                            onClick={() => {
                              const dateOnly = moment(
                                consultItem?.scheduled_date
                              ).format("YYYY-MM-DD");
                              const scheduleDateTime = moment(
                                `${dateOnly} ${consultItem?.timing}`,
                                "YYYY-MM-DD h.mmA"
                              );
                              const timeBeforeTenMinutes = scheduleDateTime
                                .clone()
                                .subtract(10, "minutes");

                              const currentdate = moment();
                              if (currentdate >= timeBeforeTenMinutes) {
                                sampleCollectConfirm();
                              }
                              //
                              // setSampleCollected(true);
                            }}
                            style={{
                              fontSize: isTabScreen ? "12px" : "16px",
                              fontWeight: "400",
                              textAlign: "center",
                              backgroundColor: "lightgray",
                              height: isTabScreen ? "5vh" : "7vh",
                              cursor: "pointer",
                              borderRadius: "15px",
                              color: "white",
                              width: "80%",
                              marginBottom: "12px",
                              alignSelf: "center",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: "12px",
                            }}
                          >
                            Sample Collected
                          </div>
                        )}

                      {isPathology &&
                        consultItem?.sample_confirmation === 1 &&
                        !consultItem?.pathology_report?.[0]?.filekey &&
                        !upload && (
                          <button
                            className="uploadbutton"
                            style={{
                              fontSize: isTabScreen ? "12px" : "16px",
                            }}
                            onClick={() => setUpload(true)}
                          >
                            Upload Report
                          </button>
                        )}

                      {isPathology &&
                        consultItem?.sample_confirmation === 1 &&
                        !consultItem?.pathology_report.length > 0 &&
                        upload && (
                          <>
                            <textarea
                              type="text"
                              value={summary}
                              style={{
                                width: "85%",
                                borderRadius: "12px",
                                border: "1px solid gray",
                                marginBottom: "15px",
                                padding: 6,
                                alignSelf: "center",
                                marginTop: "12px",
                              }}
                              placeholder="Add Summary"
                              onChange={(e) => setSummary(e.target.value)}
                            />
                            <div
                              style={{
                                display: "flex",
                                alignSelf: "center",
                                flexDirection: "column",
                              }}
                            >
                              <input
                                style={{
                                  marginBottom: 12,
                                  width: "80%",
                                  display: "flex",
                                  alignSelf: "center",
                                }}
                                type="file"
                                accept=".png, .jpg, .jpeg, .pdf"
                                onChange={handleFileChangemultiple}
                              />

                              {filemulti.length > 0 && (
                                <div>
                                  <p>Selcted files:</p>
                                  <ul>
                                    {filemulti.map((file, index) => {
                                      return (
                                        <li key={index}>
                                          {file.name}

                                          <span
                                            style={{
                                              cursor: "pointer",
                                              marginLeft: "8px",
                                            }}
                                            onClick={() =>
                                              handleRemoveFile(index)
                                            }
                                          >
                                            &#x2715;
                                          </span>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              )}
                              <div
                                className="uploadbutton"
                                onClick={() => {
                                  if (file) handleUpload(consultItem);
                                  else {
                                    var msg = "Please Select Report File";
                                    const error_color = "error";
                                    handleAlertMsg(error_color, msg);
                                  }
                                }}
                                style={{
                                  fontSize: isTabScreen ? "12px" : "16px",
                                }}
                              >
                                Upload Report
                              </div>
                            </div>
                          </>
                        )}

                      {isPathology &&
                        consultItem?.sample_confirmation === 1 &&
                        consultItem?.pathology_report.length > 0 &&
                        !consultComplete && (
                          <div
                            onClick={() => {
                              if (
                                fileUploaded ||
                                consultItem?.pathology_report.length > 0
                              )
                                markAsDone();
                              else {
                                var msg = "Please Upload Report";
                                const error_color = "error";
                                handleAlertMsg(error_color, msg);
                                // alert("Please Upload Report");
                              }
                            }}
                            style={{
                              fontSize: isTabScreen ? "12px" : "18px",
                              fontWeight: "400",
                              backgroundColor: fileUploaded
                                ? "lightgreen"
                                : "#e0e0e0",
                              borderRadius: "15px",
                              height: isTabScreen ? "5vh" : "8vh",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                              padding: "3%",
                              color: "#7C9CBF",
                              marginTop: "6px",
                              width: "80%",
                              alignSelf: "center",
                              marginBottom: "20px",
                            }}
                          >
                            Mark as Done
                          </div>
                        )}
                      {isSonography && (
                        <button
                          onClick={showconsentform}
                          className="viewconsent"
                        >
                          View Consent
                        </button>
                      )}
                    </div>
                  </motion.div>
                </>
              ) : (
                <div style={{ height: "15%", width: "100%" }}></div>
              )}

              {clickedInspect && startConsultationCall && startConsultation && (
                <MeetingNew
                  userId={profileData.user.id}
                  consultaion={isYogaExperts ? "BatchId" : "consultaionId"}
                  consultaionId={isYogaExperts ? isYogaBatchId : consultaionId}
                  showCommunication={handleClickCommunication}
                  handleMeetingLeave={handleMeetingLeave}
                  participant={userData?.fname + " " + userData?.lname}
                  callroute={callroute}
                />
              )}

              {startConsultation && startConsult && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "6%",
                    height: "100vh",
                    width: "88%",
                    backgroundColor: "#FFFFFF",
                    boxShadow: "0px 0px 79.1591px rgba(90, 90, 90, 0.1)",
                    borderRadius: "22px",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      left: "94%",
                      marginTop: "20px",
                      marginRight: "20px",
                      cursor: "pointer",
                      width: "3%",
                    }}
                    onClick={(e) => handleClickOptions(e)}
                  >
                    <MoreVertIcon fontSize="large" />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <StyledMenu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <MenuItem sx={{ height: 50 }} onClick={handleClose}>
                        <div
                          style={{
                            backgroundColor: "#ca6b6f",
                            height: "80%",
                            padding: "10px",
                            borderRadius: "10px",
                            border: "1px solid black",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "white",
                          }}
                        >
                          End Consultation
                        </div>
                      </MenuItem>
                    </StyledMenu>
                  </div>
                </div>
              )}
            </>
          )}

          {!clickedInspect && (
            <div
              style={{
                marginTop: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                paragraph
                sx={{
                  color: configData.THEME_COLORS.PRIMARY,
                }}
              >
                <div
                  style={{
                    fontSize: isTabScreen ? "16px" : "32px",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                    marginLeft: isTabScreen ? "30px" : "60px",
                  }}
                >
                  {"Communication"}
                </div>
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "60px",
                }}
              >
                <Badge
                  badgeContent={count?.toString()}
                  color="primary"
                  overlap="circular"
                  style={{ left: -5, cursor: "pointer" }}
                  sx={{
                    "& .MuiBadge-badge": {
                      color: "#FFFFFF",
                      backgroundColor: "#E42346",
                    },
                  }}
                >
                  <NotificationsIcon
                    onClick={shownotificationofdr}
                    sx={{
                      width: "25px",
                      height: "25px",
                      color: "#004AAD",
                      cursor: "pointer",
                    }}
                  />
                </Badge>
                <Dropdown
                  submenu={submenu}
                  profileData={profileData}
                  onClick={() => showsubmenu()}
                  page="communication"
                />
              </div>
            </div>
          )}

          {!clickedInspect && !isYogaExperts && (
            <div
              style={{
                height: "100%",
                width: "88%",
                backgroundColor: "white",
                marginBottom: "20px",
                borderRadius: "12px",
                marginLeft: isTabScreen ? "30px" : "60px",
              }}
            >
              <div
                style={{
                  borderRadius: "12px",
                  display: "flex",
                  marginLeft: isTabScreen ? "1px" : "2%",
                  marginBottom: "1.6%",
                }}
              >
                <TextField
                  value={searched}
                  placeholder="Search Patient"
                  onChange={handleSearch}
                  inputProps={{
                    style: {
                      fontSize: isTabScreen
                        ? "12px"
                        : isScreen3
                        ? "25px"
                        : "15.54px",
                    },
                  }}
                  sx={{
                    backgroundColor: "#F2F2F2",
                    height: "40px",
                    justifyContent: "center",
                    width: window.innerWidth / 3,
                    marginTop: "1.6%",
                    borderRadius: "50px",
                    "& fieldset": { border: "none" },
                    color: "#232323",
                  }}
                />

                <div
                  style={{
                    fontSize: isTabScreen
                      ? "12px"
                      : isScreen3
                      ? "20px"
                      : "15.54px",
                    fontWeight: "500",
                    color: configData.THEME_COLORS.PRIMARY,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "1.6%",
                    marginLeft: "2%",
                    cursor: "pointer",
                    width: isScreen3 ? "120px" : "90px",
                  }}
                  onClick={() => setSearched("")}
                >
                  CLEAR ALL
                </div>
                {isTabScreen && !clicked ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft:
                        isTabScreen && clicked ? "5%" : clicked ? "16%" : "20%",
                    }}
                  >
                    <Typography
                      paragraph
                      sx={{
                        color: configData.THEME_COLORS.PRIMARY,
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          fontSize:
                            isTabScreen && clicked
                              ? "8px"
                              : isTabScreen
                              ? "12px"
                              : "20px",
                          fontWeight: "500",
                          fontFamily: "Poppins",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setDesc(!desc);
                          handleSort();
                        }}
                      >
                        {desc ? "New - Old" : "Old - New"}
                      </div>

                      <img
                        src={"/Communication/sort.png"}
                        style={{
                          marginTop: "8px",
                          marginRight: "12px",
                          resizeMode: "contain",
                          height: "18px",
                          width: "18px",
                        }}
                        alt="sort"
                      />
                    </Typography>
                  </div>
                ) : !isTabScreen && !clicked ? (
                  <div
                    style={{
                      position: "relative",
                      top: isScreen3 ? "30px" : "75px",
                      display: "flex",
                      alignItems: "center",
                      marginLeft:
                        isTabScreen && clicked ? "5%" : clicked ? "16%" : "34%",
                    }}
                  >
                    <Typography
                      paragraph
                      sx={{
                        color: configData.THEME_COLORS.PRIMARY,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          fontSize:
                            isTabScreen && clicked
                              ? "8px"
                              : isTabScreen
                              ? "12px"
                              : isScreen3
                              ? "20px"
                              : "16px",
                          fontWeight: "500",
                          fontFamily: "Poppins",
                          cursor: "pointer",
                          width: isScreen3 ? "120px" : "90px",
                        }}
                        onClick={() => {
                          setDesc(!desc);
                          handleSort();
                        }}
                      >
                        {desc ? "New - Old" : "Old - New"}
                      </div>

                      <img
                        src={"/Communication/sort.png"}
                        style={{
                          marginTop: "4px",
                          marginLeft: "-5px",
                          marginRight: "12px",
                          resizeMode: "contain",
                          height: "20px",
                          width: "20px",
                        }}
                      />
                    </Typography>
                  </div>
                ) : (
                  !isTabScreen &&
                  clicked && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft:
                          isTabScreen && clicked
                            ? "5%"
                            : clicked
                            ? "16%"
                            : "38%",
                      }}
                    >
                      <Typography
                        paragraph
                        sx={{
                          color: configData.THEME_COLORS.PRIMARY,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize:
                              isTabScreen && clicked
                                ? "8px"
                                : isTabScreen
                                ? "12px"
                                : "16px",
                            fontWeight: "500",
                            fontFamily: "Poppins",
                            cursor: "pointer",
                            width: "90px",
                          }}
                          onClick={() => {
                            setDesc(!desc);
                            handleSort();
                          }}
                        >
                          {desc ? "New - Old" : "Old - New"}
                        </div>

                        <img
                          src={"/Communication/sort.png"}
                          style={{
                            marginTop: "4px",
                            marginRight: "12px",
                            resizeMode: "contain",
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      </Typography>
                    </div>
                  )
                )}
                {isTabScreen && clicked && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "2%",
                      marginLeft:
                        isTabScreen && clicked ? "5%" : clicked ? "16%" : "20%",
                    }}
                  >
                    <Typography
                      paragraph
                      sx={{
                        color: configData.THEME_COLORS.PRIMARY,
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          fontSize:
                            isTabScreen && clicked
                              ? "8px"
                              : isTabScreen
                              ? "12px"
                              : "20px",
                          fontWeight: "500",
                          fontFamily: "Poppins",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setDesc(!desc);
                          handleSort();
                        }}
                      >
                        {desc ? "New - Old" : "Old - New"}
                      </div>

                      <img
                        src={"/Communication/sort.png"}
                        style={{
                          marginTop: "8px",
                          marginRight: "12px",
                          resizeMode: "contain",
                          height: "18px",
                          width: "18px",
                        }}
                      />
                    </Typography>
                  </div>
                )}
              </div>
              <div
                style={{
                  width: isTabScreen ? "300px" : "100%",
                  marginLeft: isTabScreen ? "8px" : "20px",
                  height: "50px",
                  borderRadius: "12px",
                  display: "flex",
                  marginTop: isScreen3 ? "2.5%" : "1%",
                }}
              >
                <Box
                  sx={{
                    width: isTabScreen
                      ? "66%"
                      : clicked && isVisible
                      ? "36%"
                      : "26%",
                  }}
                >
                  <FormControl fullWidth size={"small"}>
                    <Select
                      sx={{
                        boxShadow: "none",
                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                      }}
                      style={{
                        backgroundColor: "#F2F2F2",
                        fontSize: isTabScreen
                          ? "8px"
                          : isTabScreen && clicked
                          ? "10px"
                          : isScreen3
                          ? "25px"
                          : "15px",
                        fontFamily: "Poppins",
                        fontWeight: "500",
                        marginRight: "15px",
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={drType}
                      displayEmpty
                      onChange={handleChange1}
                    >
                      <MenuItem
                        value=""
                        style={{
                          fontSize: isTabScreen
                            ? "9px"
                            : isScreen3
                            ? "25px"
                            : "16px",
                          fontFamily: "Poppins",
                          fontWeight: "500",
                        }}
                      >
                        Consultation Completed
                      </MenuItem>
                      {consultcomplete?.map((item, index) => {
                        return (
                          <MenuItem
                            value={item}
                            style={{
                              fontSize: isTabScreen
                                ? "10px"
                                : isScreen3
                                ? "22px"
                                : "15px",
                              fontFamily: "Poppins",
                              fontWeight: "500",
                            }}
                          >
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    width: isTabScreen
                      ? "45%"
                      : clicked && isVisible
                      ? "27%"
                      : "20%",
                  }}
                >
                  <FormControl fullWidth size={"small"}>
                    <Select
                      sx={{
                        boxShadow: "none",
                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                      }}
                      style={{
                        backgroundColor: "#F2F2F2",
                        fontSize: isTabScreen
                          ? "8px"
                          : isTabScreen && clicked
                          ? "10px"
                          : isScreen3
                          ? "25px"
                          : "15px",
                        fontFamily: "Poppins",
                        fontWeight: "500",
                        marginRight: "15px",
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={number}
                      onChange={handleChange2}
                      displayEmpty
                    >
                      <MenuItem
                        value=""
                        style={{
                          fontSize: isTabScreen ? "9px" : "16px",
                          fontFamily: "Poppins",
                          fontWeight: "500",
                        }}
                      >
                        Pregnancy Week
                      </MenuItem>
                      {consultationNumbers?.map((item, index) => {
                        return (
                          <MenuItem
                            value={item}
                            style={{
                              fontSize: isTabScreen
                                ? "10px"
                                : isScreen3
                                ? "22px"
                                : "16px",
                              fontFamily: "Poppins",
                              fontWeight: "500",
                            }}
                          >
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    width: isTabScreen
                      ? "60%"
                      : clicked && isVisible
                      ? "27%"
                      : "20%",
                  }}
                >
                  <FormControl fullWidth size={"small"}>
                    <Select
                      sx={{
                        boxShadow: "none",
                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                      }}
                      style={{
                        backgroundColor: "#F2F2F2",
                        fontSize: isTabScreen
                          ? "8px"
                          : isTabScreen && clicked
                          ? "10px"
                          : isScreen3
                          ? "25px"
                          : "15px",
                        fontFamily: "Poppins",
                        fontWeight: "500",
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={week}
                      displayEmpty
                      onChange={(e) => handleChange3(e)}
                    >
                      <MenuItem
                        value=""
                        style={{
                          fontSize: isTabScreen ? "9px" : "16px",
                          fontFamily: "Poppins",
                          fontWeight: "500",
                        }}
                      >
                        Care Percentage
                      </MenuItem>
                      {carePercentages?.map((item, index) => {
                        return (
                          <MenuItem
                            value={index}
                            style={{
                              fontSize: isTabScreen
                                ? "10px"
                                : isScreen3
                                ? "22px"
                                : "16px",
                              fontFamily: "Poppins",
                              fontWeight: "500",
                            }}
                          >
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </div>
          )}

          {!clickedInspect && (
            <div
              style={{
                width: isTabScreen ? "90%" : "90%",
                marginLeft: isTabScreen ? "30px" : "50px",
              }}
            >
              <div
                className="container"
                style={{ height: "80vh", overflowY: "scroll" }}
              >
                <div className="row">
                  {isYogaExperts
                    ? reportsBatch?.map((item, index) => {
                        return (
                          <ReportBoxYoga
                            onClick={() => handleClick(item)}
                            onClick2={() => handleClick2(item)}
                            item={item}
                            consultaionId={consultaionId}
                            reports1={reports1}
                            isVisible={isVisible}
                            isVisibleInspect={isVisibleInspect}
                            clicked={clicked}
                            patientId={patientId}
                            isPathology={isPathology}
                            isSonography={isSonography}
                          />
                        );
                      })
                    : filteredPatients?.map((item, index) => {
                        return (
                          <ReportBox
                            onClick={() => handleClick(item)}
                            onClick2={() => handleClick2(item)}
                            item={item}
                            consultaionId={consultaionId}
                            reports1={reports1}
                            setShowSent={setShowSent}
                            isVisible={isVisible}
                            isVisibleInspect={isVisibleInspect}
                            clicked={clicked}
                            patientId={patientId}
                            isPathology={isPathology}
                            isSonography={isSonography}
                          />
                        );
                      })}
                </div>
              </div>
            </div>
          )}

          {!clickedInspect && startConsultationCall2 && startConsultation && (
            <MeetingNew
              consultaion={isYogaExperts ? "BatchId" : "consultaionId"}
              consultaionId={isYogaExperts ? isYogaBatchId : consultaionId}
              showCommunication={handleClickCommunication}
              handleMeetingLeave={handleMeetingLeave}
              callroute={callroute}
            />
          )}
        </div>
      </StyleRoot>
      {clicked && isVisible && (
        <StyleRoot>
          <div className="test" style={styles.fadeIn}>
            {!addPrescription && (
              <ReportDetailsBox
                title={"Messaging Platform"}
                chatData={chatData}
                patientid={patientId}
                showSent={showSent}
                followup={followup}
                audioVedio={audioVedio}
                drid={profileData?.user?.id}
                onBackClick={() => {
                  setClicked(false);
                  setIsVisible(false);
                }}
                startVideoConsultFromChat={startVideoConsultFromChat}
                fromInspect={clickedInspect}
                drnm={userData?.fname + " " + userData?.lname}
                consultationnumber={consultNo}
                consultaionId={consultaionId}
                consultItem={consultItem}
                profileData={profileData}
                setRenderagain={setRenderagain}
              />
            )}
          </div>
        </StyleRoot>
      )}
      {showReport && (
        <div className="test" style={styles.fadeIn}>
          <ReportsBox
            title={"Reports"}
            number={"12"}
            data={reports1}
            onBackClick={() => {
              setShowReport(false);
              setShowCalendar(true);
            }}
          />
        </div>
      )}

      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div
            style={{
              alignSelf: "center",
              display: "flex",
              flexDirection: "column",
              // transform: "translate(-50%, -50%)",
            }}
          >
            <div
              style={{
                marginTop: isScreen3 ? "10%" : "5%",
                fontSize: isTabScreen ? "14px" : isScreen3 ? "45px" : "20px",
                display: "flex",
                textAlign: "center",
                width: "90%",
                alignSelf: "center",
              }}
            >
              Are you sure to end this consultation ?
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "5%",
                alignSelf: "center",
                justifyContent: "space-around",
                width: "80%",
              }}
            >
              <div
                style={{
                  height: "6vh",
                  width: "9vw",
                  fontSize: isTabScreen ? "12px" : isScreen3 ? "30px" : "20px",
                  backgroundColor: configData.THEME_COLORS.PRIMARY,
                  display: "flex",
                  // alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "10px",
                  color: "white",
                  cursor: "pointer",
                  marginBottom: "6%",
                }}
                onClick={() => {
                  setOpen2(false);
                  setStartConsultation(false);
                  setStartConsult(false);
                  setConsultComplete(true);
                  setClickedInspect(true);
                  setShowCalendar(true);
                }}
              >
                Yes
              </div>
              <div
                onClick={() => {
                  setOpen2(false);
                }}
                style={{
                  height: "6vh",
                  width: "9vw",
                  fontSize: isTabScreen ? "12px" : isScreen3 ? "30px" : "20px",
                  backgroundColor: configData.THEME_COLORS.PRIMARY,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "10px",
                  color: "white",
                  cursor: "pointer",
                  marginBottom: "6%",
                }}
              >
                No
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={open3}
        onClose={handleClose3}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="lg"
        centered
      >
        <Box className="modal-container">
          <div>

          
          
          <span
            className="close3"
            onClick={handleClose3}
            style={{ color: "white", cursor: "pointer" }}
          >
            &times;
          </span>
          <img ref={consentimageRef} src={imageurl} alt="nt found"></img>
          <button className="btn btn-primary btn-sm my-4" onClick={handleDownload}>Download Image</button>
          </div>
         
        </Box>
      </Modal>
      <AlertShow
        alertShowHide={showAlert}
        alertClose={handleCloseAlert}
        setalertMsg={alertMsg}
        severity={severity}
      />
    </div>
  );
}

export default Communication;
