import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import configData from "../config.json";

function ReportsBox({
  title,
  data,
  number,
  month,
  week,
  date,
  year,
  onBackClick,
}) {
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const [value, onChange] = useState(new Date());
  return (
    <div style={{ backgroundColor: "white", width: "30vw" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            marginTop: "10%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            onClick={() => onBackClick()}
            src={"/Communication/right.png"}
            style={{
              resizeMode: "contain",
              height: isTabScreen ? "21px" : "38px",
              width: isTabScreen ? "14px" : "28px",
              left: isTabScreen ? "-5%" : "-10%",
              marginTop: "2px",
              cursor: "pointer",
              position: "relative",
            }}
          />
          <div
            style={{
              fontSize: isTabScreen ? "24px" : "32px",
              fontWeight: "600",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: configData.THEME_COLORS.SECONDARY,
              fontFamily: "Poppins",
            }}
          >
            {"Mrs. Jane Doe"}
          </div>
        </div>
        <hr></hr>
        <div style={{ display: "flex", alignItems: "center", marginTop: "7%" }}>
          <div
            style={{
              color: "#004AADCC",
              fontSize: isTabScreen ? "19px" : "28px",
              fontWeight: "600",
              marginLeft: "8%",
            }}
          >
            Reports
          </div>
          <img
            // onClick={() => onBackClick()}
            src={"/Communication/Filter.png"}
            style={{
              marginLeft: "40%",
              resizeMode: "contain",
              height: isTabScreen ? "21px" : "31px",
              width: isTabScreen ? "21px" : "31px",
            }}
          />
        </div>

        <div
          style={{
            marginTop: "15px",
            height: "68vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflow: "auto",
          }}
        >
          {data?.map(() => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignSelf: "center",
                  marginTop: "38px",
                  width: "80%",
                  backgroundColor: "#F0FAF8",
                  borderRadius: "12px",
                  border: "1px solid #E8E7E7",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "90%",
                    height: "26px",
                    justifyContent: "space-between",
                    margin: "20px",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: isTabScreen ? "14px" : "16px",
                      color: configData.THEME_COLORS.PRIMARY,
                    }}
                  >
                    {year}
                  </div>
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: isTabScreen ? "14px" : "16px",
                      color: configData.THEME_COLORS.PRIMARY,
                    }}
                  >
                    WEEK {week}
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "5px",
                    fontSize: isTabScreen ? "18px" : "20px",
                    fontWeight: "500",
                    color: configData.THEME_COLORS.SECONDARY,
                    alignSelf: "center",
                  }}
                >
                  {"Consultation" + " " + number}
                </div>

                <div
                  style={{
                    marginTop: "15px",
                    fontSize: isTabScreen ? "32px" : "40px",
                    fontWeight: "600",
                    color: configData.THEME_COLORS.PRIMARY,
                    alignSelf: "center",
                    height: "120px",
                    width: "86px",
                    textAlign: "center",
                  }}
                >
                  {"18"} {"SEP"}
                </div>
                <div
                  style={{
                    marginTop: "12px",
                    fontSize: isTabScreen ? "14px" : "16px",
                    fontWeight: "400",
                    alignSelf: "center",
                    textAlign: "center",
                    width: "70%",
                  }}
                >
                  Report indicates no pathogens. Patient is healthy.
                </div>

                <div
                  style={{
                    marginTop: "8%",
                    display: "flex",
                    alignSelf: "center",
                    alignItems: "center",
                    marginBottom: "22px",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: isTabScreen ? "14px" : "16px",
                      color: "#000000",
                      cursor: "pointer",
                    }}
                  >
                    Report
                  </div>
                  <img
                    src={"/Communication/download.jpg"}
                    style={{
                      marginLeft: "4%",
                      resizeMode: "contain",
                      height: "20px",
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ReportsBox;
