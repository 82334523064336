import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import axios from "axios";
import moment from "moment";
import configData from "../config.json";

function Prescriptiondetails({
  medicationDetails,
  chatData,
  redirectToChat,
  onClose,
}) {
  const [medicine, setMedicine] = useState([]);
  const [profileData, setProfileData] = useState({});
  const [patientData, setPatientData] = useState({});
  const [base64Image, setBase64Image] = useState("");
  const [signature, setSignature] = useState();
  const prescriptionContainerRef = useRef();

  const submit = () => {
    setTimeout(() => {
      convertToImage();
    }, 1000);
  };

  const fetchInitialData = async (id) => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/users/get_single_user`,
        {
          userId: id,
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return {};
    }
  };

  useEffect(() => {
    if (medicationDetails?.data?.id) {
      const fetchDoctorAndPatientData = async () => {
        const doctorData = await fetchInitialData(
          medicationDetails.data.doctor_id
        );

        setProfileData(doctorData);

        const patientDataTemp = await fetchInitialData(
          medicationDetails.data.patient_id
        );

        setPatientData(patientDataTemp?.user);

        setMedicine(medicationDetails.medicine);
      };
      fetchDoctorAndPatientData();
    }
  }, [medicationDetails]);

  const convertToImage = async () => {
    const prescriptionContainer = prescriptionContainerRef.current;

    const wrapper = document.createElement("div");
    wrapper.style.padding = "20px";
    wrapper.style.backgroundColor = "white"; // Optional: Add background if needed
    wrapper.style.width = "fit-content"; // Ensure the content size fits the container
    wrapper.style.margin = "auto"; // Center the wrapper
    wrapper.appendChild(prescriptionContainer.cloneNode(true)); // Clone the content

    document.body.appendChild(wrapper); // Temporarily add to the document

    try {
      const canvas = await html2canvas(wrapper);
      const image = canvas.toDataURL("image/png");

      // Trigger the download of the image
      const link = document.createElement("a");
      link.href = image;
      link.download = `prescription${medicationDetails?.data?.id}.png`;
      link.click();
    } catch (error) {
      console.error("Error converting to image:", error);
    } finally {
      // Clean up the wrapper after rendering
      document.body.removeChild(wrapper);
    }
  };

  useEffect(() => {
    if (signature) {
      const convertImageToBase64 = async (imageUrl) => {
        try {
          const response = await fetch(imageUrl);
          const blob = await response.blob();
          const reader = new FileReader();

          reader.onloadend = () => {
            setBase64Image(reader.result);
          };
          reader.readAsDataURL(blob);
        } catch (error) {
          console.error("Error converting image to base64:", error);
        }
      };
      convertImageToBase64(signature);
    }
  }, [signature]);

  const sendMessage = async (id, imagedata) => {
    try {
      const payload = {
        sender_id: profileData?.user?.id,
        receiver_id: chatData?.id,
        message: imagedata,
        is_image: 1,
      };
      const response = await axios.post(
        `${configData.SERVER_API_URL}/inbox/send_message`,
        payload
      );
      if (response.status === 200) {
        redirectToChat();
      } else {
        alert("Error sending message");
      }
    } catch (error) {
      console.error("Error in sending message:", error);
    }
  };

  return (
    <>
      <div>
        <div ref={prescriptionContainerRef}>
          <div style={{
            display:'flex',
            alignItems:'center',
            backgroundColor: "#F0588B",
            justifyContent:'space-between'
          }}>
            <div
              className="py-3 text-white d-flex justify-content-between"
              style={{ backgroundColor: "#fff", padding: "20px" }}
            >
              <div className="pt-2 d-flex align-items-center ps-2">
                <div className="mx-2 text-white fs-3">
                  <img src={"/logo-removebg.png"} width={"100px"} />
                </div>
              </div>
            </div>
            <div
              className="text-white d-flex justify-content-between"
              style={{ backgroundColor: "#F0588B" }}
            >
              <div></div>
              <div className="py-4 pe-4 text-end">
                <div className="fw-bolder text-capitalize">
                  Dr.{" "}
                  {`${profileData?.user?.fname || ""} ${
                    profileData?.user?.lname || ""
                  }`}
                </div>
                <p className="text-white drpost">
                  {profileData?.user?.medical_expert_name || ""}
                </p>
                <p className="text-white areanm">
                  {profileData?.user?.area_name || ""}
                </p>
                <h6>
                  Medical Registration Number:{" "}
                  {profileData?.medical_reg?.[0]?.reg_num || "N/A"}
                </h6>
              </div>
            </div>
          </div>
          <div className="py-2 d-flex ms-2 align-items-center justify-content-between">
            <div className="ps-2">
              <div className="text-capitalize pe-2">{`${
                patientData?.fname || ""
              } ${patientData?.lname || ""}`}</div>
              <div className="text-secondary">
                {" "}
                {patientData?.dob
                  ? `${moment().diff(moment(patientData.dob), "years")} years`
                  : "DOB not available"}
              </div>
            </div>
            <div className="pe-2">{moment().format("DD MMM, YYYY")}</div>
          </div>

          <hr className="w-100" />
          <h6 className="py-2 text-secondary ps-2">MEDICINES</h6>
          <table
            className="prescription-table"
            style={{
              width: "100%",
            }}
          >
            <thead>
              <tr>
                <th style={{ width: "10%" }} className="ps-2">
                  #
                </th>
                <th style={{ width: "40%" }}>NAME</th>
                <th style={{ width: "50%" }}>INSTRUCTION</th>
              </tr>
            </thead>
            <tbody>
              {medicine.map((medication, index) => (
                <tr key={index}>
                  <td style={{ width: "10%" }} className="ps-2">
                    {index + 1}
                  </td>
                  <td style={{ width: "40%" }} className="fw-bolder">
                    {medication["Medicine_Name"]}
                  </td>
                  <td style={{ width: "50%" }}>
                    {medication.dosage_frequency === "Everyday"
                      ? "Daily"
                      : "Alternate Day"}
                    , {medication.packaging_type} , {medication.dosage_timing}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <hr
            className="w-100 "
            style={{
              margin: "20px 0px",
            }}
          />
          <table
            className="prescription-table"
            style={{
              width: "100%",
            }}
          >
            <tbody>
              <tr>
                <td>Comments:</td>
              </tr>
              <tr>
                <td>{medicine?.[medicine.length - 1]?.description}</td>
              </tr>
            </tbody>
          </table>

          <div
            style={{
              margin: "100px 0px",
            }}
          >
            {base64Image && (
              <img
                src={base64Image}
                style={{ width: "100px", height: "100px" }}
                alt="Doctor's Signature"
              />
            )}
            Signature
          </div>
        </div>
      </div>
      <hr className="w-100 my-3" />
      <div className="d-flex  align-items-center justify-content-between">
        <button onClick={submit} className="btn btn-primary btn-sm">
          Download
        </button>
        <button onClick={onClose} className="btn btn-primary btn-sm">
          Close
        </button>
      </div>
    </>
  );
}

export default Prescriptiondetails;
