import React, { useState, useEffect } from "react";

import configData from "../config.json";

const headcalendar = {
  marginTop: "46px",
  fontSize: "30px",
  fontWeight: "600",
  textAlign: "center",
  alignItems: "center",
  color: configData.THEME_COLORS.SECONDARY,
  fontFamily: "Poppins",
};

const Rightsidebaralert = ({ title, description, data }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        height: "100%",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      {title && (
        <>
          <div className="text-center" style={headcalendar}>
            {" "}
            {title}
          </div>
          <hr className="w-100"></hr>
        </>
      )}
      {description && (
        <p className="text-center">
          <strong>{description}</strong>
        </p>
      )}
    </div>
  );
};

export default Rightsidebaralert;
