import React from "react";
// import configData from "../config.json";
import { toast } from "react-toastify";
import configData from "../../config.json";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const ChangePassword = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const userId = localStorage.getItem("userId");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleChangePassword = async () => {
    if (newPassword === confirmPassword) {
      try {
        const response = await axios.post(
          `${configData.API_URL}user/${userId}/change-password`,
          {
            oldPassword: oldPassword,
            newPassword: newPassword,
          }
        );
        console.log(response);

        if (response.data.status) {
          toast("password changed successfully");
          setShow(!show);
        }
      } catch (error) {
        toast(error.response.data.data.message);
        console.error("Error get areas:", error);
      }
    } else {
      toast("Please check your password doesn't matched");
    }
  };
  const navigatetoweb = () => {
    window.location.href = "http://www.myprega.com";
  };
  return (
    <>
      <div
        style={{
          backgroundColor: configData.THEME_COLORS.BACKGROUND,
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="text-center">
          <div>
            <img
              onClick={navigatetoweb}
              src={"/logo-removebg.png"}
              style={{
                resizeMode: "contain",
                height: "160px",
                width: "160px",
                marginLeft: "20px",
                cursor: "pointer",
              }}
              width={200}
            />
          </div>
        </div>
        <div
          className="my-auto text-center"
          style={{ top: -30, position: "relative" }}
        >
          <div
            className="mx-auto bg-white w-50"
            style={{
              borderRadius: "12px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <h4 className="py-4 fw-bolder">Change Password</h4>

            <input
              type="password"
              className="my-3"
              placeholder="Enter Old Password"
              style={{
                width: "50%",
                height: "45px",
                borderRadius: "12px",
                border: "1px solid black",
                padding: 10,
              }}
              value={oldPassword} // Set the email value from loginForm state
              onChange={(e) => {
                setOldPassword(e.target.value);
              }}
            />

            <input
              type="password"
              className="my-3"
              placeholder="Enter New Password"
              style={{
                width: "50%",
                height: "45px",
                borderRadius: "12px",
                border: "1px solid black",
                padding: 10,
              }}
              value={newPassword} // Set the password value from loginForm state
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
            />
            <input
              type="password"
              className="my-3"
              placeholder="Enter Confirm Password"
              style={{
                width: "50%",
                height: "45px",
                borderRadius: "12px",
                border: "1px solid black",
                padding: 10,
              }}
              value={confirmPassword} // Set the password value from loginForm state
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />

            {/* <div className="mx-auto d-flex align-items-center justify-content-between w-50"> */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",

                  justifyContent: "center",
                }}
              >
                <button
                  className="my-4 login"
                  style={{
                    width: "120px",
                    height: "40px",
                    borderRadius: "12px",
                    cursor: "pointer",
                    backgroundColor: "#007bff",
                    color: "white",
                  }}
                  onClick={handleChangePassword} // Call the handleLogin function to perform login
                  // disabled={
                  // loginForm.password == "" || loginForm.email == ""
                  // !otpVerify
                  // }
                >
                  Submit
                </button>
              </div>
              {show && (
                <p style={{ marginBottom: "mb-3" }}>
                  password changed successfully
                </p>
              )}
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
