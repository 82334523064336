import React from "react";

const Casepapertwo = ({props}) => {
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>Question</th>
          <th>Answer</th>
        </tr>
      </thead>
      <tbody>
        {props?.casepaperdata2nd?.medical_condition && (
          <tr>
            <td>1. Do you have any medical condition?</td>
            <td>{props.casepaperdata2nd.medical_condition}</td>
          </tr>
        )}
        {props?.casepaperdata2nd?.regular_medication && (
          <tr>
            <td>2. Are you on any regular medication?</td>
            <td>{props.casepaperdata2nd.regular_medication}</td>
          </tr>
        )}
        {props?.casepaperdata2nd?.allergies && (
          <tr>
            <td>3. Do you have any allergies?</td>
            <td>{props.casepaperdata2nd.allergies}</td>
          </tr>
        )}
        {props?.casepaperdata2nd?.smoke_alcohol_habit && (
          <tr>
            <td>4. Do you have smoking or alcohol habits?</td>
            <td>{props.casepaperdata2nd.smoke_alcohol_habit}</td>
          </tr>
        )}
        {props?.casepaperdata2nd?.family_health_problems && (
          <tr>
            <td>5. Any family health problems?</td>
            <td>{props.casepaperdata2nd.family_health_problems}</td>
          </tr>
        )}
        {props?.casepaperdata2nd?.surgeries_history && (
          <tr>
            <td>6. Any history of surgeries?</td>
            <td>{props.casepaperdata2nd.surgeries_history}</td>
          </tr>
        )}
        {props?.casepaperdata2nd?.serious_illness && (
          <tr>
            <td>7. Any history of serious illness?</td>
            <td>{props.casepaperdata2nd.serious_illness}</td>
          </tr>
        )}
        {props?.casepaperdata2nd?.regular_medications && (
          <tr>
            <td>8. Do you take regular medications?</td>
            <td>{props.casepaperdata2nd.regular_medications}</td>
          </tr>
        )}
        {props?.casepaperdata2nd?.sexual_infection && (
          <tr>
            <td>9. Any history of sexual infections?</td>
            <td>{props.casepaperdata2nd.sexual_infection}</td>
          </tr>
        )}
        {props?.casepaperdata2nd?.chronic_conditions && (
          <tr>
            <td>10. Any chronic conditions?</td>
            <td>{props.casepaperdata2nd.chronic_conditions}</td>
          </tr>
        )}
        {props?.casepaperdata2nd?.family_history_diabetes && (
          <tr>
            <td>11. Any family history of diabetes?</td>
            <td>{props.casepaperdata2nd.family_history_diabetes}</td>
          </tr>
        )}
        {props?.casepaperdata2nd?.family_history_hbp && (
          <tr>
            <td>12. Any family history of high blood pressure?</td>
            <td>{props.casepaperdata2nd.family_history_hbp}</td>
          </tr>
        )}
        {props?.casepaperdata2nd?.blood_history && (
          <tr>
            <td>13. Any blood-related history?</td>
            <td>{props.casepaperdata2nd.blood_history}</td>
          </tr>
        )}
        {props?.casepaperdata2nd?.genetic_condition_family && (
          <tr>
            <td>14. Any genetic conditions in the family?</td>
            <td>{props.casepaperdata2nd.genetic_condition_family}</td>
          </tr>
        )}
        {props?.casepaperdata2nd?.mental_health_history && (
          <tr>
            <td>15. Any mental health history?</td>
            <td>{props.casepaperdata2nd.mental_health_history}</td>
          </tr>
        )}
        {props?.casepaperdata2nd?.abnormal_pap_smear_history && (
          <tr>
            <td>16. Any history of abnormal pap smears?</td>
            <td>{props.casepaperdata2nd.abnormal_pap_smear_history}</td>
          </tr>
        )}
        {props?.casepaperdata2nd?.personal_family_medical_history && (
          <tr>
            <td>17. Any personal or family medical history?</td>
            <td>{props.casepaperdata2nd.personal_family_medical_history}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default Casepapertwo;
