import React from "react";

const Casepaperthree = ({props}) => {
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>Question</th>
          <th>Answer</th>
        </tr>
      </thead>
      <tbody>
        {props?.casepaperdata3rd?.age_at_first_period && (
          <tr>
            <td>1. What was your age at the first period?</td>
            <td>{props.casepaperdata3rd.age_at_first_period} years</td>
          </tr>
        )}
        {props?.casepaperdata3rd?.regular_cycles && (
          <tr>
            <td>2. Are your cycles regular?</td>
            <td>{props.casepaperdata3rd.regular_cycles}</td>
          </tr>
        )}
        {props?.casepaperdata3rd?.pregnancy_history && (
          <tr>
            <td>3. Do you have any pregnancy history?</td>
            <td>{props.casepaperdata3rd.pregnancy_history}</td>
          </tr>
        )}
        {props?.casepaperdata3rd?.pregnancy_length && (
          <tr>
            <td>4. What was the length of your pregnancy?</td>
            <td>{props.casepaperdata3rd.pregnancy_length}</td>
          </tr>
        )}
        {props?.casepaperdata3rd?.delivery_method && (
          <tr>
            <td>5. What was the delivery method?</td>
            <td>{props.casepaperdata3rd.delivery_method}</td>
          </tr>
        )}
        {props?.casepaperdata3rd?.baby_birth_weight && (
          <tr>
            <td>6. What was the baby's birth weight?</td>
            <td>{props.casepaperdata3rd.baby_birth_weight}</td>
          </tr>
        )}
        {props?.casepaperdata3rd?.anesthesia_analgesia && (
          <tr>
            <td>7. Did you use anesthesia/analgesia during delivery?</td>
            <td>{props.casepaperdata3rd.anesthesia_analgesia}</td>
          </tr>
        )}
        {props?.casepaperdata3rd?.pregnancy_complications && (
          <tr>
            <td>8. Were there any pregnancy complications?</td>
            <td>{props.casepaperdata3rd.pregnancy_complications}</td>
          </tr>
        )}
        {props?.casepaperdata3rd?.pregnancy_problems && (
          <tr>
            <td>9. Did you experience any pregnancy problems?</td>
            <td>{props.casepaperdata3rd.pregnancy_problems}</td>
          </tr>
        )}
        {props?.casepaperdata3rd?.repro_organ_problems && (
          <tr>
            <td>10. Any reproductive organ problems?</td>
            <td>{props.casepaperdata3rd.repro_organ_problems}</td>
          </tr>
        )}
        {props?.casepaperdata3rd?.last_period_date && (
          <tr>
            <td>11. What was the date of your last period?</td>
            <td>
              {new Date(
                props.casepaperdata3rd.last_period_date
              ).toLocaleDateString()}
            </td>
          </tr>
        )}
        {props?.casepaperdata3rd?.cycle_regularity && (
          <tr>
            <td>12. How regular are your cycles?</td>
            <td>{props.casepaperdata3rd.cycle_regularity}</td>
          </tr>
        )}
        {props?.casepaperdata3rd?.labour_length && (
          <tr>
            <td>13. How long was your labor?</td>
            <td>{props.casepaperdata3rd.labour_length} hours</td>
          </tr>
        )}
        {props?.casepaperdata3rd?.delivery_nature && (
          <tr>
            <td>14. What was the nature of your delivery?</td>
            <td>{props.casepaperdata3rd.delivery_nature}</td>
          </tr>
        )}
        {props?.casepaperdata3rd?.term_deliveries && (
          <tr>
            <td>15. How many term deliveries have you had?</td>
            <td>{props.casepaperdata3rd.term_deliveries}</td>
          </tr>
        )}
        {props?.casepaperdata3rd?.premature_deliveries && (
          <tr>
            <td>16. How many premature deliveries have you had?</td>
            <td>{props.casepaperdata3rd.premature_deliveries}</td>
          </tr>
        )}
        {props?.casepaperdata3rd?.abortions && (
          <tr>
            <td>17. How many abortions have you had?</td>
            <td>{props.casepaperdata3rd.abortions}</td>
          </tr>
        )}
        {props?.casepaperdata3rd?.living_children && (
          <tr>
            <td>18. How many living children do you have?</td>
            <td>{props.casepaperdata3rd.living_children}</td>
          </tr>
        )}
        {props?.casepaperdata3rd?.repro_menustral_problem && (
          <tr>
            <td>19. Any reproductive or menstrual problems?</td>
            <td>{props.casepaperdata3rd.repro_menustral_problem}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default Casepaperthree;
