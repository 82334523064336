import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <div className="container-fluid myfooter">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-1 d-none d-lg-block"></div> */}
            <div className="col-6 col-lg-2 pt-2">
              <Link className="" to="/home">
                <img src="/logo.png" id="logo" />
              </Link>
            </div>
            <div className="col-6 col-lg-2 paddingT">
              <div className="d-flex align-items-center">
                <Link className="fw-bolder text-dark mb-0">Packages</Link>
                <img
                  src="./arrow.png"
                  className="img-fluid ms-2"
                  style={{ height: "13px", width: "11px", marginLeft: "8px" }}
                />
              </div>
              <Link to="/elementalcare" className="text-dark">
                Elemental Care
              </Link>
              <br />
              <Link to="/cherishcare" className="text-dark">
                Cherish care
              </Link>
            </div>
            <div className="col-6 col-lg-2 paddingT">
              <Link to="/about" className="fw-bolder text-dark">
                About Us
              </Link>
              <br />
              <Link to="/contact" className="fw-bolder text-dark">
                Contact Us
              </Link>
              <br />
              <p className=""></p>
            </div>
            <div className="col-6 col-lg-3 paddingT">
              <p className="text-capitalize fw-bolder mb-0 mb-md-1">
                Connect with us
              </p>
              <div className="mt-3 d-flex align-items-end">
                <div>
                  <Link href="https://www.facebook.com/myprega" target="_blank">
                    <img
                      src="/facebook.png"
                      className="img-fluid icondiv"
                    ></img>
                  </Link>
                </div>
                <div className="ms-3">
                  {" "}
                  <Link
                    href="https://www.instagram.com/myprega/"
                    target="_blank"
                    className="ml-4"
                  >
                    <img
                      src="/instagram.png"
                      className="img-fluid icondiv"
                    ></img>
                  </Link>
                </div>
                <div className="ms-3">
                  <Link
                    href="https://www.linkedin.com/company/myprega"
                    target="_blank"
                    className="ml-4"
                  >
                    <img
                      src="/linkedin.png"
                      className="img-fluid icondiv"
                    ></img>
                  </Link>
                </div>
                <div className="ms-3">
                  <Link
                    href="https://twitter.com/myprega?t=rMgXnTQC7TV5d6-nWPfxxQ&s=08"
                    target="_blank"
                    className="ml-4"
                  >
                    <img src="/twitter.png" className="img-fluid icondiv"></img>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-3 paddingT">
              <div>
                <Link to="/termsofuse">Terms of use</Link>
              </div>
              <div>
                <Link to="/refund&cancellationpolicy">
                  Refunds & cancellation policy
                </Link>
              </div>
              <div>
                {" "}
                <Link to="/disclaimerpolicy">Disclaimer Policy</Link>
              </div>
            </div>
            {/* <div className="col-lg-1 d-none d-lg-block"></div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
