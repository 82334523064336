import { fontSize } from "@mui/system";
import React, { useState } from "react";
import configData from "../../config.json";
import { useMediaQuery } from "react-responsive";
function DateBox({ day, date, patientDay, size, mode, activeDay }) {
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 991px)",
  });

  console.log("activeDay", activeDay, date);

  return (
    <div
      style={{
        marginTop: activeDay == date ? 15 : 0,
        height:
          activeDay == date
            ? isTabScreen
              ? "100px"
              : "130px"
            : isTabScreen
              ? "70px"
              : "100px",
        width: activeDay == date ? "45px" : size,
        backgroundColor: activeDay === date ? "" : "#FBFBFB",
        boxShadow:
          activeDay === date ? "" : "-1px 2px 8px rgba(0, 0, 0, 0.161)",
        borderRadius: "20px",
        display: "flex",
        flexDirection: "column",
        margin: "10px",
      }}
    >
      <div
        style={{
          marginTop: "10px",
          color: "#333333",
          fontSize: activeDay == date ? "16px" : "12px",
          fontWeight: "600",
          alignSelf: "center",
          textAlign: "center",
        }}
      >
        {day}
      </div>
      <div
        style={{
          width:
            activeDay === date
              ? isTabScreen
                ? "40px"
                : "50px"
              : isTabScreen
                ? "30px"
                : "40px",
          height:
            activeDay === date
              ? isTabScreen
                ? "40px"
                : "50px"
              : isTabScreen
                ? "30px"
                : "40px",
          background:
            "linear-gradient(180deg, #8362D7 0%, #EE4964 100%), #FF7648",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.35)",
          borderRadius: "50%",
          alignSelf: "center",
          display: "flex",
          justifyContent: "center",
          marginTop: "5px",
        }}
      >
        <div
          style={{
            fontSize: activeDay == date ? "24px" : "16px",
            fontWeight: "600",
            textAlign: "center",
            color: "#FBFBFB",
            alignSelf: "center",
          }}
        >
          {date}
        </div>
      </div>
      <div
        style={{
          fontSize: "10px",
          fontWeight: "500",
          textAlign: "center",
          alignSelf: "center",
          color: "#333333",
          marginTop: activeDay == date ? "6px" : "2px",
          fontSize: activeDay == date ? "12px" : "10px",
        }}
      >
        {mode === 2 ? "Week" : "Day"} {patientDay}
      </div>
    </div>
  );
}

export default DateBox;
