import React, { useEffect } from "react";
import MiniDrawer from "../Components/MiniDrawer";
import configData from "../../config.json";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "bootstrap/dist/css/bootstrap.min.css";
import SonographyTest from "../Components/SonographyTest";
import sonographylist from "../Components/sonographylist";
import { useState } from "react";
import { PathTable1 } from "../Components/SonographyTest";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import Dropdown from "../Components/Categories/Dropdown";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Calendarpart from "../Components/Calendarpart";
import ConsultationCardDetails from "../Components/Categories/ConsultationCardDetails";
import Rightsidebaralert from "../../common/Rightsidebaralert";
import { downloadFile } from "../../common/utils/fileUtils";
let mydatasonography;
let dateclicked;
let r;
let t;
let sdata;
function Sonography() {
  const navigate = useNavigate();
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const isTabScreen1 = useMediaQuery({
    query: "(min-width: 1024px)" && "(max-width: 1240px)",
  });
  const isTabScreen2 = useMediaQuery({
    query: "(min-width: 1024px)" && "(max-width: 1194px)",
  });
  const [value, onChange] = useState(new Date());
  const [clicked, setClicked] = useState(false);
  const [clickedschedule, setClickedschedule] = useState(false);
  const [showTime, setShowTime] = useState(false);
  const [date, setdate] = useState("20");
  const [clickedindex, setIndex] = useState();
  const [clickcomment, setclickcomment] = useState(false);
  const [content, showcontent] = useState(false);
  const [showbtn, setshowbtn] = useState(false);
  const [consultations, setConsultations] = useState();
  const [patientdata, setPatientdata] = useState();
  const [rendercalendar, setRendercalendar] = useState(false);
  const [objectData, setObjectData] = useState();
  const [dates, setDates] = useState();
  const [timings, setTimings] = useState();
  const [timeData, setTimeData] = useState();
  const [doctorInfo, setDoctorInfo] = useState();
  const [info, setInfo] = useState(false);
  const [consultId, setConsultId] = useState();
  const [viewBtn, setViewBtn] = useState(false);
  const [consultationsData, setConsultationsData] = useState();
  const [viewindex, setIndex1] = useState();
  const [consultno, setConsultno] = useState();
  const [reschedulealert, setReschedulealert] = useState();
  const [reschedulestatus, setReschedulestatus] = useState(false);
  const showsubmenu = () => {
    setSubmenu(!submenu);
  };
  const [submenu, setSubmenu] = useState(false);
  useEffect(() => {
    getPatientDetail();
    // const interval = setInterval(() => {
    //   getPatientDetail();
    // }, 2000);
    // return () => clearInterval(interval);
  }, []);
  const getPatientDetail = async () => {
    console.log("PATIENTID-", localStorage.getItem("userId"));
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/users/get_single_user`,
        {
          userId: localStorage.getItem("userId"),
        }
      );

      if (response.data.success) {
        console.log("get loginuserdata successfully-", response.data.user);
        let area = response.data.user.area_id;
        setPatientdata(response.data?.user);
        getDoctorConsultations(response.data?.user?.sonography?.id);
      } else {
        console.error("Error loginuserdata:", response.data.error);
      }
    } catch (error) {
      console.error("Error loginuserdata:", error);
    }
  };
  const getDoctorConsultations = async (DoctorID) => {
    try {
      console.log(
        "DoctorID--",
        DoctorID,
        localStorage.getItem("userId").toString()
      );
      const response = await axios.post(
        `${configData.SERVER_API_URL}/consultation/get_doctor_consultation`,
        {
          patient_id: localStorage.getItem("userId").toString(),
          doctor_id: DoctorID,
        }
      );

      if (response.data?.success) {
        console.log(
          "DoctorConsultsSONO-",
          response.data?.patient_consultations
        );

        setConsultations(response.data?.patient_consultations);
      }
    } catch (error) {
      console.error("Error get consults:", error);
    }
  };
  useEffect(() => {
    console.log("PP==", sdata);
    getDoctorAvailability(sdata?.id);
    setDoctorInfo(patientdata?.sonography);
    setInfo(sdata);
  }, [sdata]);

  const handleChangeConsultationView1 = async (consultno, consultdata) => {
    try {
      console.log("consult_id", consultdata, consultno);
      setConsultId(consultdata?.id);
      // fetchToken(consultdata?.id);
      const response = await axios.post(
        `${configData.SERVER_API_URL}/consultation/get_consultation_by_id`,
        {
          patient_id: localStorage.getItem("userId").toString(),
          consultation_id: consultdata?.id,
        }
      );
      if (response.data?.success) {
        console.log("consultationsData", doctorInfo);
        console.log("consultationsData", response.data);
        const consultData = {
          doctorName: doctorInfo?.fname + " " + doctorInfo?.lname,
          date: response.data.consultation_data[0].scheduled_date,
          mode: response.data.consultation_data[0].consultation_type,
          timing: response.data.consultation_data[0].timing,
        };
        setConsultationsData(consultData);
        setConsultno(response.data.consultation_data[0]?.consultation_number);
        setRendercalendar(false);
        setReschedulestatus(false);
        setClicked(false);
        setViewBtn(true);
      }
    } catch (error) {
      console.error("Error get consults:", error);
    }
  };

  const getDoctorAvailability = async (cId) => {
    if (patientdata?.pathology?.id) {
      try {
        console.log(
          "check api",
          localStorage.getItem("userId"),
          patientdata?.sonography?.id,
          cId
        );
        const response = await axios.post(
          `${configData.SERVER_API_URL}/consultation/get_doctor_availability`,
          {
            patient_id: localStorage.getItem("userId").toString(),
            doctor_id: patientdata?.pathology?.id,
            consultation_id: cId,
          }
        );
        console.log("get_doctor_availability", response.data);
        if (response.data?.success) {
          const selectedDate = [];
          const dateTimeData = [];
          response.data.doctor_availability.map((item) => {
            console.log("item", item);
            selectedDate.push(item.dates);

            const formattedDateobj2 = {
              id: item?.id,
              date: item?.dates,
              timing_id: item?.timing_id,
              timings: item?.timings,
            };
            dateTimeData.push(formattedDateobj2);
          });
          setObjectData(dateTimeData);
          console.log("DoctorAvailability-", response.data);
          console.log("DoctorAvailability-", selectedDate);
          console.log("DoctorAvailability-", dateTimeData);
          setTimeData(response.data?.doctor_availability[0]);
          setDates(selectedDate);
          setTimings(
            response.data?.doctor_availability[0]?.timings?.split(",")
          );
        }
      } catch (error) {
        console.error("Error get availdates:", error);
      }
    } else {
    }
  };
  const handleClick = (e, staticTitle) => {
    t = staticTitle;
    console.log("P@P", e, e.id);
    setIndex(e.id);
    setIndex1();
    mydatasonography = e;
    sdata = e;
    setClicked(true);
    setRendercalendar(true);
    setReschedulestatus(false);
    console.log("+++", e);
    // navigate("/patient/Doctor_consultation", {
    //   state: {
    //     id: 4,
    //     drid: e.doctor_id,
    //     cardid:e.id,
    //   },
    // })
  };
  const viewconsultation = (e) => {
    console.log("@#@#", e);
    setIndex1(e.id);
    setIndex();
    handleChangeConsultationView1(e.id, e);
  };
  const handleschedule = (e) => {
    alert("o");
    setIndex(e.index);
    setClickedschedule(!clickedschedule);
  };
  const handlechange = (e) => {
    dateclicked = e;
    r = moment(dateclicked).format("DD MMMM YYYY");
    console.log("!!!!!!!&&&&&&!!!!!!!!", r);
    setdate(r);
  };
  const commentclick = (e) => {
    setclickcomment(!clickcomment);
    showcontent(false);
    setIndex(e.index);
  };
  const showmorecontent = () => {
    showcontent(true);
  };
  const showmorebtn = (e) => {
    setshowbtn(e.index);
    setIndex(e.index);
    // alert("bbb");
  };
  const dorescheduleconsultation = (data) => {
    const userId = localStorage.getItem("userId");
    api
      .updatepatientmessagetodoctor({
        patient_id: userId,
        doctor_id: data?.doctor_id,
        consultation_number: data?.consultation_number,
        show_msg: 1,
      })
      .then((res) => {
        if (res.success) {
          setReschedulealert({
            title: "Thank You to Re-schedule your consultation",
            description: `Dr. ${data?.doctor_firstName} ${data?.doctor_lastName} will contact you soon.`,
            consultinfo: data,
          });
          setClicked(false);
          setRendercalendar(false);
          setViewBtn(false);
          setReschedulestatus(true);
        }
      })
      .catch((rej) => {
        console.log(rej);
      });
  };
  const viewreport = (e) => {
    let fileKey = e.fileKey;
    if (fileKey) {
      downloadFile(fileKey);
    }
  };
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
      }}
    >
      <MiniDrawer />
      <div style={{ width: "70%", backgroundColor: "#FFF0F5" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#FFF0F5",
            paddingTop: "48px",
            paddingLeft: isTabScreen || isTabScreen2 ? "20px" : "59px",
            paddingBottom: "27px",
            color: configData.THEME_COLORS.PRIMARY,
            fontFamily: "Poppins",
          }}
        >
          <div
            style={{
              fontSize: isTabScreen ? "24px" : "32px",
              fontWeight: "600",
              fontFamily: "Poppins",
            }}
          >
            Sonography
          </div>
          <div
            style={{
              paddingRight: "69px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <NotificationsIcon
              sx={{ width: "25px", height: "25px", color: "#004AAD" }}
            />
            {/* <AccountCircleIcon
              sx={{
                marginLeft: "16px",
                width: "33px",
                height: "33px",
                color: "#004AAD",
              }}
            /> */}
            <Dropdown
              submenu={submenu}
              onClick={() => showsubmenu()}
              page="sonography"
            />
          </div>
        </div>
        <div className="">
          <Container style={{ backgroundColor: "#FFF0F5" }}>
            <Row
              className="mx-0"
              style={{
                height: window.innerHeight / 1,
                overflowY: "scroll",
                width: "100%",
                paddingRight: "20px",
              }}
            >
              {consultations?.map((e, index) => {
                const staticTitle = sonographylist[index]?.title;
                const msg1 = sonographylist[index]?.msg1;
                const msg2 = sonographylist[index]?.msg2;
                return (
                  <SonographyTest
                    number={index}
                    data={e}
                    title={staticTitle}
                    date={
                      e.scheduled_date
                        ? moment(e.scheduled_date).format("DD MMM")
                        : null
                    }
                    id={e.id}
                    teststatus={
                      e.is_completed == 1
                        ? "COMPLETED"
                        : e.scheduled_date != null
                        ? "SCHEDULED"
                        : "UNSCHEDULED"
                    }
                    drimg={e.drimg}
                    drname={e.drname}
                    is_completed={e.is_completed}
                    consulted_by_doctor={e.consulted_by_doctor}
                    drid={e.drid}
                    mobno={e.mobno}
                    msg1={msg1}
                    msg2={msg2}
                    doctorInfo={doctorInfo}
                    checkreportbtn={e.checkreportbtn}
                    testincludedbtn={e.testincludedbtn}
                    scheduletestbtn={e.scheduletestbtn}
                    onClick={() => handleClick(e, staticTitle)}
                    onclick1={() => handleschedule(e)}
                    onclick2={() => commentclick(e)}
                    onclick3={() => showmorecontent(e)}
                    onclick4={() => showmorebtn(e)}
                    onClick5={() => viewconsultation(e)}
                    viewreport={() => viewreport(e)}
                    handlewantreschedule={() => dorescheduleconsultation(e)}
                    week={e.weekDates}
                    index={e.index}
                    clickedindex={clickedindex}
                    viewindex={viewindex}
                    clickcomment={clickcomment}
                    clickedschedule={clickedschedule}
                    content={content}
                    showbtn={showbtn}
                    clicked={clicked}
                  />
                );
              })}
            </Row>
          </Container>
        </div>
      </div>
      <div style={{ backgroundColor: "white" }}>
        {clicked === true ? (
          <>
            <div style={{ width: "30vw" }}>
              <Calendarpart
                scheduleddata={sdata}
                //  state={location?.state}
                dates={dates}
                timings={timings}
                info={info}
                timeData={timeData}
                objectData={objectData}
                doctorInfo={doctorInfo}
                //  falseshowtime={falseshowtime}
              />
            </div>
          </>
        ) : rendercalendar === true ? (
          <>
            <div style={{ backgroundColor: "white", width: "30%" }}>
              <Calendarpart
                scheduleddata={sdata}
                //  state={location?.state}
                dates={dates}
                timings={timings}
                info={info}
                timeData={timeData}
                objectData={objectData}
                doctorInfo={doctorInfo}
                //  falseshowtime={falseshowtime}
              />
            </div>
          </>
        ) : reschedulestatus === true ? (
          <>
            <div className="mt-4 d-flex">
              <div onClick={() => setReschedulestatus(false)} className="ps-2">
                <img
                  src={"/GarbhaSanskar/back.png"}
                  style={{
                    height: "21px",
                    width: "30px",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
            <Rightsidebaralert
              title={reschedulealert?.title}
              description={reschedulealert?.description}
              data={reschedulealert?.data}
            />
          </>
        ) : (
          <div style={{ width: "30vw" }}>
            <div>
              <div
                style={{
                  paddingTop: "48px",
                  fontSize: isTabScreen
                    ? "24px"
                    : isTabScreen1
                    ? "24px"
                    : "32px",
                  color: configData.THEME_COLORS.PRIMARY,
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  color: "#F0588B",
                  textAlign: "center",
                }}
              >
                Lab Details
              </div>
              <hr className="mb-0 w-100"></hr>
              <div>
                {/* div for inner details below lab details dhande */}
                <div
                  className="px-4 d-flex align-items-center"
                  style={{ marginTop: "25px" }}
                >
                  <img
                    src={"/Pathology/sunrise.png"}
                    style={{
                      borderRadius: "50%",
                      height: "50px",
                      width: "50px",
                    }}
                  ></img>
                  <div
                    style={{
                      paddingLeft: "20px",
                      fontWeight: "600",
                      fontSize: isTabScreen ? "14px" : "20px",
                      color: "rgba(0, 74, 173, 1)",
                    }}
                  >
                    {patientdata?.sonography?.fname}{" "}
                    {patientdata?.sonography?.lname}
                  </div>
                </div>
                {/* inner details below lab details closed */}
                <div
                  className="d-flex px-md-1 px-lg-4"
                  style={{ paddingTop: "25px" }}
                >
                  {" "}
                  {/* div for lab address */}
                  <div>
                    <img
                      src={"/Pathology/clinic.png"}
                      style={{
                        height: "20px",
                        width: "20px",
                        marginTop: "5px",
                      }}
                    ></img>
                    <div
                      style={{
                        fontSize: "8px",
                        fontWeight: "400",
                        color: "rgba(112, 112, 112, 1)",
                      }}
                    >
                      Address
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: isTabScreen ? "14px" : "16px",
                      fontWeight: "400",
                      paddingLeft: "21px",
                      color: "rgba(51, 51, 51, 1)",
                    }}
                  >
                    {patientdata?.sonography?.clinic_address?.address_line_1}
                  </div>
                </div>

                <div
                  className="px-4 d-flex align-items-center"
                  style={{ paddingTop: "19px" }}
                >
                  <img
                    src={"/Pathology/map.png"}
                    style={{ height: "20px", width: "15px", marginTop: "5px" }}
                  ></img>
                  <div
                    style={{
                      color: "rgba(0, 82, 204, 1)",
                      fontSize: isTabScreen ? "12px" : "16px",
                      fontWeight: "400",
                      paddingLeft: "25px",
                    }}
                  >
                    Check on map
                  </div>
                </div>
                <hr className="mt-1 mb-0 w-100"></hr>

                {viewBtn === true ? (
                  <>
                    <div className="mt-4 d-flex">
                      <div onClick={() => setViewBtn(false)} className="ps-2">
                        <img
                          src={"/GarbhaSanskar/back.png"}
                          style={{
                            height: "21px",
                            width: "30px",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                      <div className="ps-5">Consultation {consultno}</div>
                    </div>
                    <ConsultationCardDetails
                      consultationsData={consultationsData}
                    />
                  </>
                ) : (
                  <div>
                    <div
                      className="text-center"
                      style={{
                        marginTop: "25px",
                        color: "rgba(240, 88, 139, 1)",
                        fontSize: isTabScreen ? "20px" : "24px",
                        fontWeight: "500",
                      }}
                    >
                      Test Summary
                    </div>
                    <PathTable1 data={consultations} />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Sonography;
