import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Radium, { StyleRoot } from "radium";
import { fadeIn } from "react-animations";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MiniDrawer from "../Components/MiniDrawer";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import configData from "../config.json";
import axios from "axios";
import Dropdown from "../Components/Dropdown";
import { useMediaQuery } from "react-responsive";

import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ReportBox from "../Components/ReportBox";

import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SignatureUpload from "../Components/SignatureUpload";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import AlertShow from "../Components/AlertShow";

const times = [
  "9:00AM",
  "9:30AM",
  "10:00AM",
  "10:30AM",
  "11:00AM",
  "11:30AM",
  "12:00PM",
  "12:30PM",
  "1:00PM",
  "1:30PM",
  "2:00PM",
  "2:30PM",
  "3:00PM",
  "3:30PM",
  "4:00PM",
  "4:30PM",
  "5:00PM",
  "5:30PM",
  "6:00PM",
  "6:30PM",
];

const timePeriod = ["AM", "PM"];

let medical_expert_id = "";
const styles = {
  fadeIn: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeIn, "fadeIn"),
  },
};

const rectangleStyle = {
  width: "150px",
  height: "18px",
  backgroundColor: "#3498db",
  transform: "skewX(-20deg)",
  color: "white",
};
const specialisation = {
  fontFamily: "poppins",
  fontWeight: "500",
  fontSize: "20.25px",
  lineHeight: "43.88px",
  color: "#B5B5C3",
};
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "25%",
  borderRadius: "20px",
  width: "25%",
  bgcolor: "background.paper",
};

const nextStyle = {
  marginRight: "50px",
  height: "40px",
  width: "90px",
  backgroundColor: "#004AAD",
  color: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: "500",
  borderRadius: "12px",
  marginTop: "20px",
  marginBottom: "25px",
  cursor: "pointer",
  padding: 5,
  float: "inline-end",
};

const steps = [
  {
    id: 1,
    name: "basic details",
    complete: false,
  },
  {
    id: 2,
    name: "specialisation",
    complete: false,
  },
  {
    id: 3,
    name: "medical details",
    complete: false,
  },
  {
    id: 4,
    name: "educational details",
    complete: false,
  },
  {
    id: 5,
    name: "clinic details",
    complete: false,
  },
  {
    id: 6,
    name: "story",
    complete: false,
  },
];
let s;

var selectedcityId, selectedStateId, selectedState, selectedStateName;
var selectedcity;
var selectedcityName;
function Profile() {
  const location = useLocation();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [severity, setSeverity] = useState("");

  const { profileData } = location?.state;
  useEffect(() => {
    console.log(profileData);
    const isDetail =
      profileData.user?.phone &&
      profileData.user?.phone2 &&
      profileData.user?.languages_name != null &&
      profileData.user?.email != null &&
      profileData.user?.gender != null &&
      profileData.user?.story != null &&
      profileData.clinics[0]?.state_name != null &&
      profileData.clinics[0]?.city_name != null &&
      profileData.clinics[0]?.name != null &&
      profileData.clinics[0]?.phone != null &&
      profileData.clinics[0]?.email != null &&
      profileData.clinics[0]?.address_line_1 &&
      profileData.educations[0]?.name != null &&
      profileData.educations[0]?.college != null &&
      profileData.educations[0]?.completion_year != null &&
      profileData.educations[0]?.experience != null &&
      profileData.medical_reg[0]?.reg_num != null &&
      profileData.medical_reg[0]?.reg_council != null &&
      profileData.medical_reg[0]?.reg_year != null &&
      profileData.user?.profile_pic;

    if (isDetail) {
      // setProfileComplete(true);
      setProfileCompletefinal(true);
      setCurrentStep(0);
    }
  }, []);
  // const [profileData, setProfileData] = useState(initialData);

  // console.log("!!!!!!", profileData);
  const showsubmenu = () => {
    setSubmenu(!submenu);
  };
  const [submenu, setSubmenu] = useState(false);
  useEffect(() => {
    fetch(`${configData.SERVER_API_URL}/common/get_languages`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setLanguages(data.languages);
      })
      .catch((error) => {
        console.error("Error fetching languages:", error);
      });
  }, []);
  useEffect(() => {
    fetch(`${configData.SERVER_API_URL}/common/get_blood_group`)
      .then((response) => response.json())
      .then((data) => {
        setBloodGroups(data.bloodGroup); // Note the key "bloodGroup" from the API response
      })
      .catch((error) => {
        console.error("Error fetching blood groups:", error);
      });
  }, []);
  // useEffect(() => {
  //   getapi1();
  // }, [profileData]);
  // const getapi1 = async () => {
  //   try {
  //     const id = localStorage.getItem("userId");
  //     if (id) {
  //       const response = await axios.post(
  //         `${configData.SERVER_API_URL}/users/get_single_user`,
  //         {
  //           userId: id,
  //         }
  //       );
  //       if (response.data.success) {
  //         console.log("fetched$$$$$$$$$$$$$$$$$$$$$", response.data);
  //         setProfileData(response.data);
  //       } else {
  //         console.error(
  //           "Error adding add user education details:",
  //           response.data.error
  //         );
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error", error);
  //   }
  // };
  const handleLanguageCheckboxChange = (languageId, isChecked) => {
    console.log("Checkbox changed:", languageId, isChecked);
    // if (isChecked) {
    //   setSelectedLanguages((prevSelectedLanguages) =>
    //     prevSelectedLanguages ? languageId : languageId
    //   );
    // } else {
    //   setSelectedLanguages((prevSelectedLanguages) =>
    //     prevSelectedLanguages
    //       ?.split(",")
    //       .filter((id) => id !== languageId.toString())
    //       .join(",")
    //   );
    // }
    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   ["languages"]: selectedLanguages,
    // }));
    // console.log("ppp", selectedLanguages);

    // Clone the selectedLanguages state for modification
    setSelectedLanguages((prevSelectedLanguages) => {
      let updatedSelectedLanguages = [...prevSelectedLanguages];

      if (isChecked) {
        // If the checkbox is checked and the languageId is not already in the array, add it
        if (!updatedSelectedLanguages.includes(languageId)) {
          updatedSelectedLanguages.push(parseInt(languageId));
        }
      } else {
        // If the checkbox is unchecked, remove the languageId from the array if it's present
        updatedSelectedLanguages = updatedSelectedLanguages.filter(
          (id) => id !== languageId,
        );
      }

      // Convert the array of language IDs to a comma-separated string
      const updatedLanguagesString = updatedSelectedLanguages.join(",");
      console.log("Updated Languages:", updatedLanguagesString);

      // Update the form data with the unique language IDs
      setFormData((prevFormData) => ({
        ...prevFormData,
        languages: updatedLanguagesString,
      }));

      return updatedSelectedLanguages; // Return the updated state
    });
    // console.log("ppp", updatedLanguagesString);
  };

  const [selectedId, setSelectedId] = useState(1);
  const [profileDataMain, setProfileDataMain] = useState(profileData);
  const [specials, setSpecials] = useState(profileDataMain?.specialisations);
  const [medicalReg, setMedicalReg] = useState(profileDataMain?.medical_reg);
  const [educations, setEducations] = useState(profileDataMain?.educations);

  const [educationId, setEducationId] = useState();
  const [yogaId, setYogaId] = useState();

  const [selectedImage, setSelectedImage] = useState();
  const [isUploadPopupOpen, setIsUploadPopupOpen] = useState(false);
  const fileInputRef = useRef(null);
  const initialData = {
    phoneNumber: profileData.user?.phone,
    emailAddress: profileData.user?.email,
    gender: profileData.user?.gender,
    dob: profileData.user?.dob,
    bg: profileData.user?.blood_group_name,
    Timezone: profileData.user?.timezone,
    AlternateNo: profileData.user?.phone2,
    languages: profileData.user?.languages_name,
    mrn: profileData?.medical_reg[0]?.reg_num,
    specialisation:
      profileDataMain?.specialisations?.length > 0
        ? profileDataMain?.specialisations
        : "",
    Regcouncil: profileData.medical_reg[0]?.reg_council,
    Regyear: profileData.medical_reg[0]?.reg_year,

    name: profileData.educations[0]?.name,
    college: profileData.educations[0]?.college,
    completion_year: profileData.educations[0]?.completion_year,
    experience: profileData.educations[0]?.experience,

    cityest: profileData.clinics[0]?.city_id,
    stateest: profileData.clinics[0]?.state_id,
    hospitalnm: profileData.clinics[0]?.name,
    localityest: "pune",
    clinicphno: profileData.clinics[0]?.phone,
    clinicmail: profileData.clinics[0]?.email,
    clinicadd: profileData.clinics[0]?.address_line_1,

    yourstory: profileData.user?.story,
    yoursign: profileData.user?.doctor_signature,
  };

  const [yogaBatchDetails, setYogaBatchDetails] = useState();

  const handleImageClick = () => {
    // Trigger a click event on the hidden file input
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleCloseUploadPopup = () => {
    setIsUploadPopupOpen(false);
  };

  const handleImageChange = (event) => {
    console.log("event.target.files?", event.target?.files[0]);
    const file = event.target?.files[0];
    setSelectedImage(file);
  };

  const [isEditing, setIsEditing] = useState(false);
  const [isEditing1, setIsEditing1] = useState(false);
  const [isEditing2, setIsEditing2] = useState(false);
  const [isEditing3, setIsEditing3] = useState(false);
  const [isEditing4, setIsEditing4] = useState(false);
  const [isEditing6, setIsEditing6] = useState(false);
  const [isEditing5, setIsEditing5] = useState(false);
  const [isEditing7, setIsEditing7] = useState(false);

  const [formData, setFormData] = useState(initialData);
  const [isEditingInitial, setIsEditingInitial] = useState(false);
  const [fields, setFields] = useState([{ isEditing: false, value: "M.D." }]);
  const [bloodGroups, setBloodGroups] = useState([]);
  const [medicalreg, setmedicalreg] = useState(false);
  const [completedsteps, setCompletedSteps] = useState(steps);
  const [currentStep, setCurrentStep] = useState(1);
  const [profileComplete, setProfileComplete] = useState(false);
  const [profileCompletefinal, setProfileCompletefinal] = useState(false);
  const [showYogaTime, setShowYogaTime] = useState(false);

  const [add1, setAdd1] = useState(false);
  const [add2, setAdd2] = useState(false);
  const [add3, setAdd3] = useState(false);
  const [add4, setAdd4] = useState(false);

  const [special1, setSpecial1] = useState("");

  const [mrn, setMrn] = useState();
  const [regCouncil, setRegCouncil] = useState();
  const [regYear, setRegYear] = useState();
  const [medId, setmedId] = useState();

  const [hover, setHover] = useState(false);
  const [basicdetail, setBasicdetail] = useState(false);
  const [medicaldetail, setMedicaldetail] = useState(false);
  const [education, setEducation] = useState(false);
  const [hospitaldetail, setHospitaldetail] = useState(false);
  const [storydetail, setStorydetail] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showSuccessMessagespecial, setShowSuccessMessagespecial] =
    useState(false);
  const [showSuccessMessagemedical, setShowSuccessMessagemedical] =
    useState(false);
  const [showSuccessMessageedu, setShowSuccessMessageedu] = useState(false);
  const [showSuccessMessageclinic, setShowSuccessMessageclinic] =
    useState(false);

  const [notification, setNotification] = useState([]);
  const [shownotification, setShownotification] = useState(false);
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [count, setCount] = useState(0);
  const [editSplitTimeValue, setEditSplitTimeValue] = useState([]);
  const [signurl, setSignurl] = useState("null");
  const [signtoshow, setSigntoshow] = useState(false);
  const handleClose = () => {
    setProfileComplete(false);
  };
  const handleClose1 = () => {
    setShowSuccessMessage(false);
  };
  const handleClosespecial = () => {
    setShowSuccessMessagespecial(false);
  };
  const handleClosemedical = () => {
    setShowSuccessMessagemedical(false);
  };
  const handleCloseedu = () => {
    setShowSuccessMessageedu(false);
  };
  const handleCloseclinic = () => {
    setShowSuccessMessageclinic(false);
  };

  const handleCloseYogaTime = () => {
    setShowYogaTime(false);
  };

  const handleBatchItem = (time) => {
    const updatedTimes = [...selectedTimes];

    // Check if the time is already in the array
    const index = updatedTimes.indexOf(time);

    if (index === -1 && updatedTimes.length < 2) {
      // If not present and less than 2 selected, add it to the array
      updatedTimes.push(time);
    } else if (index !== -1) {
      // If present, remove it from the array
      updatedTimes.splice(index, 1);
    } else {
      var msg = "Select onley two time sloat.";
      const error_color = "error";
      handleAlertMsg(error_color, msg);
    }

    // Sort the selected times in ascending order
    // updatedTimes.sort((a, b) => a.localeCompare(b));
    // updatedTimes.sort((a, b) => {

    //   const comparisonResult = compareTimes(a, b);
    //   return comparisonResult;
    // });

    // const comparisonResult = compareTimes(time1, time2);
    console.log("comparisonTime..", updatedTimes);
    // Update the state
    setSelectedTimes(updatedTimes);
  };

  const handleAddBatchtime = async () => {
    try {
      let time1 = editSplitTimeValue[0];
      let time2 = editSplitTimeValue[2];
      if (time1 !== 0 || time2 !== 0) {
        const hasColonInTime1 = editSplitTimeValue[0].includes(":");
        const hasdotInTime1 = editSplitTimeValue[0].includes(".");
        const hasColonInTime2 = editSplitTimeValue[2].includes(":");
        const hasdotInTime2 = editSplitTimeValue[2].includes(".");
        if (!hasColonInTime1) {
          time1 = editSplitTimeValue[0] + ":00";
        }
        if (hasdotInTime1) {
          time1 = editSplitTimeValue[0].replace(".", ":");
        }
        if (!hasColonInTime2) {
          time2 = editSplitTimeValue[2] + ":00";
        }
        if (hasdotInTime2) {
          time2 = editSplitTimeValue[2].replace(".", ":");
        }
        console.log("hasColonInTime1", hasColonInTime1);

        const period1 = editSplitTimeValue[1] === 0 ? "AM" : "PM";
        const period2 = editSplitTimeValue[3] === 0 ? "AM" : "PM";
        const formattedString = time1 + period1 + "-" + time2 + period2;

        console.log("checkdata", formattedString);
        console.log("checkdata", yogaId);
        const response = await axios.post(
          `${configData.SERVER_API_URL}/consultation/update_yoga_batches_timiing`,
          {
            timing: formattedString,
            id: yogaId,
          },
        );
        console.log("response", response);
        if (response.data.success) {
          var msg = "Batch time sloat added successfully.";
          const error_color = "success";
          handleAlertMsg(error_color, msg);
          setShowYogaTime(false);
          getBatchDetails();
          setYogaId();
          setEditSplitTimeValue([]);
        } else {
          var msg = "Something is bad.";
          const error_color = "error";
          handleAlertMsg(error_color, msg);
          setShowYogaTime(false);
          setEditSplitTimeValue([]);
        }
      } else {
        var msg = "Please enter valid Time";
        const error_color = "error";
        handleAlertMsg(error_color, msg);
      }
    } catch (error) {
      console.log("Error :", error);
    }
  };

  useEffect(() => {
    console.log("specials??", specials);
  }, [specials]);

  useEffect(() => {
    if (selectedImage) uploadImage();
  }, [selectedImage]);

  useEffect(() => {
    console.log("specials??", completedsteps);
  }, [completedsteps]);

  useEffect(() => {
    getStateandCity();
  }, [profileDataMain]);

  useEffect(() => {
    console.log("SEEEEE", formData);
    if (profileData.user.gender == null) {
      setFormData({
        ...formData,
        gender: "Male",
      });
    }
  }, []);

  const getBatchDetails = async () => {
    try {
      const response = await axios.get(
        `${configData.SERVER_API_URL}/consultation/get_yoga_batches_timiing`,
      );
      if (response.data.success) {
        setYogaBatchDetails(response.data.Batches);
      }
    } catch (error) {
      console.log("Error : ", error);
    }
  };
  console.log("yogaBatchDetails", yogaBatchDetails);

  useEffect(() => {
    getBatchDetails();
  }, []);

  useEffect(() => {
    console.log("profileDataMain??", profileDataMain);
    medical_expert_id = profileDataMain?.user?.medical_expert_id;
    console.log("profileDataMain??", medical_expert_id);
    setSpecials(profileDataMain?.specialisations);
    setMedicalReg(profileDataMain?.medical_reg);
    setEducations(profileDataMain?.educations);
  }, [profileDataMain]);
  useEffect(() => {
    setSelectedId(profileDataMain.user?.blood_group);
    // setSelectedLanguages(profileData.user.languages.split(","));

    if (profileDataMain.user.languages) {
      const languageIdsArray = profileDataMain.user.languages
        .split(",")
        .map((id) => parseInt(id));
      setSelectedLanguages(languageIdsArray);
    }
    getdetail();
  }, []);

  const fetchNotifications = async () => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/common/get_notification`,
        {
          receiver_id: profileData.id,
        },
      );

      if (response.status === 200) {
        const l = response.data.notifications.length;
        console.log(
          "GetNotification Response",
          response.data.notifications,
          response.data.notifications[l - 1],
        );

        setNotification(response.data.notifications);
        let cnt = response.data.notifications[l - 1].notification_count;
        // console.log("cnt>", cnt);
        setCount(cnt);
      } else {
        console.log("Error in getting notification");
      }
    } catch (error) {
      console.log("Error in getting catch block", error.message);
    }
  };
  const readnotification = async () => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/common/read_notification`,
        { receiver_id: profileData.id },
      );
      if (response.status === 200) {
        console.log("successfully read ");
      } else {
        console.log("Error in reading notification");
      }
    } catch (error) {
      console.log("Error", error.message);
    }
    fetchNotifications();
  };

  const shownotificationofdr = () => {
    setShownotification(!shownotification);
    readnotification();
  };

  const handleEditClick = () => {
    let updatedFields = [...fields];
    updatedFields[0].isEditing = true; // Set the initial field to editable
    setFields(updatedFields);
    setIsEditingInitial(true);
  };

  const handleAddField = () => {
    const newFields = [...fields, { isEditing: true, value: "" }];
    setFields(newFields);
  };

  const handleChange = (index, newValue) => {
    let updatedFields = [...fields];
    console.log("<<<", updatedFields);
    updatedFields[0].value = newValue;
    setFields(updatedFields);
    console.log(fields);
  };

  const handleSave = (index, Id) => {
    const updatedFields = [...fields];
    console.log("osaoasoa", updatedFields);
    updatedFields[0].isEditing = false;
    setFields(updatedFields);
    setIsEditingInitial(false);
    updateSpecial(Id);
  };

  const handleSave5 = (index, Id) => {
    const updatedFields = [...fields];
    console.log("osaoasoa", updatedFields);
    updatedFields[0].isEditing = false;
    setFields(updatedFields);
    setIsEditingInitial(false);
    // updateSpecial(Id);
  };
  const Edit = () => {
    setIsEditing(true);
  };
  const Editprofile = () => {
    setIsEditing(true);
    setProfileCompletefinal(false);
    setCurrentStep(1);
  };
  const Edit1 = (id) => {
    setmedId(id);
    setIsEditing1(true);
  };
  const Edit2 = () => {
    setIsEditing2(true);
  };
  const Edit3 = () => {
    setIsEditing3(true);
  };
  const Edit4 = () => {
    setIsEditing4(true);
  };
  const Edit6 = () => {
    setIsEditing6(true);
  };
  const Edit5 = () => {
    setIsEditing5(true);
  };
  const Edit7 = (time) => {
    console.log("Item3", time);
    // setShowYogaTime(true);
    setIsEditing7(true);
    const splitArray = splitTimeRange(time);
    console.log("splitArray", splitArray);
    setEditSplitTimeValue(splitArray);
    // setSelectedTimes([]);
  };

  const splitTimeRange = (timeRange) => {
    const parts = timeRange.split("-");
    const timeMoment = moment(parts[0], "h:mmA");
    const timeMoment1 = moment(parts[1], "h:mmA");
    console.log("parts----", timeMoment);
    console.log("parts----", timeMoment1);
    if (timeMoment.isValid() && timeMoment1.isValid()) {
      // Extract hours, minutes, and period using moment
      const hours = timeMoment.format("h");
      const minutes = timeMoment.format("mm");
      const period = timeMoment.format("A");

      const hours1 = timeMoment1.format("h");
      const minutes1 = timeMoment1.format("mm");
      const period1 = timeMoment1.format("A");

      const element0 = hours + ":" + minutes;
      const element1 = period === "AM" ? 0 : 1;
      const element2 = hours1 + ":" + minutes1;
      const element3 = period1 === "AM" ? 0 : 1;
      // console.log("timeCheck",timeCheck);
      // Return an array with time components
      console.log("parts----", element0, element1, element2, element3);
      return [element0, element1, element2, element3];
    } else {
      console.error("Invalid time format");
      return null;
    }
    // const regex = /(\d{1,2}:\d{2})([APMapm]{2})\s*-\s*(\d{1,2}:\d{2})([APMapm]{2})/;
    // const match = timeRange.match(regex);
    // console.log("timeRange",timeRange);
    // if (match) {
    //   // Elements 1 and 3 are the times, 2 and 4 are the AM/PM indicators
    //   const [, time1, period1, time2, period2] = match;
    //   const period1_ = period1 === 'AM' ? 0 : 1;
    //   const period2_ = period2 === 'AM' ? 0 : 1;
    //   return [time1, period1_, time2, period2_];
    // } else {
    //   // Handle invalid time range format
    //   console.error('Invalid time range format');
    //   return null;
    // }
  };

  function markStepComplete(stepId) {
    const updatedSteps = completedsteps.map((step) => {
      if (step.id === stepId) {
        return { ...step, complete: true };
      }
      return step;
    });

    return updatedSteps;
  }

  function received(data) {
    setSignurl(data);
    console.log("PP", data);
    setFormData((prevFormData) => ({
      ...prevFormData,
      yoursign: data,
    }));
  }

  function markStepComplete2(stepId) {
    const updatedSteps = completedsteps.map((step) => {
      if (step.id === stepId) {
        return { ...step, complete: false };
      }
      return step;
    });

    return updatedSteps;
  }
  const save = async () => {
    setIsEditing(false);
    // const isDetailsFilled =
    //   formData.AlternateNo &&
    //   formData.emailAddress &&
    //   formData.phoneNumber &&
    //   formData.gender &&
    //   formData.dob &&
    //   selectedLanguages?.length > 0;

    // if (!selectedImage) {
    //   alert("Please select a profile image");
    //   return;
    // }
    // if (!isDetailsFilled) {
    //   alert("Please fill in all details before saving.");
    //   setBasicdetail(false);
    //   return;
    // } else {
    //   setBasicdetail(true);
    // }

    try {
      const response = await fetch(
        `${configData.SERVER_API_URL}/users/update_user_personal`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: profileData.user.id, // User ID
            fname: profileData.user.fname, // Assuming you have firstName property in formData
            lname: profileData.user.lname, // Assuming you have lastName property in formData
            email: formData.emailAddress, // Assuming you have emailAddress property in formData
            phone: parseInt(formData.phoneNumber), // Assuming you have phoneNumber property in formData
            address_line_1: formData.addressLine1, // Assuming you have addressLine1 property in formData
            address_line_2: formData.addressLine2,
            city_id: null,
            state_id: null,
            specialization: null,
            qualifications: null,
            about_me: null,
            gender: formData.gender,
            dob: moment(formData.dob).format("YYYY/MM/DD"),
            // blood_group: parseInt(selectedId), // Assuming you're storing blood group ID
            phone2: parseInt(formData.AlternateNo),
            languages: selectedLanguages?.join(","), // Assuming languages is an array of IDs
          }),
        },
      );
      if (response.ok) {
        setBasicdetail(true);
        setShowSuccessMessage(true);
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 2000);

        // You can perform further actions if n
      } else {
        console.log("Failed to save user data");
        // Handle the error scenario if needed
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
    getdetail();
  };

  const deleteSpecial = async (Id) => {
    setIsEditing(false);
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/users/delete_user_specialisation`,
        {
          id: Id,
          userId: profileData.user.id,
        },
      );

      if (response.data.success) {
        setIsEditing(false);
        setAdd1(false);
        setIsEditingInitial(false);

        console.log("Special Deleted successfully");
        getdetail();
        alert("Specialisation Deleted Successfully");
      } else {
        console.error("Error adding Special:", response.data.error);
      }
    } catch (error) {
      console.error("Error adding Special:", error);
    }
  };
  const handledelete = async (Id) => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/users/delete_user_education`,
        {
          id: Id,
          userId: profileData.user.id,
        },
      );
      if (response.data.success) {
        console.log("Education Deleted successfully");
        getdetail();
      } else {
        console.error("Error adding Special:", response.data.error);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };
  const uploadImage = async () => {
    console.log("detail", selectedImage, profileData.user.id);

    const form = new FormData();
    form.append("userId", profileData.user.id);
    form.append("profile_pic", selectedImage);

    console.log("FORM-", form);
    let isFormDataEmpty = true;

    // Iterate through the FormData entries
    form.forEach((value, key) => {
      isFormDataEmpty = false;
      // You can log the key and value if needed
      console.log(`Key: ${key}, Value: ${value}`);
    });

    if (isFormDataEmpty) {
      console.log("FormData is empty");
    } else {
      console.log("FormData is not empty");
    }

    try {
      const response = await axios.post(
        profileDataMain?.user?.profile_pic
          ? `${configData.SERVER_API_URL}/users/update_user_profile_pic`
          : `${configData.SERVER_API_URL}/users/add_user_profile_pic`,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );

      if (response.data.success) {
        console.log("Profile Image Uploaded successfully");
        getdetail();
        if (profileDataMain?.user?.profile_pic) {
          const msg = "Profile Image Updated successfully";
          const error_color = "success";
          handleAlertMsg(error_color, msg);
        }
      } else {
        alert("Profile Image Upload Failed");
        console.error("Profile Image Upload", response.data.error);
      }
    } catch (error) {
      console.error("Profile Image Upload", error);
    }
  };

  const addSpecial = async () => {
    console.log("detail", profileData.user.id);
    setIsEditing(false);
    if (special1 === "") {
      alert("Please enter specialisation!");
      return;
    }
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/users/add_user_specialisation`,
        {
          name: special1,
          userId: profileData.user.id,
        },
      );

      if (response.data.success) {
        setIsEditing1(false);
        setAdd1(false);
        setIsEditingInitial(false);
        console.log("Special added successfully");
        alert("Specialisation Added Successfully");
        setTimeout(() => {
          getdetail();
        }, 1000);
      } else {
        console.error("Error adding Special:", response.data.error);
      }
    } catch (error) {
      console.error("Error adding Special:", error);
    }
  };

  const updateSpecial = async (Id) => {
    console.log("detail", profileData.user.id);
    let sp = specials.filter((item) => item.id === Id);
    console.log("check sss", sp);
    setIsEditing1(false);
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/users/update_user_specialisation`,
        {
          id: Id,
          name: sp[0]?.name,
          userId: profileData.user.id,
        },
      );

      if (response.data.success) {
        setIsEditing1(false);
        setAdd1(false);
        console.log("Special Updated successfully");
        getdetail();
        setShowSuccessMessagespecial(true);
        setTimeout(() => {
          setShowSuccessMessagespecial(false);
        }, 2000);
      } else {
        console.error("Error adding Special:", response.data.error);
      }
    } catch (error) {
      console.error("Error adding Special:", error);
    }
  };
  const getdetail = async () => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/users/get_single_user`,
        {
          userId: profileData.user.id,
        },
      );

      if (response.data.success) {
        setIsEditing1(false);
        console.log("get single user successfully", response.data);
        setProfileDataMain(response.data);
      } else {
        console.error(
          "Error adding medical registration details:",
          response.data.error,
        );
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
    // }, 60000);
  };
  const handleAlertMsg = (error_type, msg) => {
    setShowAlert(true);
    setSeverity(error_type);
    setAlertMsg(msg);
    setTimeout(() => {
      setShowAlert(false);
    }, 2500);
  };
  const ondataReceived = (sign) => {
    console.log("{{", sign);
  };
  const handleEdit = (index) => {
    const updatedFields = fields.map((field, i) => ({
      ...field,
      isEditing: i === index,
    }));
    setFields(updatedFields);
  };
  const save3 = async () => {
    setIsEditing1(false);
    console.log("detail", formData, profileData.user.id);

    var url;
    const isHospitaldetails =
      formData.hospitalnm &&
      formData.clinicadd &&
      formData.cityest &&
      formData.stateest &&
      formData.clinicphno &&
      formData.clinicmail;

    if (!isHospitaldetails) {
      var msg = "Please fill in all hospital details before saving.";
      const error_color = "error";
      handleAlertMsg(error_color, msg);

      setHospitaldetail(false);
      return;
    } else {
      setHospitaldetail(true);
    }
    try {
      if (profileData.clinics?.length > 0) url = "/users/update_user_clinic";
      else url = "/users/add_user_clinic";

      const response = await axios.post(`${configData.SERVER_API_URL}${url}`, {
        id: profileData.clinics[0]?.clinic_id,
        name: formData.hospitalnm,
        addLine1: formData.clinicadd,
        addLine2: formData.clinicadd,
        locality: formData.cliniclocalityest,
        city_id: formData.cityest,
        state_id: formData.stateest,
        phone: formData.clinicphno,
        email: formData.clinicmail,
        userId: profileData.user.id,
      });

      if (response.data.success) {
        setIsEditing1(false);
        setShowSuccessMessageclinic(true);
        setTimeout(() => {
          setShowSuccessMessageclinic(false);
        }, 2000);
        getdetail();
      } else {
        var msg = "Please Enter all details.";
        const error_color = "error";
        handleAlertMsg(error_color, msg);
        console.error(
          "Error  Hospital registration details:",
          response.data.error,
        );
      }
    } catch (error) {
      var msg = "Please Enter all details.";
      const error_color = "error";
      handleAlertMsg(error_color, msg);
      console.error("Error adding medical registration details:", error);
    }
  };
  const save2 = () => {
    setIsEditing2(false);
  };
  const saveAll = async () => {
    setProfileComplete(true);
    setProfileCompletefinal(true);
    const updatedSteps = markStepComplete(7);
    setCompletedSteps(updatedSteps);
    setTimeout(() => {
      setProfileComplete(false);
    }, 2000);
  };
  const save4 = async () => {
    setIsEditing4(false);
    const isstorydetails = formData.yourstory && formData.yoursign;

    if (!isstorydetails) {
      var msg = "Please fill details before saving.";
      const error_color = "error";
      handleAlertMsg(error_color, msg);
      setStorydetail(false);
      setSigntoshow(true);
      return;
    } else {
      setStorydetail(true);
      setSigntoshow(true);
      const updatedSteps = markStepComplete(6);
      setCompletedSteps(updatedSteps);
      if (medical_expert_id !== 4) {
        setProfileComplete(true);
        setProfileCompletefinal(true);
      }
      if (medical_expert_id !== 4) {
        setCurrentStep(1);
      }
      setIsEditing(false);
      setIsEditing1(false);
      setIsEditing2(false);
      setIsEditing3(false);
      setIsEditing4(false);
      setIsEditing5(false);
      setIsEditing6(false);
      if (medical_expert_id !== 4) {
        setTimeout(() => {
          setProfileComplete(false);
        }, 2000);
      }
    }
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/users/add_user_story`,
        {
          userId: profileData.user?.id,
          story: formData.yourstory,
        },
      );

      if (response.data.success) {
        setIsEditing4(false);
        var msg = "User Story added successfully!!!";
        const error_color = "success";
        handleAlertMsg(error_color, msg);
      } else {
        console.error(
          "Error adding add user story details:",
          response.data.error,
        );
      }
    } catch (error) {
      console.error("Error adding user story details:", error);
    }
    // getdetail();
  };
  const save1 = async (mode, Id) => {
    var url;
    let md = medicalReg.filter((item) => item.id === Id);
    setIsEditing1(false);
    const isMedicaldetail =
      mode === 1
        ? md[0].reg_num
        : mrn && mode === 1
          ? md[0].reg_council
          : regCouncil && mode === 1
            ? md[0].reg_year
            : regYear;
    if (!isMedicaldetail) {
      var msg = "Please fill all medical registration details.";
      const error_color = "error";
      handleAlertMsg(error_color, msg);
      setMedicaldetail(false);
      return;
    } else {
      setMedicaldetail(true);
    }
    try {
      if (mode === 1) url = "/users/update_user_medi_regi";
      else url = "/users/add_user_medi_regi";
      // console.log("urlll", url, profileData.clinics[0]?.id);
      // update_user_medi_regi
      const response = await axios.post(`${configData.SERVER_API_URL}${url}`, {
        id: Id,
        regNum: mode === 1 ? md[0].reg_num : mrn,
        council: mode === 1 ? md[0].reg_council : regCouncil,
        regYear: mode === 1 ? md[0].reg_year : regYear,
        userId: profileData.user.id,
      });

      if (response.data.success) {
        if (mode === 1) setIsEditing1(false);
        else {
          setAdd2(false);
          console.log("Medical registration= details added successfully");

          setMedicaldetail(true);
        }
        if (mode === 1) {
          setShowSuccessMessagemedical(true);
          setTimeout(() => {
            setShowSuccessMessagemedical(false);
          }, 2000);
        } else {
          setShowSuccessMessagemedical(true);
          setTimeout(() => {
            setShowSuccessMessagemedical(false);
          }, 2000);
        }
      } else {
        console.error(
          "Error adding medical registration details:",
          response.data.error,
        );
      }
    } catch (error) {
      console.error("Error adding medical registration details:", error);
    }
    getdetail();
  };
  const save7 = () => {
    setShowYogaTime(true);
  };

  const save6 = async (mode, Id) => {
    setIsEditing6(false);
    var url;

    let ed = educations.filter((item) => item.id === Id);
    const isEducationdetail =
      mode === 2
        ? formData.name
        : ed[0].name && mode === 2
          ? formData.college
          : ed[0].college && mode === 2
            ? formData.completion_year
            : ed[0].completion_year && mode === 2
              ? formData.experience
              : ed[0].experience;
    if (!isEducationdetail) {
      var msg = "Please fill all Education details.";
      const error_color = "error";
      handleAlertMsg(error_color, msg);
      setEducation(false);
      return;
    } else {
      setEducation(true);
    }
    try {
      if (mode === 1) url = "/users/update_user_education";
      else url = "/users/add_user_education";
      const response = await axios.post(`${configData.SERVER_API_URL}${url}`, {
        id: Id,
        degree: mode === 2 ? formData.name : ed[0].name,
        college: mode === 2 ? formData.college : ed[0].college,
        year: mode === 2 ? formData.completion_year : ed[0].completion_year,
        experience: mode === 2 ? formData.experience : ed[0].experience,
        userId: profileData.user.id,
      });

      if (response.data.success) {
        setIsEditing1(false);
        console.log("Add user detail successfully", response);
        setShowSuccessMessageedu(true);
        setTimeout(() => {
          setShowSuccessMessageedu(false);
        }, 2000);
        setAdd3(false);
      } else {
        console.error(
          "Error adding add user education details:",
          response.data.error,
        );
      }
    } catch (error) {
      console.error("Error adding medical registration details:", error);
    }
    getdetail();
  };
  const save5 = () => {
    setIsEditing5(false);
  };

  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });

  const isScreen3 = useMediaQuery({
    query: "(min-width: 2000px)",
  });
  const [educationList, setEducationList] = useState([
    { degree: "", college: "", year: "", exp: "", isEditing: false },
    // Other education objects
  ]);

  const handleEditClick2 = () => {
    setIsEditing2(true);
  };

  const handleChange4 = (field, value) => {
    console.log("<<<<<", value);
    console.log(">>>>", field);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
    setSelectedId(value);
    console.log("???", formData);
  };
  const handleChange6 = (field, value) => {
    console.log("<<<<<", value);
    console.log(">>>>", field);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
    console.log("???", formData);
  };
  const handleChangestory = (field, value) => {
    console.log("<<<<<", value);
    console.log(">>>>", field);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
    console.log("???Story", formData);
  };

  const handleChange5 = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
    console.log("AA???", field, value);
  };

  const handleChangeMedical = (index, key, value) => {
    const updateMedical = [...medicalReg];
    console.log("1212=", value);
    updateMedical[index][key] = value;
    setMedicalReg(updateMedical);
  };

  const handleChangeEducation = (index, key, value) => {
    const updateEducation = [...educations];
    console.log("1212=", value);
    updateEducation[index][key] = value;
    setEducations(updateEducation);
  };

  const handleChangeBatch = (index, key, value) => {
    console.log("editSplitTimeValue", editSplitTimeValue);
    const editSplitTimeValueArray = [...editSplitTimeValue];
    editSplitTimeValueArray[index] = value;
    setEditSplitTimeValue(editSplitTimeValueArray);
    // const
  };

  const handleChange3 = (index, key, value) => {
    const updatedSpecials = [...specials];
    console.log("1212=", value);
    updatedSpecials[index][key] = value;
    setSpecials(updatedSpecials);
  };

  const [languages, setLanguages] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  useEffect(() => {
    fetchStateList();
    s = profileData.clinics[0]?.state_id;
  }, []);

  const fetchStateList = async () => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/common/get_states`,
        {
          countryId: "101", // Replace with the actual country ID
        },
      );

      setStateList(response.data.states);
      selectedState = response.data.states?.find(
        (state) => state.id == selectedStateId,
      );
      selectedStateName = selectedState ? selectedState?.name : null;
      console.log("selectedStateName", selectedStateId, selectedStateName);
    } catch (error) {
      console.error("Error fetching state list:", error);
    }
  };

  const fetchcity = async (stateid) => {
    fetch(`${configData.SERVER_API_URL}/common/get_cities`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stateId: stateid,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setCityList(data.cities);

        selectedcityId = formData.cityest;
        selectedcity = data.cities?.find((city) => city.id == selectedcityId);
        selectedcityName = selectedcity ? selectedcity?.name : "";
        console.log("selectedCityName", selectedcityId, selectedcityName);
      })
      .catch((error) => {
        console.error("Error fetching cities:", error);
        setCityList([]);
      });
  };

  const handleStateSelect = (stateId) => {
    setCityList([]);
    // console.log("DSDDSDSDSD", stateId);
    selectedStateId = stateId;
    selectedState = stateList?.find((state) => state.id == selectedStateId);
    selectedStateName = selectedState ? selectedState?.name : "";
    console.log("selectedStateName", selectedStateId, selectedStateName);

    fetch(`${configData.SERVER_API_URL}/common/get_cities`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stateId: stateId,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setCityList(data.cities);
      })
      .catch((error) => {
        console.error("Error fetching cities:", error);
        setCityList([]);
      });
  };

  const getStateandCity = () => {
    selectedStateId = formData.stateest;
    if (selectedStateId) fetchcity(selectedStateId);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  return (
    <div
      className="mm"
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
      }}
    >
      <MiniDrawer />
      <StyleRoot
        style={{
          height: "100%",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          backgroundColor: configData.THEME_COLORS.BACKGROUND,
        }}
      >
        <div className="test" style={styles.fadeIn}>
          <div
            style={{
              marginLeft: "4%",
            }}
          >
            <div
              style={{
                marginTop: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                paragraph
                sx={{
                  color: configData.THEME_COLORS.PRIMARY,
                }}
              >
                <div
                  style={{
                    fontSize: isTabScreen
                      ? "16px"
                      : isScreen3
                        ? "42px"
                        : "32px",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                    marginLeft: isTabScreen ? "19px" : "20px",
                  }}
                >
                  {"Profile"}
                </div>
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "60px",
                }}
              >
                <Badge
                  badgeContent={count?.toString()}
                  color="primary"
                  overlap="circular"
                  style={{ left: -5, cursor: "pointer" }}
                  sx={{
                    "& .MuiBadge-badge": {
                      color: "#FFFFFF",
                      backgroundColor: "#E42346",
                    },
                  }}
                >
                  <NotificationsIcon
                    onClick={shownotificationofdr}
                    sx={{
                      width: isScreen3 ? "40px" : "22.48px",
                      height: isScreen3 ? "41px" : "23.09px",
                      color: "#004AAD",
                    }}
                  />
                </Badge>
                <Dropdown
                  submenu={submenu}
                  profileData={profileData}
                  onClick={() => showsubmenu()}
                  page="profile"
                />
              </div>
              {/* <Dropdown submenu={submenu} /> */}
            </div>

            <div
              style={{
                width: "95%",
                backgroundColor: "white",
                boxShadow: "0px 0px 111.295px rgba(90, 90, 90, 0.1)",
                borderRadius: "14.8393px 14.8393px 0px 0px",
                alignSelf: "center",
                marginBottom: "50px",
                display: "flex",
                flexDirection: "column",
                // display: "none",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "70%",
                  alignItems: "center",
                  marginTop: "3%",
                  marginBottom: "2%",
                  alignSelf: "center",
                  display: profileCompletefinal === true ? "none" : "flex",
                }}
              >
                {completedsteps.map((item) => (
                  <div
                    style={{
                      width: "8vw",
                      height: "12px",
                      backgroundColor: item.complete ? "#F0588B" : "#FFF0F5",
                      transform: "skewX(-20deg)",
                    }}
                  ></div>
                ))}
              </div>
              <div
                style={{
                  marginTop: "1%",
                  height: "25vh",
                  width: "94%",
                  display: "flex",
                  alignSelf: "center",
                  // background:
                  //   'linear-gradient(93.37deg, rgba(0, 74, 173, 0.05) 3.33%, rgba(240, 88, 139, 0.025) 52.78%)',
                  border:
                    profileCompletefinal === false
                      ? "0.92346px solid rgba(203, 198, 198, 0.2)"
                      : "none",
                  borderRadius: "100px 0px 0px 0px",
                  backgroundColor: "white",
                  // profileCompletefinal === false ? "white" : "white",
                  // backgroundColor: '#CBC6C633'
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "5%",
                    marginTop: "14%",
                    width: "50%",
                  }}
                >
                  <div
                    className={hover ? "transparent-image" : ""}
                    style={{
                      position: "absolute",
                      borderRadius: "50%",
                      zIndex: 999,
                    }}
                    onClick={handleImageClick}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                  >
                    <img
                      src={
                        profileDataMain?.user?.profile_pic
                          ? `${configData.API_URL}` +
                            profileDataMain?.user?.profile_pic
                          : "/Profile/Group-1151.png"
                      }
                      style={{
                        marginTop: "5px",
                        resizeMode: "contain",
                        backgroundColor: "lightblue",
                        // backgroundColor: '#CBC6C633',
                        borderRadius: "50%",
                        height: isTabScreen
                          ? "100px"
                          : isScreen3
                            ? "14vh"
                            : "160px",
                        width: "160px",
                        cursor: "pointer",
                      }}
                      onClick={handleImageClick}
                      alt="Group"
                    />

                    {hover && (
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          cursor: "pointer",
                          borderRadius: "50%",
                          height: "20%",
                          width: "20%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <EditIcon
                          fontSize="large"
                          sx={{
                            color: profileDataMain?.user?.profile_pic
                              ? "white"
                              : "#004AAD",
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div
                    style={{ marginLeft: "36%", position: "relative", top: 60 }}
                  >
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: isTabScreen
                          ? "20px"
                          : isScreen3
                            ? "48px"
                            : "32px",
                        color: "#333333",
                      }}
                    >
                      Dr. {profileData.user.fname} {profileData.user.lname}
                    </div>
                    <div style={specialisation}>
                      {profileData.user.medical_expert_name}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {profileCompletefinal === false ? (
                  <div>
                    {currentStep === 1 && (
                      <>
                        <div
                          className="d-flex justify-content-between align-items-center mx-5"
                          style={{ marginTop: "10%" }}
                        >
                          <div
                            style={{
                              fontSize: isTabScreen
                                ? "17px"
                                : isScreen3
                                  ? "28px"
                                  : "22px",
                              fontWeight: "600",
                            }}
                          >
                            Professional Details
                          </div>
                          <div>
                            <button
                              className="me-2"
                              onClick={Edit}
                              style={{
                                fontWeight: "400",
                                fontSize: isTabScreen ? "15px" : "20px",
                                color: "#7C9CBF",
                                cursor: "pointer",
                                border: "1px solid #cccccc",
                                position: "relative",
                                zIndex: "99",
                                marginLeft: "auto",
                                backgroundColor: "white",
                                display: isEditing === true ? "none" : "block",
                              }}
                            >
                              <EditIcon />
                            </button>
                          </div>
                        </div>

                        <div
                          style={{
                            marginTop: "2%",
                            width: "95%",
                            paddingBottom: "1%",
                            borderRadius: "15px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                position: "relative",
                                display: "flex",
                                flexDirection: "column",
                                marginLeft: "5%",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: isTabScreen
                                    ? "15px"
                                    : isScreen3
                                      ? "24px"
                                      : "20px",
                                  fontWeight: "bolder",
                                  // width: "35%",
                                  color: isEditing ? "black" : "#004AAD",
                                }}
                              >
                                Phone Number
                              </div>

                              <div
                                style={{
                                  fontSize: isTabScreen
                                    ? "15px"
                                    : isScreen3
                                      ? "24px"
                                      : "20px",
                                  marginTop: "10px",
                                  // width: "65%",
                                }}
                              >
                                {isEditing ? (
                                  <input
                                    type="number"
                                    style={{
                                      borderRadius: "12px",
                                      border: "1px solid #cccccc",
                                      padding: 6,
                                      width: "300px",
                                    }}
                                    maxLength={10}
                                    value={formData.phoneNumber}
                                    onChange={(e) =>
                                      handleChange4(
                                        "phoneNumber",
                                        e.target.value,
                                      )
                                    }
                                  />
                                ) : (
                                  <div
                                    style={{
                                      borderRadius: "12px",
                                      borderRadius: "12px",
                                      border: "1px solid #cccccc",
                                      padding: 6,
                                      height: "45px",
                                      width: "300px",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    {formData.phoneNumber}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginRight: "100px",
                                width: "30%",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: isTabScreen
                                    ? "15px"
                                    : isScreen3
                                      ? "24px"
                                      : "20px",
                                  fontWeight: "bolder",
                                  // width: "35%",
                                  position: "relative",
                                  color: isEditing ? "black" : "#004AAD",
                                }}
                              >
                                Email Address
                              </div>
                              <div
                                style={{
                                  fontSize: isTabScreen
                                    ? "15px"
                                    : isScreen3
                                      ? "24px"
                                      : "20px",
                                  // width: "65%",
                                  marginTop: "10px",
                                }}
                              >
                                {isEditing ? (
                                  <input
                                    type="text"
                                    style={{
                                      borderRadius: "12px",
                                      border: "1px solid #cccccc",
                                      padding: 6,
                                      width: "300px",
                                    }}
                                    value={formData.emailAddress}
                                    onChange={(e) =>
                                      handleChange4(
                                        "emailAddress",
                                        e.target.value,
                                      )
                                    }
                                  />
                                ) : (
                                  <div
                                    style={{
                                      borderRadius: "12px",
                                      borderRadius: "12px",
                                      border: "1px solid #cccccc",
                                      padding: 6,
                                      width: "300px",
                                      height: "45px",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    {formData.emailAddress}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginTop: "15px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                // marginTop: "10px",
                                flexDirection: "column",
                                // marginLeft: "100px",
                                marginLeft: "5%",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: isTabScreen
                                    ? "15px"
                                    : isScreen3
                                      ? "24px"
                                      : "20px",
                                  // width: "35%",
                                  fontWeight: "bolder",
                                  color: isEditing ? "black" : "#004AAD",
                                }}
                              >
                                Date of Birth
                              </div>
                              <div
                                style={{
                                  fontSize: isTabScreen
                                    ? "15px"
                                    : isScreen3
                                      ? "24px"
                                      : "20px",
                                  // width: "65%",
                                  marginTop: "10px",

                                  borderRadius: "12px",
                                  border: "1px solid #cccccc",
                                }}
                              >
                                {isEditing ? (
                                  <div
                                    style={{
                                      borderRadius: "15px",
                                      borderRadius: "12px",
                                      border: "1px solid #cccccc",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <input
                                      type="date"
                                      className="custom-datepicker" // Add a custom CSS class to the DatePicker component
                                      value={moment(formData.dob).format(
                                        "YYYY-MM-DD",
                                      )}
                                      onChange={(date) => {
                                        console.log("++", date);
                                        handleChange4(
                                          "dob",
                                          moment(date.target.value).format(
                                            "YYYY-MM-DD",
                                          ),
                                        );
                                      }}
                                      dateFormat="yyyy-MM-dd"
                                      showTimeSelect={false} // Disable time selection
                                    />
                                  </div>
                                ) : formData?.dob ? (
                                  <div
                                    style={{
                                      borderRadius: "12px",
                                      border: "1px solid #cccccc",
                                      padding: 6,
                                      width: "300px",
                                      backgroundColor: "white",
                                      height: "45px",
                                    }}
                                  >
                                    {moment(formData.dob).format("YYYY-MM-DD")}
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      borderRadius: "12px",
                                      padding: 6,
                                      height: "45px",
                                      width: "300px",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    {""}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                // marginTop: "10px",
                                flexDirection: "column",
                                justifyContent: "center",
                                marginRight: "100px",
                                width: "30%",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: isTabScreen
                                    ? "15px"
                                    : isScreen3
                                      ? "24px"
                                      : "20px",
                                  // width: "35%",
                                  fontWeight: "bolder",
                                  color: isEditing ? "black" : "#004AAD",
                                }}
                              >
                                Alternate Contact Number
                              </div>
                              <div
                                style={{
                                  fontSize: isTabScreen
                                    ? "15px"
                                    : isScreen3
                                      ? "24px"
                                      : "20px",
                                  // width: "65%",
                                  marginTop: "10px",
                                }}
                              >
                                {isEditing ? (
                                  <input
                                    style={{
                                      borderRadius: "12px",
                                      border: "1px solid #cccccc",
                                      padding: 6,
                                      width: "300px",
                                    }}
                                    type="number"
                                    value={formData.AlternateNo}
                                    onChange={(e) =>
                                      handleChange4(
                                        "AlternateNo",
                                        e.target.value,
                                      )
                                    }
                                  />
                                ) : (
                                  <div
                                    style={{
                                      borderRadius: "12px",
                                      borderRadius: "12px",
                                      border: "1px solid #cccccc",
                                      padding: 6,
                                      width: "300px",
                                      backgroundColor: "white",
                                      height: "45px",
                                    }}
                                  >
                                    {formData.AlternateNo}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div
                            className="d-flex"
                            style={{
                              marginTop: "20px",
                              alignSelf: "center",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                // display: "flex",
                                marginLeft: "5%",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: isTabScreen
                                    ? "15px"
                                    : isScreen3
                                      ? "24px"
                                      : "20px",

                                  color: isEditing ? "black" : "#004AAD",
                                  fontWeight: "bolder",
                                }}
                              >
                                Languages you can speak/understand
                              </div>
                              <div
                                style={{
                                  fontSize: isTabScreen
                                    ? "15px"
                                    : isScreen3
                                      ? "24px"
                                      : "20px",
                                }}
                              >
                                {isEditing ? (
                                  <div>
                                    {languages.map((language) => {
                                      return (
                                        <div key={language.id}>
                                          <input
                                            type="checkbox"
                                            id={language.id}
                                            value={language.id}
                                            checked={selectedLanguages.includes(
                                              language.id,
                                            )}
                                            onChange={(e) =>
                                              handleLanguageCheckboxChange(
                                                language.id,
                                                e.target.checked,
                                              )
                                            }
                                          />
                                          <label
                                            htmlFor={language.id}
                                            style={{ marginLeft: "12px" }}
                                          >
                                            {language.name}
                                          </label>
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      borderRadius: "12px",
                                      borderRadius: "12px",
                                      border: "1px solid #cccccc",
                                      padding: 6,
                                      width: "300px",
                                      backgroundColor: "white",
                                      height: "45px",
                                    }}
                                  >
                                    {profileDataMain.user?.languages_name}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              style={{
                                position: "relative",
                                display: "flex",
                                flexDirection: "column",
                                // alignItems: "center",
                                marginRight: "100px",
                                marginBottom: "12px",
                                width: "30%",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: isTabScreen
                                    ? "15px"
                                    : isScreen3
                                      ? "24px"
                                      : "20px",
                                  // width: "35%",
                                  fontWeight: "bolder",
                                  color: isEditing ? "black" : "#004AAD",
                                }}
                              >
                                Gender
                              </div>
                              <div
                                style={{
                                  fontSize: isTabScreen
                                    ? "15px"
                                    : isScreen3
                                      ? "24px"
                                      : "20px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {isEditing ? (
                                  <>
                                    <div
                                      style={{
                                        backgroundColor: "white",
                                        width: "250px",
                                        padding: 10,
                                        borderRadius: "20px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-around",
                                        marginTop: "12px",
                                      }}
                                    >
                                      <label
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          style={{
                                            display: "none", // Hide the actual radio input
                                          }}
                                          type="radio"
                                          value="Male"
                                          checked={formData.gender === "Male"}
                                          onChange={(e) =>
                                            setFormData({
                                              ...formData,
                                              gender: e.target.value,
                                            })
                                          }
                                        />
                                        <div
                                          style={{
                                            textAlign: "center",
                                            width: "90px",
                                            padding: "8px",
                                            background:
                                              formData.gender === "Male"
                                                ? "#F0588B"
                                                : "white",
                                            borderRadius: "25px",
                                            cursor: "pointer",
                                            color:
                                              formData.gender === "Male"
                                                ? "#fff"
                                                : "#000",
                                          }}
                                        >
                                          Male
                                        </div>
                                      </label>
                                      <label
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          style={{
                                            display: "none", // Hide the actual radio input
                                          }}
                                          type="radio"
                                          value="Female"
                                          checked={formData.gender === "Female"}
                                          onChange={(e) =>
                                            setFormData({
                                              ...formData,
                                              gender: e.target.value,
                                            })
                                          }
                                        />
                                        <div
                                          style={{
                                            width: "90px",
                                            padding: "8px",
                                            background:
                                              formData.gender === "Female"
                                                ? "#F0588B"
                                                : "white", // Change background color when checked
                                            borderRadius: "25px",
                                            cursor: "pointer", // Add pointer cursor to indicate interactivity
                                            color:
                                              formData.gender === "Female"
                                                ? "#fff"
                                                : "#000", // Change text color when checked
                                          }}
                                        >
                                          Female
                                        </div>
                                      </label>
                                    </div>
                                  </>
                                ) : (
                                  <div
                                    style={{
                                      borderRadius: "12px",
                                      borderRadius: "12px",
                                      border: "1px solid #cccccc",
                                      padding: 6,
                                      width: "300px",
                                      backgroundColor: "white",
                                      height: "45px",
                                    }}
                                  >
                                    {formData.gender}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="pb-2 d-flex justify-content-end">
                          <div
                            style={{
                              fontWeight: "400",
                              // fontSize: isTabScreen ? "15px" : "20px",

                              cursor: "pointer",
                              display: isEditing === false ? "none" : "flex",
                              float: "right",

                              marginRight: "30px",
                              height: "40px",
                              width: "90px",
                              backgroundColor: "#004AAD",
                              color: "white",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontWeight: "500",
                              borderRadius: "12px",
                            }}
                            onClick={save}
                          >
                            Save
                          </div>
                          <div
                            className="p-5"
                            onClick={() => {
                              getdetail();
                              const isDetailsFilled =
                                formData.AlternateNo &&
                                formData.emailAddress &&
                                formData.phoneNumber &&
                                formData.gender &&
                                formData.dob &&
                                selectedLanguages?.length > 0;

                              if (!isDetailsFilled) {
                                var msg = "Please fill in all details.";
                                const error_color = "error";
                                handleAlertMsg(error_color, msg);
                              } else if (
                                !profileDataMain.user.profile_pic ||
                                typeof profileDataMain.user.profile_pic !==
                                  "string"
                              ) {
                                var msg = "Please select a profile image";
                                const error_color = "error";
                                handleAlertMsg(error_color, msg);
                              } else {
                                const updatedSteps = markStepComplete(1);
                                setCompletedSteps(updatedSteps);
                                setCurrentStep(2);
                              }
                            }}
                          >
                            <img src="/lefticon.png"></img>
                          </div>
                        </div>
                      </>
                    )}
                    {currentStep === 2 && (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                          className="d-flex justify-content-between align-items-center mx-5"
                          style={{ marginTop: "12%" }}
                        >
                          <div
                            style={{
                              fontSize: isTabScreen
                                ? "17px"
                                : isScreen3
                                  ? "28px"
                                  : "22px",
                              fontWeight: "600",
                            }}
                          >
                            Specialisations Details
                          </div>

                          <div
                            className="bg-white me-3"
                            style={{
                              width: "24px",
                              height: "24px",
                              border: "1px solid black",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "50%",
                              marginLeft: "auto",
                              marginTop: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => setAdd1(true)}
                          >
                            <AddIcon />
                          </div>
                        </div>

                        {add1 && (
                          <div
                            style={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              display: "flex",
                              // flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              className="mx-5"
                              style={{
                                fontSize: "20px",
                                width: "35%",
                                position: "relative",
                                color: "rgb(0, 74, 173)",
                                fontWeight: "bolder",
                              }}
                            >
                              Specialisation
                            </div>
                            <div
                              style={{
                                fontSize: "20px",
                                width: "65%",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <>
                                <input
                                  style={{
                                    borderRadius: "12px",
                                    border: "1px solid #cccccc",
                                    padding: 6,
                                    width: "20%",
                                  }}
                                  type="text"
                                  value={special1}
                                  onChange={(e) => setSpecial1(e.target.value)}
                                />
                                <div style={{ width: "45%" }}>
                                  <div
                                    className="savebtn"
                                    onClick={() => addSpecial()}
                                  >
                                    Save
                                  </div>
                                </div>
                              </>
                            </div>
                          </div>
                        )}

                        <div>
                          {fields.map((field, index) => (
                            <div
                              className="ms-5"
                              key={index}
                              style={{
                                marginTop: "1%",
                                // marginLeft: "2.5%",
                                width: "95%",
                                paddingTop: "2%",
                                paddingBottom: "1%",
                                backgroundColor: "white",
                                borderRadius: "15px",
                                display: "block",
                                position: "relative",
                                minHeight: "100px",
                              }}
                            >
                              {add1 && (
                                <>
                                  {specials.map((item, index) => {
                                    return (
                                      <div
                                        style={{
                                          display: "flex",
                                          marginTop: "10px",
                                          width: "400px",
                                        }}
                                        key={item.id}
                                      >
                                        <input
                                          style={{
                                            borderRadius: "12px",
                                            border: "1px solid #cccccc",
                                            padding: 6,
                                          }}
                                          type="text"
                                          value={specials[index].name}
                                          onChange={(e) =>
                                            handleChange3(
                                              index,
                                              "name",
                                              e.target.value,
                                            )
                                          }
                                        />
                                        <div
                                          onClick={() =>
                                            handleSave(index, item.id)
                                          }
                                          style={{
                                            fontWeight: "400",
                                            fontSize: "20px",
                                            color: "rgb(124, 156, 191)",
                                            cursor: "pointer",
                                            marginLeft: "3rem",
                                            // marginTop: "10px",
                                          }}
                                        >
                                          <SaveIcon />
                                        </div>
                                        <div
                                          style={{
                                            cursor: "pointer",
                                            marginLeft: "25px",
                                            color: "rgb(124, 156, 191)",
                                          }}
                                          onClick={() => {
                                            // console.log("deleeteddd", item?.id);
                                            deleteSpecial(item?.id);
                                            handleSave5(index, item.id);
                                          }}
                                        >
                                          <DeleteIcon />
                                        </div>
                                      </div>
                                    );
                                  })}
                                </>
                              )}
                              {profileDataMain?.specialisations.length > 0 &&
                              !add1 ? (
                                <div
                                  style={{
                                    display: "flex",
                                    // alignItems: "center",
                                    justifyContent: "center",
                                    width: "90%",
                                    // marginLeft: "20px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontSize: isTabScreen
                                        ? "15px"
                                        : isScreen3
                                          ? "24px"
                                          : "20px",
                                      fontWeight: "bolder",
                                      width: "20%",
                                      position: "relative",
                                      color: isEditing ? "black" : "#004AAD",
                                    }}
                                  >
                                    Specialisation
                                  </div>
                                  <div
                                    style={{
                                      fontSize: "20px",
                                      width: "80%",
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    {field.isEditing ? (
                                      <>
                                        {specials.map((item, index) => {
                                          return (
                                            <div
                                              style={{
                                                display: "flex",
                                                marginTop: "10px",
                                              }}
                                              key={item.id}
                                            >
                                              <input
                                                style={{
                                                  borderRadius: "12px",
                                                  border: "1px solid #cccccc",
                                                  padding: 6,
                                                }}
                                                type="text"
                                                value={specials[index].name}
                                                onChange={(e) =>
                                                  handleChange3(
                                                    index,
                                                    "name",
                                                    e.target.value,
                                                  )
                                                }
                                              />
                                              <div
                                                onClick={() =>
                                                  handleSave(index, item.id)
                                                }
                                                style={{
                                                  fontWeight: "400",
                                                  fontSize: "20px",
                                                  color: "rgb(124, 156, 191)",
                                                  cursor: "pointer",
                                                  marginLeft: "3rem",
                                                  // marginTop: "10px",
                                                }}
                                              >
                                                <SaveIcon />
                                              </div>
                                              <div
                                                style={{
                                                  cursor: "pointer",
                                                  marginLeft: "25px",
                                                  color: "rgb(124, 156, 191)",
                                                }}
                                                onClick={() => {
                                                  // console.log("deleeteddd", item?.id);
                                                  deleteSpecial(item?.id);
                                                  handleSave5(index, item.id);
                                                }}
                                              >
                                                <DeleteIcon />
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </>
                                    ) : (
                                      <>
                                        {profileDataMain?.specialisations
                                          .length !== 0 ? (
                                          <>
                                            {profileDataMain?.specialisations.map(
                                              (item, index) => (
                                                <React.Fragment key={index}>
                                                  <div
                                                    style={{
                                                      borderRadius: "12px",
                                                      border:
                                                        "1px solid #cccccc",
                                                      padding: 6,
                                                      backgroundColor: "white",
                                                      width: "170px",
                                                      flexDirection: "column",
                                                      marginTop: "5px",
                                                    }}
                                                    className="ps-2 d-inline"
                                                  >
                                                    {item.name}
                                                  </div>
                                                </React.Fragment>
                                              ),
                                            )}
                                            <div
                                              className="mt-2"
                                              style={{
                                                borderRadius: "12px",
                                                border: "1px solid #cccccc",
                                                padding: 6,
                                                backgroundColor: "white",
                                                width: "170px",
                                              }}
                                            >
                                              {
                                                profileDataMain?.user
                                                  .medical_expert_name
                                              }
                                            </div>
                                          </>
                                        ) : (
                                          // Render medical_expert_name when specialisations is empty
                                          <div className="mt-2">
                                            {
                                              profileDataMain?.user
                                                .medical_expert_name
                                            }
                                          </div>
                                        )}

                                        <div
                                          onClick={() => handleEdit(index)}
                                          style={{
                                            fontWeight: "400",
                                            fontSize: "20px",
                                            color: "#7C9CBF",
                                            cursor: "pointer",
                                            alignSelf: "flex-end",
                                            marginRight: "20px",
                                          }}
                                        >
                                          <EditIcon />
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="mt-2"
                                  style={{
                                    width: "275px",
                                    border: "1px solid #cccccc",
                                    borderRadius: "12px",
                                    padding: "6px",
                                  }}
                                >
                                  {profileDataMain.user.medical_expert_name}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div
                            className="p-5"
                            onClick={() => {
                              const updatedSteps = markStepComplete2(1);
                              setCompletedSteps(updatedSteps);
                              setCurrentStep(1);
                            }}
                          >
                            <img src="/righticon.png"></img>
                          </div>
                          <div
                            className="p-5"
                            onClick={() => {
                              const updatedSteps = markStepComplete(2);
                              setCompletedSteps(updatedSteps);
                              setCurrentStep(3);
                            }}
                          >
                            <img src="/lefticon.png"></img>
                            {/* Next */}
                          </div>
                        </div>
                      </div>
                    )}
                    {currentStep === 3 && (
                      <>
                        <div
                          className="d-flex justify-content-between align-items-center mx-5"
                          style={{ marginTop: "12%" }}
                        >
                          <div
                            style={{
                              fontSize: isTabScreen
                                ? "17px"
                                : isScreen3
                                  ? "28px"
                                  : "22px",
                              fontWeight: "bolder",
                            }}
                          >
                            Medical Registrations Details
                          </div>
                          {medicalReg?.map(
                            (item, index) =>
                              (item.id !== medId || !isEditing1) && (
                                <div
                                  className="text-end pe-3"
                                  onClick={() => {
                                    Edit1(item.id);
                                  }}
                                  style={{
                                    fontWeight: "400",
                                    fontSize: isTabScreen ? "15px" : "20px",
                                    color: "#7C9CBF",
                                    cursor: "pointer",
                                    marginTop: "10px",
                                  }}
                                >
                                  {/* <span>Edit9</span> */}
                                  <EditIcon />
                                </div>
                              ),
                          )}

                          {medicalReg?.length === 0 ? (
                            <div
                              className="bg-white me-3"
                              style={{
                                width: "24px",
                                height: "24px",
                                border: "1px solid black",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "50%",
                                marginLeft: "auto",
                                marginTop: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => setAdd2(!add2)}
                            >
                              <AddIcon />
                            </div>
                          ) : null}
                        </div>
                        {!add2 && medicalReg?.length > 0
                          ? medicalReg?.map((item, index) => (
                              <div
                                className="ps-5"
                                style={{
                                  marginTop: "1%",
                                  width: "95%",
                                  paddingTop: "4%",
                                  paddingBottom: "1%",
                                  backgroundColor: "white",
                                  borderRadius: "15px",
                                }}
                                key={item?.id}
                              >
                                <div
                                  className="me-3"
                                  style={{
                                    position: "relative",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-around",
                                    marginTop: "-3%",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontSize: isTabScreen
                                        ? "14px"
                                        : isScreen3
                                          ? "24px"
                                          : "20px",
                                      width: "35%",
                                      fontWeight: "bolder",
                                      color: isEditing1 ? "black" : "#004AAD",
                                    }}
                                  >
                                    Medical Registration Number
                                  </div>
                                  <div
                                    style={{
                                      fontSize: isTabScreen
                                        ? "14px"
                                        : isScreen3
                                          ? "21px"
                                          : "18px",
                                      width: "65%",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    {isEditing1 && item?.id === medId ? (
                                      <input
                                        style={{
                                          borderRadius: "12px",
                                          border: "1px solid #cccccc",
                                          padding: 6,
                                        }}
                                        type="number"
                                        value={medicalReg[index]?.reg_num}
                                        onChange={
                                          (e) =>
                                            handleChangeMedical(
                                              index,
                                              "reg_num",
                                              e.target.value,
                                            )
                                          // handleChange5("mrn", e.target.value)
                                        }
                                      />
                                    ) : (
                                      <div
                                        style={{
                                          borderRadius: "12px",
                                          border: "1px solid #cccccc",
                                          padding: 6,
                                          height: "45px",
                                          width: "300px",
                                          backgroundColor: "white",
                                        }}
                                      >
                                        {item?.reg_num}
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginTop: "10px",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontSize: isTabScreen
                                        ? "14px"
                                        : isScreen3
                                          ? "24px"
                                          : "20px",
                                      width: "35%",
                                      fontWeight: "bolder",
                                      width: "35%",
                                      position: "relative",
                                      color: isEditing1 ? "black" : "#004AAD",
                                    }}
                                  >
                                    Registration Council
                                  </div>
                                  <div
                                    style={{
                                      fontSize: isTabScreen
                                        ? "14px"
                                        : isScreen3
                                          ? "21px"
                                          : "18px",
                                      width: "65%",
                                    }}
                                  >
                                    {isEditing1 && item?.id === medId ? (
                                      <input
                                        style={{
                                          borderRadius: "12px",
                                          border: "1px solid #cccccc",
                                          padding: 6,
                                        }}
                                        type="text"
                                        value={medicalReg[index]?.reg_council}
                                        onChange={
                                          (e) =>
                                            handleChangeMedical(
                                              index,
                                              "reg_council",
                                              e.target.value,
                                            )
                                          // handleChange5("Regcouncil", e.target.value)
                                        }
                                      />
                                    ) : (
                                      <div
                                        style={{
                                          borderRadius: "12px",
                                          border: "1px solid #cccccc",
                                          padding: 6,
                                          height: "45px",
                                          width: "300px",
                                          backgroundColor: "white",
                                        }}
                                      >
                                        {item?.reg_council}
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div
                                  style={{
                                    position: "relative",
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "10px",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontSize: isTabScreen
                                        ? "14px"
                                        : isScreen3
                                          ? "24px"
                                          : "20px",
                                      width: "35%",
                                      fontWeight: "bolder",
                                      width: "35%",
                                      color: isEditing1 ? "black" : "#004AAD",
                                    }}
                                  >
                                    Registration year
                                  </div>
                                  <div
                                    style={{
                                      fontSize: isTabScreen
                                        ? "14px"
                                        : isScreen3
                                          ? "21px"
                                          : "18px",
                                      width: "65%",
                                    }}
                                  >
                                    {isEditing1 && item?.id === medId ? (
                                      <input
                                        style={{
                                          borderRadius: "12px",
                                          border: "1px solid #cccccc",
                                          padding: 6,
                                        }}
                                        type="number"
                                        value={medicalReg[index]?.reg_year}
                                        onChange={
                                          (e) =>
                                            handleChangeMedical(
                                              index,
                                              "reg_year",
                                              e.target.value,
                                            )
                                          // handleChange5("Regyear", e.target.value)
                                        }
                                      />
                                    ) : (
                                      <div
                                        style={{
                                          borderRadius: "12px",
                                          border: "1px solid #cccccc",
                                          padding: 6,
                                          height: "45px",
                                          width: "300px",
                                          backgroundColor: "white",
                                        }}
                                      >
                                        {item?.reg_year}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                {/* {(item.id !== medId || !isEditing1) && (
                                  <div
                                    className="text-end pe-3"
                                    onClick={() => {
                                      Edit1(item.id);
                                    }}
                                    style={{
                                      fontWeight: "400",
                                      fontSize: isTabScreen ? "15px" : "20px",
                                      color: "#7C9CBF",
                                      cursor: "pointer",
                                      marginTop: "10px",
                                      // marginRight: "30px",
                                    }}
                                  >
                                    <EditIcon />
                                  </div>
                                )} */}

                                {isEditing1 && item.id === medId && (
                                  <button
                                    className="text-end"
                                    style={{
                                      fontWeight: "500",
                                      cursor: "pointer",
                                      display:
                                        isEditing3 === false ? "none" : "block",
                                      marginTop: "10px",
                                      marginRight: "50px",
                                      marginLeft: "auto",
                                      height: "40px",
                                      width: "90px",
                                      backgroundColor: "#004AAD",
                                      color: "white",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: "12px",
                                    }}
                                    onClick={() => save1(1, item?.id)}
                                  >
                                    {/* Save9 */}
                                    Save
                                  </button>
                                )}
                              </div>
                            ))
                          : !add2 && (
                              <div
                                style={{
                                  marginTop: "1%",

                                  width: "95%",
                                  paddingTop: "4%",

                                  paddingBottom: "1%",
                                  backgroundColor: "white",
                                  borderRadius: "15px",
                                  height: "100px",
                                }}
                              ></div>
                            )}
                        {add2 && (
                          <div
                            style={{
                              marginTop: "1%",
                              marginLeft: "5%",
                              width: "95%",
                              paddingTop: "4%",
                              // paddingLeft: "4%",
                              paddingBottom: "1%",
                              backgroundColor: "white",
                              borderRadius: "15px",
                            }}
                          >
                            <div
                              className="me-3"
                              style={{
                                position: "relative",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-around",
                                marginTop: "-3%",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: isTabScreen
                                    ? "15px"
                                    : isScreen3
                                      ? "24px"
                                      : "20px",
                                  width: "35%",
                                }}
                              >
                                Medical Registration Number
                              </div>
                              <div
                                style={{
                                  fontSize: isTabScreen
                                    ? "15px"
                                    : isScreen3
                                      ? "24px"
                                      : "20px",
                                  width: "65%",
                                  marginLeft: "5px",
                                }}
                              >
                                <input
                                  style={{
                                    borderRadius: "12px",
                                    border: "1px solid #cccccc",
                                    padding: 6,
                                  }}
                                  type="number"
                                  value={mrn}
                                  onChange={(e) => setMrn(e.target.value)}
                                />
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginTop: "10px",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: isTabScreen
                                    ? "15px"
                                    : isScreen3
                                      ? "24px"
                                      : "20px",
                                  width: "35%",
                                  position: "relative",
                                }}
                              >
                                Registration Council
                              </div>
                              <div
                                style={{
                                  fontSize: isTabScreen
                                    ? "15px"
                                    : isScreen3
                                      ? "24px"
                                      : "20px",
                                  width: "65%",
                                }}
                              >
                                <input
                                  style={{
                                    borderRadius: "12px",
                                    border: "1px solid #cccccc",
                                    padding: 6,
                                  }}
                                  type="text"
                                  value={regCouncil}
                                  onChange={(e) =>
                                    setRegCouncil(e.target.value)
                                  }
                                />
                              </div>
                            </div>

                            <div
                              style={{
                                position: "relative",
                                display: "flex",
                                alignItems: "center",
                                marginTop: "10px",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: isTabScreen
                                    ? "15px"
                                    : isScreen3
                                      ? "24px"
                                      : "20px",
                                  width: "35%",
                                }}
                              >
                                Registration year
                              </div>
                              <div
                                style={{
                                  fontSize: isTabScreen
                                    ? "15px"
                                    : isScreen3
                                      ? "24px"
                                      : "20px",
                                  width: "65%",
                                }}
                              >
                                <input
                                  style={{
                                    borderRadius: "12px",
                                    border: "1px solid #cccccc",
                                    padding: 6,
                                  }}
                                  type="number"
                                  value={regYear}
                                  onChange={(e) => setRegYear(e.target.value)}
                                />
                              </div>
                            </div>
                            <div
                              className="text-end"
                              style={{
                                fontWeight: "500",
                                cursor: "pointer",
                                marginTop: "10px",
                                marginRight: "50px",
                                marginLeft: "auto",
                                height: "40px",
                                width: "90px",
                                backgroundColor: "#004AAD",
                                color: "white",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "12px",
                              }}
                              onClick={() => save1(2)}
                            >
                              Save
                            </div>
                          </div>
                        )}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div
                            className="p-5"
                            onClick={() => {
                              const updatedSteps = markStepComplete2(2);
                              setCompletedSteps(updatedSteps);
                              setCurrentStep(2);
                            }}
                          >
                            <img src="/righticon.png"></img>
                          </div>
                          <div
                            className="p-5"
                            onClick={() => {
                              let isMedicaldetail = false;
                              medicalReg?.forEach((item) => {
                                if (
                                  item?.reg_council &&
                                  item?.reg_num &&
                                  item?.reg_year
                                ) {
                                  isMedicaldetail = true;
                                }
                              });
                              if (!isMedicaldetail) {
                                var msg =
                                  "Please fill all medical registration details.";
                                const error_color = "error";
                                handleAlertMsg(error_color, msg);
                              } else {
                                const updatedSteps = markStepComplete(3);
                                setCompletedSteps(updatedSteps);
                                setCurrentStep(4);
                              }
                            }}
                          >
                            <img src="/lefticon.png"></img>
                          </div>
                        </div>
                      </>
                    )}
                    {currentStep === 4 && (
                      <>
                        <div
                          className="d-flex justify-content-between align-items-center mx-5"
                          style={{ marginTop: "12%" }}
                        >
                          <div
                            style={{
                              fontSize: isTabScreen
                                ? "17px"
                                : isScreen3
                                  ? "28px"
                                  : "22px",
                              fontWeight: "600",
                            }}
                          >
                            Educational details
                          </div>
                          <div
                            className="bg-white me-3"
                            style={{
                              width: "24px",
                              height: "24px",
                              border: "1px solid black",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "50%",
                              marginLeft: "auto",
                              marginTop: "10px", // Adjust this as needed
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setFormData((prevFormData) => ({
                                ...prevFormData,
                                name: "",
                                college: "",
                                experience: "",
                                completion_year: "",
                              }));
                              setAdd3(!add3);
                            }}
                          >
                            <AddIcon />
                          </div>
                        </div>
                        <div
                          style={{
                            marginTop: "1%",

                            minHeight: "100px",
                            width: "95%",
                            paddingTop: "1%",

                            paddingBottom: "1%",
                            // backgroundColor: '#CBC6C633',
                            backgroundColor: "white",
                            borderRadius: "15px",
                            marginBottom: "4%",
                          }}
                        >
                          <table
                            className="ms-5"
                            style={{
                              marginTop: "1%",
                              width: "95%",
                              borderCollapse: "collapse",
                            }}
                          >
                            <thead>
                              <tr
                                style={{
                                  color: "rgb(0, 74, 173)",
                                  fontWeight: "bolder",
                                  fontSize: "20px",
                                }}
                              >
                                <th>Degree</th>
                                <th>College/Institute</th>
                                <th>Year of Completion</th>
                                <th>Year of Experience</th>
                                {/* <th>Actions</th> */}
                              </tr>
                            </thead>
                            {educations?.map((item, index) => (
                              <>
                                <tbody>
                                  <td>
                                    {isEditing6 && item?.id === educationId ? (
                                      <input
                                        type="text"
                                        value={educations[index]?.name}
                                        style={{
                                          width: "96%",
                                          borderRadius: "12px",
                                          border: "1px solid #cccccc",
                                          marginTop: "10px",
                                          padding: 6,
                                        }}
                                        onChange={(e) =>
                                          handleChangeEducation(
                                            index,
                                            "name",
                                            e.target.value,
                                          )
                                        }
                                      />
                                    ) : (
                                      <div
                                        style={{
                                          width: "96%",
                                          borderRadius: "12px",
                                          border: "1px solid #cccccc",
                                          marginTop: "10px",
                                          padding: "6px",
                                          backgroundColor: "white",
                                        }}
                                      >
                                        {item?.name}
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    {isEditing6 && item?.id === educationId ? (
                                      <input
                                        type="text"
                                        value={educations[index]?.college}
                                        style={{
                                          width: "96%",
                                          borderRadius: "12px",
                                          border: "1px solid #cccccc",
                                          marginTop: "10px",
                                          padding: 6,
                                        }}
                                        onChange={(e) =>
                                          handleChangeEducation(
                                            index,
                                            "college",
                                            e.target.value,
                                          )
                                        }
                                      />
                                    ) : (
                                      <div
                                        style={{
                                          width: "96%",
                                          borderRadius: "12px",
                                          border: "1px solid #cccccc",
                                          marginTop: "10px",
                                          padding: "6px",
                                          backgroundColor: "white",
                                        }}
                                      >
                                        {item?.college}
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    {isEditing6 && item?.id === educationId ? (
                                      <input
                                        type="number"
                                        value={
                                          educations[index]?.completion_year
                                        }
                                        style={{
                                          width: "96%",
                                          borderRadius: "12px",
                                          border: "1px solid #cccccc",
                                          marginTop: "10px",
                                          padding: 6,
                                        }}
                                        onChange={(e) =>
                                          handleChangeEducation(
                                            index,
                                            "completion_year",
                                            e.target.value,
                                          )
                                        }
                                      />
                                    ) : (
                                      // profileData.educations[0]?.completion_year
                                      <div
                                        style={{
                                          width: "96%",
                                          borderRadius: "12px",
                                          border: "1px solid #cccccc",
                                          marginTop: "10px",
                                          padding: "6px",
                                          backgroundColor: "white",
                                        }}
                                      >
                                        {item?.completion_year}
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    {isEditing6 && item?.id === educationId ? (
                                      <input
                                        type="number"
                                        value={educations[index]?.experience}
                                        style={{
                                          width: "96%",
                                          borderRadius: "12px",
                                          border: "1px solid #cccccc",
                                          marginTop: "10px",
                                          padding: 6,
                                        }}
                                        onChange={(e) =>
                                          handleChangeEducation(
                                            index,
                                            "experience",
                                            e.target.value,
                                          )
                                        }
                                      />
                                    ) : (
                                      // profileData.educations[0]?.experience
                                      <div
                                        style={{
                                          width: "96%",
                                          borderRadius: "12px",
                                          border: "1px solid #cccccc",
                                          marginTop: "10px",
                                          padding: "6px",
                                          backgroundColor: "white",
                                        }}
                                      >
                                        {item?.experience}
                                      </div>
                                    )}
                                  </td>
                                  {isEditing6 && item?.id === educationId && (
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <div
                                          style={{
                                            fontWeight: "400",
                                            fontSize: isTabScreen
                                              ? "15px"
                                              : "20px",
                                            color: "rgb(124, 156, 191)",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setEducationId();
                                            save6(1, item?.id);
                                          }}
                                        >
                                          <SaveIcon />
                                        </div>
                                        <div
                                          style={{
                                            cursor: "pointer",
                                            marginLeft: "18px",
                                            color: "rgb(124, 156, 191)",
                                          }}
                                          onClick={() => {
                                            handledelete(item.id);
                                          }}
                                        >
                                          <DeleteIcon />
                                        </div>
                                      </div>
                                    </td>
                                  )}
                                  {!add3 && item?.id !== educationId && (
                                    <td>
                                      <div
                                        onClick={() => {
                                          setEducationId(item?.id);
                                          Edit6();
                                        }}
                                        style={{
                                          fontWeight: "400",
                                          fontSize: isTabScreen
                                            ? "15px"
                                            : "20px",
                                          color: "#7C9CBF",
                                          cursor: "pointer",
                                          marginLeft: "20px",
                                        }}
                                      >
                                        <EditIcon />
                                      </div>
                                    </td>
                                  )}
                                </tbody>
                              </>
                            ))}

                            {add3 && (
                              <>
                                <tbody>
                                  <td>
                                    <input
                                      type="text"
                                      value={formData.name}
                                      style={{
                                        width: "96%",
                                        borderRadius: "12px",
                                        border: "1px solid #cccccc",
                                        marginTop: "10px",
                                        padding: 6,
                                      }}
                                      onChange={(e) =>
                                        handleChange6("name", e.target.value)
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      value={formData.college}
                                      style={{
                                        width: "96%",
                                        borderRadius: "12px",
                                        border: "1px solid #cccccc",
                                        marginTop: "10px",
                                        padding: 6,
                                      }}
                                      onChange={(e) =>
                                        handleChange6("college", e.target.value)
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      value={formData.completion_year}
                                      style={{
                                        width: "96%",
                                        borderRadius: "12px",
                                        border: "1px solid #cccccc",
                                        marginTop: "10px",
                                        padding: 6,
                                      }}
                                      onChange={(e) =>
                                        handleChange6(
                                          "completion_year",
                                          e.target.value,
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      value={formData.experience}
                                      style={{
                                        width: "96%",
                                        borderRadius: "12px",
                                        border: "1px solid #cccccc",
                                        marginTop: "10px",
                                        padding: 6,
                                      }}
                                      onChange={(e) =>
                                        handleChange6(
                                          "experience",
                                          e.target.value,
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <div
                                      // className="text-end pe-3"
                                      style={{
                                        fontWeight: "500",
                                        marginTop: "15px",
                                        float: "right",
                                        marginRight: "10px",
                                        height: "40px",
                                        width: "90px",
                                        backgroundColor: "rgb(0, 74, 173)",
                                        color: "white",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontWeight: "500",
                                        borderRadius: "12px",
                                      }}
                                      onClick={() => save6(2)}
                                    >
                                      Save
                                    </div>
                                  </td>
                                </tbody>
                              </>
                            )}
                          </table>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div
                            className="p-5"
                            onClick={() => {
                              const updatedSteps = markStepComplete2(3);
                              setCompletedSteps(updatedSteps);
                              setCurrentStep(3);
                            }}
                          >
                            <img src="/righticon.png"></img>
                          </div>
                          <div
                            className="p-5"
                            onClick={() => {
                              let isEducationdetail = false;
                              educations?.forEach((item) => {
                                if (
                                  item?.college &&
                                  item?.name &&
                                  item?.experience &&
                                  item?.completion_year
                                ) {
                                  isEducationdetail = true;
                                }
                              });
                              if (!isEducationdetail) {
                                var msg = "Please fill all Education details.";
                                const error_color = "error";
                                handleAlertMsg(error_color, msg);
                              } else {
                                const updatedSteps = markStepComplete(4);
                                setCompletedSteps(updatedSteps);
                                setCurrentStep(5);
                              }
                            }}
                          >
                            <img src="/lefticon.png"></img>
                          </div>
                        </div>
                      </>
                    )}
                    {currentStep === 5 && (
                      <>
                        <div
                          className="d-flex justify-content-between align-items-center mx-5"
                          style={{ marginTop: "12%" }}
                        >
                          <div
                            style={{
                              fontSize: isTabScreen
                                ? "17px"
                                : isScreen3
                                  ? "28px"
                                  : "22px",
                              fontWeight: "600",
                            }}
                          >
                            Establishment/Clinic/Hospital Name Details
                          </div>

                          <div
                            onClick={Edit3}
                            style={{
                              fontWeight: "400",
                              fontSize: isTabScreen ? "15px" : "20px",
                              color: "#7C9CBF",
                              cursor: "pointer",
                              display: isEditing3 === true ? "none" : "block",
                            }}
                          >
                            <EditIcon />
                          </div>
                        </div>

                        <div
                          className="ms-5"
                          style={{
                            marginTop: "1%",
                            height: "100%",
                            width: "95%",
                            paddingTop: "4%",
                            paddingBottom: "1%",
                            backgroundColor: "white",
                            borderRadius: "15px",
                          }}
                        >
                          <div
                            style={{
                              position: "relative",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-around",
                              marginTop: "1%",
                            }}
                          >
                            <div
                              style={{
                                fontSize: isTabScreen
                                  ? "15px"
                                  : isScreen3
                                    ? "24px"
                                    : "20px",
                                width: "35%",
                                fontWeight: "bolder",
                                position: "relative",
                                color: isEditing3 ? "black" : "#004AAD",
                              }}
                            >
                              Area Name
                            </div>

                            <div
                              style={{
                                fontSize: isTabScreen
                                  ? "14px"
                                  : isScreen3
                                    ? "21px"
                                    : "18px",
                                width: "65%",
                              }}
                            >
                              <div
                                style={{
                                  borderRadius: "12px",
                                  border: "1px solid #cccccc",
                                  padding: 6,
                                  height: "45px",
                                  width: "fit-content",
                                  height: "fit-content",
                                  backgroundColor: "white",
                                  cursor: "not-allowed",
                                }}
                                title="Can't edit area name"
                              >
                                {profileData?.user?.area_name}
                              </div>
                            </div>
                          </div>

                          <div
                            style={{
                              position: "relative",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-around",
                              marginTop: "1%",
                            }}
                          >
                            <div
                              style={{
                                fontSize: isTabScreen
                                  ? "15px"
                                  : isScreen3
                                    ? "24px"
                                    : "20px",
                                width: "35%",
                                fontWeight: "bolder",
                                position: "relative",
                                color: isEditing3 ? "black" : "#004AAD",
                              }}
                            >
                              State of Establishment
                            </div>

                            <div
                              style={{
                                fontSize: isTabScreen
                                  ? "14px"
                                  : isScreen3
                                    ? "21px"
                                    : "18px",
                                width: "65%",
                              }}
                            >
                              {isEditing3 ? (
                                <>
                                  {/* State dropdown */}
                                  <select
                                    // selected={
                                    //   state.id === profileData.clinics[0].state_id
                                    // }
                                    style={{
                                      width: "32%",
                                      borderRadius: "12px",
                                      border: "1px solid #cccccc",
                                      padding: 4,
                                    }}
                                    value={formData.stateest}
                                    onChange={(e) => {
                                      handleChange5("stateest", e.target.value);
                                      handleStateSelect(e.target.value);
                                    }}
                                  >
                                    <option value="">Select a state</option>
                                    {stateList.map((state) => (
                                      <option
                                        key={state.id}
                                        value={state.id}
                                        value1={state.name}
                                      >
                                        {state.name}
                                      </option>
                                    ))}
                                  </select>
                                </>
                              ) : (
                                <div>
                                  {selectedStateName ? (
                                    <div
                                      style={{
                                        borderRadius: "12px",
                                        border: "1px solid #cccccc",
                                        padding: 6,
                                        height: "45px",
                                        width: "300px",
                                        backgroundColor: "white",
                                      }}
                                    >
                                      {selectedStateName}
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        borderRadius: "12px",
                                        border: "1px solid #cccccc",
                                        padding: 6,
                                        height: "45px",
                                        width: "300px",
                                        backgroundColor: "white",
                                      }}
                                    ></div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>

                          <div
                            style={{
                              position: "relative",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-around",
                              marginTop: "10px",
                            }}
                          >
                            <div
                              style={{
                                fontSize: isTabScreen
                                  ? "15px"
                                  : isScreen3
                                    ? "24px"
                                    : "20px",
                                width: "35%",
                                fontWeight: "bolder",
                                position: "relative",
                                color: isEditing3 ? "black" : "#004AAD",
                              }}
                            >
                              City of Establishment
                            </div>
                            <div
                              style={{
                                fontSize: isTabScreen
                                  ? "14px"
                                  : isScreen3
                                    ? "21px"
                                    : "18px",
                                width: "65%",
                              }}
                            >
                              {isEditing3 ? (
                                <>
                                  {formData.stateest && (
                                    <select
                                      style={{
                                        width: "32%",
                                        borderRadius: "12px",
                                        border: "1px solid #cccccc",
                                        padding: 4,
                                      }}
                                      value={formData.cityest}
                                      onChange={(e) =>
                                        handleChange5("cityest", e.target.value)
                                      }
                                    >
                                      <option value="">Select a city</option>
                                      {cityList.map((city) => (
                                        <option key={city.id} value={city.id}>
                                          {city.name}
                                        </option>
                                      ))}
                                    </select>
                                  )}
                                  {/* </div> */}
                                </>
                              ) : (
                                // profileData.clinics[0]?.city_name
                                // formData.cityest
                                <div>
                                  {selectedcityName ? (
                                    <div
                                      style={{
                                        borderRadius: "12px",
                                        border: "1px solid #cccccc",
                                        padding: 6,
                                        height: "45px",
                                        width: "300px",
                                        backgroundColor: "white",
                                      }}
                                    >
                                      {selectedcityName}
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        borderRadius: "12px",
                                        border: "1px solid #cccccc",
                                        padding: 6,
                                        height: "45px",
                                        width: "300px",
                                        backgroundColor: "white",
                                      }}
                                    ></div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: "10px",
                            }}
                          >
                            <div
                              style={{
                                fontSize: isTabScreen
                                  ? "15px"
                                  : isScreen3
                                    ? "24px"
                                    : "20px",
                                width: "35%",
                                fontWeight: "bolder",
                                position: "relative",
                                color: isEditing3 ? "black" : "#004AAD",
                              }}
                            >
                              Hospital name
                            </div>
                            <div
                              style={{
                                fontSize: isTabScreen
                                  ? "14px"
                                  : isScreen3
                                    ? "21px"
                                    : "18px",
                                width: "65%",
                              }}
                            >
                              {
                                isEditing3 ? (
                                  <input
                                    style={{
                                      borderRadius: "12px",
                                      border: "1px solid #cccccc",
                                      padding: 6,
                                    }}
                                    type="text"
                                    value={formData.hospitalnm}
                                    onChange={(e) =>
                                      handleChange5(
                                        "hospitalnm",
                                        e.target.value,
                                      )
                                    }
                                  />
                                ) : formData.hospitalnm ? (
                                  <div
                                    style={{
                                      borderRadius: "12px",
                                      border: "1px solid #cccccc",
                                      padding: 6,
                                      height: "45px",
                                      width: "300px",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    {formData.hospitalnm}
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      borderRadius: "12px",
                                      border: "1px solid #cccccc",
                                      padding: 6,
                                      height: "45px",
                                      width: "300px",
                                      backgroundColor: "white",
                                    }}
                                  ></div>
                                )
                                // profileData.clinics[0]?.name
                              }
                            </div>
                          </div>

                          <div
                            style={{
                              position: "relative",
                              display: "flex",
                              alignItems: "center",
                              marginTop: "10px",
                            }}
                          >
                            <div
                              style={{
                                fontSize: isTabScreen
                                  ? "15px"
                                  : isScreen3
                                    ? "24px"
                                    : "20px",
                                width: "35%",
                                fontWeight: "bolder",
                                color: isEditing3 ? "black" : "#004AAD",
                              }}
                            >
                              Clinic Phone Number
                            </div>
                            <div
                              style={{
                                fontSize: isTabScreen
                                  ? "14px"
                                  : isScreen3
                                    ? "21px"
                                    : "18px",
                                width: "65%",
                              }}
                            >
                              {isEditing3 ? (
                                <input
                                  style={{
                                    borderRadius: "12px",
                                    border: "1px solid #cccccc",
                                    padding: 6,
                                  }}
                                  type="number"
                                  value={formData.clinicphno}
                                  onChange={(e) =>
                                    handleChange5("clinicphno", e.target.value)
                                  }
                                />
                              ) : formData.clinicphno ? (
                                <div
                                  style={{
                                    borderRadius: "12px",
                                    border: "1px solid #cccccc",
                                    padding: 6,
                                    height: "45px",
                                    width: "300px",
                                    backgroundColor: "white",
                                  }}
                                >
                                  {formData.clinicphno}
                                </div>
                              ) : (
                                <div
                                  style={{
                                    borderRadius: "12px",
                                    border: "1px solid #cccccc",
                                    padding: 6,
                                    height: "45px",
                                    width: "300px",
                                    backgroundColor: "white",
                                  }}
                                ></div>
                              )}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "10px",
                            }}
                          >
                            <div
                              style={{
                                fontSize: isTabScreen
                                  ? "15px"
                                  : isScreen3
                                    ? "24px"
                                    : "20px",
                                width: "35%",
                                fontWeight: "bolder",
                                color: isEditing3 ? "black" : "#004AAD",
                              }}
                            >
                              Clinic Email address
                            </div>
                            <div
                              style={{
                                fontSize: isTabScreen
                                  ? "14px"
                                  : isScreen3
                                    ? "21px"
                                    : "18px",
                                width: "65%",
                              }}
                            >
                              {isEditing3 ? (
                                <input
                                  style={{
                                    borderRadius: "12px",
                                    border: "1px solid #cccccc",
                                    padding: 6,
                                  }}
                                  type="text"
                                  value={formData.clinicmail}
                                  onChange={(e) =>
                                    handleChange5("clinicmail", e.target.value)
                                  }
                                />
                              ) : formData.clinicmail ? (
                                <div
                                  style={{
                                    borderRadius: "12px",
                                    border: "1px solid #cccccc",
                                    padding: 6,
                                    height: "45px",
                                    width: "300px",
                                    backgroundColor: "white",
                                  }}
                                >
                                  {formData.clinicmail}
                                </div>
                              ) : (
                                <div
                                  style={{
                                    borderRadius: "12px",
                                    border: "1px solid #cccccc",
                                    padding: 6,
                                    height: "45px",
                                    width: "300px",
                                    backgroundColor: "white",
                                  }}
                                ></div>
                              )}
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "10px",
                            }}
                          >
                            <div
                              style={{
                                fontSize: isTabScreen
                                  ? "15px"
                                  : isScreen3
                                    ? "24px"
                                    : "20px",
                                width: "35%",
                                fontWeight: "bolder",
                                color: isEditing3 ? "black" : "#004AAD",
                              }}
                            >
                              Clinic Address
                            </div>
                            <div
                              style={{
                                fontSize: isTabScreen
                                  ? "14px"
                                  : isScreen3
                                    ? "21px"
                                    : "18px",
                                width: "65%",
                              }}
                            >
                              {isEditing3 ? (
                                <input
                                  style={{
                                    borderRadius: "12px",
                                    border: "1px solid #cccccc",
                                    padding: 6,
                                  }}
                                  type="text"
                                  value={formData.clinicadd}
                                  onChange={(e) =>
                                    handleChange5("clinicadd", e.target.value)
                                  }
                                />
                              ) : formData.clinicadd ? (
                                <div
                                  style={{
                                    borderRadius: "12px",
                                    border: "1px solid #cccccc",
                                    padding: 6,
                                    width: "fit-content",
                                    height: "fit-content",
                                    backgroundColor: "white",
                                  }}
                                >
                                  {formData.clinicadd}
                                </div>
                              ) : (
                                <div
                                  style={{
                                    borderRadius: "12px",
                                    border: "1px solid #cccccc",
                                    padding: 6,
                                    height: "45px",
                                    width: "300px",
                                    backgroundColor: "white",
                                  }}
                                ></div>
                              )}
                            </div>
                          </div>
                          <div
                            className=""
                            style={{
                              fontWeight: "500",
                              cursor: "pointer",
                              display: isEditing3 == false ? "none" : "block",
                              marginTop: "10px",
                              marginRight: "50px",
                              marginLeft: "auto",
                              height: "40px",
                              width: "90px",
                              backgroundColor: "#004AAD",
                              color: "white",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "12px",
                            }}
                            onClick={save3}
                          >
                            Save
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div
                            className="p-5"
                            onClick={() => {
                              const updatedSteps = markStepComplete2(4);
                              setCompletedSteps(updatedSteps);
                              setCurrentStep(4);
                            }}
                          >
                            <img src="/righticon.png"></img>
                          </div>
                          <div
                            className="p-5"
                            onClick={() => {
                              getdetail();
                              const isHospitaldetails =
                                formData.hospitalnm &&
                                formData.clinicadd &&
                                formData.cityest &&
                                formData.stateest &&
                                formData.clinicphno &&
                                formData.clinicmail;
                              if (!isHospitaldetails) {
                                var msg = "Please fill all Hospital details.";
                                const error_color = "error";
                                handleAlertMsg(error_color, msg);
                                setHospitaldetail(false);
                                return;
                              } else {
                                const updatedSteps = markStepComplete2(5);
                                setCompletedSteps(updatedSteps);
                                setCurrentStep(6);
                              }
                            }}
                          >
                            <img src="/lefticon.png"></img>
                          </div>
                        </div>
                      </>
                    )}
                    {currentStep === 6 && (
                      <>
                        <div
                          className="d-flex justify-content-between align-items-center mx-5"
                          style={{ marginTop: "12%" }}
                        >
                          <div
                            style={{
                              fontSize: isTabScreen
                                ? "17px"
                                : isScreen3
                                  ? "28px"
                                  : "22px",
                              fontWeight: "600",
                              // marginLeft: "4%",
                            }}
                          >
                            Your Story
                          </div>
                          <div
                            onClick={Edit4}
                            style={{
                              fontWeight: "400",
                              fontSize: isTabScreen ? "15px" : "20px",
                              color: "#7C9CBF",
                              cursor: "pointer",
                              display: isEditing4 === true ? "none" : "block",
                            }}
                          >
                            <EditIcon />
                          </div>
                        </div>
                        <div
                          className="ms-5"
                          style={{
                            marginTop: "1%",
                            width: "95%",
                            paddingTop: "1%",
                            paddingBottom: "1%",
                            backgroundColor: "white",
                            borderRadius: "15px",
                            marginBottom: "4%",
                            minHeight: "100px",
                          }}
                        >
                          {isEditing4 ? (
                            <textarea
                              type="text"
                              rows={6}
                              value={formData.yourstory}
                              style={{
                                marginTop: "10px",
                                padding: 6,
                                width: "96%",
                                borderRadius: "12px",
                                border: "1px solid #cccccc",
                              }}
                              onChange={(e) =>
                                handleChangestory("yourstory", e.target.value)
                              }
                            />
                          ) : (
                            <div
                              style={{
                                marginTop: "10px",
                                padding: 6,
                                width: "96%",
                                height: "200px",
                                backgroundColor: "white",
                                borderRadius: "12px",
                                border: "1px solid #cccccc",
                              }}
                            >
                              {formData.yourstory}
                            </div>
                          )}
                          {/* <div
                            className="text-end pe-3"
                            style={{
                              fontWeight: "400",
                              fontSize: isTabScreen ? "15px" : "20px",
                              color: "black",
                              cursor: "pointer",
                              display: isEditing4 === false ? "none" : "block",
                              marginTop: "10px",
                              float: "right",
                              marginRight: "30px",
                            }}
                            // onClick={save4}
                          >
                            Save
                          </div> */}
                          <div>
                            <SignatureUpload
                              profileData={profileData}
                              ondataReceived={received}
                            />
                          </div>
                        </div>
                        {/* <div
                            className="p-5"
                            onClick={() => {
                              const updatedSteps = markStepComplete2(5);
                              setCompletedSteps(updatedSteps);
                              setCurrentStep(5);
                            }}
                          >
                            <img src="righticon.png"></img>
                          </div> */}
                        <div
                          style={nextStyle}
                          onClick={() => {
                            save4();
                          }}
                        >
                          Save
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <div
                              className="p-5"
                              onClick={() => {
                                const updatedSteps = markStepComplete2(5);
                                setCompletedSteps(updatedSteps);
                                setCurrentStep(5);
                              }}
                            >
                              <img src="/righticon.png"></img>
                            </div>
                            {medical_expert_id === 4 && (
                              <div
                                className="p-5"
                                onClick={() => {
                                  getdetail();
                                  const isstorydetails = formData.yourstory;
                                  const sign = formData.yoursign;
                                  if (!isstorydetails) {
                                    var msg =
                                      "Please fill all details before saving.";
                                    const error_color = "error";
                                    handleAlertMsg(error_color, msg);
                                    setStorydetail(false);
                                    setSigntoshow(false);

                                    return;
                                  } else {
                                    setStorydetail(true);
                                    setSigntoshow(true);
                                    const updatedSteps = markStepComplete(6);
                                    setCompletedSteps(updatedSteps);
                                  }

                                  if (!sign) {
                                    var msg = "Please add sign";
                                    const error_color = "error";
                                    handleAlertMsg(error_color, msg);
                                    setSigntoshow(false);
                                  } else {
                                    setStorydetail(true);
                                    setSigntoshow(true);
                                    const updatedSteps = markStepComplete(6);
                                    setCompletedSteps(updatedSteps);
                                    setCurrentStep(7);
                                  }
                                }}
                              >
                                <img src="/lefticon.png" alt="lefticon"></img>
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {currentStep === 7 && (
                      <>
                        <div
                          className="d-flex justify-content-between align-items-center mx-5"
                          style={{ marginTop: "12%" }}
                        >
                          <div
                            style={{
                              fontSize: isTabScreen
                                ? "17px"
                                : isScreen3
                                  ? "28px"
                                  : "22px",
                              fontWeight: "600",
                            }}
                          >
                            Batch details
                          </div>
                        </div>
                        <div
                          style={{
                            marginTop: "1%",

                            minHeight: "100px",
                            width: "95%",
                            paddingTop: "1%",

                            paddingBottom: "1%",
                            // backgroundColor: '#CBC6C633',
                            backgroundColor: "white",
                            borderRadius: "15px",
                            marginBottom: "4%",
                          }}
                        >
                          <table
                            className="ms-5"
                            style={{
                              marginTop: "1%",
                              width: "95%",
                              borderCollapse: "collapse",
                            }}
                          >
                            <thead>
                              <tr>
                                <th>Batch Name</th>
                                <th>Session</th>
                                <th>Days</th>
                                <th>Timing</th>
                                {/* <th>Actions</th> */}
                              </tr>
                            </thead>
                            {yogaBatchDetails?.map((item, index) => (
                              <>
                                <tbody>
                                  <td>
                                    {isEditing7 && item?.id === educationId ? (
                                      <input
                                        type="text"
                                        value={educations[index]?.name}
                                        style={{
                                          width: "96%",
                                          borderRadius: "12px",
                                          border: "1px solid #cccccc",
                                          marginTop: "10px",
                                          padding: 6,
                                        }}
                                        onChange={(e) =>
                                          handleChangeEducation(
                                            index,
                                            "name",
                                            e.target.value,
                                          )
                                        }
                                      />
                                    ) : (
                                      <div
                                        style={{
                                          width: "96%",
                                          borderRadius: "12px",
                                          border: "1px solid #cccccc",
                                          marginTop: "10px",
                                          padding: "6px",
                                          backgroundColor: "white",
                                        }}
                                      >
                                        {item?.name}
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    {isEditing7 && item?.id === educationId ? (
                                      <input
                                        type="text"
                                        value={educations[index]?.college}
                                        style={{
                                          width: "96%",
                                          borderRadius: "12px",
                                          border: "1px solid #cccccc",
                                          marginTop: "10px",
                                          padding: 6,
                                        }}
                                        onChange={(e) =>
                                          handleChangeEducation(
                                            index,
                                            "college",
                                            e.target.value,
                                          )
                                        }
                                      />
                                    ) : (
                                      <div
                                        style={{
                                          width: "96%",
                                          borderRadius: "12px",
                                          border: "1px solid #cccccc",
                                          marginTop: "10px",
                                          padding: "6px",
                                          backgroundColor: "white",
                                        }}
                                      >
                                        {item?.session}
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    {isEditing7 && item?.id === educationId ? (
                                      <input
                                        type="number"
                                        value={
                                          educations[index]?.completion_year
                                        }
                                        style={{
                                          width: "96%",
                                          borderRadius: "12px",
                                          border: "1px solid #cccccc",
                                          marginTop: "10px",
                                          padding: 6,
                                        }}
                                        onChange={(e) =>
                                          handleChangeEducation(
                                            index,
                                            "completion_year",
                                            e.target.value,
                                          )
                                        }
                                      />
                                    ) : (
                                      // profileData.educations[0]?.completion_year
                                      <div
                                        style={{
                                          width: "96%",
                                          borderRadius: "12px",
                                          border: "1px solid #cccccc",
                                          marginTop: "10px",
                                          padding: "6px",
                                          backgroundColor: "white",
                                        }}
                                      >
                                        {item?.days}
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    {isEditing7 && item?.id === yogaId ? (
                                      <>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            // value={yogaBatchDetails[index]?.timing}
                                            value={editSplitTimeValue[0]}
                                            style={{
                                              width: "20%",
                                              borderRadius: "12px",
                                              border: "1px solid #cccccc",
                                              // marginTop: "10px",
                                              margin: "0 10px",
                                              padding: 6,
                                            }}
                                            onChange={(e) =>
                                              handleChangeBatch(
                                                0,
                                                "experience",
                                                e.target.value,
                                              )
                                            }
                                          />
                                          <Box
                                            sx={{
                                              // marginTop: isScreen2 ? "5%" : 0,
                                              width: "20%",
                                            }}
                                          >
                                            <FormControl
                                              fullWidth
                                              size={"small"}
                                            >
                                              <Select
                                                sx={{
                                                  boxShadow: "none",
                                                  ".MuiOutlinedInput-notchedOutline":
                                                    {
                                                      border: 0,
                                                    },
                                                }}
                                                style={{
                                                  backgroundColor: "#F2F2F2",
                                                  fontSize: isTabScreen
                                                    ? "10px"
                                                    : isTabScreen
                                                      ? "10px"
                                                      : "16px",
                                                  fontFamily: "Poppins",
                                                  fontWeight: "500",
                                                }}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={editSplitTimeValue[1]}
                                                displayEmpty
                                                onChange={(e) =>
                                                  handleChangeBatch(
                                                    1,
                                                    "experience",
                                                    e.target.value,
                                                  )
                                                }
                                              >
                                                {timePeriod?.map(
                                                  (item, index) => {
                                                    return (
                                                      <MenuItem
                                                        value={index}
                                                        style={{
                                                          fontSize: isTabScreen
                                                            ? "10px"
                                                            : "16px",
                                                          fontFamily: "Poppins",
                                                          fontWeight: "500",
                                                          color: "#868686",
                                                        }}
                                                      >
                                                        {item}
                                                      </MenuItem>
                                                    );
                                                  },
                                                )}
                                              </Select>
                                            </FormControl>
                                          </Box>
                                          <input
                                            type="text"
                                            value={editSplitTimeValue[2]}
                                            style={{
                                              width: "20%",
                                              borderRadius: "12px",
                                              border: "1px solid #cccccc",
                                              // marginTop: "10px",
                                              margin: "0px 10px",
                                              padding: 6,
                                            }}
                                            onChange={(e) =>
                                              handleChangeBatch(
                                                2,
                                                "experience",
                                                e.target.value,
                                              )
                                            }
                                          />
                                          <Box
                                            sx={{
                                              // marginTop: isScreen2 ? "5%" : 0,
                                              width: "20%",
                                            }}
                                          >
                                            <FormControl
                                              fullWidth
                                              size={"small"}
                                            >
                                              <Select
                                                sx={{
                                                  boxShadow: "none",
                                                  ".MuiOutlinedInput-notchedOutline":
                                                    {
                                                      border: 0,
                                                    },
                                                }}
                                                style={{
                                                  backgroundColor: "#F2F2F2",
                                                  fontSize: isTabScreen
                                                    ? "10px"
                                                    : isTabScreen
                                                      ? "10px"
                                                      : "16px",
                                                  fontFamily: "Poppins",
                                                  fontWeight: "500",
                                                }}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                // value={drType}
                                                value={editSplitTimeValue[3]}
                                                displayEmpty
                                                onChange={(e) =>
                                                  handleChangeBatch(
                                                    3,
                                                    "experience",
                                                    e.target.value,
                                                  )
                                                }
                                              >
                                                {timePeriod?.map(
                                                  (item, index) => {
                                                    return (
                                                      <MenuItem
                                                        value={index}
                                                        style={{
                                                          fontSize: isTabScreen
                                                            ? "10px"
                                                            : "16px",
                                                          fontFamily: "Poppins",
                                                          fontWeight: "500",
                                                          color: "#868686",
                                                        }}
                                                      >
                                                        {item}
                                                      </MenuItem>
                                                    );
                                                  },
                                                )}
                                              </Select>
                                            </FormControl>
                                          </Box>
                                        </div>
                                      </>
                                    ) : (
                                      // profileData.educations[0]?.experience
                                      <div
                                        style={{
                                          width: "96%",
                                          borderRadius: "12px",
                                          border: "1px solid #cccccc",
                                          marginTop: "10px",
                                          padding: "6px",
                                          backgroundColor: "white",
                                        }}
                                      >
                                        {item?.timing}
                                      </div>
                                    )}
                                  </td>
                                  {isEditing7 && item?.id === yogaId && (
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <div
                                          style={{
                                            fontWeight: "400",
                                            fontSize: isTabScreen
                                              ? "15px"
                                              : "20px",
                                            color: "rgb(124, 156, 191)",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            handleAddBatchtime();
                                          }}
                                        >
                                          <SaveIcon />
                                        </div>
                                      </div>
                                    </td>
                                  )}
                                  {!add3 && item?.id !== yogaId && (
                                    <td>
                                      <div
                                        onClick={() => {
                                          setYogaId(item?.id);
                                          Edit7(item?.timing);
                                        }}
                                        style={{
                                          fontWeight: "400",
                                          fontSize: isTabScreen
                                            ? "15px"
                                            : "20px",
                                          color: "#7C9CBF",
                                          cursor: "pointer",
                                          marginLeft: "20px",
                                        }}
                                      >
                                        <EditIcon />
                                      </div>
                                    </td>
                                  )}
                                </tbody>
                              </>
                            ))}
                          </table>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div
                            className="p-5"
                            onClick={() => {
                              const updatedSteps = markStepComplete2(7);
                              setCompletedSteps(updatedSteps);
                              setCurrentStep(6);
                            }}
                          >
                            <img src="/righticon.png" alt="righticon"></img>
                          </div>
                          <div
                            style={nextStyle}
                            onClick={() => {
                              saveAll();
                            }}
                          >
                            Save
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <div>
                    <div
                      className="d-flex justify-content-between align-items-center mx-5 ps-5"
                      style={{ marginTop: "10%" }}
                    >
                      <div
                        style={{
                          fontSize: isTabScreen
                            ? "17px"
                            : isScreen3
                              ? "28px"
                              : "22px",
                          fontWeight: "600",
                        }}
                      >
                        Professional Details
                      </div>
                      <div>
                        <button
                          className="me-2"
                          onClick={Editprofile}
                          style={{
                            fontWeight: "400",
                            fontSize: isTabScreen ? "15px" : "20px",
                            color: "#7C9CBF",
                            cursor: "pointer",
                            border: "1px solid #cccccc",
                            position: "relative",
                            zIndex: "99",
                            marginLeft: "auto",
                            backgroundColor: "white",
                          }}
                        >
                          <EditIcon />
                        </button>
                      </div>
                    </div>

                    <div
                      className="mx-5 px-5"
                      style={{ color: "#333333", fontSize: "17px" }}
                    >
                      <div className="d-flex py-2">
                        <div className="fw-bolder w-50">Phone Number</div>
                        <div className="w-50">{formData.phoneNumber}</div>
                      </div>
                      <div className="d-flex py-2">
                        <div className="fw-bolder w-50">Email Address</div>
                        <div className="w-50">{formData.emailAddress}</div>
                      </div>
                      <div className="d-flex py-2">
                        <div className="fw-bolder w-50">Date of Birth</div>
                        <div className="w-50">
                          {formData.dob ? (
                            moment(formData.dob).format("YYYY-MM-DD")
                          ) : (
                            <div className="">-</div>
                          )}
                        </div>
                      </div>
                      <div className="d-flex py-2">
                        <div className="fw-bolder w-50">
                          Alternate Contact Number
                        </div>
                        <div className=" w-50">
                          {" "}
                          {formData.AlternateNo ? (
                            formData.AlternateNo
                          ) : (
                            <div>-</div>
                          )}
                        </div>
                      </div>
                      <div className="d-flex py-2">
                        <div className="fw-bolder w-50">
                          Languages you can speak/understand
                        </div>
                        <div className="w-50">
                          {profileDataMain.user?.languages_name ? (
                            profileDataMain.user?.languages_name
                          ) : (
                            <div>-</div>
                          )}
                        </div>{" "}
                      </div>
                      <div className="d-flex py-2">
                        <div className="fw-bolder w-50">Gender</div>
                        <div className="w-50">
                          {formData.gender ? formData.gender : <div>-</div>}
                        </div>
                      </div>
                    </div>

                    <div
                      className="mx-5 pt-3 ps-5"
                      style={{
                        fontSize: isTabScreen
                          ? "17px"
                          : isScreen3
                            ? "28px"
                            : "22px",
                        fontWeight: "600",
                      }}
                    >
                      Specialisation Details
                    </div>
                    <div
                      className="mt-3 mx-5 px-5"
                      style={{ color: "#333333", fontSize: "17px" }}
                    >
                      <div className="d-flex py-2">
                        <div className="fw-bolder w-50">Specialisations</div>
                        <div>
                          {profileDataMain?.specialisations.length !== 0 ? (
                            <>
                              {profileDataMain?.specialisations.map(
                                (item, index) => (
                                  <React.Fragment key={index}>
                                    <div className="ps-2 d-inline">
                                      {item.name}
                                    </div>
                                    {index <
                                      profileDataMain.specialisations.length -
                                        1 && <div className="d-inline">,</div>}
                                  </React.Fragment>
                                ),
                              )}
                              {profileDataMain?.user.medical_expert_name && (
                                <>
                                  {profileDataMain.specialisations.length >
                                    0 && <div className="d-inline">,</div>}
                                  <div className="ps-2 d-inline">
                                    {profileDataMain.user.medical_expert_name}
                                  </div>
                                </>
                              )}
                            </>
                          ) : (
                            <div className="ps-2 mt-2">
                              {profileDataMain?.user.medical_expert_name !==
                              null
                                ? profileDataMain?.user.medical_expert_name
                                : "-"}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div
                      className="mx-5 pt-3 ps-5"
                      style={{
                        fontSize: isTabScreen
                          ? "17px"
                          : isScreen3
                            ? "28px"
                            : "22px",
                        fontWeight: "600",
                      }}
                    >
                      Medical Registration Details
                    </div>

                    <div
                      className="mt-3 mx-5 px-5"
                      style={{ color: "#333333", fontSize: "17px" }}
                    >
                      {profileDataMain?.medical_reg.length != 0 ? (
                        medicalReg?.map((item, index) => (
                          <>
                            <div className="d-flex py-2">
                              <div className="fw-bolder w-50">
                                Medical Registration Number
                              </div>
                              <div className="w-50">{item?.reg_num}</div>
                            </div>

                            <div className="d-flex py-2">
                              <div className="fw-bolder w-50">
                                Medical Registration Council
                              </div>
                              <div className="w-50">{item?.reg_council}</div>
                            </div>
                            <div className="d-flex py-2">
                              <div className="fw-bolder w-50">
                                Medical Registration Year
                              </div>
                              <div className="w-50">{item?.reg_year}</div>
                            </div>
                            <br></br>
                          </>
                        ))
                      ) : (
                        <div className="d-flex">
                          <div className="w-50 fw-bolder">
                            Registration details
                          </div>{" "}
                          <div className="w-50">-</div>
                        </div>
                      )}
                    </div>

                    <div
                      className="mx-5 ps-5 pt-3"
                      style={{
                        fontSize: isTabScreen
                          ? "17px"
                          : isScreen3
                            ? "28px"
                            : "22px",
                        fontWeight: "600",
                      }}
                    >
                      Educational Details
                    </div>

                    <div
                      className="mt-3 mx-5 px-5"
                      style={{ color: "#333333", fontSize: "17px" }}
                    >
                      {/* {medicalReg?.map((item, index) => ( */}

                      <table
                        style={{
                          marginTop: "1%",
                          // marginLeft: "2.5%",
                          width: "100%",
                          borderCollapse: "collapse",
                        }}
                      >
                        <thead>
                          <tr>
                            <th className="text-left">Degree</th>
                            <th className="text-center">College/Institute</th>
                            <th className="text-center">Year of Completion</th>
                            <th className="text-center">Year of Experience</th>
                            {/* <th>Actions</th> */}
                          </tr>
                        </thead>
                        {profileDataMain?.educations.length != 0 ? (
                          educations?.map((item, index) => (
                            <>
                              <tbody>
                                <td>
                                  <div
                                    style={{
                                      width: "100%",
                                      borderRadius: "12px",
                                      marginTop: "10px",
                                      padding: "6px",
                                      backgroundColor: "white",
                                      textAlign: "left",
                                    }}
                                  >
                                    {item?.name}
                                  </div>
                                </td>
                                <td>
                                  {" "}
                                  <div
                                    style={{
                                      width: "100%",
                                      borderRadius: "12px",

                                      marginTop: "10px",
                                      padding: "6px",
                                      backgroundColor: "white",
                                      textAlign: "center",
                                    }}
                                  >
                                    {item?.college}
                                  </div>
                                </td>
                                <td>
                                  {" "}
                                  <div
                                    style={{
                                      width: "100%",
                                      borderRadius: "12px",

                                      marginTop: "10px",
                                      padding: "6px",
                                      backgroundColor: "white",
                                      textAlign: "center",
                                    }}
                                  >
                                    {item?.completion_year}
                                  </div>
                                </td>
                                <td>
                                  {" "}
                                  <div
                                    style={{
                                      width: "100%",
                                      borderRadius: "12px",
                                      marginTop: "10px",
                                      padding: "6px",
                                      backgroundColor: "white",
                                      textAlign: "center",
                                    }}
                                  >
                                    {item?.experience}
                                  </div>
                                </td>
                              </tbody>
                            </>
                          ))
                        ) : (
                          <tbody>
                            <td>
                              <div className="tabadj">-</div>
                            </td>
                            <td>
                              <div className="tabadj">-</div>
                            </td>
                            <td>
                              <div className="tabadj">-</div>
                            </td>
                            <td>
                              <div className="tabadj">-</div>
                            </td>
                          </tbody>
                        )}
                      </table>
                    </div>

                    <div
                      className="mx-5 ps-5 pt-3"
                      style={{
                        fontSize: isTabScreen
                          ? "17px"
                          : isScreen3
                            ? "28px"
                            : "22px",
                        fontWeight: "600",
                      }}
                    >
                      Establishment/Clinic/Hospital name Details
                    </div>

                    <div
                      className="mt-3 mx-5 px-5"
                      style={{ color: "#333333", fontSize: "17px" }}
                    >
                      <div className="d-flex py-2">
                        <div className="fw-bolder w-50">
                          State of Establishment
                        </div>
                        <div className="w-50">
                          {selectedStateName != null ? (
                            selectedStateName
                          ) : (
                            <>
                              <div>-</div>
                            </>
                          )}
                        </div>
                      </div>

                      <div className="d-flex py-2">
                        <div className="fw-bolder w-50">
                          State of Establishment
                        </div>
                        <div className="w-50">
                          {selectedcityName != null ? (
                            selectedcityName
                          ) : (
                            <>
                              <div>-</div>
                            </>
                          )}
                        </div>
                      </div>

                      <div className="d-flex py-2">
                        <div className="fw-bolder w-50">Hospital Name</div>
                        <div className="w-50">
                          {formData.hospitalnm ? (
                            formData.hospitalnm
                          ) : (
                            <div>-</div>
                          )}
                        </div>
                      </div>

                      <div className="d-flex py-2">
                        <div className="fw-bolder w-50">
                          Clinic Phone Number
                        </div>
                        <div className="w-50">
                          {formData.clinicphno ? (
                            formData.clinicphno
                          ) : (
                            <div>-</div>
                          )}
                        </div>
                      </div>

                      <div className="d-flex py-2">
                        <div className="fw-bolder w-50">Clinic Email</div>
                        <div className="w-50">
                          {formData.clinicmail ? (
                            <div>{formData.clinicmail}</div>
                          ) : (
                            <div>-</div>
                          )}
                        </div>
                      </div>

                      <div className="d-flex py-2">
                        <div className="fw-bolder w-50">Clinic Address</div>
                        <div className="w-50">
                          {formData.clinicadd ? (
                            <div>{formData.clinicadd}</div>
                          ) : (
                            <div>-</div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div
                      className="mx-5 ps-5 pt-3"
                      style={{
                        fontSize: isTabScreen
                          ? "17px"
                          : isScreen3
                            ? "28px"
                            : "22px",
                        fontWeight: "600",
                      }}
                    >
                      Your Story
                    </div>

                    <div
                      className="mt-3 mx-5 px-5 mb-4"
                      style={{ color: "#333333", fontSize: "17px" }}
                    >
                      {formData.yourstory ? (
                        formData.yourstory
                      ) : (
                        <div className="d-flex">
                          <div className="w-50">Story</div>
                          <div className="w-50">-</div>
                        </div>
                      )}
                    </div>
                    {medical_expert_id === 4 && (
                      <>
                        <div
                          className="mx-5 ps-5 pt-3"
                          style={{
                            fontSize: isTabScreen
                              ? "17px"
                              : isScreen3
                                ? "28px"
                                : "22px",
                            fontWeight: "600",
                          }}
                        >
                          Batch Details
                        </div>

                        <div
                          className="mt-3 mx-5 px-5"
                          style={{ color: "#333333", fontSize: "17px" }}
                        >
                          {/* {medicalReg?.map((item, index) => ( */}

                          <table
                            style={{
                              marginTop: "1%",
                              // marginLeft: "2.5%",
                              width: "100%",
                              borderCollapse: "collapse",
                            }}
                          >
                            <thead>
                              <tr>
                                <th className="text-left">Batch Name</th>
                                <th className="text-center">Session</th>
                                <th className="text-center">Days</th>
                                <th className="text-center">Timing</th>
                                {/* <th>Actions</th> */}
                              </tr>
                            </thead>
                            {yogaBatchDetails?.length != 0 ? (
                              yogaBatchDetails?.map((item, index) => (
                                <>
                                  <tbody>
                                    <td>
                                      <div
                                        style={{
                                          width: "100%",
                                          borderRadius: "12px",
                                          marginTop: "10px",
                                          padding: "6px",
                                          backgroundColor: "white",
                                          textAlign: "left",
                                        }}
                                      >
                                        {item?.name}
                                      </div>
                                    </td>
                                    <td>
                                      {" "}
                                      <div
                                        style={{
                                          width: "100%",
                                          borderRadius: "12px",
                                          marginTop: "10px",
                                          padding: "6px",
                                          backgroundColor: "white",
                                          textAlign: "center",
                                        }}
                                      >
                                        {item?.session}
                                      </div>
                                    </td>
                                    <td>
                                      {" "}
                                      <div
                                        style={{
                                          width: "100%",
                                          borderRadius: "12px",
                                          marginTop: "10px",
                                          padding: "6px",
                                          backgroundColor: "white",
                                          textAlign: "center",
                                        }}
                                      >
                                        {item?.days}
                                      </div>
                                    </td>
                                    <td>
                                      {" "}
                                      <div
                                        style={{
                                          width: "100%",
                                          borderRadius: "12px",
                                          marginTop: "10px",
                                          padding: "6px",
                                          backgroundColor: "white",
                                          textAlign: "center",
                                        }}
                                      >
                                        {item?.timing}
                                      </div>
                                    </td>
                                  </tbody>
                                </>
                              ))
                            ) : (
                              <tbody>
                                <td>
                                  <div className="tabadj">-</div>
                                </td>
                                <td>
                                  <div className="tabadj">-</div>
                                </td>
                                <td>
                                  <div className="tabadj">-</div>
                                </td>
                                <td>
                                  <div className="tabadj">-</div>
                                </td>
                              </tbody>
                            )}
                          </table>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </StyleRoot>
      {isUploadPopupOpen && (
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* Upload popup content */}
          <div>
            {/* <h2>Upload Popup</h2> */}
            {/* Skip the "Upload" button */}
            {/* Include a close button to close the popup */}
            <button onClick={handleCloseUploadPopup}>Close</button>
          </div>
        </div>
      )}
      {/* Hidden file input */}
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleImageChange}
      />
      <Dialog
        open={profileComplete}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{
            height: 100,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Alert severity="success">{"Profile Saved Successfully"}</Alert>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showSuccessMessage}
        onClose={handleClose1}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{
            height: 100,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Alert severity="success">{"Basic details Saved Successfully"}</Alert>
        </DialogContent>
      </Dialog>
      <Dialog
        open={showSuccessMessagespecial}
        onClose={handleClosespecial}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{
            height: 100,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Alert severity="success">
            {"Specialisation details Saved Successfully"}
          </Alert>
        </DialogContent>
      </Dialog>
      <Dialog
        open={showSuccessMessagemedical}
        onClose={handleClosemedical}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{
            height: 100,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Alert severity="success">
            {"Medical Registration details Saved Successfully"}
          </Alert>
        </DialogContent>
      </Dialog>
      <Dialog
        open={showSuccessMessageedu}
        onClose={handleCloseedu}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{
            height: 100,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Alert severity="success">
            {"Education details Saved Successfully"}
          </Alert>
        </DialogContent>
      </Dialog>
      <Dialog
        open={showSuccessMessageclinic}
        onClose={handleCloseclinic}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{
            height: 100,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Alert severity="success">
            {"Hospital details Saved Successfully"}
          </Alert>
        </DialogContent>
      </Dialog>

      {/* time select popup */}
      <Dialog
        open={showYogaTime}
        onClose={handleCloseYogaTime}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
        // sx={{
        //   // height: 100,
        //   justifyContent: "center",
        //   alignItems: "center",
        //   display: "flex",
        // }}
        >
          <h1 className="font-weight-bold text-center pt-2">Select Timing</h1>
          {times.map((item, i) => (
            <React.Fragment key={i}>
              <button
                className="btn btn-default m-2 p-2"
                style={{
                  background: selectedTimes.includes(item)
                    ? "#f0588b"
                    : "white",
                  color: selectedTimes.includes(item) ? "#fff" : "#f0588b",
                  borderColor: "#f0588b",
                  borderRadius: "5px",
                  width: "20%",
                }}
                onClick={() => {
                  handleBatchItem(item);
                }}
              >
                {item}
              </button>
              {i % 4 == 3 ? <br /> : null}
            </React.Fragment>
          ))}
          <div className="text-center">
            {selectedTimes.length === 2 && (
              <button
                className="btn btn-success text-center"
                onClick={handleAddBatchtime}
              >
                Add
              </button>
            )}
          </div>
        </DialogContent>
      </Dialog>
      <AlertShow
        alertShowHide={showAlert}
        alertClose={handleCloseAlert}
        setalertMsg={alertMsg}
        severity={severity}
      />
    </div>
  );
}

export default Profile;
