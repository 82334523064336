const sonodescriptions = [
  "Obstetric ultrasound examination provides an accurate and safe clinical assessment of the gravid uterus throughout a woman’s pregnancy including characterizing pregnancy location, identifying the number of embryos present, and aiding in the prenatal diagnosis of fetal anomalies",
  "Routine ultrasound is usually performed every three to four weeks to monitor the fetus and pregnant uterus throughout pregnancy.",
  "A nuchal translucency (NT) test is an optional ultrasound performed in the first trimester of pregnancy. It helps determine your baby's risk of congenital conditions like Down syndrome.",
  "An anomaly scan is an ultrasound done to assess the physical development of the fetus and to check the presence of malformations in the growing fetus. It is done between 18 and 20 weeks because before that the organs of the fetus cannot be delineated clearly on an ultrasound.",
  "This test is done to detect a heart problem before the baby is born. It can provide a more detailed image of the baby's heart than a regular pregnancy ultrasound. The test can show: Blood flow through the heart.",
  "This scan aims to determine the growth and health of the fetus by: Measurement of the size of the fetal head (HC) ,abdomen (AC) and femur (FL). The HC, AC, and FL measurements are then used to calculate an estimate of the baby's fetal weight ( EFW). The purpose of this scan is to assess the fetus' growth by measuring the head, abdomen and femur bone. To establish a current estimated fetal weight. To identify and measure fetal heart rate.",
  "Doppler Ultrasound gives doctors a visual or audible representation of blood movement through veins, arteries and blood vessels with sound waves. The reflected sound can be used to diagnose restricted blood flow, blood clotsand fetal health.",
];
export default sonodescriptions;
