import React, { useState } from "react";

const Sonographybtn = ({ data, getConsultations, userData }) => {
  const [uploaddoc, setUploaddoc] = useState(false);
  const [file, setFile] = useState(null);
  const [summary, setSummary] = useState("");

  // Mark patient visit
  const patientVisit = (id) => {
    api
      .sonographypatientvisited({ consultation_id: id })
      .then((res) => {
        if (res.success) {
          console.log(res);
          getConsultations(data?.patient_id);
        }
      })
      .catch((rej) => {
        console.log(rej);
      });
  };

  // Mark scan completed
  const scanCompleted = (id) => {
    api
      .sonographyscanstatus({ consultation_id: id })
      .then((res) => {
        if (res.success) {
          getConsultations(data?.patient_id);
        }
      })
      .catch((rej) => {
        console.log(rej);
      });
  };

  // Handle upload report
  const handleUpload = (data) => {
    const formData = new FormData();
    formData.append("patient_id", data?.patient_id);
    formData.append("consultation_id", data?.id);
    formData.append("summary", summary);
    formData.append("file", file);
    formData.append("doctor_id", data?.doctor_id);
    formData.append("consultation_number", data?.consultation_number);
    formData.append(
      "patient_consultation_name",
      data?.patient_consultation_name
    );
    formData.append("medical_expert_name", userData?.medical_expert_name);

    api
      .reportupload(formData)
      .then((res) => {
        getConsultations(data?.patient_id);
      })
      .catch((rej) => console.log(rej));
  };
  const markAsDone = (data) => {
    api
      .sonographycompleted({
        consultation_id: data?.id,
        patient_id: data?.patient_id,
      })
      .then((res) => {
        getConsultations(data?.patient_id);
      })
      .catch((rej) => {
        console.log(rej);
      });
  };
  // Conditional rendering
  if (data?.is_completed === 1) {
    return null;
  } else if (data?.is_patient_visited == 0) {
    return (
      <button
        className="btn btn-primary"
        onClick={() => {
          const dateOnly = moment(data?.scheduled_date).format("YYYY-MM-DD");
          const scheduleDateTime = moment(
            `${dateOnly} ${data?.timing}`,
            "YYYY-MM-DD h.mmA"
          );
          const timeBeforeTenMinutes = scheduleDateTime
            .clone()
            .subtract(10, "minutes");

          const currentdate = moment();
          if (currentdate >= timeBeforeTenMinutes) {
            patientVisit(data?.id);
          }
        }}
      >
        Patient Visited
      </button>
    );
  } else if (data?.is_scan_completed === 0) {
    return (
      <button
        className="btn btn-primary"
        onClick={() => scanCompleted(data?.id)}
      >
        Scan Completed
      </button>
    );
  } else if (!data?.sonography_report?.[0]?.filekey) {
    return (
      <div className="w-100 px-3">
        {uploaddoc ? (
          <>
            <textarea
              className="form-control"
              placeholder="Add summary"
              value={summary}
              onChange={(e) => setSummary(e.target.value)}
            ></textarea>
            <input
              type="file"
              className="mt-2 w-100"
              onChange={(e) => setFile(e.target.files[0])}
            />
            <button
              className="btn btn-primary mt-2 d-flex m-auto"
              onClick={() => handleUpload(data)}
            >
              Upload Report
            </button>
          </>
        ) : (
          <button
            className="btn btn-primary mt-2 d-flex m-auto"
            onClick={() => setUploaddoc(true)}
          >
            Upload Report
          </button>
        )}
      </div>
    );
  } else {
    return (
      <button
        className="btn btn-primary mt-2 d-flex m-auto"
        onClick={() => markAsDone(data)}
      >
        Mark as Done
      </button>
    );
  }
};

export default Sonographybtn;
