import { display } from "@mui/system";
import React, { useState } from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useMediaQuery } from "react-responsive";
const chipdata = [
  { nm: "Birth plan", id: "1", val: false },
  { nm: "Books & Magazine", id: "2", val: false },
  { nm: "breast pads", id: "3", val: false },
  { nm: "camera,batteries", id: "4", val: false },
  { nm: "contact lenscase", id: "5", val: false },
  { nm: "eyeglasses", id: "6", val: false },
  { nm: "dressing gown", id: "7", val: false },
  { nm: "ear plugs", id: "8", val: false },
  { nm: "facecloth", id: "9", val: false },
  { nm: "gift for older kid", id: "10", val: false },
  { nm: "facecloth", id: "11", val: false },
  { nm: "glucose tablets", id: "12", val: false },
  { nm: "Going home outfit", id: "13", val: false },
  { nm: "hairband", id: "14", val: false },
  { nm: "hairbrush", id: "15", val: false },
  { nm: "healthy snacks", id: "16", val: false },
  { nm: "hospital notes", id: "17", val: false },
  { nm: "heating pad", id: "18", val: false },
  { nm: "juice/water bottle", id: "19", val: false },
  { nm: "laundry bag", id: "20", val: false },
  { nm: "lip balm", id: "21", val: false },
  { nm: "make-up & remover", id: "22", val: false },
  { nm: "massage oil/lotion", id: "23", val: false },
  { nm: "grip socks", id: "24", val: false },
  { nm: "basic toiletries", id: "25", val: false },
  { nm: "thick sanitary-pads", id: "26", val: false },
  { nm: "massage tools", id: "27", val: false },
  { nm: "maternity pads", id: "28", val: false },
  { nm: "music,headphones", id: "29", val: false },
  { nm: "nursing bra", id: "30", val: false },
  { nm: "old/cheap underwear", id: "31", val: false },
  { nm: "pen and paper", id: "32", val: false },
  { nm: "playing card/puzzle", id: "33", val: false },
  { nm: "slippers", id: "34", val: false },
  { nm: "socks", id: "35", val: false },
  { nm: "pain relief machine", id: "36", val: false },
  { nm: "soap/scented bathgel", id: "37", val: false },
  { nm: "Socks", id: "38", val: false },
  { nm: "Myprega's web app", id: "39", val: false },
  { nm: "tissues", id: "40", val: false },
  { nm: "toothpaste,toothbrush", id: "41", val: false },
  { nm: "towels", id: "42", val: false },
  { nm: "two long t-shirts", id: "43", val: false },
  { nm: "video recorder", id: "44", val: false },
  { nm: "water spray", id: "45", val: false },
  { nm: "your own pillow", id: "46", val: false },

  { nm: "essential oils", id: "47", val: false },
];
const chipdata1 = [
  { nm: "3all-in-one outfits", id: "1", val: false },
  { nm: "3 muslin/burp cloths", id: "2", val: false },
  { nm: "blanket to wrap", id: "3", val: false },
  { nm: "car seat", id: "4", val: false },
  { nm: "cardigan", id: "5", val: false },
  { nm: "cotton balls", id: "6", val: false },
  { nm: "hat", id: "7", val: false },
  { nm: "jacket for winter", id: "8", val: false },
  { nm: "nappy diaper cream", id: "9", val: false },
  { nm: "newborn diapers", id: "10", val: false },
  { nm: "scratch mittens", id: "11", val: false },
  { nm: "socks or booties", id: "12", val: false },
  { nm: "soft brush", id: "13", val: false },
  { nm: "soft toy/chime", id: "14", val: false },
  { nm: "wash cloth", id: "15", val: false },
  { nm: "basic wipes", id: "16", val: false },
  { nm: "Medications", id: "17", val: false },
];
const chipdata2 = [
  { nm: "book,magazine", id: "1", val: false },
  { nm: "change of clothes", id: "2", val: false },
  { nm: "comfortable shoes", id: "3", val: false },
  { nm: "digital camera", id: "4", val: false },
  { nm: "list of phone numbers", id: "5", val: false },
  { nm: "loose change(parking/snacks)", id: "6", val: false },
  { nm: "music, headphones", id: "7", val: false },
  { nm: "paper & pencil/pen", id: "8", val: false },
  { nm: "snacks & drinks", id: "9", val: false },
  { nm: "toothpaste & toothbrush", id: "10", val: false },
  { nm: "Debit & Credit card", id: "11", val: false },
  { nm: "Some cash", id: "12", val: false },
];
const bag = [{ nm: "MOTHER" }, { nm: "BABY" }, { nm: "PARTNER" }];
let indexvalue;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Hospitalbag({ setExpand, setSelected }) {
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const wrapper = {
    marginLeft: isTabScreen ? "20px" : "50px",
    marginRight: isTabScreen ? "20px" : "50px",
    paddingLeft: isTabScreen ? "20px" : "50px",
    paddingBottom: "50px",
    background: "#FBFBFB",
    borderRadius: "12px",
    paddingTop: "30px",
    marginBottom: "50px",
  };
  const back = {
    fontWeight: "600",
    fontSize: "18px",
    color: "#004AAD",
    cursor: "pointer",
  };
  const heading = {
    color: "#F0588B",
    fontWeight: "600",
    fontSize: isTabScreen ? "20px" : "24px",
    paddingTop: "10px",
  };
  const para = {
    paddingTop: "30px",
    fontWeight: "400",
    fontSize: "20px",
    color: "#333333",
  };
  const bagowner = {
    paddingTop: "50px",
    display: "flex",
  };
  const nm = {
    marginRight: "56px",
  };
  const innerbox = {
    padding: "6px",
    paddingLeft: "0px",
    borderRadius: "4px",
    paddingTop: "30px",
    paddingBottom: "30px",
    // height: "200px",
  };
  const bg = {
    backgroundColor: "rgba(217, 217, 217, 0.2)",
    border: "0.72px solid #E3E3E5",
  };
  const savebtn = {
    width: "113px",
    height: "34px",
    background: "#FFFFFF",
    border: "0.777391px solid #E42346",
    boxShadow: "0px -3.88695px 8.66387px rgba(51, 51, 51, 0.078)",
    borderRadius: "3.88695px",
    color: "#E42346",
  };
  const add = {
    width: "12px",
    height: "12px",
    position: "relative",
    top: 11,
    left: "-28px",
    cursor: "pointer",
  };
  const close = {
    width: "12px",
    height: "12px",
    position: "relative",
    left: "-28px",
    top: 10,
    cursor: "pointer",
  };
  const square = {
    backgroundColor: "white",
    width: "41px",
    height: "35px",
    Padding: "6px, 12px, 6px, 12px",
    border: "0.62px solid #D9D9D9",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "15px",
    marginTop: "15px",
  };
  const saveicon1 = {
    height: "10px",
    width: "10px",
    cursor: "pointer",
  };
  const saveicon2 = {
    height: "24px",
    width: "24px",
    cursor: "pointer",
  };
  const [btnselectedmagzine, setbtnselectedmagzine] = useState(false);
  const [valuechip, setvaluechip] = useState(false);
  const [valuechip1, setvaluechip1] = useState(false);
  const [valuechip2, setvaluechip2] = useState(false);
  const [value, setValue] = React.useState(0);
  const [va, setva] = React.useState(false);
  const [myList, setMyList] = useState(chipdata);
  const [myListbaby, setMyListbaby] = useState(chipdata1);
  const [myListpartner, setMyListpartner] = useState(chipdata2);
  const [save, clicksave] = useState(false);
  const [save1, clicksave1] = useState(false);
  const [save2, clicksave2] = useState(false);
  const [plus, setplus] = useState(false);
  const [plus1, setplus1] = useState(false);
  const [plus2, setplus2] = useState(false);
  const [edit, setedit] = useState(false);
  const [edit1, setedit1] = useState(false);
  const [edit2, setedit2] = useState(false);
  const [listonclick, setlistonclick] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const original = () => {
    setplus(true);
    clicksave(false);
  };
  const originallist = () => {
    setedit(true);
    clicksave(false);
    const newState1 = myList.map((obj) => {
      if (obj.val === true) return { ...obj, val: false };
      else return obj;
    });
    setMyList(newState1);
  };
  const original1 = () => {
    setplus1(true);
    clicksave1(false);
  };
  const originallist1 = () => {
    setedit1(true);
    clicksave1(false);
    const newState1 = myListbaby.map((obj) => {
      if (obj.val === true) return { ...obj, val: false };
      else return obj;
    });
    setMyListbaby(newState1);
  };
  const original2 = () => {
    setplus2(true);
    clicksave2(false);
  };
  const originallist2 = () => {
    setedit2(true);
    clicksave2(false);
    const newState1 = myListpartner.map((obj) => {
      if (obj.val === true) return { ...obj, val: false };
      else return obj;
    });
    setMyListpartner(newState1);
  };
  const getid = (index, val) => {
    setvaluechip(index);
    const newState = myList.map((obj) => {
      if (obj.id === index) {
        if (val === false) return { ...obj, val: true };
        else return { ...obj, val: false };
      }
      return obj;
    });
    setMyList(newState);
    console.log("%%%%%%%%%%%%%%%%", newState);
  };
  const getid1 = (index, val) => {
    setvaluechip1(index);
    const newState = myListbaby.map((obj) => {
      if (obj.id === index) {
        if (val === false) return { ...obj, val: true };
        else return { ...obj, val: false };
      }
      return obj;
    });
    setMyListbaby(newState);
    console.log("%%%%%%%%%%%%%%%%", newState);
  };
  const getid2 = (index, val) => {
    setvaluechip2(index);
    const newState = myListpartner.map((obj) => {
      if (obj.id === index) {
        if (val === false) return { ...obj, val: true };
        else return { ...obj, val: false };
      }
      return obj;
    });
    setMyListpartner(newState);
  };
  return (
    <div style={wrapper}>
      <div
        style={back}
        onClick={() => {
          setSelected(false);
          setExpand(true);
        }}
      >
        Back
      </div>
      <div style={heading}>Hospital Bag</div>
      <div style={para}>
        Just about 5 percent Trusted Source of babies are born on their actual
        due date. In reality, your baby may come a couple weeks before or after
        when you're expecting. Consider packing your bags at least 3 weeks
        before your due date. This gives you a bit of time for the unexpected.
      </div>

      <div style={bagowner}>
        {/* {bag.map((t) => (
          <>
            <div style={nm}>{t?.nm}</div>
          </>
        ))} */}
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#F0588B",
                  color: "#F0588B",
                },
              }}
            >
              <Tab label="MOTHER" {...a11yProps(0)} />
              <Tab label="BABY" {...a11yProps(1)} />
              <Tab label="PARTNER" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <div className="d-flex justify-content-between" style={bg}>
              <div style={innerbox}>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {myList.map((t, i) => (
                    <>
                      {save === true ? (
                        t.val === true && (
                          <>
                            <Chip
                              label={t.nm}
                              variant="outlined"
                              style={{
                                paddingTop: "12px",
                                paddingBottom: "12px",
                                height: "35.28px",
                                padding: "6px, 12px, 6px, 12px",
                                fontSize: "10.88px",
                                weight: "700",
                                borderRadius: "4px",
                                marginLeft: "30px",
                                marginTop: "12px",
                                borderColor: "black",
                              }}
                            ></Chip>
                          </>
                        )
                      ) : (
                        <Chip
                          label={t.nm}
                          variant="outlined"
                          id={t.id}
                          val={t.val}
                          onClick={() => getid(t.id, t.val)}
                          style={{
                            color: t.val === true ? "#ffffff" : "#6B6D81",
                            // width: t.nm.length <= 10 ? "108.59px" : "130px",
                            backgroundColor:
                              t.val === true ? "#E42346" : "#ffff",
                            marginLeft: "30px",
                            paddingTop: "12px",
                            paddingBottom: "12px",
                            height: "35.28px",
                            fontSize: "9.89px",
                            borderRadius: "4px",
                            paddingRight: "20px",
                            borderColor: "black",
                            marginBottom: "12px",
                            width: "150px",
                          }}
                        />
                      )}
                      {save === false ? (
                        t.val === true ? (
                          <img
                            src="/GarbhaSanskar/close.png"
                            style={add}
                            onClick={() => getid(t.id, t.val)}
                          ></img>
                        ) : (
                          <img
                            src="/GarbhaSanskar/add.png"
                            style={close}
                            onClick={() => getid(t.id, t.val)}
                          ></img>
                        )
                      ) : (
                        <div></div>
                      )}
                    </>
                  ))}
                </div>

                {valuechip && save === false ? (
                  <div className="mt-5 text-center">
                    <button
                      onClick={() => clicksave(true)}
                      style={savebtn}
                      className="mt-5"
                    >
                      SAVE
                    </button>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              {save === true ? (
                <div className="d-flex">
                  <div style={square} onClick={() => originallist()}>
                    <img
                      src="/GarbhaSanskar/saveicon1.png"
                      style={saveicon1}
                    ></img>
                  </div>
                  <div style={square} onClick={() => original()}>
                    <img
                      src="/GarbhaSanskar/saveicon2.png"
                      style={saveicon2}
                    ></img>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className="d-flex justify-content-between" style={bg}>
              <div style={innerbox}>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {myListbaby.map((t, i) => (
                    <>
                      {save1 === true ? (
                        t.val === true && (
                          <>
                            <Chip
                              label={t.nm}
                              variant="outlined"
                              style={{
                                paddingTop: "12px",
                                paddingBottom: "12px",
                                height: "35.28px",
                                padding: "6px, 12px, 6px, 12px",
                                fontSize: "10.88px",
                                weight: "700",
                                borderRadius: "4px",
                                marginLeft: "30px",
                                marginTop: "12px",
                                borderColor: "black",
                              }}
                            ></Chip>
                          </>
                        )
                      ) : (
                        <Chip
                          label={t.nm}
                          variant="outlined"
                          id={t.id}
                          val={t.val}
                          onClick={() => getid1(t.id, t.val)}
                          style={{
                            color: t.val === true ? "#ffffff" : "#6B6D81",
                            // width: t.nm.length <= 10 ? "108.59px" : "130px",
                            backgroundColor:
                              t.val === true ? "#E42346" : "#ffff",
                            marginLeft: "30px",
                            paddingTop: "12px",
                            paddingBottom: "12px",
                            height: "35.28px",
                            fontSize: "9.89px",
                            borderRadius: "4px",
                            paddingRight: "20px",
                            borderColor: "black",
                            marginBottom: "12px",
                            width: "150px",
                          }}
                        />
                      )}
                      {save1 === false ? (
                        t.val === true ? (
                          <img
                            src="/GarbhaSanskar/close.png"
                            style={add}
                            onClick={() => getid1(t.id, t.val)}
                          ></img>
                        ) : (
                          <img
                            src="/GarbhaSanskar/add.png"
                            style={close}
                            onClick={() => getid1(t.id, t.val)}
                          ></img>
                        )
                      ) : (
                        <div></div>
                      )}
                    </>
                  ))}
                </div>

                {valuechip1 && save1 === false ? (
                  <div className="mt-5 text-center">
                    <button
                      onClick={() => clicksave1(true)}
                      style={savebtn}
                      className="mt-5"
                    >
                      SAVE
                    </button>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              {save1 === true ? (
                <div className="d-flex">
                  <div style={square} onClick={() => originallist1()}>
                    <img
                      src="/GarbhaSanskar/saveicon1.png"
                      style={saveicon1}
                    ></img>
                  </div>
                  <div style={square} onClick={() => original1()}>
                    <img
                      src="/GarbhaSanskar/saveicon2.png"
                      style={saveicon2}
                    ></img>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <div className="d-flex justify-content-between" style={bg}>
              <div style={innerbox}>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {myListpartner.map((t, i) => (
                    <>
                      {save2 === true ? (
                        t.val === true && (
                          <>
                            <Chip
                              label={t.nm}
                              variant="outlined"
                              style={{
                                paddingTop: "12px",
                                paddingBottom: "12px",
                                height: "35.28px",
                                padding: "6px, 12px, 6px, 12px",
                                fontSize: "10.88px",
                                weight: "700",
                                borderRadius: "4px",
                                marginLeft: "30px",
                                marginTop: "12px",
                                borderColor: "black",
                              }}
                            ></Chip>
                          </>
                        )
                      ) : (
                        <Chip
                          label={t.nm}
                          variant="outlined"
                          id={t.id}
                          val={t.val}
                          onClick={() => getid2(t.id, t.val)}
                          style={{
                            color: t.val === true ? "#ffffff" : "#6B6D81",
                            // width: t.nm.length <= 10 ? "108.59px" : "130px",
                            backgroundColor:
                              t.val === true ? "#E42346" : "#ffff",
                            marginLeft: "30px",
                            paddingTop: "12px",
                            paddingBottom: "12px",
                            height: "35.28px",
                            fontSize: "9.89px",
                            borderRadius: "4px",
                            paddingRight: "20px",
                            borderColor: "black",
                            marginBottom: "12px",
                            width: "150px",
                          }}
                        />
                      )}
                      {save2 === false ? (
                        t.val === true ? (
                          <img
                            src="/GarbhaSanskar/close.png"
                            style={add}
                            onClick={() => getid2(t.id, t.val)}
                          ></img>
                        ) : (
                          <img
                            src="/GarbhaSanskar/add.png"
                            style={close}
                            onClick={() => getid2(t.id, t.val)}
                          ></img>
                        )
                      ) : (
                        <div></div>
                      )}
                    </>
                  ))}
                </div>

                {valuechip2 && save2 === false ? (
                  <div className="mt-5 text-center">
                    <button
                      onClick={() => clicksave2(true)}
                      style={savebtn}
                      className="mt-5"
                    >
                      SAVE
                    </button>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              {save2 === true ? (
                <div className="d-flex">
                  <div style={square} onClick={() => originallist2()}>
                    <img
                      src="/GarbhaSanskar/saveicon1.png"
                      style={saveicon1}
                    ></img>
                  </div>
                  <div style={square} onClick={() => original2()}>
                    <img
                      src="/GarbhaSanskar/saveicon2.png"
                      style={saveicon2}
                    ></img>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </TabPanel>
        </Box>

        <hr></hr>
      </div>
    </div>
  );
}

export default Hospitalbag;
