import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import moment from "moment";
import configData from "../config.json";
import TextField from "@mui/material/TextField";

import { useMediaQuery } from "react-responsive";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { styled } from "@mui/material/styles";

const ToggleButton1 = styled(ToggleButton)({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "#2196F3",
  },
});

function RightBox2({
  title,
  onClick,
  onClickDay,
  date1,
  dateClicked,
  editTimeSlot,
  highlightDates,
}) {
  const [value, onChange] = useState();
  const [clickedNext, setClickedNext] = useState(false);
  const [clickedDay, setClickedDay] = useState(false);

  const [alignment, setAlignment] = useState("left");
  const [alignment2, setAlignment2] = useState("left");
  const [alignment3, setAlignment3] = useState("left");

  const [updated, setUpdated] = useState(false);

  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const handleAlignment2 = (event, newAlignment) => {
    setAlignment2(newAlignment);
  };
  const handleAlignment3 = (event, newAlignment) => {
    setAlignment3(newAlignment);
  };
  // console.log('ops', moment(moment().toDate()).format('DD MMMM YYYY'))
  useEffect(() => {
    console.log("seee", date1);
    if (!editTimeSlot) onChange(date1);
  }, [date1]);

  useEffect(() => {
    console.log("selected Range", value);
  }, [value]);

  return (
    <div
      style={{
        // borderTopRightRadius: 55,
        // borderBottomRightRadius: 55,
        // alignSelf: 'flex-end',
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "30vw",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          marginTop: isTabScreen ? 20 : 50,
          fontSize:
            editTimeSlot && !isTabScreen
              ? "22px"
              : editTimeSlot && isTabScreen
                ? "18px"
                : isTabScreen
                  ? "22px"
                  : "30px",
          fontWeight: "600",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: configData.THEME_COLORS.PRIMARY,
          fontFamily: "Poppins",
        }}
      >
        {clickedNext ? "Select Date" : clickedDay ? "Update Time Slot" : title}
      </div>
      <div style={{ marginTop: 20, alignSelf: "center" }}>
        <Calendar
          onChange={onChange}
          selectRange={editTimeSlot && !clickedNext ? true : false}
          // value={value}
          value={value}
          tileClassName={({ date, view }) => {
            // console.log(
            //   'cc--',
            //   date
            //   // moment(date).format('DD MM YYYY') ==
            //   //   moment(date1).format('DD MM YYYY')
            // )
            if (editTimeSlot && !clickedNext) {
              if (
                highlightDates?.find(
                  (d) =>
                    moment(date).format("DD MM YYYY") ==
                    moment(d).format("DD MM YYYY"),
                )
              ) {
                return "highlight";
              }
            }
            // if (
            //   moment(date).format('DD MM YYYY') == '17 05 2023' ||
            //   moment(date).format('DD MM YYYY') == '16 05 2023'
            // ) {
            //   // console.log('which', moment(date).format('DD MM YYYY'))
            //   return 'highlight'
            // }
          }}
          onClickDay={() => {
            onClickDay();
            if (clickedNext) {
              setClickedNext(false);
              setClickedDay(true);
            }
          }}
        />
      </div>
      {!clickedDay && editTimeSlot && (
        <div className="d-flex align-items-center justify-content-between px-3 mt-3 w-100">
          <div
            className="d-flex align-items-center"
            style={{ marginLeft: "8%" }}
          >
            <div
              style={{
                backgroundColor: "rgba(169, 202, 187, 0.5)",
                height: "2vh",
                width: "1vw",
                borderRadius: "50%",
              }}
            ></div>
            <div
              style={{
                fontSize: isTabScreen ? "6px" : "12px",
                paddingLeft: "12px",
              }}
            >
              Previous Range
            </div>
          </div>
          <div
            className="d-flex align-items-center"
            style={{ marginRight: "8%" }}
          >
            <div
              style={{
                backgroundColor: "#58CF99",
                height: "2vh",
                width: "1vw",
                borderRadius: "50%",
              }}
            ></div>
            <div
              style={{
                fontSize: isTabScreen ? "6px" : "12px",
                paddingLeft: "12px",
              }}
            >
              Current Selected Range
            </div>
          </div>
        </div>
      )}
      {editTimeSlot && !clickedNext && !clickedDay && (
        <div
          onClick={() => setClickedNext(true)}
          style={{
            backgroundColor: configData.THEME_COLORS.PRIMARY,
            border: "1px solid rgba(0, 74, 173, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: isTabScreen ? "13px" : "20px",
            fontWeight: "500",
            alignSelf: "center",
            width: "8vw",
            borderRadius: "8px",
            color: "white",
            marginTop: "6%",
            cursor: "pointer",
          }}
        >
          Next
        </div>
      )}
      {clickedDay && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              fontSize: isTabScreen ? "14px" : "24px",
              fontWeight: "600",
              color: configData.THEME_COLORS.PRIMARY,
              textAlign: "center",
              marginTop: "3%",
            }}
          >
            Set Clinic Timing
          </div>
          <div
            style={{
              fontSize: isTabScreen ? "9px" : "12px",
              fontWeight: "400",
              marginLeft: "17%",
            }}
          >
            Start Time
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <TextField
              size="small"
              inputProps={{
                style: {
                  fontSize: "15.54px",
                },
              }}
              sx={{
                backgroundColor: "#F2F2F2",
                width: "4vw",
                marginTop: "1.6%",
                "& fieldset": { border: "1 px" },
                color: "#232323",
                marginLeft: "17%",
              }}
            />
            <div style={{ marginLeft: "5px" }}>:</div>
            <TextField
              size="small"
              inputProps={{
                style: {
                  fontSize: "15.54px",
                },
              }}
              sx={{
                backgroundColor: "#EEEEEE",
                width: "4vw",
                marginTop: "1.6%",
                "& fieldset": { border: "1 px" },
                color: "#232323",
                marginLeft: "5px",
              }}
            />
            <ToggleButtonGroup
              color="primary"
              style={{ marginLeft: "10px" }}
              size="small"
              value={alignment}
              exclusive
              onChange={handleAlignment}
              aria-label="text alignment"
            >
              <ToggleButton1
                sx={{ width: "50px" }}
                value="left"
                aria-label="left aligned"
              >
                <div>AM</div>
              </ToggleButton1>
              <ToggleButton1
                sx={{ width: "50px" }}
                value="center"
                aria-label="centered"
              >
                <div>PM</div>
              </ToggleButton1>
            </ToggleButtonGroup>
          </div>

          <div
            style={{
              fontSize: isTabScreen ? "9px" : "12px",
              fontWeight: "400",
              marginLeft: "17%",
              marginTop: "5%",
            }}
          >
            End Time
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <TextField
              size="small"
              // value={searched}
              // onChange={e => setSearched(e.target.value)}
              inputProps={{
                style: {
                  fontSize: "15.54px",
                },
              }}
              sx={{
                backgroundColor: "#F2F2F2",
                width: "4vw",
                marginTop: "1.6%",
                "& fieldset": { border: "1 px" },
                color: "#232323",
                marginLeft: "17%",
              }}
            />
            <div style={{ marginLeft: "5px" }}>:</div>
            <TextField
              size="small"
              // value={searched}
              // onChange={e => setSearched(e.target.value)}
              inputProps={{
                style: {
                  fontSize: "15.54px",
                },
              }}
              sx={{
                backgroundColor: "#EEEEEE",
                width: "4vw",
                marginTop: "1.6%",
                "& fieldset": { border: "1 px" },
                color: "#232323",
                marginLeft: "5px",
              }}
            />
            <ToggleButtonGroup
              color="primary"
              style={{ marginLeft: "10px" }}
              size="small"
              value={alignment2}
              exclusive
              onChange={handleAlignment2}
              aria-label="text alignment"
            >
              <ToggleButton1
                sx={{ width: "50px" }}
                value="left"
                aria-label="left aligned"
              >
                <div>AM</div>
              </ToggleButton1>
              <ToggleButton1
                sx={{ width: "50px" }}
                value="center"
                aria-label="centered"
              >
                <div>PM</div>
              </ToggleButton1>
            </ToggleButtonGroup>
          </div>
          <div
            style={{
              fontSize: isTabScreen ? "9px" : "12px",
              fontWeight: "400",
              marginLeft: "17%",
              marginTop: "5%",
            }}
          >
            Duration
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <TextField
              size="small"
              // value={searched}
              // onChange={e => setSearched(e.target.value)}
              inputProps={{
                style: {
                  fontSize: "15.54px",
                },
              }}
              sx={{
                backgroundColor: "#F2F2F2",
                width: "4vw",
                marginTop: "1.6%",
                "& fieldset": { border: "1 px" },
                color: "#232323",
                marginLeft: "17%",
              }}
            />
            <div style={{ marginLeft: "5px" }}>:</div>
            <TextField
              size="small"
              // value={searched}
              // onChange={e => setSearched(e.target.value)}
              inputProps={{
                style: {
                  fontSize: "15.54px",
                },
              }}
              sx={{
                backgroundColor: "#EEEEEE",
                width: "4vw",
                marginTop: "1.6%",
                "& fieldset": { border: "1 px" },
                color: "#232323",
                marginLeft: "5px",
              }}
            />
            <ToggleButtonGroup
              color="primary"
              style={{ marginLeft: "10px" }}
              size="small"
              value={alignment3}
              exclusive
              onChange={handleAlignment3}
              aria-label="text alignment"
            >
              <ToggleButton1
                sx={{ width: "50px" }}
                value="left"
                aria-label="left aligned"
              >
                <div>AM</div>
              </ToggleButton1>
              <ToggleButton1
                sx={{ width: "50px" }}
                value="center"
                aria-label="centered"
              >
                <div>PM</div>
              </ToggleButton1>
            </ToggleButtonGroup>
          </div>
          {!updated && (
            <div
              onClick={() => setUpdated(true)}
              style={{
                backgroundColor: configData.THEME_COLORS.PRIMARY,
                border: "1px solid rgba(0, 74, 173, 0.5)",
                display: "flex",
                alignSelf: "center",
                alignItems: "center",
                justifyContent: "center",
                fontSize: isTabScreen ? "13px" : "20px",
                fontWeight: "500",
                alignSelf: "center",
                width: "8vw",
                borderRadius: "8px",
                color: "white",
                marginTop: "6%",
                cursor: "pointer",
                marginBottom: "8%",
              }}
            >
              Update
            </div>
          )}
          {updated && (
            <div
              style={{
                fontSize: "18px",
                fontWeight: "500",
                color: "#26931F",
                textAlign: "center",
                marginTop: "8%",
                marginBottom: "10%",
              }}
            >
              Time Updated Successfully!
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default RightBox2;
