import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Alert } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { Modal } from "react-bootstrap";

const Addnewmedicine = ({ open, handleclose, getmedicine }) => {
  const [medicinename, setMedicinename] = useState("");
  const handlesubmit = () => {
    api
      .addmedicineapi({
        Medicine_Name: medicinename,
      })
      .then((res) => {
        if (res.success) {
          handleclose();
          getmedicine();
        }
      })
      .catch((rej) => {
        console.log(rej);
        handleclose();
      });
  };
  return (
    <div>
      <Modal show={open}>
        <div
          style={{
            padding: "20px",
          }}
        >
          <input
            type={"text"}
            className="form-controll w-100"
            placeholder="Enter Medicine Name"
            style={{
              padding: "10px",
              fontSize: "16px",
            }}
            onChange={(e) => {
              setMedicinename(e.target.value);
            }}
            max={150}
          />
        </div>

        <div className="d-flex align-items-center justify-content-between">
          <button onClick={handlesubmit} className="btn btn-primary btn-sm">
            Save
          </button>
          <button onClick={handleclose} className="btn btn-danger btn-sm">
            Cancle
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Addnewmedicine;
