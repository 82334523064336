const includedtestlist = [
  {
    testname: "Complete Blood Count (CBC) Test",
    img: "/Pathology/bloodcnt.png",
    info: "complete blood count info",
  },
  {
    testname: "Blood Group & Rh Factor Test",
    img: "/Pathology/Haemoglobin.png",
    info: "Hemoglobin (Hb) measurement is a standard test among pregnant women that is used to evaluate physical status, Measure hemoglobin protein (Iron Level) and anemia.",
  },
  {
    testname: "Rubella IgM Test",
    info: "Rubella IgM Test",
    img: "/Pathology/Haemoglobin.png",
  },
  {
    testname: "Hepatitis",
    info: "Hepatitis",
    img: "/Pathology/Haemoglobin.png",
  },
  {
    testname: "Syphilis Test",
    info: "Syphilis Test",
    img: "/Pathology/Haemoglobin.png",
  },
  {
    testname: "HIV Test",
    info: "HIV Test",
    img: "/Pathology/Haemoglobin.png",
  },
  {
    testname: "Urine Test(for sugar and protein)",
    img: "/Pathology/urine-test.png",
    info: "urine test info",
  },
  {
    testname: "Glucose Screening Test or GTT",
    info: " Glucose Screening Test or GTT",
    img: "/Pathology/Haemoglobin.png",
  },
  {
    testname: "HB Test",
    info: "HB Test",
    img: "/Pathology/Haemoglobin.png",
  },
];

const includedtestlist1 = [
  {
    testname: "HB Test",
    info: "HB Test",
    img: "/Pathology/Haemoglobin.png",
  },
  {
    testname: "Glucose screening Test or GTT",
    info: "Glucose screening Test or GTT",
    img: "/Pathology/Haemoglobin.png",
  },
  {
    testname: "Complete Blood Count (CBC) Test",
    img: "/Pathology/bloodcnt.png",
    info: "complete blood count info",
  },
  {
    testname: "Urine Test(for sugar and protein)",
    img: "/Pathology/urine-test.png",
    info: "urine test info",
  },
];
const includedtestlist2 = [
  {
    testname: "Quadruple Screen Test",
    img: "/Pathology/urine-test.png",
    info: "Quadruple screen test",
  },
];
const includedtestlist3 = [
  {
    testname: "HB Test",
    img: "/Pathology/urine-test.png",
    info: "HB TEST",
  },
  {
    testname: "Glucose screening Test or GTT",
    img: "/Pathology/urine-test.png",
    info: "Glucose screening test",
  },
  {
    testname: " Complete Blood Count (CBC) test",
    img: "/Pathology/urine-test.png",
    info: "Complete Blood Count (CBC) test",
  },
  {
    testname: "Urine Test(for sugar and protein)",
    img: "/Pathology/urine-test.png",
    info: "urine test info",
  },
];
const includedtestlist4 = [
  {
    testname: "Group B Streptococcus (GBS) Test",
    img: "/Pathology/urine-test.png",
    info: "Group B Streptococcus (GBS) Test",
  },
];
export {
  includedtestlist,
  includedtestlist1,
  includedtestlist2,
  includedtestlist3,
  includedtestlist4,
};
