import React, { useState } from "react";
import moment from "moment";
function Displaycasepaper(props) {
  const [gotopg2, setGotopg2] = useState(false);
  const [gotopg3, setGotopg3] = useState(false);
  const gotopage2 = () => {
    setGotopg2(true);
    setGotopg3(false);
  };
  const gotopage3 = () => {
    setGotopg3(true);
    setGotopg2(false);
  };
  const backtopg1 = () => {
    setGotopg2(false);
  };
  const backtopg2 = () => {
    setGotopg3(false);
    setGotopg2(true);
  };
  return (
    <>
      {gotopg2 == true ? (
        <div className="ps-2 pt-5">
          <span className="cursor" onClick={backtopg1}>
            Back
          </span>
          <div
            style={{
              color: "#F0588B",
              fontSize: "24px",
              fontWeight: "600",
            }}
          >
            Personal & family medical profile
          </div>
          <div className="d-flex flex-column justify-content-between">
            <div className="que1">
              1. Do you suffer from any existing medical conditions
              <span className="subq">(If Yes,Describe in Details)?</span>
              <div className="radio-button-container">
                <label className="radio-label">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    value="Yes"
                    name="medical_condition"
                    checked={
                      props.casepaperdata2nd.medical_condition == "Yes" ||
                      props.casepaperdata2nd.medical_condition.length > 3
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  yes
                </label>
                <label className="radio-label ms-5 ">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    value="No"
                    name="medical_condition"
                    checked={props.casepaperdata2nd.medical_condition === "No"}
                  />{" "}
                  <span className="custom-radio"></span>
                  no
                </label>
              </div>
            </div>
            {props.casepaperdata2nd.medical_condition != "Yes" &&
            props.casepaperdata2nd.medical_condition != "No" ? (
              <textarea
                className="msg"
                value={props.casepaperdata2nd?.medical_condition}
              ></textarea>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex flex-column justify-content-between">
            <div className="que1">
              2. Have you been prescribed any regular medication
              <span className="subq">(If Yes,Describe in Details)?</span>
              <div className="radio-button-container">
                <label className="radio-label">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answersec2c"
                    value="Yes"
                    checked={
                      props.casepaperdata2nd.regular_medication == "Yes" ||
                      props.casepaperdata2nd.regular_medication.length > 3
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  yes
                </label>
                <label className="radio-label ms-5 ">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answersec2c"
                    value="No"
                    checked={props.casepaperdata2nd.regular_medication == "No"}
                  />{" "}
                  <span className="custom-radio"></span>
                  no
                </label>
              </div>
            </div>
            {props.casepaperdata2nd.regular_medication != "Yes" &&
            props.casepaperdata2nd.regular_medication != "No" ? (
              <textarea
                className="msg"
                value={props.casepaperdata2nd?.regular_medication}
              ></textarea>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex flex-column justify-content-between">
            <div className="que1">
              3. Do you have any allergies
              <span className="subq">(If Yes,Describe in Details)?</span>
              <div className="radio-button-container">
                <label className="radio-label">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answersec2b"
                    value="Yes"
                    checked={
                      props.casepaperdata2nd?.allergies == "Yes" ||
                      props.casepaperdata2nd?.allergies.length > 3
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  yes
                </label>
                <label className="radio-label ms-5 ">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answersec2b"
                    value="No"
                    checked={props.casepaperdata2nd?.allergies == "No"}
                  />{" "}
                  <span className="custom-radio"></span>
                  no
                </label>
              </div>
            </div>
            {props.casepaperdata2nd?.allergies != "Yes" &&
            props.casepaperdata2nd?.allergies != "No" ? (
              <textarea
                className="msg"
                value={props.casepaperdata2nd?.allergies}
              ></textarea>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex flex-column justify-content-between">
            <div className="que1">
              4. Do you smoke or consume alcohol frequently?
              <div className="radio-button-container">
                <label className="radio-label">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answersec24"
                    value="Yes"
                    checked={
                      props.casepaperdata2nd?.smoke_alcohol_habit === "Yes" ||
                      props.casepaperdata2nd?.smoke_alcohol_habit.length > 3
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  yes
                </label>
                <label className="radio-label ms-5 ">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answersec24"
                    value="No"
                    checked={
                      props.casepaperdata2nd?.smoke_alcohol_habit === "No"
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  no
                </label>
                <label className="radio-label ms-5 ">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answersec24"
                    value="Never"
                    checked={
                      props.casepaperdata2nd?.smoke_alcohol_habit === "Never"
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  never
                </label>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column justify-content-between">
            <div className="que1">
              5. Having any Health problems in your family, such as heart
              disease or genetic conditions
              <span className="subq">(If Yes,Describe in Details)?</span>
              <div className="radio-button-container">
                <label className="radio-label">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answersec25"
                    value="Yes"
                    checked={
                      props.casepaperdata2nd?.family_health_problems ===
                        "Yes" ||
                      props.casepaperdata2nd?.family_health_problems.length > 3
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  yes
                </label>
                <label className="radio-label ms-5 ">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answersec25"
                    value="No"
                    checked={
                      props.casepaperdata2nd?.family_health_problems === "No"
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  no
                </label>
              </div>
            </div>
            {props.casepaperdata2nd?.family_health_problems != "Yes" &&
            props.casepaperdata2nd?.family_health_problems != "No" ? (
              <textarea
                className="msg"
                value={props.casepaperdata2nd?.family_health_problems}
              ></textarea>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex flex-column justify-content-between">
            <div className="que1">
              6. Have you gone through any type of surgeries previously or
              currently
              <span className="subq">(If Yes,Describe in Details)?</span>
              <div className="radio-button-container">
                <label className="radio-label">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answersec26"
                    value="Yes"
                    checked={
                      props.casepaperdata2nd?.surgeries_history === "Yes" ||
                      props.casepaperdata2nd?.surgeries_history.length > 3
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  yes
                </label>
                <label className="radio-label ms-5 ">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answersec26"
                    value="No"
                    checked={props.casepaperdata2nd?.surgeries_history === "No"}
                  />{" "}
                  <span className="custom-radio"></span>
                  no
                </label>
              </div>
            </div>
            {props.casepaperdata2nd?.surgeries_history != "Yes" &&
            props.casepaperdata2nd?.surgeries_history != "No" ? (
              <textarea
                className="msg"
                value={props.casepaperdata2nd?.surgeries_history}
              ></textarea>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex flex-column justify-content-between">
            <div className="que1">
              7. Any serious illnesses you have had
              <span className="subq">(If Yes,Describe in Details)?</span>
              <div className="radio-button-container">
                <label className="radio-label">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answersec27"
                    value="Yes"
                    checked={
                      props.casepaperdata2nd?.serious_illness === "Yes" ||
                      props.casepaperdata2nd?.serious_illness.length > 3
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  yes
                </label>
                <label className="radio-label ms-5 ">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answersec27"
                    value="No"
                    checked={props.casepaperdata2nd?.serious_illness === "No"}
                  />{" "}
                  <span className="custom-radio"></span>
                  no
                </label>
              </div>
            </div>
            {props.casepaperdata2nd?.serious_illness != "Yes" &&
            props.casepaperdata2nd?.serious_illness != "No" ? (
              <textarea
                className="msg"
                value={props.casepaperdata2nd?.serious_illness}
              ></textarea>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex flex-column justify-content-between">
            <div className="que1">
              8. Any medicines you take regularly (This includes prescription
              and over-the-counter medicines, vitamins, and supplements)?
            </div>
            <textarea
              className="msg"
              value={props.casepaperdata2nd?.regular_medications}
            ></textarea>
          </div>
          <div className="d-flex flex-column justify-content-between">
            <div className="que">
              9. Do you currently have or have ever had sexually transmitted
              infections, such as gonorrhea, chlamydia, trichomonas, herpes
              simplex, syphilis, HIV or genital warts
              <span className="subq">(If Yes,Describe in Details)?</span>
              <div className="radio-button-container">
                <label className="radio-label">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answersec29"
                    value="Yes"
                    checked={
                      props.casepaperdata2nd?.sexual_infection === "Yes" ||
                      props.casepaperdata2nd?.sexual_infection.length > 3
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  yes
                </label>
                <label className="radio-label ms-5 ">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answersec29"
                    value="No"
                    checked={props.casepaperdata2nd?.sexual_infection === "No"}
                  />{" "}
                  <span className="custom-radio"></span>
                  no
                </label>
              </div>
            </div>
            {props.casepaperdata2nd?.sexual_infection != "Yes" &&
            props.casepaperdata2nd?.sexual_infection != "No" ? (
              <textarea
                className="msg"
                value={props.casepaperdata2nd?.sexual_infection}
              ></textarea>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex flex-column justify-content-between">
            <div className="que1">
              10. Do you suffer currently or previously with any of diabetes,
              lupus, high blood pressure, lung disease or heart disease
              <span className="subq">(If Yes,Describe in Details)?</span>
              <div className="radio-button-container">
                <label className="radio-label">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answersec210"
                    value="Yes"
                    checked={
                      props.casepaperdata2nd
                        ?.personal_family_medical_history === "Yes" ||
                      props.casepaperdata2nd?.personal_family_medical_history
                        .length > 3
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  yes
                </label>
                <label className="radio-label ms-5 ">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answersec210"
                    value="No"
                    checked={
                      props.casepaperdata2nd
                        ?.personal_family_medical_history === "No"
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  no
                </label>
              </div>
            </div>
            {props.casepaperdata2nd?.personal_family_medical_history != "Yes" &&
            props.casepaperdata2nd?.personal_family_medical_history != "No" ? (
              <textarea
                className="msg"
                value={props.casepaperdata2nd?.personal_family_medical_history}
              ></textarea>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex flex-column justify-content-between">
            <div className="que1">
              11. Do you have a family history of diabetes?
              <span className="subq">(If Yes,Describe in Details)?</span>
              <div className="radio-button-container">
                <label className="radio-label">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answersec211"
                    value="Yes"
                    checked={
                      props.casepaperdata2nd?.family_history_diabetes ===
                        "Yes" ||
                      props.casepaperdata2nd?.family_history_diabetes?.length >
                        3
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  yes
                </label>
                <label className="radio-label ms-5 ">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answersec211"
                    value="No"
                    checked={
                      props.casepaperdata2nd?.family_history_diabetes === "No"
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  no
                </label>
              </div>
            </div>
            {props.casepaperdata2nd?.family_history_diabetes != "Yes" &&
            props.casepaperdata2nd?.family_history_diabetes != "No" ? (
              <textarea
                className="msg"
                value={props.casepaperdata2nd?.family_history_diabetes}
              ></textarea>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex flex-column justify-content-between">
            <div className="que1">
              12. Do you have a family history of high blood pressure
              <span className="subq">(If Yes,Describe in Details)?</span>
              <div className="radio-button-container">
                <label className="radio-label">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answersec212"
                    value="Yes"
                    checked={
                      props.casepaperdata2nd?.family_history_hbp === "Yes" ||
                      props.casepaperdata2nd?.family_history_hbp.length > 3
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  yes
                </label>
                <label className="radio-label ms-5 ">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answersec212"
                    value="No"
                    checked={
                      props.casepaperdata2nd?.family_history_hbp === "No"
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  no
                </label>
              </div>
            </div>
            {props.casepaperdata2nd?.family_history_hbp != "Yes" &&
            props.casepaperdata2nd?.family_history_hbp != "No" ? (
              <textarea
                className="msg"
                value={props.casepaperdata2nd?.family_history_hbp}
              ></textarea>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex flex-column justify-content-between">
            <div className="que1">
              13. Do you have a history of blood transfusions and trauma or
              violence
              <span className="subq">(If Yes,Describe in Details)?</span>
              <div className="radio-button-container">
                <label className="radio-label">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answersec213"
                    value="Yes"
                    checked={
                      props.casepaperdata2nd.blood_history === "Yes" ||
                      props.casepaperdata2nd.blood_history?.length > 3
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  yes
                </label>
                <label className="radio-label ms-5 ">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answersec213"
                    value="No"
                    checked={props.casepaperdata2nd.blood_history === "No"}
                  />{" "}
                  <span className="custom-radio"></span>
                  no
                </label>
              </div>
            </div>
            {props.casepaperdata2nd.blood_history != "Yes" &&
            props.casepaperdata2nd.blood_history != "No" ? (
              <textarea
                className="msg"
                value={props.casepaperdata2nd?.blood_history}
              ></textarea>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex flex-column justify-content-between">
            <div className="que1">
              14. Does your family have a predisposition to certain genetic
              conditions
              <span className="subq">(If Yes,Describe in Details)?</span>
              <div className="radio-button-container">
                <label className="radio-label">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answersec214"
                    value="Yes"
                    checked={
                      props.casepaperdata2nd?.genetic_condition_family ===
                        "Yes" ||
                      props.casepaperdata2nd?.genetic_condition_family.length >
                        3
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  yes
                </label>
                <label className="radio-label ms-5 ">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answersec214"
                    value="No"
                    checked={
                      props.casepaperdata2nd?.genetic_condition_family === "No"
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  no
                </label>
              </div>
            </div>
            {props.casepaperdata2nd?.genetic_condition_family != "Yes" &&
            props.casepaperdata2nd?.genetic_condition_family != "No" ? (
              <textarea
                className="msg"
                value={props.casepaperdata2nd?.genetic_condition_family}
              ></textarea>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex flex-column justify-content-between">
            <div className="que1">
              15. Having any current or past mental health issues, such as
              depression
              <span className="subq">(If Yes,Describe in Details)?</span>
              <div className="radio-button-container">
                <label className="radio-label">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answersec215"
                    value="Yes"
                    checked={
                      props.casepaperdata2nd?.mental_health_history === "Yes" ||
                      props.casepaperdata2nd?.mental_health_history.length > 3
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  yes
                </label>
                <label className="radio-label ms-5 ">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answersec215"
                    value="No"
                    checked={
                      props.casepaperdata2nd?.mental_health_history === "No"
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  no
                </label>
              </div>
            </div>
            {props.casepaperdata2nd?.mental_health_history != "Yes" &&
            props.casepaperdata2nd?.mental_health_history != "No" ? (
              <textarea
                className="msg"
                value={props.casepaperdata2nd?.mental_health_history}
              ></textarea>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex flex-column justify-content-between">
            <div className="que1">
              16. Have you ever had abnormal Pap smear results (The Pap test or
              Pap smear looks for precancers, cell changes on the cervix that
              might become cervical cancer)?
              <span className="subq">(If Yes,Describe in Details)</span>
              <div className="radio-button-container">
                <label className="radio-label">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answersec216"
                    value="Yes"
                    checked={
                      props.casepaperdata2nd?.abnormal_pap_smear_history ===
                        "Yes" ||
                      props.casepaperdata2nd?.abnormal_pap_smear_history
                        ?.length > 2
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  yes
                </label>
                <label className="radio-label ms-5 ">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answersec216"
                    value="No"
                    checked={
                      props.casepaperdata2nd?.abnormal_pap_smear_history ===
                      "No"
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  no
                </label>
                <label className="radio-label ms-5 ">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answersec216"
                    value="never"
                    checked={
                      props.casepaperdata2nd?.abnormal_pap_smear_history ==
                      ("never" || "Never")
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  never done test for this
                </label>
              </div>
            </div>
            {props.casepaperdata2nd?.abnormal_pap_smear_history != "Yes" &&
            props.casepaperdata2nd?.abnormal_pap_smear_history != "No" ? (
              <textarea
                className="msg"
                value={props.casepaperdata2nd?.abnormal_pap_smear_history}
              ></textarea>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex flex-column justify-content-between">
            <div className="que1">
              17. Do you have had any other personal and familial medical
              problems or history?
              <span className="subq">(If Yes,Describe in Details)?</span>
              <div className="radio-button-container">
                <label className="radio-label">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answersec217"
                    value="Yes"
                    checked={
                      props.casepaperdata2nd?.chronic_conditions === "Yes" ||
                      props.casepaperdata2nd?.chronic_conditions.length > 3
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  yes
                </label>
                <label className="radio-label ms-5 ">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answersec217"
                    value="No"
                    checked={
                      props.casepaperdata2nd?.chronic_conditions === "No"
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  no
                </label>
              </div>
            </div>
            {props.casepaperdata2nd?.chronic_conditions != "Yes" &&
            props.casepaperdata2nd?.chronic_conditions != "No" ? (
              <textarea
                className="msg"
                value={props.casepaperdata2nd?.chronic_conditions}
              ></textarea>
            ) : (
              ""
            )}
          </div>
          <div className="text-center mt-4">
            <button
              onClick={gotopage3}
              style={{
                height: "45px",
                width: "300px",
                fontSize: "15px",
                fontWeight: "400",
                border: "1px solid #F0588B",
                borderRadius: "5px",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#F0588B"; // Change background color on hover
                e.target.style.color = "white"; // Change text color on hover
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "white"; // Restore background color
                e.target.style.color = "#7C9CBF"; // Restore text color
              }}
            >
              NEXT
            </button>
          </div>
        </div>
      ) : gotopg3 == true ? (
        <>
          <div className="ps-2 pt-5">
            <span className="cursor" onClick={backtopg2}>
              Back
            </span>
            <div
              style={{
                color: "#F0588B",
                fontSize: "24px",
                fontWeight: "600",
              }}
            >
              Reproductive and menstrual history
            </div>
            <div className="d-flex justify-content-between">
              <div className="que">
                1. What was your age when you had your first period?
              </div>
              <textarea
                className="box"
                value={props.casepaperdata3rd?.age_at_first_period}
              ></textarea>
            </div>
            <div className="d-flex flex-column">
              <div className="que">2. Whether your cycles are regular?</div>
              <div className="radio-button-container ">
                <label className="radio-label ">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answer31"
                    value="Yes"
                    checked={props.casepaperdata3rd?.regular_cycles == "Yes"}
                  />{" "}
                  <span className="custom-radio"></span>
                  Yes
                </label>

                <label className="radio-label ms-5">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answer31"
                    value="No"
                    checked={props.casepaperdata3rd?.regular_cycles == "No"}
                  />{" "}
                  <span className="custom-radio"></span>
                  No
                </label>
                <label className="radio-label ms-5">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answer31"
                    value="sometimes regular"
                    checked={
                      props.casepaperdata3rd?.regular_cycles ==
                      "sometimes regular"
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  sometimes regular
                </label>

                <label className="radio-label ms-5">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answer31"
                    value="sometimes irregular"
                    checked={
                      props.casepaperdata3rd?.regular_cycles ==
                      "sometimes irregular"
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  sometimes irregular
                </label>
              </div>
            </div>
            <div className="d-flex flex-column justify-content-between">
              <div className="que">
                3. Having Any previous pregnancies, abortions, miscarriages, or
                stillbirths
                <span className="subq">(If Yes,Describe in Details)?</span>
                <div className="radio-button-container">
                  <label className="radio-label">
                    <input
                      style={{ display: "none" }}
                      type="radio"
                      name="answer33"
                      value="Yes"
                      checked={
                        props.casepaperdata3rd?.pregnancy_history === "Yes"
                      }
                    />{" "}
                    <span className="custom-radio"></span>
                    yes
                  </label>
                  <label className="radio-label ms-5 ">
                    <input
                      style={{ display: "none" }}
                      type="radio"
                      name="answer33"
                      value="No"
                      checked={
                        props.casepaperdata3rd?.pregnancy_history === "No"
                      }
                    />{" "}
                    <span className="custom-radio"></span>
                    no
                  </label>
                </div>
              </div>
              {props.casepaperdata3rd?.pregnancy_history != "Yes" &&
              props.casepaperdata3rd?.pregnancy_history != "No" ? (
                <textarea
                  className="msg"
                  value={props.casepaperdata3rd?.pregnancy_history}
                ></textarea>
              ) : (
                ""
              )}
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="que">
                4. If having any previous pregnancies, including miscarriages
                and abortions then what is the length of pregnancy, including
                the number of weeks at which the baby was delivered?
              </div>
              <textarea
                className="msg"
                value={props.casepaperdata3rd?.pregnancy_length}
              ></textarea>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="que">
                5. If having any previous pregnancies, including miscarriages
                and abortions then which is the method of delivery?
              </div>
              <textarea
                className="msg"
                value={props.casepaperdata3rd?.delivery_method}
              ></textarea>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="que">
                6. If having any previous pregnancies, including miscarriages
                and abortions then what is the birth weight of the baby?
              </div>
              <textarea
                className="msg"
                value={props.casepaperdata3rd?.baby_birth_weight}
              ></textarea>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="que">
                7. If having any previous pregnancies, including miscarriages
                and abortions then which type of anesthesia or analgesia used?
              </div>
              <textarea
                className="msg"
                value={props.casepaperdata3rd?.anesthesia_analgesia}
              ></textarea>
            </div>
            <div className="d-flex flex-column justify-content-between">
              <div className="que">
                8. If having any previous pregnancies, including miscarriages
                and abortions then is there occurrence of any infections, blood
                pressure problems, or bleeding complications
                <span className="subq">(If Yes,Describe in Details)?</span>
                <div className="radio-button-container">
                  <label className="radio-label">
                    <input
                      style={{ display: "none" }}
                      type="radio"
                      name="answer38"
                      value="Yes"
                      checked={
                        props.casepaperdata3rd?.pregnancy_complications ===
                        "Yes"
                      }
                    />{" "}
                    <span className="custom-radio"></span>
                    yes
                  </label>
                  <label className="radio-label ms-5 ">
                    <input
                      style={{ display: "none" }}
                      type="radio"
                      name="answer38"
                      value="No"
                      checked={
                        props.casepaperdata3rd?.pregnancy_complications === "No"
                      }
                    />{" "}
                    <span className="custom-radio"></span>
                    no
                  </label>
                </div>
              </div>
              {props.casepaperdata3rd?.pregnancy_complications == "Yes" &&
              props.casepaperdata3rd?.pregnancy_complications != "No" ? (
                <textarea
                  className="msg"
                  value={props.casepaperdata3rd?.pregnancy_complications}
                ></textarea>
              ) : (
                ""
              )}
            </div>
            <div className="d-flex flex-column justify-content-between">
              <div className="que">
                9. What are the Problems with previous pregnancies
                <span className="subq">
                  (If having any, Please mention in details)?
                </span>
                <div className="radio-button-container">
                  <label className="radio-label">
                    <input
                      style={{ display: "none" }}
                      type="radio"
                      name="answer39"
                      value="Yes"
                      checked={
                        props.casepaperdata3rd?.pregnancy_problems === "Yes" ||
                        props.casepaperdata3rd?.pregnancy_problems.length > 2
                      }
                    />{" "}
                    <span className="custom-radio"></span>
                    yes
                  </label>
                  <label className="radio-label ms-5 ">
                    <input
                      style={{ display: "none" }}
                      type="radio"
                      name="answer39"
                      value="No"
                      checked={
                        props.casepaperdata3rd?.pregnancy_problems === "No"
                      }
                    />{" "}
                    <span className="custom-radio"></span>
                    no
                  </label>
                </div>
              </div>
              {props.casepaperdata3rd?.pregnancy_problems != "Yes" &&
              props.casepaperdata3rd?.pregnancy_problems != "No" ? (
                <textarea
                  className="msg"
                  value={props.casepaperdata3rd?.pregnancy_problems}
                ></textarea>
              ) : (
                ""
              )}
            </div>
            <div className="d-flex flex-column justify-content-between">
              <div className="que">
                10. Having any problems with your reproductive organs (If having
                any, Please mention in details)?
                <span className="subq">
                  (If having any, Please mention in details)?
                </span>
                <div className="radio-button-container">
                  <label className="radio-label">
                    <input
                      style={{ display: "none" }}
                      type="radio"
                      name="answer310"
                      value="Yes"
                      checked={
                        props.casepaperdata3rd?.repro_organ_problems == "Yes" ||
                        props.casepaperdata3rd?.repro_organ_problems.length > 2
                      }
                    />{" "}
                    <span className="custom-radio"></span>
                    yes
                  </label>
                  <label className="radio-label ms-5 ">
                    <input
                      style={{ display: "none" }}
                      type="radio"
                      name="answer310"
                      value="No"
                      checked={
                        props.casepaperdata3rd?.repro_organ_problems == "No"
                      }
                    />{" "}
                    <span className="custom-radio"></span>
                    no
                  </label>
                </div>
              </div>
              {props.casepaperdata3rd?.repro_organ_problems != "Yes" &&
              props.casepaperdata3rd?.repro_organ_problems != "No" ? (
                <textarea
                  className="msg"
                  value={props.casepaperdata3rd?.repro_organ_problems}
                ></textarea>
              ) : (
                ""
              )}
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="que">
                11. When was your last menstrual period?
              </div>
              <textarea
                type="date"
                className="msg"
                value={moment(props.casepaperdata3rd?.last_period_date).format(
                  "DD-MM-YYYY",
                )}
                // dateFormat="yyyy-mm-dd"
              ></textarea>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="que">
                12. How regular was your menstrual cycle (Mention the average
                gap in number of days between two periods) ?
              </div>
              <textarea
                type="number"
                className="msg"
                value={props.casepaperdata3rd?.cycle_regularity}
              ></textarea>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="que">
                13. What is the length of previous labour if having any?
              </div>
              <textarea
                type="number"
                className="msg"
                value={props.casepaperdata3rd?.labour_length}
              ></textarea>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="que">
                14. Nature of previous delivery if having any? (Type in
                question)
              </div>
              <textarea
                type="text"
                className="msg"
                value={props.casepaperdata3rd?.delivery_nature}
              ></textarea>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="que">
                15.Number of previous term deliveries (after 37 weeks) if having
                any?
              </div>
              <textarea
                type="number"
                className="msg"
                value={props.casepaperdata3rd?.term_deliveries}
              ></textarea>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="que">
                16.Number of previous premature deliveries (greater than 20 and
                less than 37 weeks) if having any?
              </div>
              <textarea
                type="number"
                className="msg"
                value={props.casepaperdata3rd?.premature_deliveries}
              ></textarea>
            </div>{" "}
            <div className="d-flex justify-content-between align-items-center">
              <div className="que">17.Number of abortions if having any?</div>
              <textarea
                type="number"
                className="msg"
                value={props.casepaperdata3rd?.abortions}
              ></textarea>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="que">
                18. Number of living children if having any?
              </div>
              <textarea
                // type="number"
                className="msg"
                defaultvalue={props.casepaperdata3rd?.living_children}
              ></textarea>
            </div>
            <div className="d-flex flex-column justify-content-between pb-2">
              <div className="que">
                19. Having any other reproductive or menstrual problems or
                history
                <span className="subq">
                  (If Yes, Please Mention them in Details)?
                </span>
                <div className="radio-button-container">
                  <label className="radio-label">
                    <input
                      style={{ display: "none" }}
                      type="radio"
                      name="answer10last"
                      value="Yes"
                      checked={
                        props.casepaperdata3rd?.repro_menustral_problem ===
                          "Yes" ||
                        props.casepaperdata3rd?.repro_menustral_problem.length >
                          2
                      }
                    />{" "}
                    <span className="custom-radio"></span>
                    yes
                  </label>
                  <label className="radio-label ms-5 ">
                    <input
                      style={{ display: "none" }}
                      type="radio"
                      name="answer10last"
                      value="No"
                      checked={
                        props.casepaperdata3rd?.repro_menustral_problem === "No"
                      }
                    />{" "}
                    <span className="custom-radio"></span>
                    no
                  </label>
                </div>
              </div>
              {props.casepaperdata3rd?.repro_menustral_problem != "Yes" &&
              props.casepaperdata3rd?.repro_menustral_problem != "No" ? (
                <textarea
                  className="msg"
                  value={props.casepaperdata3rd?.repro_menustral_problem}
                ></textarea>
              ) : (
                ""
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="pt-5 ps-3">
            <div
              style={{
                color: "#F0588B",
                fontSize: "24px",
                fontWeight: "600",
              }}
            >
              General lifestyle habits & History
            </div>
            <div className="d-flex justify-content-between my-1">
              <div className="que">1. What is Your height ?</div>
              <div
                style={{
                  marginRight: "60px",
                  display: "flex",
                  alignItems: "center",
                  width: "15%",
                }}
              >
                <div className="boxin">{props.casepaperdata1st.height}</div>
                <span className="ps-2">cm</span>
              </div>
            </div>
            <div className="d-flex justify-content-between my-1">
              <div className="que">2. What is Your Age ?</div>
              <div
                style={{
                  marginRight: "60px",
                  display: "flex",
                  alignItems: "center",
                  width: "15%",
                }}
              >
                <div className="boxin">{props.casepaperdata1st.age}</div>
                <span className="ps-2">year</span>
              </div>
            </div>
            <div className="d-flex justify-content-between my-1">
              <div className="que">3. What is Your weight ?</div>
              <div
                style={{
                  marginRight: "60px",
                  display: "flex",
                  alignItems: "center",
                  width: "15%",
                }}
              >
                <div className="boxin">{props.casepaperdata1st.weight}</div>
                <span className="ps-2">Kg</span>
              </div>
            </div>
            <div className="d-flex">
              <div className="que">
                4. Are you addicted to any habits that could affect your
                pregnancy, such as tobacco, alcohol, or drug use?
              </div>
              <div className="radio-button-container ms-5">
                <label className="radio-label ms-5">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answer"
                    value="Yes"
                    checked={props.casepaperdata1st.addiction === "Yes"}
                  />{" "}
                  <span className="custom-radio"></span>
                  Yes
                </label>
                <label className="radio-label ms-5 ">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answer"
                    value="No"
                    checked={props.casepaperdata1st.addiction === "No"}
                  />{" "}
                  <span className="custom-radio"></span>
                  No
                </label>
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="que">
                5. How frequently do you consume tobacco, alcohol, or drugs ?
              </div>
              <div className="radio-button-container">
                <label className="radio-label">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answer4"
                    value="Daily"
                    checked={
                      props.casepaperdata1st.addtiction_consumption === "Daily"
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  Daily
                </label>
                <label className="radio-label ms-5 ">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answer4"
                    value="Weekly"
                    checked={
                      props.casepaperdata1st.addtiction_consumption === "Weekly"
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  Weekly
                </label>
                <label className="radio-label ms-5 ">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answer4"
                    value="monthly"
                    checked={
                      props.casepaperdata1st.addtiction_consumption ===
                      "monthly"
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  monthly
                </label>
                <label className="radio-label ms-5 ">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answer4"
                    value="occasionally"
                    checked={
                      props.casepaperdata1st.addtiction_consumption ===
                      "occasionally"
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  occasionally
                </label>
                <label className="radio-label ms-5 ">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answer4"
                    value="never"
                    checked={
                      props.casepaperdata1st.addtiction_consumption === "never"
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  never
                </label>
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="que">6. How were your exercise habits? </div>
              <div className="radio-button-container">
                <label className="radio-label">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answer6"
                    value="Daily"
                    checked={props.casepaperdata1st.exercise_habit === "Daily"}
                  />{" "}
                  <span className="custom-radio"></span>
                  Daily
                </label>
                <label className="radio-label ms-5 ">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answer6"
                    value="Rarely"
                    checked={props.casepaperdata1st.exercise_habit === "Rarely"}
                  />{" "}
                  <span className="custom-radio"></span>
                  Rarely
                </label>
                <label className="radio-label ms-5 ">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answer6"
                    value="Never"
                    checked={props.casepaperdata1st.exercise_habit === "Never"}
                  />{" "}
                  <span className="custom-radio"></span>
                  Never
                </label>
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="que">7. How was your sexual life? </div>
              <div className="radio-button-container">
                <label className="radio-label">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answer7"
                    value="Great"
                    checked={props.casepaperdata1st.sexual_life === "Great"}
                  />{" "}
                  <span className="custom-radio"></span>
                  Great
                </label>
                <label className="radio-label ms-5 ">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answer7"
                    value="satisfied"
                    checked={props.casepaperdata1st.sexual_life === "satisfied"}
                  />{" "}
                  <span className="custom-radio"></span>
                  satisfied
                </label>
                <label className="radio-label ms-5 ">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answer7"
                    value="not satisfied"
                    checked={
                      props.casepaperdata1st.sexual_life === "not satisfied"
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  not satisfied
                </label>
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="que">8. What is your frequency of sex? </div>
              <div className="radio-button-container">
                <label className="radio-label">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answer8"
                    value="Daily"
                    checked={props.casepaperdata1st.sex_frequency === "Daily"}
                  />{" "}
                  <span className="custom-radio"></span>
                  Daily
                </label>
                <label className="radio-label ms-5 ">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answer8"
                    value="Once a week"
                    checked={
                      props.casepaperdata1st.sex_frequency === "Once a week"
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  Once a week
                </label>
                <label className="radio-label ms-5 ">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answer8"
                    value="once a month"
                    checked={
                      props.casepaperdata1st.sex_frequency === "once a month"
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  once a month
                </label>
                <label className="radio-label ms-5 ">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answer8"
                    value="Rarely"
                    checked={props.casepaperdata1st.sex_frequency === "Rarely"}
                  />{" "}
                  <span className="custom-radio"></span>
                  Rarely
                </label>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="que">
                9. Any dental care you have taken previously{" "}
                <span className="subq">(If Yes,Describe in Details)?</span>
                <div className="radio-button-container">
                  <label className="radio-label">
                    <input
                      style={{ display: "none" }}
                      type="radio"
                      name="answer9a"
                      value="Yes"
                      checked={
                        props.casepaperdata1st.dental_care == "Yes" ||
                        props.casepaperdata1st.dental_care.length > 3
                      }
                    />{" "}
                    <span className="custom-radio"></span>
                    yes
                  </label>
                  <label className="radio-label ms-5 ">
                    <input
                      style={{ display: "none" }}
                      type="radio"
                      name="answer9a"
                      value="No"
                      checked={props.casepaperdata1st.dental_care == "No"}
                    />{" "}
                    <span className="custom-radio"></span>
                    no
                  </label>
                </div>
              </div>
              {props.casepaperdata1st.dental_care != "Yes" &&
                props.casepaperdata1st.dental_care != "No" && (
                  <textarea
                    className="msg"
                    value={props.casepaperdata1st?.dental_care}
                  ></textarea>
                )}
            </div>
            <div className="d-flex flex-column justify-content-between">
              <div className="que">
                10. Have you taken any vaccinations previously{" "}
                <span className="subq">
                  (If Yes, Please Mention them in Details)?
                </span>
                <div className="radio-button-container">
                  <label className="radio-label">
                    <input
                      style={{ display: "none" }}
                      type="radio"
                      name="answer10a"
                      value="Yes"
                      checked={
                        props.casepaperdata1st.vaccinations == "Yes" ||
                        props.casepaperdata1st.vaccinations.length > 2
                      }
                    />{" "}
                    <span className="custom-radio"></span>
                    yes
                  </label>
                  <label className="radio-label ms-5 ">
                    <input
                      style={{ display: "none" }}
                      type="radio"
                      name="answer10a"
                      value="No"
                      checked={props.casepaperdata1st.vaccinations == "No"}
                    />{" "}
                    <span className="custom-radio"></span>
                    no
                  </label>
                </div>
              </div>
              {props.casepaperdata1st.vaccinations != "Yes" &&
                props.casepaperdata1st.vaccinations != "No" && (
                  <textarea
                    className="msg"
                    value={props.casepaperdata1st?.vaccinations}
                  ></textarea>
                )}
            </div>
            <div className="d-flex ">
              <div className="que">
                11. What are your general lifestyle habits?
              </div>
              <textarea
                className="msg"
                value={props.casepaperdata1st?.general_lifestyle_habits}
              ></textarea>
            </div>
            <div className="d-flex">
              <div className="que">
                12. Do you have any other lifestyle issues?
              </div>
              <textarea
                className="msg"
                value={props.casepaperdata1st?.lifestyle_issues}
              ></textarea>
            </div>
            <div className="d-flex flex-column">
              <div className="que">13. How was your work environment? </div>
              <div className="radio-button-container">
                <label className="radio-label">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answer13"
                    value="great"
                    checked={
                      props.casepaperdata1st.work_enviornment === "great"
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  Great
                </label>
                <label className="radio-label ms-5 ">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answer13"
                    value="Average"
                    checked={
                      props.casepaperdata1st.work_enviornment === "Average"
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  Average
                </label>
                <label className="radio-label ms-5 ">
                  <input
                    style={{ display: "none" }}
                    type="radio"
                    name="answer13"
                    value="Depressive"
                    checked={
                      props.casepaperdata1st.work_enviornment === "Depressive"
                    }
                  />{" "}
                  <span className="custom-radio"></span>
                  Depressive
                </label>
              </div>
            </div>{" "}
            <div className="text-center mt-4">
              <button
                onClick={gotopage2}
                style={{
                  height: "45px",
                  width: "300px",
                  fontSize: "15px",
                  fontWeight: "400",
                  border: "1px solid #F0588B",
                  borderRadius: "5px",
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "#F0588B"; // Change background color on hover
                  e.target.style.color = "white"; // Change text color on hover
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "white"; // Restore background color
                  e.target.style.color = "#7C9CBF"; // Restore text color
                }}
              >
                NEXT
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Displaycasepaper;
