import React from "react";

const Handleevent = ({
  item,
  button_name,
  onClick,
  newItemData,
  d2,
  isTabScreen,
}) => {
  return (
    <button
      style={{
        width: "100px",
        height: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#004AAD",
        border: "1px solid rgba(0, 74, 173, 0.5)",
        fontSize: isTabScreen ? "8px" : "12px",
        color: "white",
        boxShadow: "0px 4px 8px rgba(44, 39, 56, 0.078)",
        borderRadius: "5px",
        cursor: "pointer",
      }}
      data-patient_id={item.patient_id}
      data-id={item.id}
      data-last_date={d2}
      data-item={newItemData}
      onClick={onClick}
    >
      {button_name}
    </button>
  );
};

export default Handleevent;
