import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import configData from "../config.json";
import EventComponent from "./EventComponent";
import EventTypeHeader from "./EventTypeHeader";
import NoEventBox from "./NoEventBox";
function RightBox({
  title,
  onClick,
  onClickDay,
  date,
  upcoming,
  dateClicked,
  onClick1,
  click,
  consultations,
  // consultationsDates,
  todayConsultaion,
  medical_expert_id,
}) {
  console.log("@#@#", upcoming, todayConsultaion);
  // if(date === ''){
  //   date = moment(moment().toDate()).format("Do MMMM YYYY");
  // }
  const [value, onChange] = useState([new Date()]);
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1200px)",
  });
  const consultationsDates = [
    moment().add(1, "days").format("YYYY-MM-DD"),
    moment().add(2, "days").format("YYYY-MM-DD"),
  ];
  return (
    <div
      style={{
        // borderTopRightRadius: 55,
        // borderBottomRightRadius: 55,
        alignSelf: "flex-end",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "30vw",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          marginTop: 50,
          fontSize: isTabScreen ? "24px" : "32px",
          fontWeight: "600",
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          color: configData.THEME_COLORS.SECONDARY,
          fontFamily: "Poppins",
        }}
      >
        {/* <div className="ps-3">
          <img src={"/lefticon.png"} onClick={onClick1} style={{height:"22px",width:"22px"}}></img>
        </div> */}
        <div className="text-center" style={{ marginTop: "-40px" }}>
          {title}
        </div>
      </div>
      <div style={{ marginTop: 20, alignSelf: "center" }}>
        <Calendar
          onChange={onChange}
          value={value}
          // tileClassName={() => {
          //   return "red_item";
          // }}
          tileClassName={({ date, view }) => {
            if (
              upcoming?.some(
                (item) =>
                  moment(item.scheduled_date).format("YYYY-MM-DD") ===
                  moment(date).format("YYYY-MM-DD"),
              )
            ) {
              return "highlight-date";
            }
          }}
          onClickDay={onClickDay}
        />
      </div>
      <div style={{ marginTop: 10, alignSelf: "center" }}>
        {todayConsultaion !== undefined && (
          <NoEventBox
            title={
              date === ""
                ? ""
                : moment(moment().toDate()).format("Do MMMM YYYY") == date &&
                    consultations?.length == 0
                  ? ""
                  : consultations?.length > 0 || todayConsultaion > 0
                    ? "Event On"
                    : "No Event On"
            }
            date={date}
            dateClicked={
              moment(moment().toDate()).format("Do MMMM YYYY") == date &&
              todayConsultaion > 0
            }
            isEventToday={todayConsultaion > 0}
            consultations={consultations}
          />
        )}
      </div>
      <div className="">
        {consultations &&
          consultations?.map((item) => (
            <EventComponent
              className=""
              doctorName={item?.first_name + " " + item?.last_name}
              doctorType={"Pediatrician"}
              title={"Online Consultation with Dr. Jane Doe"}
              scheduled={true}
              onClick={onClick}
              // eventDate={"March 14, 2022"}
              eventDate={moment(item?.scheduled_date).format("MMM DD, YYYY")}
              starttime={item?.timing} // No need to use moment here
              endtime={moment(item?.timing, "hh:mm A")
                .add(60, "minutes")
                .format("hh:mm a")}
              Age={item?.age}
              pregweek={item?.week}
              consultationno={item?.consultation_number}
            />
          ))}
      </div>
      <div
        style={{
          height: "80vh",
          overflow: "auto",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {upcoming?.length > 0 && <EventTypeHeader title={"Upcoming Event"} />}
        {upcoming &&
          upcoming?.map((item) => (
            <EventComponent
              doctorName={item?.first_name + " " + item?.last_name}
              doctorType={"Pediatrician"}
              title={"Online Consultation with Dr. Jane Doe"}
              scheduled={true}
              onClick={onClick}
              eventDate={moment(item?.scheduled_date).format("MMM DD, YYYY")}
              starttime={item?.timing}
              endtime={moment(item?.timing, "hh:mm A")
                .add(60, "minutes")
                .format("hh:mm a")}
              Age={item?.age}
              pregweek={item?.week}
              consultationno={item?.consultation_number}
            />
          ))}
      </div>
    </div>
  );
}

export default RightBox;
