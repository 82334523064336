import React from "react";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import configData from "../../config.json";
function Profilebox({ userData }) {
  console.log("<><><><><<<<><><><><><>", userData);
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  return (
    <div>
      <div
        style={{
          fontSize: isTabScreen ? "24px" : "32px",
          color: "#004AAD",
          fontFamily: "Poppins",
          fontWeight: "600",
          color: "#004AAD",
        }}
        className="py-2 ps-3"
      >
        Profile
      </div>
      <div className="text-center">
        <img
          alt="nt found"
          className="mx-auto"
          src={
            userData.item.profile_pic
              ? configData.API_URL + userData.item.profile_pic
              : "/Profile/Group-1151.png"
          }
          style={{
            borderRadius: "50%",
            height: isTabScreen ? "50px" : "100px",
            width: isTabScreen ? "50px" : "100px",
          }}
        ></img>
      </div>
      <div
        style={{
          width: "96%",
          margin: "0px auto",
          background: "rgba(232, 231, 231, 0.3)",
          border: "0.746766px solid #CBC6C6",
          borderRadius: "17.9224px",
        }}
        className="my-3"
      >
        <div
          className="pt-2 ps-3"
          style={{
            fontWeight: "600",
            fontSize: "24px",
            lineHeight: "36px",
            color: "#696969",
          }}
        >
          Personal Details
        </div>
        <div className="py-1 ps-4">
          <div style={{ lineHeight: "32px", color: "black" }}>
            Name:
            <span style={{ color: "#333333" }}>
              {userData?.item?.fname} {userData?.item?.lname}
            </span>
          </div>
          <div className="d-flex justify-content-between py-1">
            <div style={{ color: "black" }}>
              Age:{" "}
              <span style={{ color: "#333333" }}>{userData?.item?.age}</span>
            </div>
            <div className="pe-3">Gender: Female</div>
          </div>
          <div className="py-1" style={{ lineHeight: "32px", color: "black" }}>
            Patient ID: <span style={{ color: "#333333" }}>{userData?.id}</span>
          </div>
          <div className="py-1" style={{ lineHeight: "32px" }}>
            Date of Birth:{" "}
            <span style={{ color: "#333333" }}>
              {userData?.item?.dob
                ? moment(userData?.item?.dob).format("DD-MM-YYYY")
                : "Not given"}
            </span>
          </div>
        </div>

        <div
          className="pt-5 ps-3"
          style={{
            fontWeight: "600",
            fontSize: "24px",
            lineHeight: "36px",
            color: "#696969",
          }}
        >
          Contact Details
        </div>
        <div className="py-1 ps-4">
          <div style={{ lineHeight: "32px", color: "black" }}>
            Mobile:{" "}
            <span style={{ color: "#333333" }}>{userData.item.phone}</span>
          </div>
          <div className="py-1" style={{ lineHeight: "32px", color: "black" }}>
            Email:{" "}
            <span style={{ color: "#333333" }}> {userData.item.email}</span>
          </div>
          <div className="py-1" style={{ lineHeight: "32px", color: "black" }}>
            Address:{" "}
            <span style={{ color: "#333333" }}>
              {" "}
              {userData.item?.area_name}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profilebox;
