import React, { useState, useEffect } from "react";

const Enterotp = ({ verifyopt, handleprevious, handlegenerateotp }) => {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [timer, setTimer] = useState(120); // 2 minutes countdown in seconds
  const [isResendAvailable, setIsResendAvailable] = useState(false);

  // Start countdown timer
  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(countdown); // Cleanup interval on unmount
    } else {
      setIsResendAvailable(true); // Show resend button when timer reaches 0
    }
  }, [timer]);

  // Handle OTP input change
  const handleChange = (element, index) => {
    const value = element.value.slice(-1); // Only take the last character
    if (/^\d$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (element.nextSibling) {
        element.nextSibling.focus();
      }
    }
  };

  const handleKeyDown = (event, index) => {
    const newOtp = [...otp];
    if (event.key === "Backspace") {
      if (otp[index] !== "") {
        newOtp[index] = "";
        setOtp(newOtp);
      } else if (index > 0) {
        const prevInput = event.target.previousSibling;
        if (prevInput) {
          prevInput.focus();
          newOtp[index - 1] = ""; // Clear previous box
          setOtp(newOtp);
        }
      }
    }
  };

  // Handle paste event
  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData("Text").slice(0, 6);
    if (/^\d{6}$/.test(pasteData)) {
      setOtp(pasteData.split(""));
    }
  };

  // Reset timer and resend OTP
  const handleResend = () => {
    setOtp(new Array(6).fill(""));
    setTimer(120); // Reset timer to 2 minutes
    setIsResendAvailable(false);
    handlegenerateotp(); // Trigger resend OTP function
  };

  // Format timer display as MM:SS
  const formatTime = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <form
      style={{
        borderRadius: "12px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <h4 className="fw-bolder py-4">Verify OTP</h4>

      <div style={{ display: "flex", gap: "8px" }}>
        {otp.map((data, index) => (
          <input
            key={index}
            type="text"
            className="otp-input"
            style={{
              width: "40px",
              height: "45px",
              borderRadius: "8px",
              border: "1px solid black",
              textAlign: "center",
              fontSize: "20px",
            }}
            maxLength="1"
            value={otp[index]}
            onChange={(e) => handleChange(e.target, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onPaste={handlePaste}
          />
        ))}
      </div>

      <div className="mt-3">
        {isResendAvailable ? (
          <p
            onClick={handleResend}
            style={{
              color: "#ee5e8f",
              cursor: "pointer",
              fontWeight: "600",
            }}
          >
            Resend OTP
          </p>
        ) : (
          <p style={{ color: "#bac1cb" }}>Time Remaining: {formatTime()}</p>
        )}
      </div>

      <div className="d-flex align-items-center justify-content-end w-50 mx-auto">
        <button
          className="my-4 login"
          style={{
            padding: "10px 20px",
            borderRadius: "12px",
            backgroundColor: "#007bff",
            color: "white",
          }}
          onClick={(e) => {
            e.preventDefault();
            verifyopt(otp.join(""));
          }}
        >
          Verify OTP
        </button>
      </div>

      <div className="py-4" style={{ color: "#bac1cb" }}>
        Back to
        <span
          className="ps-2"
          style={{ color: "#ee5e8f", cursor: "pointer" }}
          onClick={handleprevious}
        >
          Login
        </span>
      </div>
    </form>
  );
};

export default Enterotp;
