export const downloadFile = (fileKey) => {
  api
    .downloadfile({
      fileKey: fileKey,
    })
    .then((data) => {
      const fileExtension = fileKey.split(".").pop().toLowerCase();
      const mimeType = {
        pdf: "application/pdf",
        jpg: "image/jpeg",
        jpeg: "image/jpeg",
        png: "image/png",
      }[fileExtension];

      if (!mimeType) {
        throw new Error("Unsupported file type");
      }
      const blob = new Blob([data], { type: mimeType });
      const fileUrl = URL.createObjectURL(blob);
      if (window.matchMedia("(max-width: 768px)").matches) {
        const fileContainer = document.createElement("div");
        fileContainer.style.position = "fixed";
        fileContainer.style.top = "0";
        fileContainer.style.left = "0";
        fileContainer.style.width = "100%";
        fileContainer.style.height = "100%";
        fileContainer.style.background = "rgba(0, 0, 0, 0.8)";
        fileContainer.style.zIndex = "9999";

        if (mimeType === "application/pdf") {
          fileContainer.innerHTML = `
            <iframe src="${fileUrl}" width="100%" height="100%" style="border: none; background: white;"></iframe>
          `;
        } else {
          fileContainer.innerHTML = `
            <img src="${fileUrl}" alt="Image Preview" style="display: block; max-width: 100%; max-height: 100%; margin: auto;">
          `;
        }

        fileContainer.innerHTML += `
          <button 
            style="position: absolute; top: 10px; right: 10px; padding: 10px; background: red; color: white; border: none; cursor: pointer; z-index: 10000;"
            onclick="this.parentNode.remove(); URL.revokeObjectURL('${fileUrl}');">
            Close
          </button>
        `;
        document.body.appendChild(fileContainer);
      } else {
        if (mimeType === "application/pdf") {
          const pdfWindow = window.open(fileUrl, "_blank");
          if (pdfWindow) {
            pdfWindow.focus();
          } else {
            console.error(
              "Failed to open PDF in a new tab. Check pop-up blockers."
            );
          }
        } else {
          const imageWindow = window.open(fileUrl, "_blank");
          if (imageWindow) {
            imageWindow.focus();
          } else {
            console.error(
              "Failed to open image in a new tab. Check pop-up blockers."
            );
          }
        }
      }
      setTimeout(() => URL.revokeObjectURL(fileUrl), 60000);
    })
    .catch((err) => {
      console.error("Error handling the file:", err);
    });
};
