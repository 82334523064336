import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer';
import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from 'react-router-dom';
function Cherishcare() {
   useEffect(() => {
     window.scrollTo(0, 0);
   }, []);
  const [userid, setUserid] = useState(0);
  useEffect(() => {
    const userIdValue = localStorage.getItem("userId");
    setUserid(userIdValue);
  }, [userid]);
  const navigate=useNavigate();
  return (
    <>
      <Header userid={userid} />

      <div className="container-fluid position-relative" style={{width:"100%",overflowX:"hidden"}}>
      <div className="newdesign">
      </div>
      <div className="row position-absolute top-0">
      <div className="col-md-6 order-md-2 d-none d-md-block" style={{ paddingLeft: '44px', paddingTop: '60px'}}>
      <img src="/elementalcareimg3.png" alt="nt found"></img>
        </div>
      <div className="col-md-6 order-md-1 pt-5 mt-5">
          <h1 className="text-dark  fw-bolder pt-3 mt-0 mt-md-5 ps-5 headlineheronew" style={{ fontSize: '38px', paddingBottom: '30px' }} >
          Cherish Care: The only care your pregnancy will ever need!
          </h1>
          <h1 className="ps-5 pt-3 fw-bolder headlinesubheronew" style={{ fontSize: '22px', paddingBottom: '20px'}} >
          From essential health checkups to gift hampers, we get you everything covered in one place with our very all-embracing Cherish Package. Delivers unbeatable care at an unbeatable price through an unbeatable pregnancy platform. This is the package your nine-month pregnancy will only need
          </h1>
          <Link style={{ cursor: "pointer", color: "white",fontSize:"28px" }}
                  to="/pricing"
                  className="mt-5 mx-auto mx-md-0 ms-md-5 explorepkg">
                  Buy Now
            </Link>
          {/* <Link
  style={{ 
    cursor: "pointer", 
    color: "white", 
    fontSize: "24px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "20px",
    paddingRight: "20px",
    marginTop: "5px",
    marginRight: "auto",
    marginLeft: "auto",
    marginLeft: "5px",
  }}
  to="/pricing"
  className="mt-5 mx-auto mx-md-0 ms-md-5 explorepkg">
  Buy Now
</Link> */}

        </div>
      </div>
    </div>
     

    

      <div className="container-fluid my-3">
        <div className="container">
          <div className="row pt-5">
            <div className="col-12 col-lg-6 text-center text-center d-none d-md-block" style={{ paddingLeft: '100px' }}>
              <img src="/e1.png" className="img-fluid" id="oneclick" alt='nt found' style={{height: '300px', width: '400px'}} />
            </div>
            <div className="col-12 col-lg-6 text-left">
              <h2 className="text-left text-capitalize pb-4 pt-5 fw-bolder" style={{ fontSize: '32px', paddingRight: '100px', paddingTop: '20px' }}>
                24x7 Availability At One Click
              </h2>
              <div className="text-center mb-3">
                <img
                  src="/e1.png"
                  className="img-fluid d-md-none"
                  id="mobileoneclick" alt='nt found'
                />
              </div>
              <p className="info1" style={{fontWeight: 'bold', paddingRight: '100px' }}>
                Getting too nauseous at midnight? Will your doctor see you this
                time? Not a problem, with our 24x7 availability and emergency
                care option, we cater to your need of the hour at a single
                click.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid my-3">
        <div className="container">
          <div className="row pt-5">
            <div className="col-12 col-lg-6 text-center order-lg-2 d-none d-md-block" style={{ paddingLeft: '150px' }}>
              <img src="/e2.png" className="img-fluid" id="sitback" alt='nt found' />
            </div>
            <div className="col-12 col-lg-6 text-left order-lg-1">
              <h2 className="text-left text-capitalize pb-4 pt-5 fw-bolder" style={{ fontSize: '32px', paddingLeft: '80px', paddingTop: '20px' }}>
                With Our Health Experts, Know More
              </h2>
              <div className="text-center d-md-none mb-3">
                <img src="/e2.png" className="img-fluid" id="mobilesitback" alt='nt found'/>
              </div>
              <p className="info1" style={{fontWeight: 'bold', paddingLeft: '80px', paddingTop: '20px' }}> 
                Wondering how many medical experts work on you and your baby's
                growth? We incorporate 10 medical experts accompanied by 48
                consultations under one roof.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid my-3">
        <div className="container pt-5">
          <div className="row pt-5">
            <div className="col-12 col-lg-6 text-center text-center d-none d-md-block" style={{ paddingLeft: '100px' }}>
              <img src="/e3.png" className="img-fluid" id="belowsitback" alt='nt found' />
            </div>
            <div className="col-12 col-lg-6 text-left pt-5">
              <h2 className="text-left text-capitalize pb-4 pt-5 fw-bolder" style={{ fontSize: '32px', paddingRight: '100px', paddingTop: '20px' }}>
                Sit Back, Every Activity Matters
              </h2>
              <div className="d-md-none text-center mb-3">
                <img src="/e3.png" className="img-fluid" id="mobilebelowsitback" alt='nt found' />
              </div>
              <p className="info1" style={{fontWeight: 'bold', paddingRight: '100px', paddingTop: '20px' }}>
                Sit Back, Every Activity Matters. During your crucial journey,
                every little activity you do affects the baby growing inside
                you. With our Garbh Sanskar (foetus education) program, dive
                deep into its details.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid my-3">
        <div className="container">
          <div className="row pt-5">
            <div className="col-12 col-lg-6 text-center text-center order-lg-2 d-none d-md-block">
              <img src="/e4.png" className="img-fluid" id="feelconnection" alt='nt found' />
            </div>
            <div className="col-12 col-lg-6 text-left pt-3 order-lg-1">
              <h2 className="text-left text-capitalize pb-4 pt-5 fw-bolder" style={{ fontSize: '32px', paddingLeft: '80px', paddingTop: '20px' }}>
                Feel a Connection
              </h2>
              <div className="text-center">
                <img
                  src="/e4.png"
                  className="img-fluid d-md-none mb-3"
                  id="mobilefeelconnection" alt="nt found"
                />
              </div>
              <p className="info1" style={{fontWeight: 'bold', paddingLeft: '80px', paddingTop: '20px' }}>
                With a single motto within tranarency between you and your
                doctor, we help you build your trust and feel a connection with
                your experts.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid my-3">
        <div className="container">
          <div className="row pt-5">
            <div className="col-12 col-lg-6 text-center d-none d-md-block" style={{ paddingLeft: '100px' }}>
              <img src="/e5.png" className="Relax" alt='nt found' />
            </div>
            <div className="col-12 col-lg-6 text-left pt-5">
              <h2 className="text-left text-capitalize pb-4 pt-5 fw-bolder" style={{ fontSize: '32px', paddingRight: '100px', paddingTop: '20px' }}>
                Relax, We Know Your Schedule
              </h2>
              <div className="text-center mb-3">
                <img src="/e5.png" className=" Rmobile d-md-none" alt='nt found' />
              </div>
              <p className="info1" style={{fontWeight: 'bold', paddingRight: '110px', paddingTop: '0px' }}>
                Leave the hassle of remembering the essential dates of
                consultancies, tests, scans, visits and more behind. Our fully
                automated remote dashboard remembers it all. Say goodbye to the
                hard copies of prescriptions and other essential documents.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid my-3">
        <div className="container pt-5">
          <div className="row pt-5">
            <div className="col-12 col-lg-6 text-center order-lg-2 d-none d-md-block">
              <img src="/e6.png" className="ch" />
            </div>
            <div className="col-12 col-lg-6 text-center text-center order-lg-1">
              <h2 className="text-left text-capitalize pb-4 pt-5 fw-bolder" style={{ fontSize: '32px', paddingLeft: '80px', paddingTop: '20px' }}>
                In-Clinic Visits, Surprisingly Inevitable
              </h2>
              <div className="text-center mb-3">
                <img src="/e6.png" className="ch d-md-none" />
              </div>
              <p className="info1 text-left" style={{fontWeight: 'bold', paddingLeft: '80px', paddingTop: '0px' }}>
                We understand the online consultations help you in every best
                possible way but with those bad contractions and nausea, its
                time for you to visit the clinic.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid my-3">
        <div className="container">
          <div className="row pt-5">
            <div className="col-12 col-lg-6 text-center d-none d-md-block" style={{ paddingLeft: '100px' }}>
              <img src="/e7.png" className="img-fluid pe-5" id="seebaby" />
            </div>
            <div className="col-12 col-lg-6 text-left pt-3">
              <h2 className="text-left text-capitalize pb-4 pt-5 fw-bolder" style={{ fontSize: '32px', paddingRight: '100px', paddingTop: '20px' }}>
                See Your Baby's Moves
              </h2>
              <div className="text-center mb-3 d-md-none">
                <img src="/e7.png" className="img-fluid pe-5" id="mobileseebaby"  alt="nt found"/>
              </div>
              <p className="info1" style={{fontWeight: 'bold', paddingRight: '100px', paddingTop: '20px' }}>
                Since we know how precious this time is for you, we've planned 9
                ultrasound and 12 types of pathology tests for you in a way that
                includes extras like not so conventional tests.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid my-3">
        <div className="container">
          <div className="row pt-5">
            <div className="col-12 col-lg-6 text-center order-lg-2 pt-2 d-none d-md-block">
              <img src="/e8.png" className="img-fluid pt-5"  alt="nt found"/>
            </div>
            <div className="col-12 col-lg-6 text-left pt-3 order-lg-1">
              <h2 className="text-left text-capitalize pb-4 pt-5 fw-bolder" style={{ fontSize: '32px', paddingLeft: '80px', paddingTop: '20px' }}>
                Be Calm, Health Tracker Does It All
              </h2>
              <div className="text-center mb-3 d-md-none">
                <img src="/e8.png" className="img-fluid pt-5"  alt="nt found"/>
              </div>
              <p className="info1" style={{fontWeight: 'bold', paddingLeft: '80px', paddingTop: '20px' }}>
                Our Health Tracker where oxy pulse meter reading, temperature
                check, weight and other essential checkups are included already.
                It is designed in a way that it records, measures and updates
                the experts about your baby and your health in a single click.
                The experts remember your health on every visit.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid my-3">
        <div className="container pt-5">
          <div className="row pt-5">
            <div className="col-12 col-lg-6 text-center d-none d-md-block" style={{ paddingLeft: '100px' }}>
              <img src="/e9.png" className="img-fluid pe-5" id="wekeep" alt='nt found'/>
            </div>
            <div className="col-12 col-lg-6 text-left">
              <h2 className="text-left text-capitalize pb-4 pt-5 fw-bolder" style={{ fontSize: '32px', paddingRight: '100px', paddingTop: '20px' }}>
                We Keep Your Data Under Wraps
              </h2>

              <div className="text-center d-md-none mb-3">
                <img src="/e9.png" className="img-fluid pe-5" id="mobwekeep" alt='nt found'/>
              </div>
              <p className="info1" style={{fontWeight: 'bold', paddingRight: '100px', paddingTop: '20px' }}>
                We know why privacy matters, so thats why your entire data
                including health records, prescriptions,reports, chats and
                schedule is end-to-end encrypted and lies only between you and
                your medical experts. No one including us, can have access to
                them other than you and your experts.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid my-3">
        <div className="container pt-5">
          <div className="row pt-5">
            <div className="col-12 col-lg-6 text-center order-lg-2 d-none d-md-block">
              <img src="/e10.png" className="img-fluid Rx" alt='nt found' />
            </div>
            <div className="col-12 col-lg-6 text-left order-lg-1 pt-3">
              <h2 className="text-left text-capitalize pb-4 pt-5 fw-bolder" style={{ fontSize: '32px', paddingLeft: '80px', paddingTop: '20px' }}>
                Prescriptions & Reports In Apple-Pie Order
              </h2>
              <div className="text-center d-md-none mb-3">
                <img src="/e10.png" className="pieorder" alt='nt found' />
              </div>
              <p className="info1" style={{fontWeight: 'bold', paddingLeft: '80px', paddingTop: '20px' }}>
                You get lifetime access to your account on our automated
                platform. It keeps you away from the fuss of managing the
                reports, prescriptions, chats and other data as the platform
                does it for you!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid my-3">
        <div className="container">
          <div className="row pt-5">
            <div className="col-12 col-lg-6 text-center d-none d-md-block" style={{ paddingLeft: '100px' }}>
              <img src="/e11.png" className="img-fluid" id="specialhamper" />
            </div>
            <div className="col-12 col-lg-6 pt-3 text-left pt-5">
              <h2 className="text-left text-capitalize pb-4 pt-5 fw-bolder" style={{ fontSize: '32px', paddingRight: '100px', paddingTop: '20px' }}>
                A special Hamper To Pamper
              </h2>
              <div className="text-center d-md-none mb-3">
                <img src="/e11.png" className="img-fluid" id="mspecialhamper" />
              </div>
              <p className="info1" style={{fontWeight: 'bold', paddingRight: '100px', paddingTop: '20px' }}>
                Since this is the most precious time of your life we wouldnt
                miss a chance to make you feel ecial. Sending you delightful
                gift hampers every month including ecial fruits, nuts and
                aromatic flowers together is our gesture to let you know youre
                ecial to us.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid my-1">
        <div className="container pt-5">
          <div className="row pt-5">
            <div className="col-12 col-lg-6 text-center order-lg-2 d-none d-md-block">
              <img src="/e12.png" className="img-fluid" />
            </div>
            <div className="col-12 col-lg-6 text-left pt-5 order-lg-1">
              <h2 className="text-left text-capitalize pb-4 pt-5 fw-bolder" style={{ fontSize: '32px', paddingLeft: '80px', paddingTop: '20px' }}>
                Fully-Remote Yet Cutting-Edge Tech System
              </h2>

              <img src="/e12.png" className="img-fluid fullyremote d-md-none" />

              <p className="info1" style={{fontWeight: 'bold', paddingLeft: '80px', paddingTop: '20px' }}>
                Since our system is fully remote, it does everything that you
                need at every step. From remembering all your data to providing
                you with end-to-end encrypted privacy, our system is built with
                groundbreaking technology chunks.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid my-3">
        <div className="container">
          <div className="row pt-5">
            <div className="col-12 col-lg-6 text-center d-none d-md-block" style={{ paddingLeft: '100px' }}>
              <img src="/e13.png" className="img-fluid" id="lowprice" />
            </div>
            <div className="col-12 col-lg-6 pt-3 text-left pt-3">
              <h2 className="text-left text-capitalize pb-4 pt-5 fw-bolder" style={{ fontSize: '32px', paddingRight: '100px', paddingTop: '20px' }}>
                Astonishingly Awesome, Price At Rock-Bottom
              </h2>
              <div className="text-center d-md-none mb-3">
                <img src="/e13.png" className="img-fluid" id="mlowprice" />
              </div>
              <p className="info1" style={{fontWeight: 'bold', paddingRight: '100px', paddingTop: '20px' }}>
                Make your journey experience memorable at a pocket-friendly
                price.
              </p>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="text-center my-5">
        <Link
          to="/pricing"
          className="buy mx-auto fw-bolder text-white"
          style={{fontSize: '20px'}}
        >
          BUY NOW
        </Link>
      </div>
      <br />
      <Footer />
    </>
  );
}

export default Cherishcare
