import React, { useState } from "react";
import configData from "../config.json";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
function NoEventBox({ title, date, dateClicked, isEventToday, consultations }) {
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1200px)",
  });
  const currentDate = moment().format("YYYY-MM-DD");
  console.log("HH", title);
  console.log("HH1", date);
  return (
    <div
      style={{
        alignSelf: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "8px",
        margin: isTabScreen ? "5px" : "20px",
        fontSize: isTabScreen ? "10px" : "20px",
        fontWeight: "500",
        fontFamily: "Poppins",
        borderRadius: "12px",
        backgroundColor: configData.THEME_COLORS.SECONDARY,
        color: "white",
        flexWrap: "wrap",
        textAlign: "center",
      }}
    >
      {dateClicked || (isEventToday > 0 && isEventToday)
        ? date
          ? "Event on" + " " + date
          : "Today's Event"
        : title === ""
          ? "No Event Today"
          : title + " " + date}
    </div>
  );
}

export default NoEventBox;
