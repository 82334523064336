import React, { useState } from "react";
import configData from "../config.json";
import { useMediaQuery } from "react-responsive";
import moment from "moment";

const BatchSchedule = ({ data }) => {
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  return (
    <>
      <div>
        <div
          style={{
            paddingTop: "20px",
            paddingLeft: "25px",
            marginBottom: "12px",
          }}
        >
          {" "}
          {data?.name}
          {/* {props.item.consultation_number}{""} */}
        </div>
        <div
          style={{
            border: "1px solid #CBC6C6",
            backgroundColor: "rgba(240, 250, 248, 0.502)",
            borderRadius: "12px",
            width: "86%",
            margin: "0px auto",
          }}
        >
          <div
            style={{
              paddingTop: "18px",
              paddingBottom: "18px",
              textAlign: "center",
              color: configData.THEME_COLORS.PRIMARY,
              fontSize: isTabScreen ? "16px" : "20px",
            }}
          ></div>
          <div
            className="pb-3 text-center fw-bolder"
            style={{ fontSize: isTabScreen ? "13px" : "20px" }}
          >
            {data?.session}
          </div>

          <div className="px-3 d-flex justify-content-between align-items-center">
            <div className="d-flex flex-column justify-content-center">
              <div
                className="pb-1"
                style={{ fontSize: isTabScreen ? "9px" : "16px" }}
              >
                Timing
              </div>
              <div
                className="pt-1"
                style={{
                  color: "#868686",
                  fontSize: isTabScreen ? "9px" : "16px",
                }}
              >
                {data?.timing}
              </div>
            </div>
            <div style={{ fontSize: isTabScreen ? "9px" : "16px" }}>
              Days: {data?.days}
            </div>
          </div>

          <div className="my-3 d-flex justify-content-between align-items-center mx-md-1 mx-lg-3">
            <div
              style={{
                fontSize: isTabScreen ? "11px" : "16px",
                color: "green",

                textAlign: "center",
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BatchSchedule;
