import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import MiniDrawer from "../Components/MiniDrawer";
import configData from "../../config.json";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "bootstrap/dist/css/bootstrap.min.css";
import DateBox from "../Components/DateBox";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import BrainActivity from "../Components/Categories/BrainActivity";
import DailyStory from "../Components/Categories/DailyStory";
import DailyReading from "../Components/Categories/DailyReading";
import MyReadBook from "../Components/Categories/MyReadBook";
import Embryonic from "../Components/Categories/Embryonic";
import SignsAndSymptoms from "../Components/Categories/SignsAndSymptoms";

import Hospitalbag from "../Components/Categories/Hospitalbag";
import Dailymotivation from "../Components/Categories/Dailymotivation";
import Dailymeditation from "../Components/Categories/Dailymeditation";
import Kegalexercise from "../Components/Categories/Kegalexercise";
import PregnancyRaga from "../Components/Categories/PregnancyRaga";
import Notes from "../Components/Categories/Notes";
import Dropdown from "../Components/Categories/Dropdown";

// import clothinglist from "../Components/Clothing";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import { useNavigate } from "react-router-dom/dist";
const clothinglist = [
  {
    month: 1,
    heading:
      "The foetus is like a bubble during this stage and continues to develop, thus pregnant women should wear light, loose clothing",
    ClothingColour:
      "You Should Wear White or Light Coloured Clothes in Your First Month of Pregnancy.",
    Ornaments: "You can be wear (white-coloured) diamond, silver ornaments.",
  },
  {
    month: 2,
    heading:
      "Because the embryo transforms into the foetus and begins to build the major organs of the body beginning this month, bright colours that accelerate the creation process should be worn.",
    ClothingColour:
      "You Should Wear Red Orange and Pink Coloured Clothes in Your Second Month of Pregnancy.",
    Ornaments:
      "You can be wear Copper's, (white-coloured) diamond or silver ornaments.",
  },
  {
    month: 3,
    heading:
      "From this month onwards hands, feet, head begin to form.Yellow clothing should be worn to speed up the creation process because new organs are developing.",
    ClothingColour:
      "You Should Wear Yellow Coloured Clothes in Your Third Month of Pregnancy.",
    Ornaments:
      "You can be wear (yellow coloured) Gold, Pokhraj & Topaz Ornaments.",
  },
  {
    month: 4,
    heading:
      "During this month, all the senses of the unborn baby become functional, and the development of the head is intensified. So in order to accelerate the creation of new cells and muscles, Saffron yellow and red clouded cloths should be worn.",
    ClothingColour:
      "You Should Wear Saffron yellow, Orange and red Coloured Clothes in Your Fourth Month of Pregnancy.",
    Ornaments: "You can be wear (yellow coloured) Gold or Manek Ornaments.",
  },
  {
    month: 5,
    heading:
      "The unborn child's intellect and brain would grow throughout this month. Mind is developing, So Wear white jewellery to influence that growth.",
    ClothingColour:
      "You Should Wear White or light coloured Coloured Clothes in Your Fifth Month of Pregnancy.",
    Ornaments: "You can be wear (white) silver & pearl Ornaments.",
  },
  {
    month: 6,
    heading:
      "By this month the skin of the unborn baby is developing and all the senses and organs are functioning. Hair on the head, eyebrows, and eyelids. So wear brown or light-coloured clothing.",
    ClothingColour:
      "You Should Wear Brown (light-coloured) or Grey Coloured Clothes in Your Sixth Month of Pregnancy.",
    Ornaments: "You can be wear Sapphire & Neelam Ornaments.",
  },
  {
    month: 7,
    heading:
      "By this month the embryo is fully developed, with its growth of different organs and body structures. As the nervous system develops, wear a bright red colour ornament that accelerates it.",
    ClothingColour:
      "You Should Wear Red & Pink Coloured Clothes in Your Seventh Month of Pregnancy.",
    Ornaments: "You can be wear Gold, Panna and Emerald Ornaments.",
  },
  {
    month: 8,
    heading:
      "Infants get developed very well. Muscles become stronger. There is also a Weight gains. So You can wear colourful cloths.",
    ClothingColour:
      "You Should Wear colourful (bright and beautiful colours) Clothes in Your Eighth Month of Pregnancy.",
    Ornaments: "You can be wear Gold Ornaments.",
  },
  {
    month: 9,
    heading:
      "This month sees a rise in the foetal movement. It is preparing to enter the outer world (infant heads down and feet up). So wear white coloured comfortable clothes that relax the mind and body.",
    ClothingColour:
      "You Should Wear White colour (Loose clothing) Clothes in Your Ninth Month of Pregnancy.",
    Ornaments: "You can be wear (white-coloured) pearls & silver Ornaments.",
  },
];

function GarbhaSanskar() {
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const [dates, setDates] = useState([]);
  const activeDate = moment().format("DD");

  const handleDate = (lmpdate) => {
    const lmp_date = moment(lmpdate, "YYYY-MM-DD");
    const nextDate = [];
    const previouDate = [];
    const currentDate = moment();
    const currentDateFormat = currentDate.format("DD");
    const currentDateFormatDay = currentDate.format("ddd");
    const currentdaysDiff = currentDate.diff(lmp_date, "days");
    const currentDateObject = {
      date: currentDateFormat,
      day: currentDateFormatDay,
      patientDay: currentdaysDiff,
    };
    console.log(currentDate);
    for (let i = 1; i <= 3; i++) {
      const nextDay = currentDate.clone().add(i, "days");
      const nextDayFormay = nextDay.format("DD");
      const nextDayFormayDay = nextDay.format("ddd");
      const nextdaysDiff = nextDay.diff(lmp_date, "days");

      const nextObject = {
        date: nextDayFormay,
        day: nextDayFormayDay,
        patientDay: nextdaysDiff,
      };

      const preDate = currentDate.clone().subtract(i, "days");
      const preDateFormat = preDate.format("DD");
      const preDateFormatDay = preDate.format("ddd");
      const predaysDiff = preDate.diff(lmp_date, "days");

      const preDateObject = {
        date: preDateFormat,
        day: preDateFormatDay,
        patientDay: predaysDiff,
      };

      previouDate.push(preDateObject);
      nextDate.push(nextObject);
    }
    previouDate.reverse();
    const formattedDates = [...previouDate, currentDateObject, ...nextDate];
    setDates(formattedDates);
  };

  const Categories = [
    {
      name: "Brain Activity",
      id: 1,
      img: "/GarbhaSanskar/Brain-Activity.jpeg",
    },
    {
      name: "Daily Story",
      id: 2,
      img: "/GarbhaSanskar/daily-story.png",
    },
    {
      name: "Activity of the Day",
      id: 3,
      img: "/GarbhaSanskar/Activityoftheday2.jpeg",
    },
    {
      name: "Hospital Bag",
      id: 4,
      img: "/GarbhaSanskar/Hospital-bag.png",
    },
    {
      name: "My Read Book",
      id: 5,
      img: "/GarbhaSanskar/My-read-book_Daily-story-2.png",
    },
    {
      name: "Notes",
      id: 6,
      img: "/GarbhaSanskar/notes.png",
    },
    // {
    //   name: "Daily Meditation",
    //   id: 7,
    //   img: "/GarbhaSanskar/Daily-Meditation.png",
    // },
    {
      name: "Daily  Motivation",
      id: 8,
      img: "/GarbhaSanskar/Daily-Motivation4.png",
    },
    // {
    //   name: "Daily Kegel Exercise",
    //   id: 9,
    //   img: "/GarbhaSanskar/Kegel-Exercise.png",
    // },
    {
      name: "Signs & Symptoms",
      id: 10,
      img: "/GarbhaSanskar/Signs&Symptoms.jpg",
    },
    {
      name: "Embryonic Development",
      id: 11,
      img: "/GarbhaSanskar/Embryonic-Development.jpg",
    },
    {
      name: "Daily Reading",
      id: 12,
      img: "/GarbhaSanskar/Daily-Reading.png",
    },
    {
      name: "Pregnancy Raga",
      id: 13,
      img: "/GarbhaSanskar/Pregnancy-Raga's.png",
    },
  ];

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 200,
    bgcolor: "background.paper",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    p: 4,
  };

  const wrapper = {
    marginLeft: isTabScreen ? "20px" : "50px",
    marginRight: isTabScreen ? "20px" : "50px",
    paddingLeft: isTabScreen ? "20px" : "50px",
    paddingBottom: "50px",
    background: "#FBFBFB",
    borderRadius: "12px",
    paddingTop: "30px",
    marginBottom: "50px",
  };
  const [viewAll, setViewAll] = useState(false);
  const [dietTips, setDietTips] = useState(false);
  const [clothingTips, setClothingTips] = useState(false);
  const [category, setCategory] = useState();
  const [selected, setSelected] = useState(false);
  const [expand, setExpand] = useState(true);
  const [diettips, setDiettips] = useState("");
  const [patientdata, setPatientdata] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [value, setValue] = React.useState("one");
  const [weekwisedata, setWeekwisedata] = useState([]);
  const [daywisedata, setDaywisedata] = useState([]);
  const [length, setLength] = useState();
  const [weight, setWeight] = useState();
  const [kg, setKg] = useState();
  const [cm, setCm] = useState();
  const [babywt, setBabywt] = useState([]);
  const [ht, setHeight] = useState();
  const showsubmenu = () => {
    setSubmenu(!submenu);
  };
  const [submenu, setSubmenu] = useState(false);

  const divRef = useRef(null);

  // const history = useHistory();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state == 1) {
      setSelected(true);
      setExpand(false);
      setCategory(3);
    }
    if (location.state == 2) {
      setExpand(true);
      setDietTips(true);
    }
  }, []);
  useEffect(() => {
    getsinglepatientdetail();
    console.log("<><><><>", patientdata);
  }, []);

  const getsinglepatientdetail = async () => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/users/get_single_user`,
        {
          userId: localStorage.getItem("userId"),
        }
      );

      if (response.data.success) {
        console.log("get single user successfully", response.data.user);
        setPatientdata(response.data.user);
        handleDate(response.data.user?.Lmp_date);
      } else {
        console.error(
          "Error adding medical registration details:",
          response.data.error
        );
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };
  useEffect(() => {
    gettinggarbhsanskardata();
  }, [patientdata, length, cm, weight, kg]);

  const gettinggarbhsanskardata = async () => {
    try {
      console.log("@#", localStorage.getItem("userId"));
      const response = await axios.post(
        `${configData.SERVER_API_URL}/patient/get_garbhsanskar_data`,
        {
          patientId: localStorage.getItem("userId"),
        }
      );

      if (response.data.success) {
        console.log("garbhsanskar data", response.data);
        setWeekwisedata(response.data?.weekwiseData[0]);
        setDaywisedata(response.data?.weekwiseData[0]?.dayWiseData[0]);
        const height = response.data?.weekwiseData[0]?.length;
        setHeight(height);
        if (height) {
          const [length, cm] = height?.split(" ");
          setLength(length);
          setCm(cm);
        }
        const weightbaby = response.data?.weekwiseData[0]?.weight;
        setBabywt(weightbaby);
        console.log("weight", weightbaby);
        if (weightbaby) {
          const str = weightbaby;
          const parts = str?.split(" ");
          if (parts.length > 2) {
            const weight = parts[0] + parts[1] + parts[2];
            const kg = parts[3];
            setWeight(weight);
            setKg(kg);
          } else {
            const weight = parts[0];
            const kg = parts[1];
            setWeight(weight);
            setKg(kg);
          }

          console.log("weight1", parts);
          console.log("weight12", kg);
        }
      } else {
        console.error("Error:", response.data.error);
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };

  const pregnancyDays = patientdata.pregnancy_days;
  console.log("PPDDAYS", pregnancyDays);
  // const pregnancyMonth = 2;
  var currentRecommendation = null;
  var months = Math.ceil(pregnancyDays / 28);
  var week = Math.ceil(pregnancyDays / 7);
  console.log("<><><><><>", months, week);
  console.log("CLOTHINGGGG>>>>>", clothinglist);
  const clothingRecommendations = clothinglist.filter(
    (item) => item.month == months
  );
  console.log("????{}{{{}{}", clothingRecommendations);

  if (clothingRecommendations) {
    const currentRecommendation = clothingRecommendations.find(
      (item) => item.month == months
    );
  }

  // console.log("@#@#@#@#", clothingInfo);
  const handleChange = (event, newValue) => {
    console.log("newValue", newValue);
    setValue(newValue);
    if (newValue === "two") handleOpen();
  };
  useEffect(() => {
    console.log("WEEK", week);
    getdiettips();
  }, [week]);
  const getdiettips = async () => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/patient/get_diet_tips_data_week_wise`,
        { week: week }
      );
      if (response.status == 200) {
        console.log("????@@", response.data.data[0].name);
        const p = response.data.data[0].name;
        setDiettips(p);
      } else {
        console.log("response failedd");
      }
    } catch (error) {
      console.log("error");
    }
  };
  const scrollToTop = () => {
    divRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        backgroundColor: "#FFF0F5",
        justifyContent: "center",
      }}
    >
      <MiniDrawer />
      <div
        style={{
          width:
            selected === false ? "100%" : expand === true ? "100%" : "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // backgroundColor: '#FFF0F5',
            paddingTop: "48px",
            marginLeft: isTabScreen ? "20px" : "59px",
            paddingBottom: "27px",
            color: configData.THEME_COLORS.PRIMARY,
            fontFamily: "Poppins",
          }}
        >
          <div
            style={{
              fontSize: isTabScreen ? "24px" : "32px",
              fontWeight: "600",
              fontFamily: "Poppins",
            }}
          >
            Garbha Sanskar
          </div>
          <div
            style={{
              paddingRight: "69px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <NotificationsIcon
              sx={{ width: "25px", height: "25px", color: "#004AAD" }}
            />
            <Dropdown
              submenu={submenu}
              onClick={() => showsubmenu()}
              page="pregnancyCare"
            />
          </div>
        </div>
        {selected === false && (
          <Container>
            <Row>
              <div
                style={{
                  // height: window.innerHeight + 40,
                  width: isTabScreen ? "92%" : "90%",
                  margin: isTabScreen ? "0px auto" : "0px 50px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  background: "#FBFBFB",
                  borderRadius: "12px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    // height: '65%'
                  }}
                >
                  {location.state && (
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: "600",
                        color: configData.THEME_COLORS.PRIMARY,
                        cursor: "pointer",
                        paddingLeft: "20px",
                        paddingTop: "30px",
                      }}
                      onClick={() => {
                        if (location.state == 2) {
                          // history.goBack();
                        } else {
                          setSelected(false);
                          setExpand(true);
                        }
                      }}
                    >
                      Back
                    </div>
                  )}

                  <div
                    style={{
                      fontSize: isTabScreen ? "20px" : "26px",
                      fontWeight: "600",
                      color: configData.THEME_COLORS.SECONDARY,
                      marginLeft: "20px",
                      marginTop: "20px",
                    }}
                  >
                    How Big Is My Baby?
                  </div>
                </div>
                <div
                  className="w-100"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div
                    style={{
                      marginTop: isTabScreen ? "15px" : "20px",
                      alignSelf: "center",
                      display: "flex",
                      overflow: "hidden",
                      justifyContent: "space-between",
                      width: "80%",
                      alignItems: "center",
                      height: isTabScreen ? "100" : "130",
                    }}
                  >
                    {dates.map((item, index) => (
                      <DateBox
                        day={item.day}
                        date={item.date}
                        patientDay={item.patientDay}
                        size={"40px"}
                        activeDay={activeDate}
                      />
                    ))}
                    {/* <Carousel items={dates} active={0} /> */}
                  </div>
                </div>
                {/* <div
                  style={{
                    display: "flex",
                    alignSelf: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                > */}
                <div className="container mt-5">
                  <div className="row">
                    <div className="col-3">
                      <div
                        style={{
                          height: "13vw",
                          width: "80%",
                          margin: "0px auto",
                          backgroundColor: "white",
                          boxShadow: "0px 1px 24px rgba(196, 196, 196, 0.1608)",
                          borderRadius: "8px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                            alignItems: "center",
                            width: "85%",
                            alignSelf: "center",
                          }}
                        >
                          <img
                            src={"/GarbhaSanskar/height2.png"}
                            style={{
                              borderRadius: "50%",
                              height: isTabScreen ? "20px" : "40.91px",
                              width: isTabScreen ? "20px" : "40.91px",
                              marginTop: "13px",
                            }}
                            alt="height2"
                          ></img>
                          <div
                            style={{
                              fontWeight: "500",
                              fontSize: isTabScreen ? "10px" : "16px",
                              marginTop: "13px",
                            }}
                          >
                            Height
                          </div>
                        </div>
                        <div
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            alignItems: "center",
                            alignSelf: "center",
                            marginBottom: "10px",
                          }}
                        >
                          {ht === null ? (
                            <span className="ps-2">
                              {" "}
                              <HorizontalRuleIcon />
                            </span>
                          ) : (
                            <>
                              {" "}
                              <div
                                style={{
                                  fontSize: isTabScreen ? "20px" : "36px",
                                  fontWeight: "400",
                                  color: "#272927",
                                }}
                              >
                                {length}
                              </div>
                              <div
                                style={{
                                  fontSize: isTabScreen ? "12px" : "20px",
                                  fontWeight: "600",
                                  color: "#818181",
                                  textAlign: "right",
                                  marginTop: 10,
                                  marginLeft: "6px",
                                }}
                              >
                                {cm}
                              </div>
                            </>
                          )}
                        </div>
                      </div>

                      <div
                        style={{
                          width: "80%",
                          margin: "20px auto",
                          height: "13vw",
                          backgroundColor: "white",
                          boxShadow: "0px 1px 24px rgba(196, 196, 196, 0.1608)",
                          borderRadius: "8px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-around",
                            alignSelf: "center",
                            width: "85%",
                          }}
                        >
                          <img
                            src={"/GarbhaSanskar/weight2.png"}
                            style={{
                              borderRadius: "50%",
                              height: isTabScreen ? "20px" : "40.91px",
                              width: isTabScreen ? "20px" : "40.91px",
                              marginTop: "13px",
                            }}
                            alt="weight2"
                          ></img>
                          <div
                            style={{
                              fontWeight: "500",
                              fontSize: isTabScreen ? "10px" : "16px",
                              marginTop: "13px",
                            }}
                          >
                            Weight
                          </div>
                        </div>
                        <div
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            alignItems: "center",
                            alignSelf: "center",
                            marginBottom: "10px",
                          }}
                        >
                          {babywt === null ? (
                            <span className="ps-2">
                              <HorizontalRuleIcon />
                            </span>
                          ) : (
                            <>
                              <div
                                style={{
                                  fontSize: isTabScreen ? "20px" : "36px",
                                  fontWeight: "400",
                                  color: "#272927",
                                }}
                              >
                                {weight}
                              </div>
                              <div
                                style={{
                                  fontSize: isTabScreen ? "12px" : "20px",
                                  fontWeight: "600",
                                  color: "#818181",
                                  textAlign: "right",
                                  marginTop: 10,
                                  marginLeft: "6px",
                                }}
                              >
                                {kg}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-3">
                      <div
                        className="my-2"
                        style={{
                          width: "80%",
                          margin: "0px auto",
                        }}
                      >
                        <div>
                          {weekwisedata?.shape_image === null ||
                          weekwisedata?.shape_image === undefined ? (
                            <div
                              className="mb-2 text-center bg-white d-flex justify-content-center align-items-center"
                              style={{
                                height: "200px",
                                borderRadius: "50%",
                                boxShadow: "0px 1px 24px rgba(0, 0, 0, 0.1608)",
                              }}
                            >
                              {" "}
                              <HorizontalRuleIcon />
                            </div>
                          ) : (
                            <img
                              className="mx-auto mb-2 img-fluid"
                              src={
                                "https://admin.myprega.com/baby-shape/" +
                                weekwisedata?.shape_image
                              }
                              style={{
                                height: "190px",
                                width: "190px",
                                borderRadius: "50%",
                                boxShadow: "0px 1px 24px rgba(0, 0, 0, 0.1608)",
                              }}
                              alt="straw"
                            />
                          )}
                        </div>
                        <div
                          className="pt-2 pb-2 pl-2 w-100 ps-2"
                          style={{ borderRadius: "8px" }}
                        >
                          <div
                            style={{
                              fontSize: isTabScreen ? "12px" : "22px",
                              fontWeight: "500",
                              marginTop: isTabScreen ? "6px" : "30px",
                              marginLeft: "8%",
                            }}
                          >
                            Your Baby is Size of a {" "}
                            {weekwisedata?.baby_shap_name}
                          </div>
                          {/* <div
                          style={{
                            fontSize: isTabScreen ? "12px" : "22px",
                            fontWeight: "500",
                            marginTop: "7px",
                            marginLeft: "8%",
                          }}
                        >
                          Strawberry
                        </div> */}
                        </div>
                      </div>
                    </div>

                    <div className="col-6">
                      <div
                        style={{
                          width: "90%",
                          margin: "0px auto",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#BEE6FD",
                            borderRadius: "8px",
                            display: "flex",
                            flexDirection: "column",
                            boxShadow: "0px 1px 24px rgba(0, 0, 0, 0.1608)",
                          }}
                        >
                          <div
                            style={{
                              padding: "10px",
                              overflow: "auto",
                              marginLeft: "9px",
                              fontSize: isTabScreen ? "10px" : "16px",
                            }}
                          >
                            {daywisedata?.description}
                          </div>
                          <div
                            style={{
                              backgroundColor: "white",
                              // height: "50px",
                            }}
                          >
                            <div
                              style={{
                                marginLeft: "22px",
                                padding: "10px 0px",
                                fontSize: isTabScreen ? "12px" : "16px",
                                fontWeight: "500",
                              }}
                            >
                              Fact of the Day
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            marginTop: "80px",
                            marginBottom: "20px",
                            backgroundColor: "#BEE6FD",
                            borderRadius: "8px",
                            display: "flex",
                            flexDirection: "column",
                            boxShadow: "0px 1px 24px rgba(0, 0, 0, 0.1608)",
                          }}
                        >
                          <div
                            style={{
                              padding: "10px",
                              marginLeft: "9px",
                              overflow: "auto",
                              fontSize: isTabScreen ? "10px" : "16px",
                            }}
                          >
                            {weekwisedata?.Hormonal_information}
                          </div>
                          <div
                            style={{
                              backgroundColor: "white",
                            }}
                          >
                            <div
                              style={{
                                marginLeft: "22px",
                                padding: "10px 0px",
                                fontSize: isTabScreen ? "12px" : "16px",
                                fontWeight: "500",
                              }}
                            >
                              Hormone Information
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </div>

              <div
                style={{
                  paddingBottom: "4%",
                  width: "90%",
                  backgroundColor: "#FBFBFB",
                  margin: isTabScreen ? "30px auto" : "40px 50px",
                  borderRadius: "10px",
                  // boxShadow: '0px 1px 24px rgba(0, 0, 0, 0.1608)'
                }}
              >
                <div
                  style={{
                    marginTop: "20px",
                    marginLeft: "2%",
                    fontSize: isTabScreen ? "18px" : "24px",
                    fontWeight: "600",
                    color: configData.THEME_COLORS.PRIMARY,
                  }}
                >
                  Diet
                </div>
                <div style={{ display: "flex", marginLeft: "2%" }}>
                  <img
                    src={"/GarbhaSanskar/Rectangle.png"}
                    style={{
                      marginTop: "15px",
                      height: isTabScreen ? "150px" : "200px",
                      width: isTabScreen ? "150px" : "200px",
                      cursor: "pointer",
                      alignSelf: "center",
                    }}
                    alt="Rectangle"
                  />
                  <div
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      className="px-2 py-2"
                      style={{
                        alignSelf: "center",
                        fontSize: isTabScreen ? "14px" : "16px",
                        fontWeight: "400",
                        width: "90%",
                      }}
                    >
                      {diettips}
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginTop: "3%",
                  paddingBottom: "4%",
                  marginBottom: "3%",
                  // height: '85vh',
                  width: "90%",
                  backgroundColor: "#FBFBFB",
                  margin: isTabScreen ? "20px auto" : "0px 50px",
                  borderRadius: "10px",
                  // boxShadow: '0px 1px 24px rgba(0, 0, 0, 0.1608)'
                }}
              >
                <div
                  style={{
                    marginTop: "4%",
                    marginLeft: "4%",
                    fontSize: isTabScreen ? "18px" : "24px",
                    fontWeight: "600",
                    color: configData.THEME_COLORS.PRIMARY,
                  }}
                >
                  Clothing
                </div>
                {clothingRecommendations.map((p) => {
                  return (
                    <>
                      <div
                        className="px-3 py-2"
                        style={{
                          fontWeight: "400",
                          fontSize: isTabScreen ? "14px" : "20px",
                          alignSelf: "center",
                          padding: "0px 15px",
                          marginTop: "2%",
                          marginLeft: "3%",
                        }}
                      >
                        {p.heading}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          marginLeft: "10%",
                          justifyContent: "space-around",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              marginTop: "10px",
                              marginLeft: "16%",
                              fontSize: isTabScreen ? "16px" : "20px",
                              fontWeight: "600",
                              color: configData.THEME_COLORS.SECONDARY,
                            }}
                          >
                            Clothing Colour
                          </div>
                          <img
                            src={"/GarbhaSanskar/Rectangle-(1).png"}
                            style={{
                              marginTop: "10px",
                              marginLeft: "10%",
                              height: isTabScreen ? "150px" : "200px",
                              width: isTabScreen ? "150px" : "200px",
                              cursor: "pointer",
                            }}
                            alt="Rectangle"
                          />

                          <div
                            style={{
                              marginTop: "25px",
                              fontSize: isTabScreen ? "13px" : "16px",
                              fontWeight: "400",
                              padding: "0px 15px",
                              width: "70%",
                              textAlign: "center",
                              marginLeft: "-3%",
                            }}
                          >
                            {p.ClothingColour}
                          </div>
                        </div>
                        <div
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              marginTop: "10px",
                              marginLeft: "17%",
                              fontSize: isTabScreen ? "16px" : "20px",
                              fontWeight: "600",
                              color: configData.THEME_COLORS.SECONDARY,
                            }}
                          >
                            Ornaments
                          </div>
                          <img
                            src={"/GarbhaSanskar/Rectangle-(1).png"}
                            style={{
                              marginTop: "10px",
                              marginLeft: "10%",
                              height: isTabScreen ? "150px" : "200px",
                              width: isTabScreen ? "150px" : "200px",
                              cursor: "pointer",
                            }}
                            alt="Rectangle"
                          />

                          <div
                            style={{
                              marginTop: "25px",
                              fontSize: isTabScreen ? "13px" : "16px",
                              fontWeight: "400",
                              padding: "0px 15px",
                              width: "70%",
                              textAlign: "center",
                              marginLeft: "-3%",
                            }}
                          >
                            {p.Ornaments}
                          </div>
                        </div>
                      </div>
                      {/* {patientdata.pregnancy_day} */}
                    </>
                  );
                })}
              </div>

              <div
                style={{
                  margin: isTabScreen ? "30px auto" : "40px 50px",
                  width: isTabScreen ? "92%" : "90%",
                  background: "#FBFBFB",
                  borderRadius: "12px 12px 0px 0px",
                }}
              >
                <div
                  style={{
                    marginTop: "40px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      marginLeft: isTabScreen ? "20px" : "50px",
                      fontSize: isTabScreen ? "18px" : "24px",
                      color: configData.THEME_COLORS.SECONDARY,
                      fontWeight: "600",
                    }}
                  >
                    Categories
                  </div>

                  <div
                    style={{
                      marginRight: "20px",
                      fontSize: isTabScreen ? "14px" : "16px",
                      fontWeight: "600",
                      color: configData.THEME_COLORS.PRIMARY,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setViewAll(!viewAll);
                      scrollToTop();
                    }}
                  >
                    {!viewAll ? "View All" : "Collapse"}
                  </div>
                </div>
                <div
                  ref={divRef}
                  style={{
                    marginTop: "39px",
                    height: viewAll ? "88%" : window.innerHeight / 3,
                    flexWrap: "wrap",
                    display: "flex",
                    width: "100%",
                    marginLeft: "0.4%",
                    overflowY: viewAll ? "none" : "hidden",
                  }}
                >
                  <div className="container">
                    <div className="row" style={{ marginBottom: "40px" }}>
                      {Categories.map((item, index) => (
                        <div className="px-0 col-3">
                          <div
                            style={{
                              height: isTabScreen ? "130px" : "200px",
                              width: isTabScreen ? "96%" : "90%",
                              margin: "20px 10px auto",
                              background: "#BEE6FD",
                              // boxShadow: '0px 1px 50px rgba(0, 0, 0, 0.08)',
                              borderRadius: "8px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setSelected(true);
                              setExpand(false);
                              setCategory(item.id);
                            }}
                          >
                            <img
                              src={item.img}
                              style={{
                                height: isTabScreen ? "45px" : "60px",
                                width: isTabScreen ? "45px" : "60px",
                                marginTop: "13px",
                                marginBottom: "8.39px",
                              }}
                              alt="dummyimage"
                            ></img>
                            <div
                              className="px-1"
                              style={{
                                fontWeight: "500",
                                fontSize: isTabScreen ? "13px" : "16px",
                                color: "#000000",
                                textAlign: "center",
                                width: 100,
                                height: "50px",
                              }}
                            >
                              {item.name}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        )}

        {selected && category === 1 && (
          <BrainActivity
            setExpand={setExpand}
            setSelected={setSelected}
            value={value}
            handleChange={handleChange}
            open={open}
            userId={patientdata.id}
            pregday={patientdata.pregnancy_days}
          />
        )}
        {selected && category === 2 && (
          <DailyStory setExpand={setExpand} setSelected={setSelected} />
        )}

        {selected && category === 3 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={wrapper}>
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: "600",
                  color: configData.THEME_COLORS.PRIMARY,
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (location.state == 1) {
                    // history.goBack();
                  } else {
                    setSelected(false);
                    setExpand(true);
                  }
                }}
              >
                Back
              </div>

              <div
                style={{
                  marginTop: "10px",
                  fontSize: "24px",
                  fontWeight: "600",
                  color: configData.THEME_COLORS.SECONDARY,
                }}
              >
                Activity of the Day
              </div>
              <div
                style={{
                  fontSize: isTabScreen ? "20px" : "24px",
                  fontWeight: "600",
                  marginTop: "37px",
                  marginLeft: "12px",
                }}
              >
                Activity Name
              </div>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  marginTop: "15px",
                  paddingRight: "10px",
                  marginLeft: "12px",
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </div>

              <img
                src={"/GarbhaSanskar/Youtube-player.png"}
                style={{
                  height: "450px",
                  width: "80%",
                  marginTop: "40px",
                  marginBottom: "80px",
                  marginLeft: "12px",
                }}
              ></img>
            </div>
          </div>
        )}
        {selected && category === 5 && (
          <MyReadBook setExpand={setExpand} setSelected={setSelected} />
        )}
        {selected && category === 4 && (
          <Hospitalbag setExpand={setExpand} setSelected={setSelected} />
        )}
        {selected && category === 6 && (
          <Notes setExpand={setExpand} setSelected={setSelected} />
        )}
        {/* {selected && category === 7 && (
          <Dailymeditation setExpand={setExpand} setSelected={setSelected} />
        )} */}
        {selected && category === 8 && (
          <Dailymotivation setExpand={setExpand} setSelected={setSelected} />
        )}
        {/* {selected && category === 9 && (
          <Kegalexercise setExpand={setExpand} setSelected={setSelected} />
        )} */}
        {selected && category === 10 && (
          <SignsAndSymptoms
            weekwisedata={weekwisedata}
            setExpand={setExpand}
            setSelected={setSelected}
          />
        )}
        {selected && category === 11 && (
          <Embryonic
            weekwisedata={weekwisedata}
            setExpand={setExpand}
            setSelected={setSelected}
          />
        )}
        {selected && category === 12 && (
          <DailyReading setExpand={setExpand} setSelected={setSelected} />
        )}
        {selected && category === 13 && (
          <PregnancyRaga setExpand={setExpand} setSelected={setSelected} />
        )}
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ textAlign: "center" }}>Have an answer in mind?</div>
          <div
            style={{
              width: "80px",
              backgroundColor: configData.THEME_COLORS.PRIMARY,
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              padding: "6px",
              marginTop: "10px",
              alignSelf: "center",
              cursor: "pointer",
            }}
            onClick={() => handleClose()}
          >
            Yes
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default GarbhaSanskar;
