import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import MiniDrawer from "../Components/MiniDrawer";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import Typography from "@mui/material/Typography";
import configData from "../config.json";
import Box from "@mui/material/Box";
import { InputAdornment, TextField } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import Patientlist from "../Components/Patientlist";
import SearchIcon from "@mui/icons-material/Search";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Consultationrightbox from "../Components/Consultationrightbox";
import Reports from "../Components/Reports";
import Profilebox from "../Components/Profilebox";
import Casepaper from "../Components/Casepaper";
import RecentHistoryRightBox from "../Components/RecentHistoryRightBox";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Carousel from "react-elastic-carousel";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import { ResponsiveContainer } from "recharts";
import Dropdown from "../Components/Dropdown";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Badge from "@mui/material/Badge";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import Time from "../Components/Time";
import Displaycasepaper from "../Components/Displaycasepaper";
import AlertShow from "../Components/AlertShow";
let p;
let singledate;
let stid;
// let mydate;
// let scheduleinfo;
// let profiledata;
// let patientreport;
function YogaPatient() {
  const doctorTypes = [
    "Gynaecologist",
    "Doctor Of Medicine",
    "Sonography Lab",
    "Pathalogy Lab",
  ];
  const consultTypes = ["Online", "In Person"];

  const consultationNumbers = ["108", "323"];
  const [schedule, setSchedule] = useState(false);
  const [myschedule, setMyschedule] = useState(false);
  const [update, setUpdate] = useState(false);
  const [close, setClose] = useState(false);
  const [dateClicked, setDateClicked] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [drType, setDrtype] = useState("");
  const [number, setNumber] = useState("");
  const [report, setReport] = useState(null);
  const [mycasepaper, setCasepaper] = useState(null);
  const [myhealthtracker, setHealthtracker] = useState(null);
  const [profile, setProfile] = useState(null);
  const [cam, setCam] = useState(false);
  const [range, setRange] = useState(false);
  const [consultId, setConsultId] = useState();
  const [timeId, setTimeId] = useState();

  const [patients, setPatients] = useState();
  const [filteredPatients, setFilteredPatients] = useState();

  const [dates, setDates] = useState([]);
  const [weekdate, setWeekdate] = useState([]);
  const [consultations, setConsultations] = useState();
  const [timings, setTimings] = useState();
  const [timingsafter, setTimingsafter] = useState();
  const [timingid, setTimingid] = useState();
  const [scheduledr, setScheduledr] = useState(false);
  const [timeData, setTimeData] = useState();
  const [isUpdatedDate, setIsUpdatedDate] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [changeWeekConsultaionId, setChangeWeekConsultaionId] = useState();
  const [patientId, setPatientId] = useState();
  const [addweekBtn, setAddWeekBtn] = useState(false);
  const [week, setWeek] = useState(null);

  // const [value, setValue] = useState(dates);    initial value use to set already given date selected
  const [value, setValue] = useState(dates);
  const [searchMedicine, setSearchMedicine] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [data, setData] = useState([]);
  const [desc, setDesc] = useState(true);
  const [tracker, settracker] = useState(false);
  const [rangeupdate, setUpdaterange] = useState(false);
  const [userData, setUserData] = useState(null);
  const [graphData, setGraphData] = useState([]);
  const [dateObjects, setDateObjects] = useState([]);
  const [enabledDates, setEnabledDates] = useState([
    "2023-11-16",
    " 2023-11-25",
  ]);
  const [profileData, setProfileData] = useState([]);
  const [casepaperdata1st, setCasepaperdata1st] = useState("");
  const [casepaperdata2nd, setCasepaperdata2nd] = useState("");
  const [casepaperdata3rd, setCasepaperdata3rd] = useState("");
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const [submenu, setSubmenu] = useState(false);

  const [isPathology, setIsPathology] = useState(false);

  const [notification, setNotification] = useState([]);
  const [shownotification, setShownotification] = useState(false);
  const [count, setCount] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [severity, setSeverity] = useState("");

  const rowNumbers = Array.from({ length: 40 }, (_, index) => index + 1);

  const fullscreen = () => {
    setCam(true);
  };

  useEffect(() => {
    getDetail();
    getTimings();
  }, []);
  useEffect(() => {
    console.log("by default", dates);
  }, [dates]);
  const viewschedule = (data) => {
    p = data;
    console.log("wwwwwww", p);
    getConsultations(p.id);
    setSchedule(true);
    setMyschedule(p.id);
    setProfile(false);
    setUpdate(false);
    setCasepaper(false);
    setProfile(false);
    setReport(false);
    setRange(false);
  };

  useEffect(() => {
    const filteredData = patients?.filter(
      (patient) =>
        patient.fname.toLowerCase().includes(searchInput.toLowerCase()) ||
        patient.lname.toLowerCase().includes(searchInput.toLowerCase()),
    );

    setFilteredPatients(filteredData);
    console.log("Patientfilter", filteredData);
  }, [searchInput, patients]);

  const updateschedule = (data) => {
    console.log("updateschedule----", data);
    p = data;
    setMyschedule(true);
    setSchedule(true);
    getConsultations(p.id);
    setUpdate(p.id);
    setCasepaper(false);
    setProfile(false);
    setReport(false);
    setRange(false);
  };
  const closerightside = () => {
    setClose(true);
    setSchedule(false);
  };
  const showreport = (data) => {
    console.log("report", data);
    p = data;
    setSchedule(true);
    setMyschedule(false);
    setUpdate(false);
    setCasepaper(false);
    setProfile(false);
    setReport(p.id);
    setRange(false);
  };
  const showcasepaper = (data) => {
    p = data;
    setSchedule(true);
    setMyschedule(false);
    setUpdate(false);
    setProfile(false);
    setRange(false);
    setReport(false);
    setCasepaper(p.id);
    getcasepaper(p.id);
  };
  const showhealthtracker = (data) => {
    p = data;
    setSchedule(false);
    setMyschedule(false);
    setUpdate(false);
    setProfile(false);
    setReport(false);
    setCasepaper(false);
    setRange(false);
    setHealthtracker(p.id);
    getHealthVitalsForGraph(p.id);
  };
  const showprofile = (data) => {
    p = data;
    console.log("MYYYYY PPPRROOFFILEE DDDAAATAAA", p);
    setSchedule(true);
    setMyschedule(false);
    setRange(false);
    setReport(false);
    setUpdate(false);
    setProfile(p.id);
    setCasepaper(false);
    setRange(false);
  };

  const changerange = (item) => {
    console.log("C ID-changeRange", item);
    let p = item.weekDates;
    setWeekdate(p);
    setDates(p);
    setEnabledDates(p);
    // mydate=item.weekDates;
    // setValue(item.weekDates);
    console.log("VALUES OF CALENDAR---", item);
    setConsultId(item?.id);
    setRange(true);
    setSchedule(true);
    setMyschedule(false);
    setUpdate(item.id);
    if (item.doctor_scheduled === 1) {
      getDoctorAvailability(item.doctor_id, item.id, item.patient_id);
    }
    setProfile(false);
    setReport(false);
    setCasepaper(false);
    setHealthtracker(false);
    setTimingsafter([]);
    setDateObjects([]);
  };

  const changeWeek = (event) => {
    console.log("modal Open", event.currentTarget.dataset.patient_id);
    const consultation_id = event.currentTarget.dataset.id;
    const patient_id = event.currentTarget.dataset.patient_id;
    setChangeWeekConsultaionId(consultation_id);
    setPatientId(patient_id);
    setOpen(true);
  };

  const consulationTypeChanege = async (event) => {
    const consulation_id = event.currentTarget.dataset.id;
    console.log(
      "Are you sure you want to change consulation type?",
      consulation_id,
    );
    const consulationType = event.currentTarget.dataset.type;
    const toConsulationType =
      consulationType === "Online" ? "In-Person" : "Online";
    // Display a confirmation dialog to the user
    const userConfirmed = window.confirm(
      `Are you sure you want to change consulation type ${consulationType} to ${toConsulationType}?`,
    );

    if (userConfirmed) {
      try {
        const week = event.currentTarget.dataset.week;
        const item = JSON.parse(event.currentTarget.dataset.props);
        // Convert the consulationType to the corresponding type value ('1' or '2')
        const consultation_type = consulationType === "Online" ? "2" : "1";
        // Send a request to update the consultation type
        const response = await axios.post(
          `${configData.SERVER_API_URL}/consultation/update_consultation`,
          {
            consultation_id: consulation_id,
            consultation_type: consultation_type,
            week: week,
          },
        );

        if (response.data.success) {
          // Handle success if needed
          const patientData = {
            id: item.patient_id,
          };
          updateschedule(patientData);
        } else {
          console.log("Not Updated");
          // Handle failure if needed
        }
      } catch (error) {
        console.log("Error:", error);
        // Handle the error, e.g., show an error message to the user
      }
    } else {
      // The user clicked Cancel in the confirmation dialog
      console.log("Consultation type change canceled.");
      // Do nothing or handle the cancellation if needed
    }
  };

  const getcasepaper = async (patienid) => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/users/get_case_paper_form`,
        {
          patient_id: patienid,
        },
      );
      if (response.data.success) {
        console.log("@@response=======", response.data);
        const responsedata = response.data;
        const d =
          responsedata.case_paper_form.general_lifestyle_habits_history[0];
        const casepaperdata1st = d[0];
        setCasepaperdata1st(casepaperdata1st);

        const e =
          responsedata.case_paper_form.personal_family_medical_profile[0];
        const casepaperdata2nd = e[0];
        setCasepaperdata2nd(casepaperdata2nd);

        const f =
          responsedata.case_paper_form.reproductive_menstrual_history[0];
        const casepaperdata3rd = f[0];
        setCasepaperdata3rd(casepaperdata3rd);

        console.log(
          "first#####",
          casepaperdata1st,
          casepaperdata2nd,
          casepaperdata3rd,
        );
      } else {
        console.log("not getting casepaper details");
      }
    } catch (error) {
      console.log("error in fecthing casepaper detail", error);
    }
  };
  const getDoctorAvailability = async (doctorid, id, patient_id) => {
    try {
      console.log("check availability of dr.", patient_id, doctorid, id);
      const response = await axios.post(
        `${configData.SERVER_API_URL}/consultation/get_doctor_availability`,
        {
          patient_id: patient_id,
          doctor_id: doctorid,
          consultation_id: id,
        },
      );

      if (response.data?.success) {
        console.log("DoctorAvailability-", response.data);
        setTimeData(response.data?.doctor_availability[0]);
        const selectedDatesArray = [];
        const oArray = [];
        const selectedDates =
          response.data?.doctor_availability[0]?.dates?.split(",") || [];
        const DoctorAvailabilityData = response.data?.doctor_availability;
        DoctorAvailabilityData.map((date) => {
          let d = moment(date.dates, "DD-MM-YYYY").format("YYYY-MM-DD");
          selectedDatesArray.push(date.dates);
          const formattedDateobj2 = {
            id: date.id,
            date: date.dates,
            timing_id: date.timing_id,
          };
          oArray.push(formattedDateobj2);
        });
        const newselectedDatesArray = selectedDatesArray
          .join(",")
          .split(",")
          .map((date) => date.trim());
        console.log("DoctorAvailability", newselectedDatesArray);
        console.log("oArray", oArray);
        setDateObjects(oArray);
        setIsUpdatedDate(true);
        //  setDates(newselectedDatesArray);
        setValue(newselectedDatesArray);
        // setDates(response.data?.doctor_availability[0]?.dates?.split(","));
        setTimingsafter(
          response.data?.doctor_availability[0]?.timings?.split(","),
        );
        setTimingid(
          response.data?.doctor_availability[0]?.timing_id?.split(","),
        );
      }
    } catch (error) {
      console.error("Error get availdates:", error);
    }
  };

  const getNextDates = (e) => {
    // console.log("getNextDates",e.currentTarget.dataset.last_date);
    const lastDate = moment(e.currentTarget.dataset.last_date, "YYYY-MM-DD");
    const item = JSON.parse(e.currentTarget.dataset.item);
    console.log("itemm", item);
    const nextdates = [];
    for (let i = 1; i <= 7; i++) {
      const currentdate = lastDate.clone().add(i, "days");
      nextdates.push(currentdate.format("YYYY-MM-DD"));
    }
    const updateItem = {
      ...item,
      doctor_scheduled: 0,
      weekDates: nextdates,
    };
    changerange(updateItem);
    if (item.doctor_scheduled === 1) {
      getDoctorAvailability(item.doctor_id, item.id, item.patient_id);
    }
    // console.log("--nextdates",nextdates);
  };
  const handlechange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
    setDesc(true);
  };

  const handleAlertMsg = (error_type, msg) => {
    setShowAlert(true);
    setSeverity(error_type);
    setAlertMsg(msg);
    setTimeout(() => {
      setShowAlert(false);
    }, 2500);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  const handleChange1 = (event) => {
    setDrtype(event.target.value);
  };
  const handleChange2 = (event) => {
    const week = event.target.value;
    setNumber(week);
  };

  const onClickDay = (item) => {
    setDateClicked(true);
  };

  const showsubmenu = () => {
    setSubmenu(!submenu);
  };
  useEffect(() => {
    getapi1();
  }, []);
  useEffect(() => {}, [consultations]);

  const fetchNotifications = async () => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/common/get_notification`,
        {
          receiver_id: userData.id,
        },
      );

      if (response.status === 200) {
        const l = response.data.notifications?.length;
        console.log(
          "GetNotification Response",
          response.data.notifications,
          response.data.notifications[l - 1],
        );

        setNotification(response.data.notifications);
        let cnt = response.data.notifications[l - 1].notification_count;
        // console.log("cnt>", cnt);
        setCount(cnt);
      } else {
        console.log("Error in getting notification");
      }
    } catch (error) {
      console.log("Error in getting catch block", error.message);
    }
  };
  const readnotification = async () => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/common/read_notification`,
        { receiver_id: userData.id },
      );
      if (response.status === 200) {
        console.log("successfully read ");
      } else {
        console.log("Error in reading notification");
      }
    } catch (error) {
      console.log("Error", error.message);
    }
    fetchNotifications();
  };

  const shownotificationofdr = () => {
    setShownotification(!shownotification);
    readnotification();
  };

  const getapi1 = async () => {
    try {
      const id = localStorage.getItem("userId");
      if (id) {
        const response = await axios.post(
          `${configData.SERVER_API_URL}/users/get_single_user`,
          {
            userId: id,
          },
        );
        if (response.data.success) {
          console.log("fetched Profile Data", response.data);
          setProfileData(response.data);
        } else {
          console.error(
            "Error adding add user education details:",
            response.data.error,
          );
        }
      }
    } catch (error) {
      console.error("Error", error);
    }
  };
  const handleSort = () => {
    const data = filteredPatients;
    data?.sort((a, b) => {
      let c = new Date(a.created_date);
      let d = new Date(b.created_date);
      return desc ? d - c : c - d;
    });
    setFilteredPatients(data);
  };
  useEffect(() => {}, []);
  // const tileClassName = ({ date }) => {
  //   const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
  //     .toString()
  //     .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
  //   return dates.includes(formattedDate) ? "highlighted" : "";
  // };

  const getHealthVitalsForGraph = async (id) => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/patient/get_health_details_monthwise`,
        { patient_id: id },
      );
      if (response.data) {
        console.log(
          "Graphhealthvitals-",
          response.data?.health_Details[0].blood_pressure,
        );
        setGraphData(response.data?.health_Details);
      }
    } catch (error) {
      console.error("Error Graphhealthvitals:", error);
    }
  };
  const closefullcam = () => {
    setCam(false);
  };
  const backfromtracker = () => {
    setHealthtracker(false);
  };
  const hline = {
    width: "100%",
    height: "1px",
    backgroundColor: "#C0CCDA",
  };
  const updaterange = () => {
    setUpdaterange(true);
  };

  const getTimeing = (id) => {
    // console.log("check---",id)
    if (id) {
      const numId = parseInt(id, 10);
      const matchTime = timings.filter((timing) => timing.id === numId);
      return matchTime[0].start_time;
    }
  };

  const handleClickAddWeek = async () => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/consultation/update_consultation_week`,
        {
          week_number: week,
          consultation_id: changeWeekConsultaionId,
        },
      );
      if (response.data.success) {
        var msg = response.data.msg;
        const error_color = "success";
        handleAlertMsg(error_color, msg);
        // alert(response.data.msg);
        setAddWeekBtn(false);
        setOpen(false);
        setWeek(null);
        getConsultations(patientId);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };
  const handleClickWeek = async (week) => {
    setWeek(week);
    setAddWeekBtn(true);
  };

  const dateWiseTime = useCallback(
    (singledate) => {
      const date = singledate;
      console.log("dateObjects--", dateObjects);
      const dObjects = dateObjects;
      const gettimeDateWise = dObjects.filter((data) => data.date === date);
      const timeArray = [];
      if (
        gettimeDateWise &&
        gettimeDateWise?.length !== 0 &&
        gettimeDateWise[0].timing_id
      ) {
        const ids = gettimeDateWise[0].timing_id;
        const idsArray = ids.split(",");
        setTimingid(idsArray);
        idsArray.forEach((targetId) => {
          const time = getTimeing(targetId);
          timeArray.push(time);
        });
        stid = timeArray;
        setTimingsafter(timeArray);
      } else {
        stid = [];
        setTimingid([]);
        setTimingsafter([]);
      }
    },
    [dateObjects, getTimeing, singledate, timingid, timingsafter],
  );

  const onChange = (newValue) => {
    console.log("Selected Date Range:", newValue);
    singledate = moment(newValue).format("YYYY-MM-DD");
    setValue(singledate);
    console.log("SELECTED SINGLE DSATE", dateObjects);
    dateWiseTime(singledate);
    updaterange();
  };

  const getDetail = async () => {
    try {
      const id = localStorage.getItem("userId");
      if (id) {
        const response = await axios.post(
          `${configData.SERVER_API_URL}/users/get_single_user`,
          {
            userId: id,
          },
        );
        if (response.data.success) {
          console.log("fetched User Data", response.data?.user);
          setUserData(response.data?.user);

          getpatientlist(
            response.data?.user?.area_id,
            response.data.user?.id,
            response.data.user?.medical_expert_id,
          );
        } else {
          console.error("Error ----:", response.data.error);
        }
      }
    } catch (error) {
      console.error("Error", error);
    }
  };
  const getpatientlist = async (areadID, doctorId, medical_expert_id) => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/users/get_paitent_list`,
        {
          area_id: areadID,
          doctor_id: doctorId,
          medical_expert_id: medical_expert_id,
        },
      );
      if (response.status === 200) {
        setPatients(response.data.patientList);
        console.log("PATIENTTTTT DATA", response.data.patientList);
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };
  const getConsultations = async (id) => {
    console.log("~~~~~~~~~~~", id);
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/consultation/get_patient_consultation`,
        {
          patient_id: id,
          doctor_id: profileData.user.id,
        },
      );

      if (response.status === 200) {
        console.log("fetched-consultations", response.data);
        setConsultations(response.data?.patient_consultations);
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };
  useEffect(() => {
    console.log("enabledDates", enabledDates);

    if (enabledDates?.length > 0) {
      // const Date_ = moment(enabledDates[0] ,'DD-MM-YYYYY').format('YYYY-MM-DD');
      const firstEnabledDate = new Date(enabledDates[0]);
      console.log("enabledDates", firstEnabledDate);
      // Set the initial month to the month of the first enabled date
      setValue(firstEnabledDate);
    }
  }, [enabledDates]);

  // const tileDisabled = ({ date }) => {
  //   const formattedDate = date.toISOString().split("T")[0];
  //   return !enabledDates.includes(formattedDate);
  // };

  const isSameDay = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };
  const tileDisabled = ({ date, view }) => {
    console.log("enabledate", date, view);
    if (view === "month") {
      const currentDate = date;
      let isEnabled = false;
      setEnabledDates(dates);
      console.log("ENABLED DATES", dates);
      enabledDates?.forEach((enabledDate) => {
        const [year, month, day] = enabledDate?.split("-").map(Number);
        const enabledDateObj = new Date(year, month - 1, day); // month is 0-indexed in JavaScript dates

        if (isSameDay(currentDate, enabledDateObj)) {
          isEnabled = true;
        }
      });

      return !isEnabled;
    }

    return false;
  };
  const getTimings = async () => {
    try {
      const response = await axios.get(
        `${configData.SERVER_API_URL}/consultation/get_doctor_timings`,
      );

      if (response.status === 200) {
        console.log("get_doctor_timings", response.data);
        setTimings(response.data?.doctor_timing);
      } else {
        console.error("get_doctor_timings Error:", response.statusText);
      }
    } catch (error) {
      console.error("get_doctor_timings Error", error);
    }
  };
  // let dateObjects = [];
  // const getTime = (event) => {
  //   alert("gettime being called");
  //   console.log("Selected time", event);
  //   setTimeId(event);
  //   const formattedDateobj1 = {
  //     date: singledate,
  //     timing_id: event?.join(","),
  //   };
  //   console.log("date and timing together", formattedDateobj1);
  //   setDateObjects((prevObj) => [...prevObj, formattedDateobj1]);

  //   console.log("date and timing together as 1", dateObjects1);
  // };

  const getTime = (event, updateId) => {
    // alert("gettime being called");
    console.log("Selected time", event);
    console.log("updateId", updateId);
    setTimeId(event);
    let formattedDateobj1;
    if (isUpdatedDate && updateId) {
      formattedDateobj1 = {
        id: updateId,
        date: singledate,
        timing_id: event?.join(","),
      };
    } else {
      formattedDateobj1 = {
        date: singledate,
        timing_id: event?.join(","),
      };
    }
    // Check if the date already exists in dateObjects
    const dateExists = dateObjects?.some((obj) => obj.date === singledate);

    if (dateExists) {
      // If the date exists, replace the existing object
      setDateObjects((prevObj) =>
        prevObj.map((obj) =>
          obj.date === singledate ? formattedDateobj1 : obj,
        ),
      );
    } else {
      // If the date doesn't exist, add the new object
      setDateObjects((prevObj) => [...prevObj, formattedDateobj1]);
    }
    const regexddmmyyyy =
      /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/;
    if (regexddmmyyyy.test(value) === true) {
      let c_date = moment(value, "DD-MM-YYYY").format(
        "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)",
      );
      setValue(c_date);
    } else {
      setValue(value);
    }

    console.log("date and timing together as 1", dateObjects);
  };

  const newUpdateSchedule = async () => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/consultation/update_doctor_availability`,
        {
          consultation_id: consultId,
          dates: dateObjects,
        },
      );
      if (response.status === 200) {
        console.log("add_doctor_availability", response.data);
        var msg = "Date & Time Slots Updated!";
        const error_color = "success";
        handleAlertMsg(error_color, msg);
        // alert("Date & Time Slots Updated!");
        setSchedule(false);
        setMyschedule(false);
        setProfile(false);
        setUpdate(false);
        setCasepaper(false);
        setProfile(false);
        setReport(false);
        setRange(false);
        // setScheduledr((prevScheduledr) => {
        //   return consultId;
        // });
        setScheduledr(consultId);
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.log("Error : ", error);
    }
  };

  const updateScheduleTime = async () => {
    console.log("got hit>", consultId, singledate, timeId, dateObjects);
    var msg = "add update";
    const error_color = "success";
    handleAlertMsg(error_color, msg);
    // alert("add update");
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/consultation/add_update_doctor_availability`,
        {
          consultation_id: consultId,
          dates: dateObjects,
          // timing_id: timeId?.join(","),
        },
      );

      if (response.status === 200) {
        console.log("add_doctor_availability", response.data);
        var msg = "Date & Time Slots Updated!";
        const error_color = "success";
        handleAlertMsg(error_color, msg);
        // alert("Date & Time Slots Updated!");
        setSchedule(false);
        setMyschedule(false);
        setProfile(false);
        setUpdate(false);
        setCasepaper(false);
        setProfile(false);
        setReport(false);
        setRange(false);
        // setScheduledr((prevScheduledr) => {
        //   return consultId;
        // });
        setScheduledr(consultId);
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };
  return (
    <>
      {cam === true ? (
        <div className="w-100" style={{ height: "100vh" }}>
          <div
            style={{
              background: "rgba(240, 250, 248, 0.502)",
              border: "1.51832px solid #E8E7E7",
              borderRadius: "60.7327px 60.7327px 0px 0px",
              height: "100%",
              width: "95%",
              paddingTop: "10px",
              margin: "20px auto",
              textAlign: "center",
              color: "#F0588B",
              Weight: "500",
              fontSize: "24px",
              position: "relative",
            }}
          >
            Casepaper {p?.name}
            <div style={hline}></div>
            <div
              className="ps-2"
              style={{
                background: "white",
                borderRadius: "25px 25px 0px 0px",
                width: "95%",
                height: "90%",
                margin: "20px auto",
                overflowY: "scroll",
                textAlign: "left",
                fontSize: "15px",
              }}
            >
              {casepaperdata1st || casepaperdata2nd || casepaperdata3rd ? (
                <div>
                  <Displaycasepaper
                    casepaperdata1st={casepaperdata1st}
                    casepaperdata2nd={casepaperdata2nd}
                    casepaperdata3rd={casepaperdata3rd}
                  />
                </div>
              ) : (
                <div>Casepaper not filled</div>
              )}
              <img
                src="./Communication/cross.png"
                style={{
                  position: "absolute",
                  top: "16px",
                  right: "26px",
                  width: "20px",
                  height: "20px",
                }}
                onClick={() => closefullcam()}
                alt="cross"
              ></img>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="d-flex w-100"
          // style={{height:"100vh",overflowY:"hidden"}}
        >
          <div>
            <MiniDrawer />
          </div>
          {myhealthtracker ? (
            <div className="w-100 d-flex justify-content-between">
              {/* <div>{p.id}</div> */}
              <div
                style={{
                  width: "70%",
                  backgroundColor: "#FFF0F5",
                  // width: "100vw",
                }}
              >
                <div
                  className="d-flex align-items-center ps-3"
                  style={{ paddingTop: "59px" }}
                >
                  <div>
                    <img
                      src="./righticon.png"
                      onClick={() => backfromtracker()}
                      alt="righticon"
                    ></img>
                  </div>
                  <div className="ps-4">
                    <div
                      style={{
                        fontSize: isTabScreen ? "24px" : "32px",
                        fontWeight: "600",
                        fontFamily: "Poppins",
                        color: configData.THEME_COLORS.PRIMARY,
                      }}
                    >
                      {p?.name}
                    </div>
                    <div
                      className="pt-2"
                      style={{
                        color: "#F0588B",
                        fontSize: "24px",
                        fontWeight: "600",
                      }}
                    >
                      Health Vitals
                    </div>
                  </div>
                </div>
                <div style={{ hline }}></div>

                <div style={{ height: "60vh" }}>
                  <Carousel verticalMode itemsToShow={1.5}>
                    <div
                      className="bg-white"
                      style={{
                        height: "90%",
                        width: "90%",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      <div className="d-flex justify-content-center pt-3">
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Sugar Level
                        </div>
                        <div
                          style={{
                            padding: 5,
                            marginLeft: "15px",
                            height: "25px",
                            backgroundColor: "#58CF99",
                            display: "flex",
                            justifyContent: "center",
                            color: "#FFFFFF",
                            borderRadius: "4px",
                            alignItems: "center",
                          }}
                        >
                          Normal
                        </div>
                      </div>
                      <ResponsiveContainer
                        width={window.innerWidth / 2.6}
                        height={window.innerHeight / 2}
                      >
                        <LineChart
                          data={graphData && graphData[0]?.sugar_level}
                          margin={{
                            top: 5,
                            // // right: 30,
                            left: isTabScreen ? 0 : 20,
                            bottom: 20,
                            // bottom: 0
                          }}
                        >
                          <XAxis
                            dataKey="month_name"
                            axisLine={true}
                            fontSize={"12px"}
                          />
                          <YAxis
                            datakey="vl"
                            axisLine={true}
                            fontSize={"12px"}
                          />
                          <Tooltip />
                          <Legend
                            iconType="circle"
                            iconSize={10}
                            height={20}
                            wrapperStyle={{
                              fontSize: isTabScreen ? "10px" : "14px",
                            }}
                          />
                          <Line
                            type="monotone"
                            dataKey="avg_sugar_level"
                            stroke="#4791FF"
                            strokeWidth={2}
                            dot={{
                              fill: "#4791FF",
                              r: 3,
                            }}
                            activeDot={{ r: 8 }}
                          />
                          <Line
                            type="monotone"
                            dataKey="ideal_sugar"
                            stroke="#02BC77"
                            strokeWidth={2}
                            dot={{
                              fill: "#02BC77",
                              r: 3,
                            }}
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>

                    <div
                      className="bg-white"
                      style={{
                        height: "90%",
                        width: "90%",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      <div className="d-flex justify-content-center pt-3">
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Blood Presser
                        </div>
                        <div
                          style={{
                            padding: 5,
                            marginLeft: "15px",
                            height: "25px",
                            backgroundColor: "#58CF99",
                            display: "flex",
                            justifyContent: "center",
                            color: "#FFFFFF",
                            borderRadius: "4px",
                            alignItems: "center",
                          }}
                        >
                          Normal
                        </div>
                      </div>
                      <ResponsiveContainer
                        width={window.innerWidth / 2.6}
                        height={window.innerHeight / 2}
                      >
                        <LineChart
                          // width={isTabScreen ? 390 : isScreen3 ? 580 : 550}
                          // height={isTabScreen ? 300 : isScreen3 ? 450 : 250}
                          data={graphData && graphData[0]?.blood_pressure}
                          margin={{
                            top: 5,
                            // // right: 30,
                            left: isTabScreen ? 0 : 20,
                            bottom: 20,
                            // bottom: 0
                          }}
                        >
                          <XAxis
                            dataKey="month_name"
                            axisLine={false}
                            fontSize={"12px"}
                          />
                          <YAxis
                            datakey="vl"
                            axisLine={true}
                            fontSize={"12px"}
                          />
                          <Tooltip />
                          <Legend
                            iconType="circle"
                            iconSize={10}
                            height={20}
                            wrapperStyle={{
                              fontSize: isTabScreen ? "10px" : "14px",
                            }}
                          />
                          <Line
                            type="monotone"
                            dataKey="avg_blood_pressure"
                            stroke="#4791FF"
                            strokeWidth={2}
                            dot={{
                              fill: "#4791FF",
                              r: 3,
                            }}
                            activeDot={{ r: 8 }}
                          />
                          <Line
                            type="monotone"
                            dataKey="ideal_blood_pressure"
                            stroke="#02BC77"
                            strokeWidth={2}
                            dot={{
                              fill: "#02BC77",
                              r: 3,
                            }}
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                    <div
                      className="bg-white"
                      style={{
                        height: "90%",
                        width: "90%",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      <div className="d-flex justify-content-center pt-3">
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Temperature Level
                        </div>
                        <div
                          style={{
                            padding: 5,
                            marginLeft: "15px",
                            height: "25px",
                            backgroundColor: "#58CF99",
                            display: "flex",
                            justifyContent: "center",
                            color: "#FFFFFF",
                            borderRadius: "4px",
                            alignItems: "center",
                          }}
                        >
                          Normal
                        </div>
                      </div>
                      <ResponsiveContainer
                        width={window.innerWidth / 2.6}
                        height={window.innerHeight / 2}
                      >
                        <LineChart
                          // width={isTabScreen ? 390 : isScreen3 ? 580 : 550}
                          // height={isTabScreen ? 300 : isScreen3 ? 450 : 250}
                          data={graphData && graphData[0]?.temperature}
                          margin={{
                            top: 5,
                            // // right: 30,
                            left: isTabScreen ? 0 : 20,
                            bottom: 20,
                            // bottom: 0
                          }}
                        >
                          <XAxis
                            dataKey="month_name"
                            axisLine={false}
                            fontSize={"12px"}
                          />
                          <YAxis
                            datakey="vl"
                            axisLine={true}
                            fontSize={"12px"}
                          />
                          <Tooltip />
                          <Legend
                            iconType="circle"
                            iconSize={10}
                            height={20}
                            wrapperStyle={{
                              fontSize: isTabScreen ? "10px" : "14px",
                            }}
                          />
                          <Line
                            type="monotone"
                            dataKey="avg_temperature"
                            stroke="#4791FF"
                            strokeWidth={2}
                            dot={{
                              fill: "#4791FF",
                              r: 3,
                            }}
                            activeDot={{ r: 8 }}
                          />
                          <Line
                            type="monotone"
                            dataKey="ideal_temperature"
                            stroke="#02BC77"
                            strokeWidth={2}
                            dot={{
                              fill: "#02BC77",
                              r: 3,
                            }}
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>

                    <div
                      className="bg-white"
                      style={{
                        height: "90%",
                        width: "90%",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      <div className="d-flex justify-content-center pt-3">
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Weight
                        </div>
                        <div
                          style={{
                            padding: 5,
                            marginLeft: "15px",
                            height: "25px",
                            backgroundColor: "#58CF99",
                            display: "flex",
                            justifyContent: "center",
                            color: "#FFFFFF",
                            borderRadius: "4px",
                            alignItems: "center",
                          }}
                        >
                          Normal
                        </div>
                      </div>
                      <ResponsiveContainer
                        width={window.innerWidth / 2.6}
                        height={window.innerHeight / 2}
                      >
                        <LineChart
                          // width={isTabScreen ? 390 : isScreen3 ? 580 : 550}
                          // height={isTabScreen ? 300 : isScreen3 ? 450 : 250}
                          data={graphData && graphData[0]?.weight}
                          margin={{
                            top: 5,
                            // // right: 30,
                            left: isTabScreen ? 0 : 20,
                            bottom: 20,
                            // bottom: 0
                          }}
                        >
                          <XAxis
                            dataKey="month_name"
                            axisLine={false}
                            fontSize={"12px"}
                          />
                          <YAxis
                            datakey="vl"
                            axisLine={true}
                            fontSize={"12px"}
                          />
                          <Tooltip />
                          <Legend
                            iconType="circle"
                            iconSize={10}
                            height={20}
                            wrapperStyle={{
                              fontSize: isTabScreen ? "10px" : "14px",
                            }}
                          />
                          <Line
                            type="monotone"
                            dataKey="avg_temperature"
                            stroke="#4791FF"
                            strokeWidth={2}
                            dot={{
                              fill: "#4791FF",
                              r: 3,
                            }}
                            activeDot={{ r: 8 }}
                          />
                          <Line
                            type="monotone"
                            dataKey="ideal_weight"
                            stroke="#02BC77"
                            strokeWidth={2}
                            dot={{
                              fill: "#02BC77",
                              r: 3,
                            }}
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                  </Carousel>
                </div>
              </div>
              <div style={{ width: "30%" }}>
                <RecentHistoryRightBox data={p} />
              </div>
            </div>
          ) : (
            <div
              style={{
                backgroundColor: "rgb(246, 231, 236)",
                display: schedule === true && "flex",
                width: "100vw",
              }}
            >
              <div
                style={{
                  width: schedule === true ? "70%" : "100%",
                }}
              >
                <div
                  style={{
                    paddingTop: 50,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    backgroundColor: "rgb(246, 231, 236)",
                  }}
                >
                  <div>
                    <div
                      style={{
                        marginLeft: schedule === true ? "10px" : "60px",
                        fontSize: isTabScreen ? "24px" : "32px",
                        fontWeight: "600",
                        fontFamily: "Poppins",
                        color: configData.THEME_COLORS.PRIMARY,
                      }}
                    >
                      {"Yoga Patient"}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "60px",
                    }}
                  >
                    <Badge
                      badgeContent={count?.toString()}
                      color="primary"
                      overlap="circular"
                      style={{ left: -5, cursor: "pointer" }}
                      sx={{
                        "& .MuiBadge-badge": {
                          color: "#FFFFFF",
                          backgroundColor: "#E42346",
                        },
                      }}
                    >
                      <NotificationsIcon
                        onClick={shownotificationofdr}
                        sx={{
                          width: "25px",
                          height: "25px",
                          color: "#004AAD",
                          cursor: "pointer",
                        }}
                      />
                    </Badge>
                    <Dropdown
                      submenu={submenu}
                      profileData={profileData}
                      onClick={() => showsubmenu()}
                      page="patient"
                    />
                  </div>
                </div>
                <div
                  style={{
                    paddingRight: schedule === true ? "10px" : "40px",
                    paddingLeft: schedule === true ? "10px" : "60px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "120px",
                      // marginLeft: "2%",
                      // marginRight: "2%",
                      backgroundColor: "white",
                      marginTop: "20px",
                      marginBottom: "10px",
                      borderRadius: "15px",
                      // display: "flex",
                      // justifyContent: "space-between",
                      paddingTop: "22px",
                      paddingBottom: "12px",
                    }}
                  >
                    <div
                      onClick={() => {
                        setDesc(!desc);
                        handleSort();
                      }}
                      className="my-1 me-4"
                      style={{
                        fontSize: isTabScreen ? "12px" : "17.54px",
                        color: configData.THEME_COLORS.PRIMARY,
                        fontWeight: "500",
                        fontFamily: "Poppins",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                      }}
                    >
                      {desc ? "New - Old" : "Old - New"}
                      <img
                        src={"/Communication/sort.png"}
                        style={{
                          marginTop: "4px",
                          marginRight: "12px",
                          resizeMode: "contain",
                          height: "20px",
                          width: "20px",
                        }}
                        alt="sort"
                      />{" "}
                    </div>

                    <div
                      className="d-flex justify-space-between align-items-center"
                      style={{ width: "90%", margin: "0px auto" }}
                    >
                      {/* searchbar */}
                      {/* <div style={{ position: "relative", width: "38%" }}> */}
                      <TextField
                        type="search"
                        placeholder="Search Patient"
                        onChange={handlechange}
                        value={searchInput}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <span className="icon">
                                <SearchIcon />
                              </span>
                            </InputAdornment>
                          ),
                          sx: {
                            width: window.innerWidth / 3.8,
                            background: "#F2F2F2",
                            borderRadius: "50px",
                            border: "none",
                            height: isTabScreen ? "40px" : "45px",
                            color: "black",
                            fontSize: isTabScreen ? "10px" : "15px",
                            paddingLeft: "20px", // Adjust as needed for icon or content alignment
                            "& fieldset": { border: "none" },
                          },
                        }}
                      />
                      {/* </div> */}
                      <Box
                        sx={{
                          width: "23%",
                          margin: "0px auto",
                        }}
                      >
                        <FormControl fullWidth size={"small"}>
                          <Select
                            sx={{
                              boxShadow: "none",
                              ".MuiOutlinedInput-notchedOutline": { border: 0 },
                            }}
                            style={{
                              backgroundColor: "#F2F2F2",
                              fontSize: isTabScreen ? "10px" : "15px",
                              fontFamily: "Poppins",
                              fontWeight: "500",
                            }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={drType}
                            displayEmpty
                            onChange={handleChange1}
                          >
                            <MenuItem
                              value=""
                              style={{
                                fontSize: isTabScreen ? "10px" : "15px",
                                fontFamily: "Poppins",
                                fontWeight: "500",
                              }}
                            >
                              Consultation Completed
                            </MenuItem>
                            {doctorTypes.map((item, index) => {
                              return (
                                <MenuItem
                                  value={index}
                                  style={{
                                    fontSize: isTabScreen ? "10px" : "16px",
                                    fontFamily: "Poppins",
                                    fontWeight: "500",
                                  }}
                                >
                                  {item}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>
                      <Box
                        sx={{
                          width: "23%",
                          margin: "0px auto",
                        }}
                      >
                        <FormControl fullWidth size={"small"}>
                          <Select
                            sx={{
                              boxShadow: "none",
                              ".MuiOutlinedInput-notchedOutline": { border: 0 },
                            }}
                            style={{
                              backgroundColor: "#F2F2F2",
                              fontSize: isTabScreen
                                ? "10px"
                                : isTabScreen && clicked
                                  ? "10px"
                                  : "16px",
                              fontFamily: "Poppins",
                              fontWeight: "500",
                            }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={number}
                            onChange={handleChange2}
                            displayEmpty
                          >
                            <MenuItem
                              value=""
                              style={{
                                fontSize: isTabScreen ? "12px" : "16px",
                                fontFamily: "Poppins",
                                fontWeight: "500",
                              }}
                            >
                              Pregnancy Week
                            </MenuItem>
                            {consultationNumbers.map((item, index) => {
                              return (
                                <MenuItem
                                  value={item}
                                  style={{
                                    fontSize: isTabScreen ? "10px" : "16px",
                                    fontFamily: "Poppins",
                                    fontWeight: "500",
                                  }}
                                >
                                  {item}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>
                      <div
                        style={{
                          fontSize: isTabScreen ? "10px" : "15.54px",
                          fontWeight: "500",
                          color: configData.THEME_COLORS.PRIMARY,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          width: "10%",
                          textAlign: "center",
                        }}
                        onClick={() => {
                          setSearchInput("");
                        }}
                      >
                        CLEAR ALL
                      </div>
                    </div>
                  </div>

                  <div
                    className="container"
                    style={{ overflowY: "auto", height: "85vh" }}
                  >
                    <div
                      className="row"
                      style={{
                        marginRight: schedule === true ? "4px" : "10px",
                      }}
                    >
                      {filteredPatients?.map((item) => {
                        return (
                          <Patientlist
                            id={item.id}
                            name={item.fname + " " + item.lname}
                            age={item.age}
                            pregnancy_days={item.pregnancy_days}
                            viewschedule={item.viewschedule}
                            updateschedule={item.updateschedule}
                            viewprofile={item.viewprofile}
                            healthtracker={item.healthtracker}
                            reports={item.reports}
                            casepaper={item.casepaper}
                            onClick={viewschedule}
                            onClick1={updateschedule}
                            onClick2={showreport}
                            onClick3={showcasepaper}
                            onClick4={showhealthtracker}
                            onClick5={showprofile}
                            img1={item?.profile_pic}
                            img2={"/Communication/progresscurve.png"}
                            schedule={schedule}
                            myschedule={myschedule}
                            profile={profile}
                            update={update}
                            report={report}
                            mycasepaper={mycasepaper}
                            myhealthtracker={myhealthtracker}
                            item={item}
                            isPathology={isPathology}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>

              {schedule === true && (
                <div
                  style={{
                    width: "30%",
                    backgroundColor: "white",
                    paddingTop: "50px",
                  }}
                >
                  <div className="d-flex align-items-center px-2">
                    <img
                      src="/lefticon.png"
                      style={{
                        height: "30px",
                        width: "23px",
                        paddingLeft: "5px",
                        color: "configData.THEME_COLORS.SECONDARY",
                      }}
                      onClick={() => closerightside()}
                    ></img>
                    <div
                      style={{
                        fontSize: isTabScreen ? "24px" : "32px",
                        fontWeight: "600",
                        fontFamily: "Poppins",
                        color: configData.THEME_COLORS.SECONDARY,
                        paddingLeft: isTabScreen ? "10px" : "15px",
                      }}
                    >
                      {p?.name}
                    </div>
                  </div>
                  <br></br>
                  <div style={hline}></div>
                  {myschedule ? (
                    <>
                      {" "}
                      <Typography
                        paragraph
                        sx={{
                          color: configData.THEME_COLORS.PRIMARY,
                          display: "flex",
                          justifyContent: "end",
                          padding: "10px 10px",
                        }}
                      >
                        <div
                          style={{
                            fontSize:
                              isTabScreen && clicked
                                ? "8px"
                                : isTabScreen
                                  ? "12px"
                                  : "20px",
                            fontWeight: "500",
                            fontFamily: "Poppins",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setDesc(!desc);
                            handleSort();
                          }}
                        >
                          {desc ? "New - Old" : "Old - New"}
                        </div>

                        <img
                          src={"/Communication/sort.png"}
                          style={{
                            resizeMode: "contain",
                            height: "18px",
                            width: "18px",
                          }}
                        />
                      </Typography>
                      {update ? (
                        <div
                          style={{
                            fontSize: isTabScreen ? "20px" : "28px",
                            fontWeight: "600",
                            fontFamily: "Poppins",
                            paddingLeft: "20px",
                            color: configData.THEME_COLORS.PRIMARY,
                          }}
                        >
                          Select Consultation
                        </div>
                      ) : (
                        <div
                          style={{
                            fontSize: isTabScreen ? "20px" : "28px",
                            fontWeight: "600",
                            fontFamily: "Poppins",
                            paddingLeft: "20px",
                            color: configData.THEME_COLORS.PRIMARY,
                          }}
                        >
                          View Schedule
                        </div>
                      )}
                      <div
                        style={{
                          height: "100vh",
                          overflowY: "scroll",
                        }}
                      >
                        {consultations?.map((item) => {
                          return (
                            <Consultationrightbox
                              key={item}
                              item={item}
                              dates={dates}
                              update={update}
                              scheduledr={scheduledr}
                              onClick={() => changerange(item)}
                              // changerange={changerange}
                              changeWeek={changeWeek}
                              getNextDates={getNextDates}
                              consulationTypeChanege={consulationTypeChanege}
                            />
                          );
                        })}
                      </div>
                    </>
                  ) : range === true ? (
                    <div>
                      <div
                        className="text-center my-3"
                        style={{
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: isTabScreen ? "18px" : "24px",
                          color: "rgba(0, 74, 173, 0.8)",
                        }}
                      >
                        Range
                      </div>
                      <div className="my-2 text-center">Consultation</div>
                      <div style={{ marginTop: 20, alignSelf: "center" }}>
                        <Calendar
                          // mydate={mydate}
                          onChange={onChange}
                          tileDisabled={tileDisabled} // dates={dates}
                          // selectRange={true}
                          timeData={timeData}
                          value={value}
                          showRangeLabels={true}
                          onClickDay={() => {
                            onClickDay();
                          }}
                        />
                      </div>

                      <div className="text-center mt-2">
                        {rangeupdate === true ? (
                          <>
                            <div className="d-flex align-items-center justify-content-center">
                              <div
                                style={{
                                  backgroundColor: "#58CF99",
                                  height: "13px",
                                  width: "13px",
                                  borderRadius: "50%",
                                }}
                              ></div>
                              <div
                                style={{
                                  fontSize: isTabScreen ? "6px" : "12px",
                                  paddingLeft: "5px",
                                  textAlign: "center",
                                }}
                              >
                                Current Selected Range
                              </div>
                            </div>
                            {/* <div className="my-4" style={{ color: "#26931F" }}>
                              Date Range Updated Successfully!
                            </div> */}

                            <Time
                              showTime={range}
                              value={value}
                              timings={timings}
                              dateObjects={dateObjects}
                              timingsafter={timingsafter}
                              timingid={timingid}
                              getTime={getTime}
                              updateSchedule={updateScheduleTime}
                              newUpdateSchedule={newUpdateSchedule}
                              dates={dates}
                              isUpdatedDate={isUpdatedDate}
                              // title={trimesternm}
                              // drpost={drpost}
                              // drstatus={drstatus}
                              // onClick={onClick}
                              date={singledate}
                            />
                          </>
                        ) : (
                          <>
                            <div className="d-flex align-items-center justify-content-between px-3 mt-3 w-100">
                              <div
                                className="d-flex align-items-center"
                                style={{ marginLeft: "8%" }}
                              >
                                <div
                                  style={{
                                    backgroundColor: "rgba(169, 202, 187, 0.5)",
                                    height: "2vh",
                                    width: "1vw",
                                    borderRadius: "50%",
                                  }}
                                ></div>
                                <div
                                  style={{
                                    fontSize: isTabScreen ? "6px" : "12px",
                                    paddingLeft: "12px",
                                  }}
                                >
                                  Previous Range
                                </div>
                              </div>
                              <div
                                className="d-flex align-items-center"
                                style={{ marginRight: "8%" }}
                              >
                                <div
                                  style={{
                                    backgroundColor: "#58CF99",
                                    height: "2vh",
                                    width: "1vw",
                                    borderRadius: "50%",
                                  }}
                                ></div>
                                <div
                                  style={{
                                    fontSize: isTabScreen ? "6px" : "12px",
                                    paddingLeft: "12px",
                                  }}
                                >
                                  Current Selected Range
                                </div>
                              </div>
                            </div>
                            {/* <button
                              style={{
                                background: "#004AAD",
                                height: "40px",
                                width: "110px",
                                border: "1px solid rgba(0, 74, 173, 0.5)",
                                background: "#004AAD",
                                margin: "10px auto",
                                color: "white",
                                borderRadius: "6px",
                              }}
                              className="my-3"
                              onClick={() => updaterange()}
                            >
                              Update
                            </button> */}
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {/* {range === true && <div>jndjnjdnqndeqdoo</div>} */}

                  {report && <Reports report={p} />}
                  {profile && <Profilebox userData={p} profile={profile} />}
                  {mycasepaper && (
                    <Casepaper
                      onClick={() => fullscreen()}
                      cam={cam}
                      mycasepaper={mycasepaper}
                      casepaperdata1st={casepaperdata1st}
                      casepaperdata2nd={casepaperdata2nd}
                      casepaperdata3rd={casepaperdata3rd}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
      <AlertShow
        alertShowHide={showAlert}
        alertClose={handleCloseAlert}
        setalertMsg={alertMsg}
        severity={severity}
      />

      <Modal
        show={open}
        onHide={handleClose}
        // backdrop="static"
        keyboard={false}
        className="p-5"
      >
        <Modal.Header className="d-flex justify-content-start ">
          <img src="/lefticon.png"></img>
          <h2 className="s-w-title mx-4">Select Week</h2>
        </Modal.Header>
        <Modal.Body>
          <Table borderless responsive className="c-w-table">
            <tbody className="text-center">
              {rowNumbers.map((data, i) =>
                i % 7 === 0 ? (
                  <tr key={i}>
                    {[0, 1, 2, 3, 4, 5, 6].map((col) => (
                      <td
                        key={col}
                        onClick={() => handleClickWeek(rowNumbers[i + col])}
                        className={
                          week === rowNumbers[i + col] && week !== null
                            ? "s-w-active-td"
                            : null
                        }
                      >
                        {rowNumbers[i + col]}
                      </td>
                    ))}
                  </tr>
                ) : null,
              )}
            </tbody>
          </Table>
        </Modal.Body>
        {addweekBtn && (
          <Modal.Footer>
            <Button
              variant="primary"
              className="mx-auto"
              onClick={handleClickAddWeek}
            >
              ADD
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}

export default YogaPatient;
