import React, { useEffect, useState } from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Typography from "@mui/material/Typography";
import { useHistory, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import configData from "../../config.json";
import axios from "axios";
import Dropdown from "../Components/Categories/Dropdown";
import { FloodRounded } from "@mui/icons-material";
import AlertShow from "../Components/AlertShow";
import { useNavigate } from "react-router-dom/dist";
let percentage;
function Casepaper() {
  const initialdata = {
    patient_id: "",
    height: "",
    age: "",
    weight: "",
    addiction: "",
    addtiction_consumption: "",
    exercise_habit: "",
    sexual_life: "",
    sex_frequency: "",
    dental_care: "",
    vaccinations: "",
    general_lifestyle_habits: "",
    lifestyle_issues: "",
    work_enviornment: "",
    medical_condition: "",
    regular_medication: "",
    allergies: "",
    smoke_alcohol_habit: "",
    family_health_problems: "",
    surgeries_history: "",
    serious_illness: "",
    regular_medications: "",
    sexual_infection: "",
    chronic_conditions: "",
    family_history_diabetes: "",
    family_history_hbp: "",
    blood_history: "",
    genetic_condition_family: "",
    mental_health_history: "",
    abnormal_pap_smear_history: "",
    personal_family_medical_history: "",
    age_at_first_period: "",
    regular_cycles: "",
    pregnancy_history: "",
    pregnancy_length: "",
    delivery_method: "",
    baby_birth_weight: "",
    anesthesia_analgesia: "",
    pregnancy_complications: "",
    pregnancy_problems: "",
    repro_organ_problems: "",
    last_period_date: "",
    cycle_regularity: "",
    labour_length: "",
    delivery_nature: "",
    term_deliveries: "",
    premature_deliveries: "",
    abortions: "",
    living_children: "",
    repro_menustral_problem: "",
  };
  const [page, setPage2] = useState(false);
  const [page3, setPage3] = useState(false);
  const [page4, setPage4] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedvalueq4, setSelectedValueq4] = useState(null);
  const [anssection1, setAnswersection1] = useState(initialdata);
  const [selectedvalueq1, setSelectedValueq1] = useState(null);
  const [selectedvaluemedicine, setSelectedValuemedicine] = useState(null);
  const [selectedvalueq2, setSelectedValueq2] = useState(null);
  const [selectedvalueq3, setSelectedValueq3] = useState(null);
  const [selectedvalueq38, setSelectedValueq38] = useState(null);
  const [selectedvalueq39, setSelectedValueq39] = useState(null);
  const [selectedvalueq310, setSelectedValueq310] = useState(null);
  const [selectedvalueq33, setSelectedValueq33] = useState(null);
  const [selectedValueq31, setSelectedValueq31] = useState(null);
  const [selectedvalueq6, setSelectedValueq6] = useState(null);
  const [selectedvalueq7, setSelectedValueq7] = useState(null);
  const [selectedvalueq8, setSelectedValueq8] = useState(null);
  const [selectedvalueq9, setSelectedValueq9] = useState(null);
  const [selectedvalueqsec2ainfo, setSelectedValueqsec2ainfo] = useState(null);
  const [selectedvalueqsec2binfo, setSelectedValueqsec2binfo] = useState(null);
  const [selectedvalueqsec2cinfo, setSelectedValueqsec2cinfo] = useState(null);
  const [selectedvalueqsec27info, setSelectedValueqsec27info] = useState(null);
  const [selectedvalueqsec26info, setSelectedValueqsec26info] = useState(null);
  const [selectedvalueqsec25info, setSelectedValueqsec25info] = useState(null);
  const [selectedvalueqsec24info, setSelectedValueqsec24info] = useState(null);
  const [selectedvalueqsec29info, setSelectedValueqsec29info] = useState(null);
  const [selectedvalueqsec210info, setSelectedValueqsec210info] =
    useState(null);
  const [selectedvalueqsec211info, setSelectedValueqsec211info] =
    useState(null);
  const [selectedvalueqsec212info, setSelectedValueqsec212info] =
    useState(null);
  const [selectedvalueqsec213info, setSelectedValueqsec213info] =
    useState(null);
  const [selectedvalueqsec214info, setSelectedValueqsec214info] =
    useState(null);
  const [selectedvalueqsec215info, setSelectedValueqsec215info] =
    useState(null);
  const [selectedvalueqsec217info, setSelectedValueqsec217info] =
    useState(null);
  const [selectedvalueqsec216info, setSelectedValueqsec216info] =
    useState(null);
  const [selectedvalueqsec2a, setSelectedValueqsec2a] = useState(null);
  const [selectedvalueqsec24, setSelectedValueqsec24] = useState(null);
  const [selectedvalueqsec216, setSelectedValueqsec216] = useState(null);
  const [selectedvalueqsec29, setSelectedValueqsec29] = useState(null);
  const [selectedvalueqsec210, setSelectedValueqsec210] = useState(null);
  const [selectedvalueqsec211, setSelectedValueqsec211] = useState(null);
  const [selectedvalueqsec212, setSelectedValueqsec212] = useState(null);
  const [selectedvalueqsec213, setSelectedValueqsec213] = useState(null);
  const [selectedvalueqsec214, setSelectedValueqsec214] = useState(null);
  const [selectedvalueqsec215, setSelectedValueqsec215] = useState(null);
  const [selectedvalueqsec217, setSelectedValueqsec217] = useState(null);
  const [selectedvalueqsec27, setSelectedValueqsec27] = useState(null);
  const [selectedvalueqsec26, setSelectedValueqsec26] = useState(null);
  const [selectedvalueqsec25, setSelectedValueqsec25] = useState(null);
  const [selectedvalueqsec2b, setSelectedValueqsec2b] = useState(null);
  const [selectedvalueqsec2c, setSelectedValueqsec2c] = useState(null);
  const [selectedvalueq9a, setSelectedValueq9a] = useState(null);
  const [selectedvalueq10a, setSelectedValueq10a] = useState(null);
  const [selectedvalueq10, setSelectedValueq10] = useState(null);
  const [selectedvalueq10last, setSelectedValueq10last] = useState(null);
  const [selectedvalueq10lastmsg, setSelectedValueq10lastmsg] = useState(null);
  const [selectedvalueq11, setSelectedValueq11] = useState(null);
  const [selectedvalueq12, setSelectedValueq12] = useState(null);
  const [selectedvalueq13, setSelectedValueq13] = useState(null);
  const [casepaperdata, setCasepaperdata] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [severity, setSeverity] = useState("");
  const showsubmenu = () => {
    setSubmenu(!submenu);
  };
  const [submenu, setSubmenu] = useState(false);
  const handleRadioChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);

    setAnswersection1({
      ...anssection1,
      addiction: newValue,
    });
  };
  const periodcycle = (e) => {
    const newvalue = e.target.value;
    console.log("first", newvalue);
    setSelectedValueq31(newvalue);

    setAnswersection1({
      ...anssection1,
      regular_cycles: newvalue,
    });
  };
  const que6 = (event) => {
    const newValue = event.target.value;
    setSelectedValueq6(newValue);

    setAnswersection1({
      ...anssection1,
      exercise_habit: newValue,
    });
  };
  const que8 = (event) => {
    const newValue = event.target.value;
    setSelectedValueq8(newValue);

    setAnswersection1({
      ...anssection1,
      sex_frequency: newValue,
    });
  };
  const que7 = (event) => {
    const newValue = event.target.value;
    setSelectedValueq7(newValue);

    setAnswersection1({
      ...anssection1,
      sexual_life: newValue,
    });
  };

  const que9a = (event) => {
    const newValue = event.target.value;
    setSelectedValueq9a(newValue);
    if (newValue == "No") {
      setAnswersection1({
        ...anssection1,
        dental_care: "No",
      });
    }
    if (newValue == "Yes" && anssection1.dental_care == "No") {
      setAnswersection1({
        ...anssection1,
        dental_care: "",
      });
    }
  };

  const que9 = (event) => {
    const newValue = event.target.value;
    if (selectedvalueq9a != "No") {
      setAnswersection1({
        ...anssection1,
        dental_care: newValue,
      });
    }
  };

  const que33info = (event) => {
    const newValue = event.target.value;
    setSelectedValueq33(newValue);

    if (newValue == "No") {
      setAnswersection1({
        ...anssection1,
        pregnancy_history: "No",
      });
    }
    if (newValue == "Yes" && anssection1.pregnancy_history == "No") {
      setAnswersection1({
        ...anssection1,
        pregnancy_history: "",
      });
    }
  };

  const que33 = (event) => {
    const newValue = event.target.value;
    // setSelectedValueq33(newValue);
    if (selectedvalueq33 != "No") {
      setAnswersection1({
        ...anssection1,
        pregnancy_history: newValue,
      });
    }
  };
  const que38info = (event) => {
    const newValue = event.target.value;
    setSelectedValueq38(newValue);
    if (newValue == "No") {
      setAnswersection1({
        ...anssection1,
        pregnancy_complications: "No",
      });
    }
    if (newValue == "Yes" && anssection1.pregnancy_complications == "No") {
      setAnswersection1({
        ...anssection1,
        pregnancy_complications: "",
      });
    }
  };

  const que38 = (event) => {
    const newValue = event.target.value;
    // setSelectedValueq38(newValue);
    if (selectedvalueq38 != "No") {
      setAnswersection1({
        ...anssection1,
        pregnancy_complications: newValue,
      });
    }
  };
  const que39info = (event) => {
    const newValue = event.target.value;
    setSelectedValueq39(newValue);
    if (newValue == "No") {
      setAnswersection1({
        ...anssection1,
        pregnancy_problems: "No",
      });
    }
    if (newValue == "Yes" && anssection1.pregnancy_problems == "No") {
      setAnswersection1({
        ...anssection1,
        pregnancy_problems: "",
      });
    }
  };

  const que39 = (event) => {
    const newValue = event.target.value;

    if (selectedvalueq39 != "No") {
      setAnswersection1({
        ...anssection1,
        pregnancy_problems: newValue,
      });
    }
  };
  const que310info = (event) => {
    const newValue = event.target.value;
    setSelectedValueq310(newValue);
    if (newValue == "No") {
      setAnswersection1({
        ...anssection1,
        repro_organ_problems: "No",
      });
    }
    if (newValue == "Yes" && anssection1.repro_organ_problems == "No") {
      setAnswersection1({
        ...anssection1,
        repro_organ_problems: "",
      });
    }
  };

  const que310 = (event) => {
    const newValue = event.target.value;

    if (selectedvalueq310 != "No") {
      setAnswersection1({
        ...anssection1,
        repro_organ_problems: newValue,
      });
    }
  };
  const quesec2a = (event) => {
    const newValue = event.target.value;
    setSelectedValueqsec2a(newValue);
    if (newValue == "No") {
      setAnswersection1({
        ...anssection1,
        medical_condition: "No",
      });
    }
    if (newValue == "Yes" && anssection1.medical_condition == "No") {
      setAnswersection1({
        ...anssection1,
        medical_condition: " ",
      });
    }
  };

  const quesec2ainfo = (event) => {
    const newValue = event.target.value;
    setSelectedValueqsec2ainfo(newValue);
    if (selectedvalueqsec2a == "Yes") {
      setAnswersection1({
        ...anssection1,
        medical_condition: newValue,
      });
    }
  };

  const quesec2b = (event) => {
    const newValue = event.target.value;
    setSelectedValueqsec2b(newValue);
    if (newValue == "No") {
      setAnswersection1({
        ...anssection1,
        allergies: "No",
      });
    }
    if (newValue == "Yes" && anssection1.allergies == "No") {
      setAnswersection1({
        ...anssection1,
        allergies: "",
      });
    }
  };

  const quesec2binfo = (event) => {
    const newValue = event.target.value;
    if (selectedvalueqsec2b != "No") {
      setAnswersection1({
        ...anssection1,
        allergies: newValue,
      });
    }
  };

  const quesec25 = (event) => {
    const newValue = event.target.value;
    setSelectedValueqsec25(newValue);
    if (newValue == "No") {
      setAnswersection1({
        ...anssection1,
        family_health_problems: "No",
      });
    }
    if (newValue == "Yes" && anssection1.family_health_problems == "No") {
      setAnswersection1({
        ...anssection1,
        family_health_problems: "",
      });
    }
  };

  const quesec25info = (event) => {
    const newValue = event.target.value;

    if (selectedvalueqsec25 != "No") {
      setAnswersection1({
        ...anssection1,
        family_health_problems: newValue,
      });
    }
  };
  const quesec29 = (event) => {
    const newValue = event.target.value;
    setSelectedValueqsec29(newValue);
    if (newValue == "No") {
      setAnswersection1({
        ...anssection1,
        sexual_infection: "No",
      });
    }
    if (newValue == "Yes" && anssection1.sexual_infection == "No") {
      setAnswersection1({
        ...anssection1,
        sexual_infection: "",
      });
    }
  };

  const quesec29info = (event) => {
    const newValue = event.target.value;

    if (selectedvalueqsec29 != "No") {
      setAnswersection1({
        ...anssection1,
        sexual_infection: newValue,
      });
    }
  };
  const quesec210 = (event) => {
    const newValue = event.target.value;
    setSelectedValueqsec210(newValue);
    if (newValue == "No") {
      setAnswersection1({
        ...anssection1,
        personal_family_medical_history: "No",
      });
    }
    if (
      newValue == "Yes" &&
      anssection1.personal_family_medical_history == "No"
    ) {
      setAnswersection1({
        ...anssection1,
        personal_family_medical_history: "",
      });
    }
  };

  const quesec210info = (event) => {
    const newValue = event.target.value;

    if (selectedvalueqsec210 != "No") {
      setAnswersection1({
        ...anssection1,
        personal_family_medical_history: newValue,
      });
    }
  };
  const quesec211 = (event) => {
    const newValue = event.target.value;
    setSelectedValueqsec211(newValue);
    if (newValue == "No") {
      setAnswersection1({
        ...anssection1,
        family_history_diabetes: "No",
      });
    }
    if (newValue == "Yes" && anssection1.family_history_diabetes == "No") {
      setAnswersection1({
        ...anssection1,
        family_history_diabetes: "",
      });
    }
  };

  const quesec211info = (event) => {
    const newValue = event.target.value;

    if (selectedvalueqsec211 != "No") {
      setAnswersection1({
        ...anssection1,
        family_history_diabetes: newValue,
      });
    }
  };
  const quesec212 = (event) => {
    const newValue = event.target.value;
    setSelectedValueqsec212(newValue);
    if (newValue == "No") {
      setAnswersection1({
        ...anssection1,
        family_history_hbp: "No",
      });
    }
    if (newValue == "Yes" && anssection1.family_history_hbp == "No") {
      setAnswersection1({
        ...anssection1,
        family_history_hbp: "",
      });
    }
  };

  const quesec212info = (event) => {
    const newValue = event.target.value;
    setSelectedValueqsec212info(newValue);
    if (selectedvalueqsec212 == "yes") {
      setAnswersection1({
        ...anssection1,
        family_history_hbp: newValue,
      });
    }
  };
  const quesec213 = (event) => {
    const newValue = event.target.value;
    setSelectedValueqsec213(newValue);
    if (newValue == "No") {
      setAnswersection1({
        ...anssection1,
        blood_history: "No",
      });
    }
    if (newValue == "Yes" && anssection1.blood_history == "No") {
      setAnswersection1({
        ...anssection1,
        blood_history: "",
      });
    }
  };

  const quesec213info = (event) => {
    const newValue = event.target.value;

    if (selectedvalueqsec213 != "No") {
      setAnswersection1({
        ...anssection1,
        blood_history: newValue,
      });
    }
  };
  const quesec214 = (event) => {
    const newValue = event.target.value;
    setSelectedValueqsec214(newValue);
    if (newValue == "No") {
      setAnswersection1({
        ...anssection1,
        genetic_condition_family: "No",
      });
    }
    if (newValue == "Yes" && anssection1.genetic_condition_family == "No") {
      setAnswersection1({
        ...anssection1,
        genetic_condition_family: "",
      });
    }
  };

  const quesec214info = (event) => {
    const newValue = event.target.value;

    if (selectedvalueqsec214 != "No") {
      setAnswersection1({
        ...anssection1,
        genetic_condition_family: newValue,
      });
    }
  };
  const quesec215 = (event) => {
    const newValue = event.target.value;
    setSelectedValueqsec215(newValue);
    if (newValue == "No") {
      setAnswersection1({
        ...anssection1,
        mental_health_history: "No",
      });
    }
    if (newValue == "No" && anssection1.mental_health_history == "No") {
      setAnswersection1({
        ...anssection1,
        mental_health_history: "",
      });
    }
  };

  const quesec215info = (event) => {
    const newValue = event.target.value;

    if (selectedvalueqsec215 != "No") {
      setAnswersection1({
        ...anssection1,
        mental_health_history: newValue,
      });
    }
  };
  const quesec216 = (event) => {
    const newValue = event.target.value;
    setSelectedValueqsec216(newValue);
    if (newValue == "No") {
      setAnswersection1({
        ...anssection1,
        abnormal_pap_smear_history: "No",
      });
    }
    if (newValue == "Yes" && anssection1.abnormal_pap_smear_history == "No") {
      setAnswersection1({
        ...anssection1,
        abnormal_pap_smear_history: "",
      });
    }
  };
  const quesec216info = (event) => {
    const newValue = event.target.value;
    setSelectedValueqsec216info(newValue);
    if (selectedvalueqsec216 == "yes") {
      setAnswersection1({
        ...anssection1,
        abnormal_pap_smear_history: newValue,
      });
    }
  };
  const quesec217 = (event) => {
    const newValue = event.target.value;
    setSelectedValueqsec217(newValue);
    if (newValue == "No") {
      setAnswersection1({
        ...anssection1,
        chronic_conditions: "No",
      });
    }
    if (newValue == "Yes" && anssection1.chronic_conditions == "No") {
      setAnswersection1({
        ...anssection1,
        chronic_conditions: "",
      });
    }
  };

  const quesec217info = (event) => {
    const newValue = event.target.value;
    setSelectedValueqsec217info(newValue);
    if (selectedvalueqsec217 != "No") {
      setAnswersection1({
        ...anssection1,
        chronic_conditions: newValue,
      });
    }
  };
  const quesec27 = (event) => {
    const newValue = event.target.value;
    setSelectedValueqsec27(newValue);
    if (newValue == "No") {
      setAnswersection1({
        ...anssection1,
        serious_illness: "No",
      });
    }
    if (newValue == "Yes" && anssection1.serious_illness == "No") {
      setAnswersection1({
        ...anssection1,
        serious_illness: "",
      });
    }
  };

  const quesec27info = (event) => {
    const newValue = event.target.value;

    if (selectedvalueqsec27 != "No") {
      setAnswersection1({
        ...anssection1,
        serious_illness: newValue,
      });
    }
  };
  const quesec26 = (event) => {
    const newValue = event.target.value;
    setSelectedValueqsec26(newValue);
    if (newValue == "No") {
      setAnswersection1({
        ...anssection1,
        surgeries_history: "No",
      });
    }
    if (newValue == "Yes" && anssection1.surgeries_history == "No") {
      setAnswersection1({
        ...anssection1,
        surgeries_history: "",
      });
    }
  };

  const quesec26info = (event) => {
    const newValue = event.target.value;

    if (selectedvalueqsec26 != "No") {
      setAnswersection1({
        ...anssection1,
        surgeries_history: newValue,
      });
    }
  };

  const quesec2c = (event) => {
    const newValue = event.target.value;
    setSelectedValueqsec2c(newValue);
    if (newValue == "No") {
      setAnswersection1({
        ...anssection1,
        regular_medication: "No",
      });
    }
    if (newValue == "Yes" && anssection1.regular_medication == "No") {
      setAnswersection1({
        ...anssection1,
        regular_medication: "",
      });
    }
  };

  const quesec2cinfo = (event) => {
    const newValue = event.target.value;

    if (selectedvalueqsec2c != "No") {
      setAnswersection1({
        ...anssection1,
        regular_medication: newValue,
      });
    }
  };

  const que10a = (event) => {
    const newValue = event.target.value;
    setSelectedValueq10a(newValue);
    if (newValue == "No") {
      setAnswersection1({
        ...anssection1,
        vaccinations: "No",
      });
    }
    if (newValue == "Yes" && anssection1.vaccinations == "No") {
      setAnswersection1({
        ...anssection1,
        vaccinations: "",
      });
    }
  };

  const que10last = (event) => {
    const newValue = event.target.value;
    setSelectedValueq10last(newValue);
    if (newValue == "No") {
      setAnswersection1({
        ...anssection1,
        repro_menustral_problem: "No",
      });
    }
    if (newValue == "Yes" || anssection1.repro_menustral_problem == "No") {
      setAnswersection1({
        ...anssection1,
        repro_menustral_problem: "Yes",
      });
    }
    console.log("@@", anssection1.repro_menustral_problem);
  };
  const que10lastmsg = (event) => {
    const newValue = event.target.value;
    console.log("@@1", newValue);
    if (selectedvalueq10last != "No") {
      console.log("@@REPRO", newValue);
      setAnswersection1({
        ...anssection1,
        repro_menustral_problem: newValue,
      });
    }
    console.log("@@2", newValue, anssection1.repro_menustral_problem);
  };
  const quesec24 = (event) => {
    const newValue = event.target.value;
    setSelectedValueqsec24(newValue);

    setAnswersection1({
      ...anssection1,
      smoke_alcohol_habit: newValue,
    });
  };

  const que10 = (event) => {
    const newValue = event.target.value;
    if (selectedvalueq10 != "No") {
      setAnswersection1({
        ...anssection1,
        vaccinations: newValue,
      });
    }
  };

  const que11 = (event) => {
    const newValue = event.target.value;
    setSelectedValueq11(newValue);

    setAnswersection1({
      ...anssection1,
      general_lifestyle_habits: newValue,
    });
  };
  const que12 = (event) => {
    const newValue = event.target.value;
    setSelectedValueq12(newValue);

    setAnswersection1({
      ...anssection1,
      lifestyle_issues: newValue,
    });
  };
  const que13 = (event) => {
    const newValue = event.target.value;
    setSelectedValueq13(newValue);

    setAnswersection1({
      ...anssection1,
      work_enviornment: newValue,
    });
  };
  const inputheight = (e) => {
    const newvalue = e.target.value;
    setSelectedValueq1(newvalue);

    setAnswersection1({
      ...anssection1,
      height: newvalue,
    });
  };

  const inputperiodlast = (e) => {
    const newvalue = e.target.value;
    const formatdate = moment(newvalue).format("YYYY-MM-DD");
    setAnswersection1({
      ...anssection1,
      last_period_date: formatdate,
    });
  };
  const cycleregular = (e) => {
    const newvalue = e.target.value;

    setAnswersection1({
      ...anssection1,
      cycle_regularity: newvalue,
    });
  };
  const labourlen = (e) => {
    const newvalue = e.target.value;

    setAnswersection1({
      ...anssection1,
      labour_length: newvalue,
    });
  };
  const natureofdelivery = (e) => {
    const newvalue = e.target.value;

    setAnswersection1({
      ...anssection1,
      delivery_nature: newvalue,
    });
  };
  const termdelivery = (e) => {
    const newvalue = e.target.value;

    setAnswersection1({
      ...anssection1,
      term_deliveries: newvalue,
    });
  };
  const prematuredelivery = (e) => {
    const newvalue = e.target.value;

    setAnswersection1({
      ...anssection1,
      premature_deliveries: newvalue,
    });
  };
  const abortion = (e) => {
    const newvalue = e.target.value;

    setAnswersection1({
      ...anssection1,
      abortions: newvalue,
    });
  };
  const noofchild = (e) => {
    const newvalue = e.target.value;

    setAnswersection1({
      ...anssection1,
      living_children: newvalue,
    });
  };
  const typeofanalgesia = (e) => {
    const newvalue = e.target.value;
    setSelectedValueq1(newvalue);

    setAnswersection1({
      ...anssection1,
      anesthesia_analgesia: newvalue,
    });
  };
  const inputpreviouspregnancy = (e) => {
    const newvalue = e.target.value;

    setAnswersection1({
      ...anssection1,
      pregnancy_length: newvalue,
    });
  };
  const birthweight = (e) => {
    const newvalue = e.target.value;

    setAnswersection1({
      ...anssection1,
      baby_birth_weight: newvalue,
    });
  };
  const methodofdelivery = (e) => {
    const newvalue = e.target.value;

    setAnswersection1({
      ...anssection1,
      delivery_method: newvalue,
    });
  };
  const inputmedicines = (e) => {
    const newvalue = e.target.value;
    setSelectedValuemedicine(newvalue);

    setAnswersection1({
      ...anssection1,
      regular_medications: newvalue,
    });
  };
  const inputage = (e) => {
    const newvalue = e.target.value;
    setSelectedValueq2(newvalue);

    setAnswersection1({
      ...anssection1,
      age: newvalue,
    });
  };
  const inputweight = (e) => {
    const newvalue = e.target.value;
    setSelectedValueq3(newvalue);

    setAnswersection1({
      ...anssection1,
      weight: newvalue,
    });
  };
  const handleRadioChange4 = (e) => {
    const newvalue = e.target.value;
    setSelectedValueq4(newvalue);

    setAnswersection1({
      ...anssection1,
      addtiction_consumption: newvalue,
    });
  };
  const inputperiod = (e) => {
    const newvalue = e.target.value;
    setAnswersection1({
      ...anssection1,
      age_at_first_period: newvalue,
    });
  };
  useEffect(() => {
    getcasepaperdetails();
    console.log("ANSSECTION!!", anssection1);
  }, []);
  useEffect(() => {
    console.log("state for dental", selectedvalueq9a);
  }, [selectedvalueq9a]);
  const gotopage2 = () => {
    setPage2(true);
    setPage3(false);
    setPage4(false);
  };
  const gotopage4 = () => {
    setPage4(true);
    setPage3(false);
  };
  const gotopage3 = () => {
    setPage3(true);
  };

  const gotoinstruction = () => {
    setPage2(false);
  };
  // function validateData(data) {
  //   for (const key in data) {
  //     if (data[key] === "" || data[key] === undefined || data[key] === null) {
  //       alert(`Please check ${key} is a required field.`);
  //       console.log("<><><><><>>", data[key]);
  //       return false;
  //     }
  //   }
  //   return true;
  // }
  const submitalldata = async (e) => {
    const userId = localStorage.getItem("userId");
    console.log(userId);
    setAnswersection1({
      ...anssection1,
      patient_id: userId,
    });
    e.preventDefault();
    // if (validateData(anssection1)) {
    // history.push("/Dashboard");
    // }
    try {
      console.log("sending data to API", anssection1);
      const response = await fetch(
        `${configData.SERVER_API_URL}/users/add_update_case_paper_form`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(anssection1),
        },
      );
      console.log("CASEPAPER RESPONSE1");
      if (response) {
        console.log("CASEPAPER RESPONSE2");

        const casepaperdata = await response.json();
        if (casepaperdata.success === true) {
          var msg = "saved casepaper successfully:";
          const error_color = "success";
          handleAlertMsg(error_color, msg);
          // alert("saved casepaper successfully:");
          console.log("save casepaper", anssection1);
          navigate("/patient/Dashboard");
        }
      } else {
        const errorData = await response.json();
        console.error("Error in casepaper:", errorData);
      }
    } catch (error) {
      console.log("An error occurred:", error);
    }
  };
  const getcasepaperdetails = async (e) => {
    const userId = localStorage.getItem("userId");

    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/users/get_case_paper_form`,
        {
          patient_id: userId,
        },
      );
      if (response.status == 200) {
        const responsedata = response.data;
        console.log("RESPONSE.....", userId, responsedata);
        const d =
          responsedata.case_paper_form.general_lifestyle_habits_history[0];
        const casepaperdata1st = d[0];

        const e =
          responsedata.case_paper_form.personal_family_medical_profile[0];
        const casepaperdata2nd = e[0];

        const f =
          responsedata.case_paper_form.reproductive_menstrual_history[0];
        const casepaperdata3rd = f[0];
        let format_date = casepaperdata3rd?.last_period_date;
        let lastdate = moment(format_date)?.format("YYYY-MM-DD");
        // const formattedDate = lastdate.substring(0, 10);
        console.log("LASTDATE", lastdate);
        const keysToExclude = ["id", "created_at", "updated_at", "patient_id"];
        if (d.length == 0 && e.length == 0 && f.length == 0) {
          percentage = 0;
          console.log("0000000");
        } else {
          const totalNonNullCount = d
            .map(
              (obj) =>
                Object.values(obj).filter(
                  (value, index) =>
                    value !== "" &&
                    !keysToExclude.includes(Object.keys(obj)[index]),
                ).length,
            )
            .reduce((acc, count) => acc + count, 0);

          console.log(
            "Total non-null values inside objects casepaperdata1st:",
            totalNonNullCount,
          );

          const totalNonNullCount1 = e
            .map(
              (obj) =>
                Object.values(obj).filter(
                  (value, index) =>
                    value !== "" &&
                    !keysToExclude.includes(Object.keys(obj)[index]),
                ).length,
            )
            .reduce((acc, count) => acc + count, 0);

          console.log(
            "Total non-null values inside objects casepaperdata2nd:",
            totalNonNullCount1,
          );
          const totalNonNullCount2 = f
            .map(
              (obj) =>
                Object.values(obj).filter(
                  (value, index) =>
                    value !== "" &&
                    value !== null &&
                    !keysToExclude.includes(Object.keys(obj)[index]),
                ).length,
            )
            .reduce((acc, count) => acc + count, 0);

          console.log(
            "Total non-null values inside objects casepaperdata3rd:",
            totalNonNullCount2,
          );
          const totalanswered =
            totalNonNullCount + totalNonNullCount1 + totalNonNullCount2;
          percentage = Math.round((100 * totalanswered) / 49);

          if (d != null) {
          }
        }

        setAnswersection1({
          ...anssection1,
          patient_id: casepaperdata2nd?.patient_id,
          weight: casepaperdata1st.weight ? casepaperdata1st.weight : "",
          height: casepaperdata1st?.height,
          age: casepaperdata1st?.age,
          exercise_habit: casepaperdata1st?.exercise_habit,
          addiction: casepaperdata1st?.addiction,
          addtiction_consumption: casepaperdata1st?.addtiction_consumption,
          dental_care: casepaperdata1st?.dental_care,
          general_lifestyle_habits: casepaperdata1st?.general_lifestyle_habits,
          lifestyle_issues: casepaperdata1st?.lifestyle_issues,
          sex_frequency: casepaperdata1st?.sex_frequency,
          sexual_life: casepaperdata1st?.sexual_life,
          vaccinations: casepaperdata1st?.vaccinations,
          work_enviornment: casepaperdata1st?.work_enviornment,

          patient_id: casepaperdata2nd?.patient_id,
          medical_condition: casepaperdata2nd?.medical_condition,
          mental_health_history: casepaperdata2nd?.mental_health_history,
          abnormal_pap_smear_history:
            casepaperdata2nd?.abnormal_pap_smear_history,
          allergies: casepaperdata2nd?.allergies,
          blood_history: casepaperdata2nd?.blood_history,
          chronic_conditions: casepaperdata2nd?.chronic_conditions,
          family_health_problems: casepaperdata2nd?.family_health_problems,
          family_history_diabetes: casepaperdata2nd?.family_history_diabetes,
          family_history_hbp: casepaperdata2nd?.family_history_hbp,
          genetic_condition_family: casepaperdata2nd?.genetic_condition_family,
          personal_family_medical_history:
            casepaperdata2nd?.personal_family_medical_history,
          regular_medication: casepaperdata2nd?.regular_medication,
          regular_medications: casepaperdata2nd?.regular_medications,
          serious_illness: casepaperdata2nd?.serious_illness,
          sexual_infection: casepaperdata2nd?.sexual_infection,
          smoke_alcohol_habit: casepaperdata2nd?.smoke_alcohol_habit,
          surgeries_history: casepaperdata2nd?.surgeries_history,

          last_period_date: lastdate,

          abortions: casepaperdata3rd?.abortions,
          age_at_first_period: casepaperdata3rd?.age_at_first_period,
          anesthesia_analgesia: casepaperdata3rd?.anesthesia_analgesia,
          baby_birth_weight: casepaperdata3rd?.baby_birth_weight,
          cycle_regularity: casepaperdata3rd?.cycle_regularity,
          delivery_method: casepaperdata3rd?.delivery_method,
          delivery_nature: casepaperdata3rd?.delivery_nature,
          labour_length: casepaperdata3rd?.labour_length,

          living_children: casepaperdata3rd?.living_children,
          pregnancy_complications: casepaperdata3rd?.pregnancy_complications,
          pregnancy_history: casepaperdata3rd?.pregnancy_history,
          pregnancy_length: casepaperdata3rd?.pregnancy_length,
          pregnancy_problems: casepaperdata3rd?.pregnancy_problems,
          premature_deliveries: casepaperdata3rd?.premature_deliveries,
          regular_cycles: casepaperdata3rd?.regular_cycles,
          repro_menustral_problem: casepaperdata3rd?.repro_menustral_problem,
          repro_organ_problems: casepaperdata3rd?.repro_organ_problems,
          term_deliveries: casepaperdata3rd?.term_deliveries,
        });

        if (
          casepaperdata1st.dental_care != "Yes" &&
          casepaperdata1st.dental_care != "No"
        ) {
          setSelectedValueq9a("Yes");
        }
        if (casepaperdata1st.dental_care == "No") {
          setSelectedValueq9a("No");
        }
        if (casepaperdata1st?.dental_care == "") {
          setSelectedValueq9a("");
        }
        if (
          casepaperdata1st.vaccinations != "Yes" &&
          casepaperdata1st.vaccinations != "No"
        ) {
          setSelectedValueq10a("Yes");
        }
        if (casepaperdata1st.vaccinations == "No") {
          setSelectedValueq10a("No");
        }
        if (casepaperdata1st?.vaccinations == "") {
          setSelectedValueq10a("");
        }
        if (
          casepaperdata2nd.medical_condition != "Yes" &&
          casepaperdata2nd.medical_condition != "No"
        ) {
          setSelectedValueqsec2a("Yes");
        }
        if (casepaperdata2nd.medical_condition == "No") {
          setSelectedValueqsec2a("No");
        }
        if (casepaperdata2nd.medical_condition == "") {
          setSelectedValueqsec2a("");
        }
        if (
          casepaperdata2nd.regular_medication != "Yes" &&
          casepaperdata2nd.regular_medication != "No"
        ) {
          setSelectedValueqsec2c("Yes");
        }
        if (casepaperdata2nd.regular_medication == "No") {
          setSelectedValueqsec2c("No");
        }
        if (casepaperdata2nd.regular_medication == "") {
          setSelectedValueqsec2c("");
        }
        if (
          casepaperdata2nd.allergies != "Yes" &&
          casepaperdata2nd.allergies != "No"
        ) {
          setSelectedValueqsec2b("Yes");
        }
        if (casepaperdata2nd.allergies == "No") {
          setSelectedValueqsec2b("No");
        }
        if (casepaperdata2nd.allergies == "") {
          setSelectedValueqsec2b("");
        }
        if (
          casepaperdata2nd.family_health_problems != "Yes" &&
          casepaperdata2nd.family_health_problems != "No"
        ) {
          setSelectedValueqsec25("Yes");
        }
        if (casepaperdata2nd.family_health_problems == "No") {
          setSelectedValueqsec25("No");
        }
        if (casepaperdata2nd.family_health_problems == "") {
          setSelectedValueqsec25("");
        }
        if (
          casepaperdata2nd.surgeries_history != "Yes" &&
          casepaperdata2nd.surgeries_history != "No"
        ) {
          setSelectedValueqsec26("Yes");
        }
        if (casepaperdata2nd.surgeries_history == "No") {
          setSelectedValueqsec26("No");
        }
        if (casepaperdata2nd.surgeries_history == "") {
          setSelectedValueqsec26("");
        }
        if (
          casepaperdata2nd.serious_illness != "Yes" &&
          casepaperdata2nd.serious_illness != "No"
        ) {
          setSelectedValueqsec27("Yes");
        }
        if (casepaperdata2nd.serious_illness == "No") {
          setSelectedValueqsec27("No");
        }
        if (casepaperdata2nd.serious_illness == "") {
          setSelectedValueqsec27("");
        }
        if (
          casepaperdata2nd.sexual_infection != "Yes" &&
          casepaperdata2nd.sexual_infection != "No"
        ) {
          setSelectedValueqsec29("Yes");
        }
        if (casepaperdata2nd.sexual_infection == "No") {
          setSelectedValueqsec29("No");
        }
        if (casepaperdata2nd.sexual_infection == "") {
          setSelectedValueqsec29("");
        }
        if (
          casepaperdata2nd.personal_family_medical_history != "Yes" &&
          casepaperdata2nd.personal_family_medical_history != "No"
        ) {
          setSelectedValueqsec210("Yes");
        }
        if (casepaperdata2nd.personal_family_medical_history == "No") {
          setSelectedValueqsec210("No");
        }
        if (casepaperdata2nd.personal_family_medical_history == "") {
          setSelectedValueqsec210("");
        }
        if (
          casepaperdata2nd.family_history_diabetes != "Yes" &&
          casepaperdata2nd.family_history_diabetes != "No"
        ) {
          setSelectedValueqsec211("Yes");
        }
        if (casepaperdata2nd.family_history_diabetes == "No") {
          setSelectedValueqsec211("No");
        }
        if (casepaperdata2nd.family_history_diabetes == "") {
          setSelectedValueqsec211("");
        }
        if (
          casepaperdata2nd.family_history_hbp != "Yes" &&
          casepaperdata2nd.family_history_hbp != "No"
        ) {
          setSelectedValueqsec212("Yes");
        }
        if (casepaperdata2nd.family_history_hbp == "No") {
          setSelectedValueqsec212("No");
        }
        if (casepaperdata2nd.family_history_hbp == "") {
          setSelectedValueqsec212("");
        }
        if (
          casepaperdata2nd.blood_history != "Yes" &&
          casepaperdata2nd.blood_history != "No"
        ) {
          setSelectedValueqsec213("Yes");
        }
        if (casepaperdata2nd.blood_history == "No") {
          setSelectedValueqsec213("No");
        }
        if (casepaperdata2nd.blood_history == "") {
          setSelectedValueqsec213("");
        }
        if (
          casepaperdata2nd.genetic_condition_family != "Yes" &&
          casepaperdata2nd.genetic_condition_family != "No"
        ) {
          setSelectedValueqsec214("Yes");
        }
        if (casepaperdata2nd.genetic_condition_family == "No") {
          setSelectedValueqsec214("No");
        }
        if (casepaperdata2nd.genetic_condition_family == "") {
          setSelectedValueqsec214("");
        }
        if (
          casepaperdata2nd.mental_health_history != "Yes" &&
          casepaperdata2nd.mental_health_history != "No"
        ) {
          setSelectedValueqsec215("Yes");
        }
        if (casepaperdata2nd.mental_health_history == "No") {
          setSelectedValueqsec215("No");
        }
        if (casepaperdata2nd.mental_health_history == "") {
          setSelectedValueqsec215("");
        }
        if (
          casepaperdata2nd.personal_family_medical_history != "Yes" &&
          casepaperdata2nd.personal_family_medical_history != "No"
        ) {
          setSelectedValueqsec217("Yes");
        }
        if (casepaperdata2nd.personal_family_medical_history == "No") {
          setSelectedValueqsec217("No");
        }
        if (casepaperdata2nd.personal_family_medical_history == "") {
          setSelectedValueqsec217("");
        }
        if (
          casepaperdata2nd.pregnancy_history != "Yes" &&
          casepaperdata2nd.pregnancy_history != "No"
        ) {
          setSelectedValueq33("Yes");
        }
        if (casepaperdata2nd.pregnancy_history == "No") {
          setSelectedValueq33("No");
        }
        if (casepaperdata2nd.pregnancy_history == "") {
          setSelectedValueq33("");
        }
        if (
          casepaperdata3rd?.pregnancy_complications != "Yes" &&
          casepaperdata3rd?.pregnancy_complications != "No"
        ) {
          setSelectedValueq38("Yes");
        }
        if (casepaperdata3rd?.pregnancy_complications == "No") {
          setSelectedValueq38("No");
        }
        if (casepaperdata3rd?.pregnancy_complications == "") {
          setSelectedValueq38("");
        }
        if (
          casepaperdata3rd?.pregnancy_problems != "Yes" &&
          casepaperdata3rd?.pregnancy_problems != "No"
        ) {
          setSelectedValueq39("Yes");
        }
        if (casepaperdata3rd?.pregnancy_problems == "No") {
          setSelectedValueq39("No");
        }
        if (casepaperdata3rd?.pregnancy_problems == "") {
          setSelectedValueq39("");
        }

        if (
          casepaperdata3rd?.repro_organ_problems != "Yes" &&
          casepaperdata3rd?.repro_organ_problems != "No"
        ) {
          setSelectedValueq310("Yes");
        }
        if (casepaperdata3rd?.repro_organ_problems == "No") {
          setSelectedValueq310("No");
        }
        if (casepaperdata3rd?.repro_organ_problems == "") {
          setSelectedValueq310("");
        }
        // if only yes is selected
        if (
          (casepaperdata3rd?.repro_menustral_problem != "Yes" &&
            casepaperdata3rd?.repro_menustral_problem != "No") ||
          casepaperdata3rd?.repro_menustral_problem == "Yes"
        ) {
          setSelectedValueq10last("Yes");
        }
        if (casepaperdata3rd?.repro_menustral_problem == "No") {
          setSelectedValueq10last("No");
        }
        if (casepaperdata3rd?.repro_menustral_problem == "") {
          setSelectedValueq10last("");
        }

        console.log("GET DETAIL OF CASEPAPER1", casepaperdata1st);
        console.log("GET DETAIL OF CASEPAPERpptt", casepaperdata2nd);
        console.log("GET DETAIL OF CASEPAPE33", casepaperdata3rd);
        console.log("anssection1first", anssection1);
        // setCasepaperdata
      } else {
        console.log("error...");
      }
    } catch (error) {
      console.log("error====", error);
    }
  };
  console.log("0000000", percentage);
  // const history = useHistory();
  const navigate = useNavigate();
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1200px)",
  });

  const handleAlertMsg = (error_type, msg) => {
    setShowAlert(true);
    setSeverity(error_type);
    setAlertMsg(msg);
    setTimeout(() => {
      setShowAlert(false);
    }, 2500);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  return (
    <div
      className="pb-5"
      style={{
        backgroundColor: configData.THEME_COLORS.BACKGROUND,
      }}
    >
      <div
        className=" mx-auto"
        style={{
          paddingTop: 50,
          width: "80%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          // marginRight: "50px",
        }}
      >
        <Typography
          className="mb-0 "
          paragraph
          sx={{
            color: configData.THEME_COLORS.PRIMARY,
            // marginLeft: isTabScreen ? "20px" : "40px",
          }}
        >
          {page === false ? (
            <div
              onClick={() => {
                const percentage = 40;
                navigate("/patient/Dashboard", { percentage });
              }}
              style={{
                fontSize: isTabScreen ? "18px" : "20px",
                fontWeight: "600",
                fontFamily: "Poppins",
                cursor: "pointer",
              }}
            >
              {"Back"}
            </div>
          ) : page3 == true ? (
            <>
              <div
                style={{
                  fontSize: isTabScreen ? "18px" : "20px",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                  cursor: "pointer",
                }}
              >
                Case Paper
              </div>
              <div
                onClick={gotopage2}
                style={{
                  fontSize: isTabScreen ? "10px" : "12px",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                  cursor: "pointer",
                }}
              >
                Back
              </div>
            </>
          ) : page4 == true ? (
            <div
              style={{
                fontSize: isTabScreen ? "18px" : "20px",
                fontWeight: "600",
                fontFamily: "Poppins",
                cursor: "pointer",
              }}
            >
              Case Paper
            </div>
          ) : (
            <>
              <div
                style={{
                  fontSize: isTabScreen ? "18px" : "20px",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                  cursor: "pointer",
                }}
              >
                Case Paper
              </div>
              <div
                onClick={gotoinstruction}
                style={{
                  fontSize: isTabScreen ? "10px" : "12px",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                  cursor: "pointer",
                }}
              >
                Back
              </div>
            </>
          )}
        </Typography>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            // marginRight: "-1%",
          }}
        >
          <NotificationsIcon
            sx={{
              width: "25px",
              height: "25px",
              color: "#004AAD",
            }}
          />
          <Dropdown
            submenu={submenu}
            onClick={() => showsubmenu()}
            page="dashboard"
          />
          {/* <AccountCircleIcon
            sx={{
              marginLeft: "16px",
              width: "33px",
              height: "33px",
              color: "#004AAD",
            }}
          /> */}
        </div>
      </div>
      <div
        className=" bg-white py-4 mt-3"
        style={{
          borderTop: "4px solid #4f83b1",
          position: "relative",
          margin: "0px auto",
          borderRadius: "6px",
          width: "80%",
        }}
      >
        {page === false ? (
          <>
            <div
              style={{
                position: "absolute",
                top: "-20px",
                left: "45%",
                height: "36px",
                width: "120px",
                backgroundColor: "#4f83b1",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bolder",
                borderRadius: "5px",
              }}
            >
              Case Paper
            </div>
            <div className="pt-4">
              <div className="text-center">
                <img
                  style={{
                    resizeMode: "contain",
                    height: "250px",
                    width: "250px",
                    margin: "10px auto",
                  }}
                  src={"/Dashboard/logo-removebg.png"}
                ></img>
                <div>
                  <img
                    src={"/Dashboard/rectangle.png"}
                    style={{ margin: "0px auto" }}
                  ></img>
                </div>
              </div>
            </div>

            <h4
              className="ps-5 py-2"
              style={{
                color: "#F0588B",
                textTransform: "uppercase",
                fontSize: "24px",
              }}
            >
              instructions:
            </h4>
            <div
              className="py-3 mx-auto text-capitalize"
              style={{ width: "46%" }}
            >
              <ul className="">
                <li>
                  This section consists of questions with serial number 1-3.
                </li>
                <li>All Question are Compulsory.</li>
                <li>Answer all questions.</li>
                <li>Marks are indicated against each question.</li>
              </ul>
            </div>
            <div className="my-3 text-center">
              <button
                onClick={gotopage2}
                style={{
                  height: "45px",
                  width: "300px",
                  fontSize: "15px",
                  fontWeight: "400",
                  border: "1px solid #F0588B",
                  borderRadius: "5px",
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "#F0588B"; // Change background color on hover
                  e.target.style.color = "white"; // Change text color on hover
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "white"; // Restore background color
                  e.target.style.color = "#7C9CBF"; // Restore text color
                }}
              >
                NEXT
              </button>
            </div>
          </>
        ) : page3 === true ? (
          <div className="py-3 ps-5">
            <div
              style={{
                position: "absolute",
                top: "-20px",
                left: "45%",
                height: "36px",
                width: "120px",
                backgroundColor: "#4f83b1",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bolder",
                borderRadius: "5px",
              }}
            >
              Questions
            </div>
            <div
              style={{
                position: "absolute",
                border: "1px solid gray",
                top: "-24px",
                left: "75%",
                height: "55px",
                width: "55px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: configData.THEME_COLORS.PRIMARY,
                backgroundColor: "white",
              }}
            >
              {percentage}%
            </div>
            <div
              style={{
                color: "#F0588B",
                fontSize: "24px",
                fontWeight: "600",
              }}
            >
              Personal & family medical profile
            </div>
            <div
              style={{
                height: "450px",
                overflowY: "scroll",
              }}
            >
              <div className="d-flex flex-column justify-content-between">
                <div className="que1">
                  1. Do you suffer from any existing medical conditions
                  <span className="subq">(If Yes,Describe in Details)?</span>
                  <div className="radio-button-container">
                    <label className="radio-label">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        value="Yes"
                        name="medical_condition"
                        checked={selectedvalueqsec2a === "Yes"}
                        onChange={quesec2a}
                      />{" "}
                      <span className="custom-radio"></span>
                      yes
                    </label>
                    <label className="radio-label ms-5 ">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        value="No"
                        name="medical_condition"
                        checked={selectedvalueqsec2a === "No"}
                        onChange={quesec2a}
                      />{" "}
                      <span className="custom-radio"></span>
                      no
                    </label>
                  </div>
                </div>
                {selectedvalueqsec2a == "Yes" ? (
                  <textarea
                    className="msg"
                    onChange={quesec2ainfo}
                    value={anssection1?.medical_condition}
                  ></textarea>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex flex-column justify-content-between">
                <div className="que1">
                  2. Have you been prescribed any regular medication
                  <span className="subq">(If Yes,Describe in Details)?</span>
                  <div className="radio-button-container">
                    <label className="radio-label">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answersec2c"
                        value="Yes"
                        checked={selectedvalueqsec2c == "Yes"}
                        onChange={quesec2c}
                      />{" "}
                      <span className="custom-radio"></span>
                      yes
                    </label>
                    <label className="radio-label ms-5 ">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answersec2c"
                        value="No"
                        checked={selectedvalueqsec2c == "No"}
                        onChange={quesec2c}
                      />{" "}
                      <span className="custom-radio"></span>
                      no
                    </label>
                  </div>
                </div>
                {selectedvalueqsec2c == "Yes" ? (
                  <textarea
                    className="msg"
                    onChange={quesec2cinfo}
                    value={anssection1?.regular_medication}
                  ></textarea>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex flex-column justify-content-between">
                <div className="que1">
                  3. Do you have any allergies
                  <span className="subq">(If Yes,Describe in Details)?</span>
                  <div className="radio-button-container">
                    <label className="radio-label">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answersec2b"
                        value="Yes"
                        checked={selectedvalueqsec2b == "Yes"}
                        onChange={quesec2b}
                      />{" "}
                      <span className="custom-radio"></span>
                      yes
                    </label>
                    <label className="radio-label ms-5 ">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answersec2b"
                        value="No"
                        checked={selectedvalueqsec2b == "No"}
                        onChange={quesec2b}
                      />{" "}
                      <span className="custom-radio"></span>
                      no
                    </label>
                  </div>
                </div>
                {selectedvalueqsec2b == "Yes" ? (
                  <textarea
                    className="msg"
                    onChange={quesec2binfo}
                    value={anssection1?.allergies}
                  ></textarea>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex flex-column justify-content-between">
                <div className="que1">
                  4. Do you smoke or consume alcohol frequently?
                  <div className="radio-button-container">
                    <label className="radio-label">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answersec24"
                        value="Yes"
                        checked={anssection1.smoke_alcohol_habit === "Yes"}
                        onChange={quesec24}
                      />{" "}
                      <span className="custom-radio"></span>
                      yes
                    </label>
                    <label className="radio-label ms-5 ">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answersec24"
                        value="No"
                        checked={anssection1.smoke_alcohol_habit === "No"}
                        onChange={quesec24}
                      />{" "}
                      <span className="custom-radio"></span>
                      no
                    </label>
                    <label className="radio-label ms-5 ">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answersec24"
                        value="Never"
                        checked={anssection1.smoke_alcohol_habit === "Never"}
                        onChange={quesec24}
                      />{" "}
                      <span className="custom-radio"></span>
                      never
                    </label>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-between">
                <div className="que1">
                  5. Having any Health problems in your family, such as heart
                  disease or genetic conditions
                  <span className="subq">(If Yes,Describe in Details)?</span>
                  <div className="radio-button-container">
                    <label className="radio-label">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answersec25"
                        value="Yes"
                        checked={selectedvalueqsec25 === "Yes"}
                        onChange={quesec25}
                      />{" "}
                      <span className="custom-radio"></span>
                      yes
                    </label>
                    <label className="radio-label ms-5 ">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answersec25"
                        value="No"
                        checked={selectedvalueqsec25 === "No"}
                        onChange={quesec25}
                      />{" "}
                      <span className="custom-radio"></span>
                      no
                    </label>
                  </div>
                </div>
                {selectedvalueqsec25 === "Yes" ? (
                  <textarea
                    className="msg"
                    onChange={quesec25info}
                    value={anssection1?.family_health_problems}
                  ></textarea>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex flex-column justify-content-between">
                <div className="que1">
                  6. Have you gone through any type of surgeries previously or
                  currently
                  <span className="subq">(If Yes,Describe in Details)?</span>
                  <div className="radio-button-container">
                    <label className="radio-label">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answersec26"
                        value="Yes"
                        checked={selectedvalueqsec26 === "Yes"}
                        onChange={quesec26}
                      />{" "}
                      <span className="custom-radio"></span>
                      yes
                    </label>
                    <label className="radio-label ms-5 ">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answersec26"
                        value="No"
                        checked={selectedvalueqsec26 === "No"}
                        onChange={quesec26}
                      />{" "}
                      <span className="custom-radio"></span>
                      no
                    </label>
                  </div>
                </div>
                {selectedvalueqsec26 == "Yes" ? (
                  <textarea
                    className="msg"
                    value={anssection1?.surgeries_history}
                    onChange={quesec26info}
                  ></textarea>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex flex-column justify-content-between">
                <div className="que1">
                  7. Any serious illnesses you have had
                  <span className="subq">(If Yes,Describe in Details)?</span>
                  <div className="radio-button-container">
                    <label className="radio-label">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answersec27"
                        value="Yes"
                        checked={selectedvalueqsec27 === "Yes"}
                        onChange={quesec27}
                      />{" "}
                      <span className="custom-radio"></span>
                      yes
                    </label>
                    <label className="radio-label ms-5 ">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answersec27"
                        value="No"
                        checked={selectedvalueqsec27 === "No"}
                        onChange={quesec27}
                      />{" "}
                      <span className="custom-radio"></span>
                      no
                    </label>
                  </div>
                </div>
                {selectedvalueqsec27 == "Yes" ? (
                  <textarea
                    className="msg"
                    value={anssection1?.serious_illness}
                    onChange={quesec27info}
                  ></textarea>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex flex-column justify-content-between">
                <div className="que1">
                  8. Any medicines you take regularly (This includes
                  prescription and over-the-counter medicines, vitamins, and
                  supplements)?
                </div>
                <textarea
                  className="msg"
                  value={anssection1?.regular_medications}
                  onChange={inputmedicines}
                ></textarea>
              </div>
              <div className="d-flex flex-column justify-content-between">
                <div className="que">
                  9. Do you currently have or have ever had sexually transmitted
                  infections, such as gonorrhea, chlamydia, trichomonas, herpes
                  simplex, syphilis, HIV or genital warts
                  <span className="subq">(If Yes,Describe in Details)?</span>
                  <div className="radio-button-container">
                    <label className="radio-label">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answersec29"
                        value="Yes"
                        checked={selectedvalueqsec29 === "Yes"}
                        onChange={quesec29}
                      />{" "}
                      <span className="custom-radio"></span>
                      yes
                    </label>
                    <label className="radio-label ms-5 ">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answersec29"
                        value="No"
                        checked={selectedvalueqsec29 === "No"}
                        onChange={quesec29}
                      />{" "}
                      <span className="custom-radio"></span>
                      no
                    </label>
                  </div>
                </div>
                {selectedvalueqsec29 == "Yes" ? (
                  <textarea
                    className="msg"
                    onChange={quesec29info}
                    value={anssection1?.sexual_infection}
                  ></textarea>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex flex-column justify-content-between">
                <div className="que1">
                  10. Do you suffer currently or previously with any of
                  diabetes, lupus, high blood pressure, lung disease or heart
                  disease
                  <span className="subq">(If Yes,Describe in Details)?</span>
                  <div className="radio-button-container">
                    <label className="radio-label">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answersec210"
                        value="Yes"
                        checked={selectedvalueqsec210 === "Yes"}
                        onChange={quesec210}
                      />{" "}
                      <span className="custom-radio"></span>
                      yes
                    </label>
                    <label className="radio-label ms-5 ">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answersec210"
                        value="No"
                        checked={selectedvalueqsec210 === "No"}
                        onChange={quesec210}
                      />{" "}
                      <span className="custom-radio"></span>
                      no
                    </label>
                  </div>
                </div>
                {selectedvalueqsec210 == "Yes" ? (
                  <textarea
                    className="msg"
                    value={anssection1?.personal_family_medical_history}
                    onChange={quesec210info}
                  ></textarea>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex flex-column justify-content-between">
                <div className="que1">
                  11. Do you have a family history of diabetes?
                  <span className="subq">(If Yes,Describe in Details)?</span>
                  <div className="radio-button-container">
                    <label className="radio-label">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answersec211"
                        value="Yes"
                        checked={selectedvalueqsec211 === "Yes"}
                        onChange={quesec211}
                      />{" "}
                      <span className="custom-radio"></span>
                      yes
                    </label>
                    <label className="radio-label ms-5 ">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answersec211"
                        value="No"
                        checked={selectedvalueqsec211 === "No"}
                        onChange={quesec211}
                      />{" "}
                      <span className="custom-radio"></span>
                      no
                    </label>
                  </div>
                </div>
                {selectedvalueqsec211 == "Yes" ? (
                  <textarea
                    className="msg"
                    value={anssection1?.family_history_diabetes}
                    onChange={quesec211info}
                  ></textarea>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex flex-column justify-content-between">
                <div className="que1">
                  12. Do you have a family history of high blood pressure
                  <span className="subq">(If Yes,Describe in Details)?</span>
                  <div className="radio-button-container">
                    <label className="radio-label">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answersec212"
                        value="Yes"
                        checked={selectedvalueqsec212 === "Yes"}
                        onChange={quesec212}
                      />{" "}
                      <span className="custom-radio"></span>
                      yes
                    </label>
                    <label className="radio-label ms-5 ">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answersec212"
                        value="No"
                        checked={selectedvalueqsec212 === "No"}
                        onChange={quesec212}
                      />{" "}
                      <span className="custom-radio"></span>
                      no
                    </label>
                  </div>
                </div>
                {selectedvalueqsec212 == "Yes" ? (
                  <textarea className="msg" onChange={quesec212info}></textarea>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex flex-column justify-content-between">
                <div className="que1">
                  13. Do you have a history of blood transfusions and trauma or
                  violence
                  <span className="subq">(If Yes,Describe in Details)?</span>
                  <div className="radio-button-container">
                    <label className="radio-label">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answersec213"
                        value="Yes"
                        checked={selectedvalueqsec213 === "Yes"}
                        onChange={quesec213}
                      />{" "}
                      <span className="custom-radio"></span>
                      yes
                    </label>
                    <label className="radio-label ms-5 ">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answersec213"
                        value="No"
                        checked={selectedvalueqsec213 === "No"}
                        onChange={quesec213}
                      />{" "}
                      <span className="custom-radio"></span>
                      no
                    </label>
                  </div>
                </div>
                {selectedvalueqsec213 == "Yes" ? (
                  <textarea className="msg" onChange={quesec213info}></textarea>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex flex-column justify-content-between">
                <div className="que1">
                  14. Does your family have a predisposition to certain genetic
                  conditions
                  <span className="subq">(If Yes,Describe in Details)?</span>
                  <div className="radio-button-container">
                    <label className="radio-label">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answersec214"
                        value="Yes"
                        checked={selectedvalueqsec214 === "Yes"}
                        onChange={quesec214}
                      />{" "}
                      <span className="custom-radio"></span>
                      yes
                    </label>
                    <label className="radio-label ms-5 ">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answersec214"
                        value="No"
                        checked={selectedvalueqsec214 === "No"}
                        onChange={quesec214}
                      />{" "}
                      <span className="custom-radio"></span>
                      no
                    </label>
                  </div>
                </div>
                {selectedvalueqsec214 == "Yes" ? (
                  <textarea
                    className="msg"
                    value={anssection1?.genetic_condition_family}
                    onChange={quesec214info}
                  ></textarea>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex flex-column justify-content-between">
                <div className="que1">
                  15. Having any current or past mental health issues, such as
                  depression
                  <span className="subq">(If Yes,Describe in Details)?</span>
                  <div className="radio-button-container">
                    <label className="radio-label">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answersec215"
                        value="Yes"
                        checked={selectedvalueqsec215 === "Yes"}
                        onChange={quesec215}
                      />{" "}
                      <span className="custom-radio"></span>
                      yes
                    </label>
                    <label className="radio-label ms-5 ">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answersec215"
                        value="No"
                        checked={selectedvalueqsec215 === "No"}
                        onChange={quesec215}
                      />{" "}
                      <span className="custom-radio"></span>
                      no
                    </label>
                  </div>
                </div>
                {selectedvalueqsec215 == "Yes" ? (
                  <textarea
                    className="msg"
                    value={anssection1?.mental_health_history}
                    onChange={quesec215info}
                  ></textarea>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex flex-column justify-content-between">
                <div className="que1">
                  16. Have you ever had abnormal Pap smear results (The Pap test
                  or Pap smear looks for precancers, cell changes on the cervix
                  that might become cervical cancer)?
                  <span className="subq">(If Yes,Describe in Details)</span>
                  <div className="radio-button-container">
                    <label className="radio-label">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answersec216"
                        value="Yes"
                        checked={
                          selectedvalueqsec216 === "Yes" ||
                          anssection1.abnormal_pap_smear_history?.length > 2
                        }
                        onChange={quesec216}
                      />{" "}
                      <span className="custom-radio"></span>
                      yes
                    </label>
                    <label className="radio-label ms-5 ">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answersec216"
                        value="No"
                        checked={
                          selectedvalueqsec216 === "No" ||
                          anssection1.abnormal_pap_smear_history == "No"
                        }
                        onChange={quesec216}
                      />{" "}
                      <span className="custom-radio"></span>
                      no
                    </label>
                    <label className="radio-label ms-5 ">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answersec216"
                        value="never"
                        checked={
                          selectedvalueqsec216 === ("never" || "Never") ||
                          anssection1.abnormal_pap_smear_history ==
                            ("never" || "Never")
                        }
                        onChange={quesec216}
                      />{" "}
                      <span className="custom-radio"></span>
                      never done test for this
                    </label>
                  </div>
                </div>
                {selectedvalueqsec216 == "Yes" ? (
                  <textarea className="msg" onChange={quesec216info}></textarea>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex flex-column justify-content-between">
                <div className="que1">
                  17. Do you have had any other personal and familial medical
                  problems or history?
                  <span className="subq">(If Yes,Describe in Details)?</span>
                  <div className="radio-button-container">
                    <label className="radio-label">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answersec217"
                        value="Yes"
                        checked={selectedvalueqsec217 === "Yes"}
                        onChange={quesec217}
                      />{" "}
                      <span className="custom-radio"></span>
                      yes
                    </label>
                    <label className="radio-label ms-5 ">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answersec217"
                        value="No"
                        checked={selectedvalueqsec217 === "No"}
                        onChange={quesec217}
                      />{" "}
                      <span className="custom-radio"></span>
                      no
                    </label>
                  </div>
                </div>
                {selectedvalueqsec217 == "Yes" ? (
                  <textarea
                    className="msg"
                    value={anssection1.chronic_conditions}
                    onChange={quesec217info}
                  ></textarea>
                ) : (
                  ""
                )}
              </div>
              <div className="text-center mt-4">
                <button
                  onClick={gotopage4}
                  style={{
                    height: "45px",
                    width: "300px",
                    fontSize: "15px",
                    fontWeight: "400",
                    border: "1px solid #F0588B",
                    borderRadius: "5px",
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = "#F0588B"; // Change background color on hover
                    e.target.style.color = "white"; // Change text color on hover
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "white"; // Restore background color
                    e.target.style.color = "#7C9CBF"; // Restore text color
                  }}
                >
                  NEXT
                </button>
              </div>
            </div>
          </div>
        ) : page4 == true ? (
          <>
            {" "}
            <div
              style={{
                position: "absolute",
                top: "-20px",
                left: "45%",
                height: "36px",
                width: "120px",
                backgroundColor: "#4f83b1",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bolder",
                borderRadius: "5px",
              }}
            >
              Questions
            </div>
            <div
              style={{
                position: "absolute",
                border: "1px solid gray",
                top: "-24px",
                left: "75%",
                height: "55px",
                width: "55px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: configData.THEME_COLORS.PRIMARY,
                backgroundColor: "white",
              }}
            >
              {percentage}%
            </div>
            <div className="py-3 ps-5">
              <div
                style={{
                  color: "#F0588B",
                  fontSize: "24px",
                  fontWeight: "600",
                }}
              >
                Reproductive and menstrual history
              </div>
              <div style={{ height: "450px", overflowY: "scroll" }}>
                <div className="d-flex justify-content-between">
                  <div className="que">
                    1. What was your age when you had your first period?
                  </div>
                  <input
                    className="box"
                    value={anssection1?.age_at_first_period}
                    onChange={inputperiod}
                  ></input>
                </div>
                <div className="d-flex flex-column">
                  <div className="que">2. Whether your cycles are regular?</div>
                  <div className="radio-button-container ">
                    <label className="radio-label ">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answer31"
                        value="Yes"
                        checked={anssection1.regular_cycles == "Yes"}
                        onChange={periodcycle}
                      />{" "}
                      <span className="custom-radio"></span>
                      Yes
                    </label>

                    <label className="radio-label ms-5">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answer31"
                        value="No"
                        checked={anssection1.regular_cycles == "No"}
                        onChange={periodcycle}
                      />{" "}
                      <span className="custom-radio"></span>
                      No
                    </label>
                    <label className="radio-label ms-5">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answer31"
                        value="sometimes regular"
                        checked={
                          anssection1.regular_cycles == "sometimes regular"
                        }
                        onChange={periodcycle}
                      />{" "}
                      <span className="custom-radio"></span>
                      sometimes regular
                    </label>

                    <label className="radio-label ms-5">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answer31"
                        value="sometimes irregular"
                        checked={
                          anssection1.regular_cycles == "sometimes irregular"
                        }
                        onChange={periodcycle}
                      />{" "}
                      <span className="custom-radio"></span>
                      sometimes irregular
                    </label>
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-between">
                  <div className="que">
                    3. Having Any previous pregnancies, abortions, miscarriages,
                    or stillbirths
                    <span className="subq">(If Yes,Describe in Details)?</span>
                    <div className="radio-button-container">
                      <label className="radio-label">
                        <input
                          style={{ display: "none" }}
                          type="radio"
                          name="answer33"
                          value="Yes"
                          checked={selectedvalueq33 === "Yes"}
                          onChange={que33info}
                        />{" "}
                        <span className="custom-radio"></span>
                        yes
                      </label>
                      <label className="radio-label ms-5 ">
                        <input
                          style={{ display: "none" }}
                          type="radio"
                          name="answer33"
                          value="No"
                          checked={selectedvalueq33 === "No"}
                          onChange={que33info}
                        />{" "}
                        <span className="custom-radio"></span>
                        no
                      </label>
                    </div>
                  </div>
                  {selectedvalueq33 == "Yes" ? (
                    <textarea
                      className="msg"
                      value={anssection1?.pregnancy_history}
                      onChange={que33}
                    ></textarea>
                  ) : (
                    ""
                  )}
                </div>
                <div className="d-flex flex-column">
                  <div className="que">
                    4. If having any previous pregnancies, including
                    miscarriages and abortions then what is the length of
                    pregnancy, including the number of weeks at which the baby
                    was delivered?
                  </div>
                  <textarea
                    className="msg"
                    value={anssection1?.pregnancy_length}
                    onChange={inputpreviouspregnancy}
                  ></textarea>
                </div>
                <div className="d-flex flex-column">
                  <div className="que">
                    5. If having any previous pregnancies, including
                    miscarriages and abortions then which is the method of
                    delivery?
                  </div>
                  <textarea
                    className="msg"
                    value={anssection1?.delivery_method}
                    onChange={methodofdelivery}
                  ></textarea>
                </div>
                <div className="d-flex flex-column">
                  <div className="que">
                    6. If having any previous pregnancies, including
                    miscarriages and abortions then what is the birth weight of
                    the baby?
                  </div>
                  <textarea
                    className="msg"
                    value={anssection1?.baby_birth_weight}
                    onChange={birthweight}
                  ></textarea>
                </div>
                <div className="d-flex flex-column">
                  <div className="que">
                    7. If having any previous pregnancies, including
                    miscarriages and abortions then which type of anesthesia or
                    analgesia used?
                  </div>
                  <textarea
                    className="msg"
                    value={anssection1?.anesthesia_analgesia}
                    onChange={typeofanalgesia}
                  ></textarea>
                </div>
                <div className="d-flex flex-column justify-content-between">
                  <div className="que">
                    8. If having any previous pregnancies, including
                    miscarriages and abortions then is there occurrence of any
                    infections, blood pressure problems, or bleeding
                    complications
                    <span className="subq">(If Yes,Describe in Details)?</span>
                    <div className="radio-button-container">
                      <label className="radio-label">
                        <input
                          style={{ display: "none" }}
                          type="radio"
                          name="answer38"
                          value="Yes"
                          checked={selectedvalueq38 === "Yes"}
                          onChange={que38info}
                        />{" "}
                        <span className="custom-radio"></span>
                        yes
                      </label>
                      <label className="radio-label ms-5 ">
                        <input
                          style={{ display: "none" }}
                          type="radio"
                          name="answer38"
                          value="No"
                          checked={selectedvalueq38 === "No"}
                          onChange={que38info}
                        />{" "}
                        <span className="custom-radio"></span>
                        no
                      </label>
                    </div>
                  </div>
                  {selectedvalueq38 == "Yes" ? (
                    <textarea className="msg" onChange={que38}></textarea>
                  ) : (
                    ""
                  )}
                </div>
                <div className="d-flex flex-column justify-content-between">
                  <div className="que">
                    9. What are the Problems with previous pregnancies
                    <span className="subq">
                      (If having any, Please mention in details)?
                    </span>
                    <div className="radio-button-container">
                      <label className="radio-label">
                        <input
                          style={{ display: "none" }}
                          type="radio"
                          name="answer39"
                          value="Yes"
                          checked={selectedvalueq39 === "Yes"}
                          onChange={que39info}
                        />{" "}
                        <span className="custom-radio"></span>
                        yes
                      </label>
                      <label className="radio-label ms-5 ">
                        <input
                          style={{ display: "none" }}
                          type="radio"
                          name="answer39"
                          value="No"
                          checked={selectedvalueq39 === "No"}
                          onChange={que39info}
                        />{" "}
                        <span className="custom-radio"></span>
                        no
                      </label>
                    </div>
                  </div>
                  {selectedvalueq39 == "Yes" ? (
                    <textarea
                      className="msg"
                      defaultValue={anssection1?.pregnancy_problems}
                      onChange={que39}
                    ></textarea>
                  ) : (
                    ""
                  )}
                </div>
                <div className="d-flex flex-column justify-content-between">
                  <div className="que">
                    10. Having any problems with your reproductive organs (If
                    having any, Please mention in details)?
                    <span className="subq">
                      (If having any, Please mention in details)?
                    </span>
                    <div className="radio-button-container">
                      <label className="radio-label">
                        <input
                          style={{ display: "none" }}
                          type="radio"
                          name="answer310"
                          value="Yes"
                          checked={selectedvalueq310 == "Yes"}
                          onChange={que310info}
                        />{" "}
                        <span className="custom-radio"></span>
                        yes
                      </label>
                      <label className="radio-label ms-5 ">
                        <input
                          style={{ display: "none" }}
                          type="radio"
                          name="answer310"
                          value="No"
                          checked={selectedvalueq310 == "No"}
                          onChange={que310info}
                        />{" "}
                        <span className="custom-radio"></span>
                        no
                      </label>
                    </div>
                  </div>
                  {selectedvalueq310 == "Yes" ? (
                    <textarea
                      className="msg"
                      value={anssection1?.repro_organ_problems}
                      onChange={que310}
                    ></textarea>
                  ) : (
                    ""
                  )}
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="que">
                    11. When was your last menstrual period?
                  </div>
                  <input
                    type="date"
                    className="box1"
                    onChange={inputperiodlast}
                    value={anssection1?.last_period_date}

                    // dateFormat="yyyy-mm-dd"
                    // placeholderText="yyyy-mm-dd"
                  ></input>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="que">
                    12. How regular was your menstrual cycle (Mention the
                    average gap in number of days between two periods) ?
                  </div>
                  <input
                    className="box"
                    value={anssection1?.cycle_regularity}
                    onChange={cycleregular}
                  ></input>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="que">
                    13. What is the length of previous labour if having any?
                  </div>
                  <input
                    className="box"
                    value={anssection1?.labour_length}
                    onChange={labourlen}
                  ></input>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="que">
                    14. Nature of previous delivery if having any? (Type in
                    question)
                  </div>
                  <input
                    className="box"
                    value={anssection1?.delivery_nature}
                    onChange={natureofdelivery}
                  ></input>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="que">
                    15.Number of previous term deliveries (after 37 weeks) if
                    having any?
                  </div>
                  <input
                    className="box"
                    value={anssection1?.term_deliveries}
                    onChange={termdelivery}
                  ></input>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="que">
                    16.Number of previous premature deliveries (greater than 20
                    and less than 37 weeks) if having any?
                  </div>
                  <input
                    className="box"
                    value={anssection1?.premature_deliveries}
                    onChange={prematuredelivery}
                  ></input>
                </div>{" "}
                <div className="d-flex justify-content-between align-items-center">
                  <div className="que">
                    17.Number of abortions if having any?
                  </div>
                  <input
                    className="box"
                    value={anssection1?.abortions}
                    onChange={abortion}
                  ></input>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="que">
                    18. Number of living children if having any?
                  </div>
                  <input
                    className="box"
                    value={anssection1?.living_children}
                    onChange={noofchild}
                  ></input>
                </div>
                <div className="d-flex flex-column justify-content-between pb-2">
                  <div className="que">
                    19. Having any other reproductive or menstrual problems or
                    history
                    <span className="subq">
                      (If Yes, Please Mention them in Details)?
                    </span>
                    <div className="radio-button-container">
                      <label className="radio-label">
                        <input
                          style={{ display: "none" }}
                          type="radio"
                          name="answer10last"
                          value="Yes"
                          checked={selectedvalueq10last === "Yes"}
                          onChange={que10last}
                        />{" "}
                        <span className="custom-radio"></span>
                        yes
                      </label>
                      <label className="radio-label ms-5 ">
                        <input
                          style={{ display: "none" }}
                          type="radio"
                          name="answer10last"
                          value="No"
                          checked={selectedvalueq10last === "No"}
                          onChange={que10last}
                        />{" "}
                        <span className="custom-radio"></span>
                        no
                      </label>
                    </div>
                  </div>
                  {selectedvalueq10last == "Yes" ? (
                    <textarea
                      className="msg"
                      value={
                        anssection1?.repro_menustral_problem != "Yes"
                          ? anssection1?.repro_menustral_problem
                          : ""
                      }
                      onChange={que10lastmsg}
                    ></textarea>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  style={{
                    textTransform: "uppercase",
                    width: "80%",
                  }}
                >
                  * The privacy of our users is our first priority. We, at
                  myprega.com, understand that privacy of data is a fundamental
                  human right. Your medical history, information you provided
                  and discussions with your doctor will be stored in our safe
                  and secure database. This information will strictly remain
                  confidential. We’re committed to keeping you safe. We’ve built
                  myprega.com with industry-leading end-to-end encryption so
                  your personal information, medical information & history
                  provided by you, your medical records & reports and messages
                  between you and medical experts are protected by default, and
                  stay between you and your appointed medical experts. No one
                  else, not even myprega, can read or listen to them.
                </div>
                <div className="text-center mt-4">
                  <span
                    onClick={gotopage3}
                    style={{
                      color: "#4E83B1",
                      fontWeight: "bolder",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                  >
                    BACK
                  </span>
                  <button
                    onClick={submitalldata}
                    className="ms-4"
                    style={{
                      height: "45px",
                      width: "120px",
                      fontSize: "15px",
                      fontWeight: "400",
                      borderRadius: "5px",
                      backgroundColor: "#4E83B1",
                      color: "white",
                      border: "none",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "#F0588B"; // Change background color on hover
                      e.target.style.color = "white"; // Change text color on hover
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#4E83B1"; // Restore background color
                      e.target.style.color = "white"; // Restore text color
                    }}
                  >
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div>
            <div
              style={{
                position: "absolute",
                top: "-20px",
                left: "45%",
                height: "36px",
                width: "120px",
                backgroundColor: "#4f83b1",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bolder",
                borderRadius: "5px",
              }}
            >
              Questions
            </div>
            <div
              style={{
                position: "absolute",
                border: "1px solid gray",
                top: "-24px",
                left: "75%",
                height: "55px",
                width: "55px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: configData.THEME_COLORS.PRIMARY,
                backgroundColor: "white",
              }}
            >
              {percentage}%
            </div>
            <div className="py-3 ps-5">
              <div
                style={{
                  color: "#F0588B",
                  fontSize: "24px",
                  fontWeight: "600",
                }}
              >
                General lifestyle habits & History
              </div>
              <div style={{ height: "450px", overflowY: "scroll" }}>
                <div className="d-flex justify-content-between">
                  <div className="que">1. What is Your height ?</div>
                  <div
                    style={{
                      marginRight: "60px",
                      display: "flex",
                      alignItems: "center",
                      width: "15%",
                    }}
                  >
                    <input
                      className="boxin"
                      value={anssection1?.height}
                      onChange={inputheight}
                    ></input>
                    <span className="ps-2">cm</span>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="que">2. What is Your Age ?</div>
                  <div
                    style={{
                      marginRight: "60px",
                      display: "flex",
                      alignItems: "center",
                      width: "15%",
                    }}
                  >
                    <input
                      className="boxin"
                      value={anssection1?.age}
                      onChange={inputage}
                    ></input>
                    <span className="ps-2">year</span>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="que">3. What is Your weight ?</div>
                  <div
                    style={{
                      marginRight: "60px",
                      display: "flex",
                      alignItems: "center",
                      width: "15%",
                    }}
                  >
                    <input
                      className="boxin"
                      value={anssection1?.weight}
                      onChange={inputweight}
                    ></input>
                    <span className="ps-2">Kg</span>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="que">
                    4. Are you addicted to any habits that could affect your
                    pregnancy, such as tobacco, alcohol, or drug use?
                  </div>
                  <div className="radio-button-container ms-5">
                    <label className="radio-label ms-5">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answer"
                        value="Yes"
                        checked={anssection1.addiction === "Yes"}
                        onChange={handleRadioChange}
                      />{" "}
                      <span className="custom-radio"></span>
                      Yes
                    </label>
                    <label className="radio-label ms-5 ">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answer"
                        value="No"
                        checked={anssection1.addiction === "No"}
                        onChange={handleRadioChange}
                      />{" "}
                      <span className="custom-radio"></span>
                      No
                    </label>
                    {/* <p>
                    Selected value:
                    {this.state.selectedValue !== null
                      ? this.state.selectedValue
                      : "None"}
                  </p> */}
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <div className="que">
                    5. How frequently do you consume tobacco, alcohol, or drugs
                    ?
                  </div>
                  <div className="radio-button-container">
                    <label className="radio-label">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answer4"
                        value="Daily"
                        checked={anssection1.addtiction_consumption === "Daily"}
                        onChange={handleRadioChange4}
                      />{" "}
                      <span className="custom-radio"></span>
                      Daily
                    </label>
                    <label className="radio-label ms-5 ">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answer4"
                        value="Weekly"
                        checked={
                          anssection1.addtiction_consumption === "Weekly"
                        }
                        onChange={handleRadioChange4}
                      />{" "}
                      <span className="custom-radio"></span>
                      Weekly
                    </label>
                    <label className="radio-label ms-5 ">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answer4"
                        value="monthly"
                        checked={
                          anssection1.addtiction_consumption === "monthly"
                        }
                        onChange={handleRadioChange4}
                      />{" "}
                      <span className="custom-radio"></span>
                      monthly
                    </label>
                    <label className="radio-label ms-5 ">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answer4"
                        value="occasionally"
                        checked={
                          anssection1.addtiction_consumption === "occasionally"
                        }
                        onChange={handleRadioChange4}
                      />{" "}
                      <span className="custom-radio"></span>
                      occasionally
                    </label>
                    <label className="radio-label ms-5 ">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answer4"
                        value="never"
                        checked={anssection1.addtiction_consumption === "never"}
                        onChange={handleRadioChange4}
                      />{" "}
                      <span className="custom-radio"></span>
                      never
                    </label>
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <div className="que">6. How were your exercise habits? </div>
                  <div className="radio-button-container">
                    <label className="radio-label">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answer6"
                        value="Daily"
                        checked={anssection1.exercise_habit === "Daily"}
                        onChange={que6}
                      />{" "}
                      <span className="custom-radio"></span>
                      Daily
                    </label>
                    <label className="radio-label ms-5 ">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answer6"
                        value="Rarely"
                        checked={anssection1.exercise_habit === "Rarely"}
                        onChange={que6}
                      />{" "}
                      <span className="custom-radio"></span>
                      Rarely
                    </label>
                    <label className="radio-label ms-5 ">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answer6"
                        value="Never"
                        checked={anssection1.exercise_habit === "Never"}
                        onChange={que6}
                      />{" "}
                      <span className="custom-radio"></span>
                      Never
                    </label>
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <div className="que">7. How was your sexual life? </div>
                  <div className="radio-button-container">
                    <label className="radio-label">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answer7"
                        value="Great"
                        checked={anssection1.sexual_life === "Great"}
                        onChange={que7}
                      />{" "}
                      <span className="custom-radio"></span>
                      Great
                    </label>
                    <label className="radio-label ms-5 ">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answer7"
                        value="satisfied"
                        checked={anssection1.sexual_life === "satisfied"}
                        onChange={que7}
                      />{" "}
                      <span className="custom-radio"></span>
                      satisfied
                    </label>
                    <label className="radio-label ms-5 ">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answer7"
                        value="not satisfied"
                        checked={anssection1.sexual_life === "not satisfied"}
                        onChange={que7}
                      />{" "}
                      <span className="custom-radio"></span>
                      not satisfied
                    </label>
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <div className="que">8. What is your frequency of sex? </div>
                  <div className="radio-button-container">
                    <label className="radio-label">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answer8"
                        value="Daily"
                        checked={anssection1.sex_frequency === "Daily"}
                        onChange={que8}
                      />{" "}
                      <span className="custom-radio"></span>
                      Daily
                    </label>
                    <label className="radio-label ms-5 ">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answer8"
                        value="Once a week"
                        checked={anssection1.sex_frequency === "Once a week"}
                        onChange={que8}
                      />{" "}
                      <span className="custom-radio"></span>
                      Once a week
                    </label>
                    <label className="radio-label ms-5 ">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answer8"
                        value="once a month"
                        checked={anssection1.sex_frequency === "once a month"}
                        onChange={que8}
                      />{" "}
                      <span className="custom-radio"></span>
                      once a month
                    </label>
                    <label className="radio-label ms-5 ">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answer8"
                        value="Rarely"
                        checked={anssection1.sex_frequency === "Rarely"}
                        onChange={que8}
                      />{" "}
                      <span className="custom-radio"></span>
                      Rarely
                    </label>
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-between">
                  <div className="que">
                    9. Any dental care you have taken previously{" "}
                    <span className="subq">(If Yes,Describe in Details)?</span>
                    <div className="radio-button-container">
                      <label className="radio-label">
                        <input
                          style={{ display: "none" }}
                          type="radio"
                          name="answer9a"
                          value="Yes"
                          checked={selectedvalueq9a == "Yes"}
                          onChange={que9a}
                        />{" "}
                        <span className="custom-radio"></span>
                        yes
                      </label>
                      <label className="radio-label ms-5 ">
                        <input
                          style={{ display: "none" }}
                          type="radio"
                          name="answer9a"
                          value="No"
                          checked={selectedvalueq9a == "No"}
                          onChange={que9a}
                        />{" "}
                        <span className="custom-radio"></span>
                        no
                      </label>
                    </div>
                  </div>
                  {selectedvalueq9a == "Yes" && (
                    // &&selectedvalueq9a != "No"
                    <textarea
                      className="msg"
                      onChange={que9}
                      value={anssection1?.dental_care}
                    ></textarea>
                  )}
                </div>
                <div className="d-flex flex-column justify-content-between">
                  <div className="que">
                    10. Have you taken any vaccinations previously{" "}
                    <span className="subq">
                      (If Yes, Please Mention them in Details)?
                    </span>
                    <div className="radio-button-container">
                      <label className="radio-label">
                        <input
                          style={{ display: "none" }}
                          type="radio"
                          name="answer10a"
                          value="Yes"
                          checked={selectedvalueq10a == "Yes"}
                          onChange={que10a}
                        />{" "}
                        <span className="custom-radio"></span>
                        yes
                      </label>
                      <label className="radio-label ms-5 ">
                        <input
                          style={{ display: "none" }}
                          type="radio"
                          name="answer10a"
                          value="No"
                          checked={selectedvalueq10a == "No"}
                          onChange={que10a}
                        />{" "}
                        <span className="custom-radio"></span>
                        no
                      </label>
                    </div>
                  </div>
                  {selectedvalueq10a == "Yes" && (
                    <textarea
                      className="msg"
                      onChange={que10}
                      value={anssection1?.vaccinations}
                    ></textarea>
                  )}
                </div>
                <div className="d-flex flex-column justify-content-between">
                  <div className="que">
                    11. What are your general lifestyle habits?
                  </div>
                  <textarea
                    className="msg"
                    onChange={que11}
                    value={anssection1?.general_lifestyle_habits}
                  ></textarea>
                </div>
                <div className="d-flex flex-column justify-content-between">
                  <div className="que">
                    12. Do you have any other lifestyle issues?
                  </div>
                  <textarea
                    className="msg"
                    onChange={que12}
                    value={anssection1?.lifestyle_issues}
                  ></textarea>
                </div>
                <div className="d-flex flex-column">
                  <div className="que">13. How was your work environment? </div>
                  <div className="radio-button-container">
                    <label className="radio-label">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answer13"
                        value="great"
                        checked={anssection1.work_enviornment === "Great"}
                        onChange={que13}
                      />{" "}
                      <span className="custom-radio"></span>
                      Great
                    </label>
                    <label className="radio-label ms-5 ">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answer13"
                        value="Average"
                        checked={anssection1.work_enviornment === "Average"}
                        onChange={que13}
                      />{" "}
                      <span className="custom-radio"></span>
                      Average
                    </label>
                    <label className="radio-label ms-5 ">
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        name="answer13"
                        value="Depressive"
                        checked={anssection1.work_enviornment === "Depressive"}
                        onChange={que13}
                      />{" "}
                      <span className="custom-radio"></span>
                      Depressive
                    </label>
                  </div>
                </div>
                <div className="text-center mt-4">
                  <button
                    onClick={gotopage3}
                    style={{
                      height: "45px",
                      width: "300px",
                      fontSize: "15px",
                      fontWeight: "400",
                      border: "1px solid #F0588B",
                      borderRadius: "5px",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "#F0588B"; // Change background color on hover
                      e.target.style.color = "white"; // Change text color on hover
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "white"; // Restore background color
                      e.target.style.color = "#7C9CBF"; // Restore text color
                    }}
                  >
                    NEXT
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <AlertShow
        alertShowHide={showAlert}
        alertClose={handleCloseAlert}
        setalertMsg={alertMsg}
        severity={severity}
      />
    </div>
  );
}

export default Casepaper;
