import { width } from "@mui/system";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import configData from "../../config.json";

function ReportDetailsBox({ title, data }) {
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const [value, onChange] = useState(new Date());
  return (
    <div style={{ backgroundColor: "blue", width: "40%" }}>
      <div
        style={{
          alignSelf: "flex-end",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "30vw",
        }}
      >
        <div
          style={{
            marginTop: 50,
            fontSize: isTabScreen ? "26px" : "32px",
            fontWeight: "500",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: configData.THEME_COLORS.SECONDARY,
            fontFamily: "Poppins",
          }}
        >
          {title}
        </div>
        <hr></hr>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            marginTop: "38px",
            width: "80%",
            backgroundColor: "#F0FAF8",
            borderRadius: "12px",
            border: "1px solid #E8E7E7",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "90%",
              height: "26px",
              justifyContent: "space-between",
              margin: "20px",
            }}
          >
            <div
              style={{
                fontWeight: "500",
                fontSize: isTabScreen ? "14px" : "16px",
                color: configData.THEME_COLORS.PRIMARY,
              }}
            >
              {data?.month} {data?.date}
            </div>
            <div
              style={{
                fontWeight: "500",
                fontSize: isTabScreen ? "14px" : "16px",
                color: configData.THEME_COLORS.PRIMARY,
              }}
            >
              WEEK {data?.week}
            </div>
          </div>
          <div
            style={{
              marginTop: "5px",
              fontSize: isTabScreen ? "18px" : "20px",
              fontWeight: "500",
              color: configData.THEME_COLORS.SECONDARY,
              alignSelf: "center",
            }}
          >
            {data?.name}
          </div>

          <div
            style={{
              marginTop: "15px",
              fontSize: isTabScreen ? "32px" : "40px",
              fontWeight: "600",
              color: configData.THEME_COLORS.PRIMARY,
              alignSelf: "center",
              height: "120px",
              width: "86px",
              textAlign: "center",
            }}
          >
            {data?.date} {data?.month}
          </div>
          <div
            style={{
              marginTop: "12px",
              fontSize: isTabScreen ? "14px" : "16px",
              fontWeight: "400",
              alignSelf: "center",
              textAlign: "center",
              width: "80%",
            }}
          >
            Consulted Dr. June Doe for health of fetus.
          </div>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              alignSelf: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontWeight: "500",
                fontSize: isTabScreen ? "14px" : "16px",
                color: "#000000",
              }}
            >
              Prescription
            </div>
            <img
              src={"/Reports/Vector.png"}
              style={{
                resizeMode: "contain",
                height: "20px",
                marginLeft: "10px",
              }}
            />
          </div>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              alignSelf: "center",
              alignItems: "center",
              marginBottom: "22px",
            }}
          >
            <div
              style={{
                fontWeight: "500",
                fontSize: isTabScreen ? "14px" : "16px",
                color: "#000000",
              }}
            >
              Report
            </div>
            <img
              src={"/Reports/Vector.png"}
              style={{
                resizeMode: "contain",
                height: "20px",
                marginLeft: "10px",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportDetailsBox;
