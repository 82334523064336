import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
function Refundandcancellationpolicy() { 
  const [userid, setUserid] = useState(0);
   useEffect(() => {
     window.scrollTo(0, 0);
   }, []);
  const supportmyprega = () => {
  const mailtoLink = `mailto:info@myprega.com?subject=About&body=" "`;

  window.location.href = mailtoLink;
};

useEffect(() => {
  const userIdValue = localStorage.getItem("userId");
  setUserid(userIdValue);
}, [userid]);
  return (
    <div>
      <Header userid={userid} />{" "}
      <div className="container-fluid pt-5">
        <div
          className="row pt-5 px-5"
          // style={{ paddingLeft: "60px" }}
        >
          <div className="col-12" style={{ textAlign: "center", color: "black" }}>
            <h2 className="fw-bolder py-4">REFUNDS AND CANCELLATION POLICY</h2>
          </div>

          <div className="col-12">
            <p style={{ color: "black" }}>
              We thank you and appreciate your use of the platform for availing
              service through the platform. Please read the policy, conditions
              and process carefully as they will give you important information
              and guidelines about your rights and obligations as our customer,
              concerning any purchase you make through us. The policy concerning
              the processing of your refund, Cancellation and Return shall be
              following the clauses as set forth. The Company nor the platform
              shall strictly undertake any exchange to any products purchased
              through the platform and/or services availed through the service
              providers on the platforms.
            </p>
          </div>

          <div className="col-12" style={{ marginTop: "20px" }}>
            <p style={{ color: "black" }}>
              The Company follows a strict Return and Refund policy, for
              appropriate solution kindly refer as follows:
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-1"></div>
          <div className="col-11">
            <h5>1. RETURNS</h5>
            <div
              style={{
                color: "black",
                display: "flex",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "20px",
              }}
            >
              <div style={{ textAlign: "center", width: "3%" }}>i.</div>
              <div
                style={{
                  width: "97%",
                  paddingRight: "20px",
                  margin: "0px auto",
                }}
                className="pr-5"
              >
                If the Practitioner with whom User has booked a paid appointment
                via the Platform, has not been able to meet the User, User will
                need to write to us at
                <Link onClick={supportmyprega} className="text-primary">
                  "support@myprega.com"
                </Link>
                within five (05) days from the occurrence of such event; in
                which case, the entire consultation amount as mentioned on the
                Platform will be refunded to the User within the next five (05)
                to six (06) business days.
              </div>
            </div>

            <div
              style={{
                color: "black",
                display: "flex",
                paddingTop: "5px",
                paddingBottom: " 5px",
              }}
            >
              <div style={{ textAlign: "center", width: "3%" }}>
                <span>ii.</span>
              </div>
              <div style={{ width: "97%", paddingRight: "20px" }}>
                In a case where the User does not show up for the appointment
                booked with a Practitioner, without cancelling the appointment
                beforehand, the amount will not be refunded.
              </div>
            </div>

            <div
              style={{
                color: "black",
                display: "flex",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "20px",
              }}
            >
              <div style={{ textAlign: "center", width: "3%" }}>
                <span>iii.</span>
              </div>
              <div style={{ width: "97%", paddingRight: "20px" }}>
                Users will not be entitled to any refunds in cases where, the
                Practitioner is unable to meet the User at the exact time of the
                scheduled appointment time and the User is required to wait,
                irrespective of the fact whether the User is required to wait or
                choose to not obtain the medical services from the said
                Practitioner.
              </div>
            </div>

            <div
              style={{
                color: "black",
                display: "flex",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "20px",
              }}
            >
              <div style={{ textAlign: "center", width: " 3%" }}>
                <span>iv.</span>
              </div>
              <div style={{ width: "97%", paddingRight: "20px" }}>
                In the rare occasion that the user does not get Blood Pints Due
                to unavailability or any other reason, or in any case, the Blood
                Pints get damaged during Delivery attempted the User will get
                Full amount as Refund within two (02) days from the occurrence
                of such event.
              </div>
            </div>

            <div
              style={{
                color: "black",
                display: "flex",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "20px",
              }}
            >
              <div style={{ textAlign: "center", width: "3%" }}>
                <span>v.</span>
              </div>
              <div style={{ width: "97%", paddingRight: "20px" }}>
                If you cancel an order of blood pints before it is shipped to
                you, or if you refuse delivery, a cancellation service charge
                will be deducted and the balance amount will be refunded within
                seven (7) business days from the date of cancellation request.
              </div>
            </div>

            <div
              style={{
                color: "black",
                display: "flex",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "20px",
              }}
            >
              <div style={{ textAlign: "center", width: "3%" }}>
                <span>vi.</span>
              </div>
              <div style={{ width: "97%", paddingRight: "20px" }}>
                In women pregnancy care subscription packages, if the Customer
                does not wish to continue the subscription, the Company shall
                refund the unused period of subscription after deduction of
                cancellation and service charges, within fourteen (14) business
                days from the date of cancellation request.
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-1"></div>
          <div className="col-11">
            <h5>2. REFUND</h5>
            <div
              style={{
                color: "black",
                display: "flex",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "20px",
              }}
            >
              <div style={{ textAlign: "center", width: "3%" }}>i.</div>
              <div style={{ width: "97%", paddingRight: "20px" }}>
                If the Practitioner with whom User has booked a paid appointment
                via the Platform, has not been able to meet the User, User will
                need to write to us at
                <Link onClick={supportmyprega}>"support@myprega.com"</Link> within
                five (05) days from the occurrence of such event; in which case,
                the entire consultation amount as mentioned on the Platform will
                be refunded to the User within the next five (05) to six (06)
                business days.
              </div>
            </div>

            <div
              style={{
                color: "black",
                display: "flex",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "20px",
              }}
            >
              <div style={{ textAlign: "center", width: "3%" }}>
                <span>ii.</span>
              </div>
              <div style={{ width: "97%", paddingRight: "20px" }}>
                If a User avails pregnancy care service from the platform at any
                time between pregnancy and not from the start of the pregnancy
                then the treatment/care starting from that week when the user
                buys services. Users get the refund of money of unused
                Consultancies, unused Tests and unused ultrasounds as per
                decided by company and company has final rights regarding this
                which will be excluding taxes and service charges return.
              </div>
            </div>

            <div
              style={{
                color: "black",
                display: "flex",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "20px",
              }}
            >
              <div style={{ textAlign: "center", width: "3%" }}>
                <span>iii.</span>
              </div>
              <div style={{ width: "97%", paddingRight: "20px" }}>
                In a case where the User does not show up for the appointment
                booked with a Practitioner, without cancelling the appointment
                beforehand, the amount will not be refunded.
              </div>
            </div>

            <div
              style={{
                color: "black",
                display: "flex",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "20px",
              }}
            >
              <div style={{ textAlign: "center", width: "3%" }}>
                <span>iv.</span>
              </div>
              <div style={{ width: "97%", paddingRight: "20px" }}>
                Users will not be entitled to any refunds in cases where, the
                Practitioner is unable to meet the User at the exact time of the
                scheduled appointment time and the User is required to wait,
                irrespective of the fact whether the User is required to wait or
                choose to not obtain the medical services from the said
                Practitioner.
              </div>
            </div>

            <div
              style={{
                color: "black",
                display: "flex",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "20px",
              }}
            >
              <div style={{ textAlign: "center", width: "3%" }}>
                <span>v.</span>
              </div>
              <div style={{ width: "97%", paddingRight: "20px" }}>
                If the User does not get Blood Pints Due to unavailability, or
                if the Blood Pints get damaged during delivery, the User will
                get the full amount refunded within two (02) days from the
                occurrence of such event.
              </div>
            </div>

            <div
              style={{
                color: "black",
                display: "flex",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "20px",
              }}
            >
              <div style={{ textAlign: "center", width: "3%" }}>
                <span>vi.</span>
              </div>
              <div style={{ width: "97%", paddingRight: "20px" }}>
                If the user cancels an order of blood pints before it is shipped
                to, or in the likelihood of refusal of acceptance of delivery by
                the user, a cancellation service charge will be deducted and the
                balance amount will be refunded within seven (07) business days
                from the date of cancellation request.
              </div>
            </div>

            <div
              style={{
                color: "black",
                display: "flex",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "20px",
              }}
            >
              <div style={{ textAlign: "center", width: "3%" }}>
                <span>vii.</span>
              </div>
              <div style={{ width: "97%", paddingRight: "20px" }}>
                In the women pregnancy care subscription Package, in the case
                that the user does not wish to continue the subscription, the
                company shall refund the unused period of your subscription
                after deduction of cancellation charges, within fourteen (14)
                business days from the date of cancellation request.
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-1"></div>
          <div className="col-11">
            <h5>3. CANCELLATION</h5>
            <div style={{ color: "black" }}>
              The Company at its sole discretion may cancel the user's service
              request after the payment is made, if the Company:{" "}
            </div>
            <div
              style={{
                color: "black",
                display: "flex",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "20px",
              }}
            >
              <div style={{ textAlign: "center", width: "3%" }}>i.</div>
              <div style={{ width: "97%", paddingRight: "20px" }}>
                If it suspects a User has undertaken a fraudulent transaction,
                or{" "}
              </div>
            </div>

            <div
              style={{
                color: "black",
                display: "flex",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "20px",
              }}
            >
              <div style={{ textAlign: "center", width: "3%" }}>
                <span>ii.</span>
              </div>
              <div style={{ width: "97%", paddingRight: "20px" }}>
                If it suspects a User has undertaken a transaction that is not
                following the Terms of Use, or{" "}
              </div>
            </div>

            <div
              style={{
                color: "black",
                display: "flex",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "20px",
              }}
            >
              <div style={{ textAlign: "center", width: "3%" }}>
                <span>iii.</span>
              </div>
              <div style={{ width: "97%", paddingRight: "20px" }}>
                For any reason outside the control of the Company or the company
                service area.
              </div>
            </div>

            <div
              style={{
                color: "black",
                display: "flex",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "20px",
              }}
            >
              <div style={{ textAlign: "center", width: "3%" }}>
                <span>iv.</span>
              </div>
              <div style={{ width: "97%", paddingRight: "20px" }}>
                If the Company does not want to do business with the User
              </div>
            </div>

            <div
              style={{
                color: "black",
                display: "flex",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "20px",
              }}
            >
              <div style={{ textAlign: "center", width: "3%" }}>
                <span>v.</span>
              </div>
              <div style={{ width: "97%", paddingRight: "20px" }}>
                Any malpractice used to place the order.
              </div>
            </div>

            <div
              style={{
                color: "black",
                display: "flex",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "20px",
              }}
            >
              <div style={{ textAlign: "center", width: "3%" }}>
                <span>vi.</span>
              </div>
              <div style={{ width: "97%", paddingRight: "20px" }}>
                Order placed without Doctor’s prescription for specified drugs
                as specified in drug rules.
              </div>
            </div>

            <div
              style={{
                color: "black",
                display: "flex",
                paddingTop: "5px",
                paddingBottom: " 5px",
              }}
            >
              <div style={{ textAlign: "center", width: "3%" }}>
                <span>vii.</span>
              </div>
              <div style={{ width: "97%", paddingRight: "20px" }}>
                Order placed without dated prescription or prescription for
                which Drugs already issued once.
              </div>
            </div>

            <div
              style={{
                color: "black",
                display: "flex",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "20px",
              }}
            >
              <div style={{ textAlign: "center", width: "3%" }}>
                <span>viii.</span>
              </div>
              <div style={{ width: "97%", paddingRight: "20px" }}>
                Unavailability of all the items ordered by Buyer at the time of
                booking the Order
              </div>
            </div>

            <div
              style={{
                color: "black",
                display: "flex",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "20px",
              }}
            >
              <div style={{ textAlign: "center", width: "3%" }}>
                <span>ix.</span>
              </div>
              <div style={{ width: "97%", paddingRight: "20px" }}>
                Failure to deliver Buyer Order due to lack of information,
                direction or authorization from Buyer at the time of delivery or
                no reply to the call during delivery.
              </div>
            </div>

            <div
              style={{
                color: "black",
                display: "flex",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "20px",
              }}
            >
              <div style={{ textAlign: "center", width: "3%" }}>
                <span>x.</span>
              </div>
              <div style={{ width: "97%", paddingRight: "20px" }}>
                Products ordered not for self-consumption but for commercial
                resale.
              </div>
            </div>

            <div
              style={{
                color: "black",
                display: "flex",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "20px",
              }}
            >
              <div style={{ textAlign: "center", width: "3%" }}>
                <span>xi.</span>
              </div>
              <div style={{ width: "97%", paddingRight: "20px" }}>
                Multiple orders placed for the same product at the same address
                and same Prescription on my date or before delivery of previous
                order on the same prescription.
              </div>
            </div>

            <div
              style={{
                color: "black",
                display: "flex",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "20px",
              }}
            >
              <div style={{ textAlign: "center", width: "3%" }}>
                <span>xii.</span>
              </div>
              <div style={{ width: "97%", paddingRight: "20px" }}>
                Unavailability of Original Prescription during Delivery of
                Medicine products.
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ marginTop: "20px" }}>
          {/* <div className="col-1"></div> */}
          <div className="col-12" style={{ paddingLeft: "10px" }}>
            <p className="px-5" style={{ color: "black" }}>
              Further, while all measures are taken to ensure accuracy of
              service specifications and pricing, the details of the interviewer
              and other information as reflected on the platform may be
              inaccurate due to technical issues, typographical errors or
              incorrect service information provided to the Company and in such
              an event you shall be notified as soon as such error comes to the
              notice of the Company. In such an event, the Company reserves the
              right to cancel your order and refund any money that may have been
              paid by you towards the purchase of such services or can provide
              the same package that is bought by you. We maintain a negative
              list of all fraudulent transactions and non-complying users and
              reserve the right to deny access to such users at any time or
              cancel any orders placed by them in future.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Refundandcancellationpolicy;
