import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./prescriptionmodal.css";
import Prescriptiondetails from "./Prescriptiondetails";

const PrescriptionData = ({ open, data, onClose }) => {
  return (
    <Modal
      className="prescription_modal"
      show={open}
      onHide={onClose}
      size="lg"
    >
      <Modal.Body>
        {data?.medicine?.length > 0 ? (
          <div>
            <h5>Prescription Details:</h5>
            <hr className="my-3 w-100" />
            <Prescriptiondetails medicationDetails={data} onClose={onClose} />
          </div>
        ) : (
          <p>Prescription data not available</p>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PrescriptionData;
