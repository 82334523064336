import React, { useState, useEffect, useRef } from "react";

const CounterUpComponent = ({ targetCount, duration }) => {
  const [count, setCount] = useState(0);
  const sectionRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        let startTime;
        let animationFrameId;

        const increment = (targetCount / duration) * 1000;

        const updateCount = (timestamp) => {
          if (!startTime) startTime = timestamp;
          const elapsedTime = timestamp - startTime;
          const newCount = Math.min(
            targetCount,
            (elapsedTime * increment) / 1000,
          );

          setCount(newCount);

          if (newCount < targetCount) {
            animationFrameId = requestAnimationFrame(updateCount);
          }
        };

        animationFrameId = requestAnimationFrame(updateCount);

        return () => {
          if (animationFrameId) cancelAnimationFrame(animationFrameId);
        };
      }
    }, options);

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, [targetCount, duration]);

  return (
    <div>
      <h3 className="count-it fw-bolder mb-0" ref={sectionRef}>
        {Math.round(count)}
      </h3>
    </div>
  );
};

export default CounterUpComponent;
