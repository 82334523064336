import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import axios from "axios";
import moment from "moment";
import configData from "../config.json";
let timingFormat;
function PrescriptionComponent({
  medicationDetails,
  profileData,
  chatData,
  onImageGenerated,
  redirectToChat,
  submit,
}) {
  const [base64Image, setBase64Image] = useState("");
  const [signature, setSign] = useState();
  const prescriptionContainerRef = useRef();
  console.log("???", medicationDetails, profileData, chatData, redirectToChat);

  useEffect(() => {
    // Call convertToImage after initial rendering

    if (submit === true) {
      setTimeout(() => {
        convertToImage();
      }, 1000);
    }
  }, [submit]);

  // if(profileData?.user?.doctor_signature)
  // {
  useEffect(() => {
    const { doctor_signature, id } = profileData?.user;
    const sign = `${doctor_signature}`;
    const drid = `${id}`;
    const doctor_signature_url ="https://admin.myprega.com/doctor_signature/";
    const full_doctor_signature_url = `${doctor_signature_url}${drid}/${sign}`;
    setSign(full_doctor_signature_url);
  }, []);

  // }

  const convertToImage = async () => {
    const prescriptionContainer = prescriptionContainerRef.current;

    const existingImages = document.querySelectorAll(".prescription-image");
    existingImages?.forEach((img) => {
      img.remove();
    });
    prescriptionContainer.style.display = "block";
    await html2canvas(prescriptionContainer).then((canvas) => {
      const image = canvas.toDataURL("image/png");
      const imgElement = document.createElement("img");
      imgElement.className = "prescription-image";
      imgElement.src = image;
      sendMessage(2, image);
    });
  };
  useEffect(() => {
    if (signature !== undefined || signature != null) {
      const convertImageToBase64 = async (imageUrl) => {
        try {
          const response = await fetch(imageUrl);
          const blob = await response.blob();
          const reader = new FileReader();

          reader.onloadend = () => {
            setBase64Image(reader.result);
          };
          reader.readAsDataURL(blob);
        } catch (error) {
          console.error("Error converting image to base64:", error);
        }
      };
      console.log("SIGN", signature);
      convertImageToBase64(signature).then((base64) => {
        console.log("SIGNconvertImageToBase64", base64);
        setBase64Image(base64);
      });
    }
  }, [signature]);

  const sendMessage = async (id, imagedata) => {
    try {
      const payload = {
        sender_id: profileData.user.id,
        reciever_id: chatData.id,
        message: imagedata,
        is_image: 1,
      };
      console.log("SENDINGURL", payload);
      const response = await axios.post(
        `${configData.SERVER_API_URL}/inbox/send_message`,
        payload
      );
      if (response.status === 200) {
        redirectToChat();
      } else {
        alert("error");
      }
    } catch (error) {
      console.log("error in sending msg", error);
    }

    // getmsg();
    //  setMessage("");
  };
  return (
    <div className="">
      <div ref={prescriptionContainerRef}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#F0588B",
            justifyContent: "space-between",
          }}
        >
          <div
            className="py-3 text-white d-flex justify-content-between"
            style={{ backgroundColor: "#fff" }}
          >
            <div className="pt-2 d-flex align-items-center ps-2">
              <div className="mx-2 text-white fs-3">
                <img src={"/logo-removebg.png"} width={"100px"} />
              </div>
            </div>
            <div></div>
          </div>
          <div
            className="text-white d-flex justify-content-between"
            style={{ backgroundColor: "#F0588B" }}
          >
            <div></div>
            <div className="py-4 pe-4 text-end">
              <div className="fw-bolder text-capitalize">
                Dr. {`${profileData.user.fname} ${profileData.user.lname}`}
              </div>
              <p className="text-white drpost">
                {profileData.user.medical_expert_name}
              </p>
              <p className="text-white areanm">{profileData.user.area_name}</p>
              <h6>
                Medical Registration Number:{" "}
                {profileData.medical_reg[0]?.reg_num}
              </h6>
            </div>
          </div>
        </div>
        <div className="py-2 d-flex ms-2 align-items-center justify-content-between">
          <div className="ps-2">
            <div className="text-capitalize pe-2">{`${chatData.fname} ${chatData.lname}`}</div>
            <div className="text-secondary">
              {chatData?.dob
                ? `${moment().diff(moment(chatData.dob), "years")} years`
                : "DOB not available"}
              , Female
            </div>
          </div>
          <div className="pe-2">{moment().format("DD MMM, YYYY")}</div>
        </div>

        <hr className="w-100" />
        <h6 className="py-2 text-secondary ps-2">MEDICINES</h6>
        <table className="prescription-table">
          <thead>
            <tr>
              <th style={{ width: "10%" }} className="ps-2">
                #
              </th>
              <th style={{ width: "18%" }}>NAME</th>
              <th style={{ width: "3%" }}></th>
              <th style={{ width: "50%" }}>INSTRUCTION</th>
              <th style={{ width: "3%" }}></th>
            </tr>
          </thead>
          <tbody>
            {medicationDetails?.map((medication, index) => (
              <React.Fragment key={index}>
                <br></br>
                <br></br>
                <tr key={index}>
                  <td style={{ width: "10%" }} className="ps-2">
                    {index + 1}
                  </td>
                  <td style={{ width: "18%" }} className="medinm fw-bolder">
                    {medication["Medicine_Name"]}
                  </td>
                  <td style={{ width: "3%" }}></td>
                  <td style={{ width: "50%" }}>
                    {medication.dosage_frequency === "Everyday"
                      ? "Daily"
                      : "Alternate Day"}
                    <span>,</span>
                    {medication.packaging_type}
                    <br></br>
                    {(() => {
                      const timings = medication.dosage_timing
                        .toLowerCase()
                        .trim()
                        .split(",");
                      const morningPresent = timings.includes("morning")
                        ? "1"
                        : "0";
                      const afternoonPresent = timings.includes("afternoon")
                        ? "1"
                        : "0";
                      const nightPresent = timings.includes("night")
                        ? "1"
                        : "0";
                      const timingFormat = `${morningPresent}-${afternoonPresent}-${nightPresent}`;

                      return timingFormat;
                    })()}
                    (
                    {medication.dosage_timing &&
                      medication.dosage_timing
                        .split(",")
                        .map((timing, i) => {
                          const mealInfo =
                            medication.meal_timing &&
                            medication.meal_timing.split(",")[i];
                          const mealPhrase = mealInfo ? `${mealInfo} meal` : "";
                          const time =
                            medication.add_time &&
                            medication.add_time.split(",");
                          // const mytime= time? `At ${time}`:"";
                          console.log("TTTTTTT", time);
                          let timePhrase = "";

                          if (timing.includes("morning")) {
                            timePhrase += `Morning`;
                            if (mealPhrase) {
                              timePhrase += ` ${mealPhrase}`;
                              if (time[0] !== "00")
                                timePhrase += ` At ${time[0]}`;
                            }
                          } else if (timing.includes("afternoon")) {
                            timePhrase += `Afternoon`;
                            if (mealPhrase) {
                              timePhrase += ` ${mealPhrase}`;
                              if (time[1] !== "00")
                                timePhrase += ` At ${time[1]}`;
                            }
                          } else if (timing.includes("night")) {
                            timePhrase += `Night`;
                            if (mealPhrase) {
                              timePhrase += ` ${mealPhrase}`;
                              if (time[2] !== "00")
                                timePhrase += ` At ${time[2]}`;
                            }
                          }

                          // Check if time is not "00" before including it
                          // if (time && time.trim() !== "" && time !== "00") {
                          //   timePhrase += ` at ${time}`;
                          // }

                          return timePhrase;
                        })
                        .filter((timePhrase) => timePhrase !== "")
                        .join(", ")}
                    )
                  </td>
                  <td style={{ width: "3%" }}></td>
                </tr>
                <br></br>
                <br></br>
              </React.Fragment>
            ))}
          </tbody>
        </table>
        <hr
          className="w-100 "
          style={{
            margin: "20px 0px",
          }}
        />
        <table
          className="prescription-table"
          style={{
            width: "100%",
          }}
        >
          <tbody>
            <tr>
              <td>Comments:</td>
            </tr>
            <tr>
              <td>{medicationDetails?.[medicationDetails.length - 1]?.description}</td>
            </tr>
          </tbody>
        </table>
        <div
          style={{
            margin: "100px 0px",
          }}
        >
          {base64Image && (
            <img
              src={base64Image}
              style={{ width: "100px", height: "100px" }}
              alt="Doctor's Signature"
            />
          )}
          Signature
        </div>
      </div>
    </div>
  );
}

export default PrescriptionComponent;
