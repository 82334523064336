const ragalist = [
  {
    testname: "Pregnancy Raga 1",
    img: "/GarbhaSanskar/ragaimg.png",
    img2: "/GarbhaSanskar/raga1.mp3",
  },
  {
    testname: "Raga Ahir Bhairav",
    img: "/GarbhaSanskar/ragaimg.png",
    img2: "/GarbhaSanskar/RagaAhirBhairav.mp3",
  },
  {
    testname: "Raga Bageshwari",
    img: "/GarbhaSanskar/ragaimg.png",
    img2: "/GarbhaSanskar/RagaBageshwari.mp3",
  },
  {
    testname: "Raga Bairagi Bhairav",
    img: "/GarbhaSanskar/ragaimg.png",
    img2: "/GarbhaSanskar/RagaBairagiBhairav.mp3",
  },
  {
    testname: "Raga Brindabani Sarang",
    img: "/GarbhaSanskar/ragaimg.png",
    img2: "/GarbhaSanskar/RagaBrindabaniSarang.mp3",
  },
  {
    testname: "Raga Hindol",
    img: "/GarbhaSanskar/ragaimg.png",
    img2: "/GarbhaSanskar/RagaHindol.mp3",
  },
  {
    testname: "Raga Jaunpuri",
    img: "/GarbhaSanskar/ragaimg.png",
    img2: "/GarbhaSanskar/RagaJaunpuri.mp3",
  },
  {
    testname: "Raga Kalavati",
    img: "/GarbhaSanskar/ragaimg.png",
    img2: "/GarbhaSanskar/RagaKalavati.mp3",
  },
  {
    testname: "Raga Komal Rishabh Asavari",
    img: "/GarbhaSanskar/ragaimg.png",
    img2: "/GarbhaSanskar/RagaKomalRishabhAsavari.mp3",
  },
  {
    testname: "Raga Madhmad Sarang",
    img: "/GarbhaSanskar/ragaimg.png",
    img2: "/GarbhaSanskar/RagaMadhmadSarang.mp3",
  },
  {
    testname: "Raga Malkauns",
    img: "/GarbhaSanskar/ragaimg.png",
    img2: "/GarbhaSanskar/RagaMalkauns.mp3",
  },
  {
    testname: "Raga Yamuna Kalyani",
    img: "/GarbhaSanskar/ragaimg.png",
    img2: "/GarbhaSanskar/RagaYamunaKalyani.mp3",
  },
  {
    testname: "Raga_Todi",
    img: "/GarbhaSanskar/ragaimg.png",
    img2: "/GarbhaSanskar/RagaTodi.mp3",
  },
  {
    testname: "Vasudevayani Raga Kalyani",
    img: "/GarbhaSanskar/ragaimg.png",
    img2: "/GarbhaSanskar/VasudevayaniRagaKalyani.mp3",
  },
];
export default ragalist;
