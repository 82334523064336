import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import configData from "../config.json";
function SignatureUpload({ profileData, ondataReceived }) {
  const [signatureFile, setSignatureFile] = useState(null);
  const [uploadedSignatureUrl, setUploadedSignatureUrl] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [sign, setSign] = useState(null);
  const [profileDatamain, setProfileDatamain] = useState(profileData);
  const isTabScreen = useMediaQuery({
    query: "(min-width: 768px)" && "(max-width: 1023px)",
  });
  const handleFileChange = (e) => {
    setSignatureFile(e.target.files[0]);
    console.log("SIGNATURE", e.target.files);
  };
  console.log("WW", profileData);
  useEffect(() => {
    getapi1();
  }, [uploadStatus, uploadedSignatureUrl, sign]);

  // setProfileDatamain(profileData);

  const getapi1 = async () => {
    try {
      const id = localStorage.getItem("userId");
      if (id) {
        const response = await axios.post(
          `${configData.SERVER_API_URL}/users/get_single_user`,
          {
            userId: id,
          }
        );
        if (response.data.success) {
          console.log("fetched$$$$$$$$$$$$$$$$$$$$$", response.data);
          setProfileDatamain(response.data);
          const { doctor_signature, id } = response.data?.user;
          const sign = `${doctor_signature}`;
          console.log("DD", sign);
          const drid = `${id}`;
          const doctor_signature_url =
            "https://admin.myprega.com/doctor_signature/";
          const full_doctor_signature_url = `${doctor_signature_url}${drid}/${sign}`;
          setUploadedSignatureUrl(full_doctor_signature_url);
          setSign(sign);
          ondataReceived(sign);
        } else {
          console.error(
            "Error adding add user education details:",
            response.data.error
          );
        }
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const handleUpload = async () => {
    try {
      const formData = new FormData();
      formData.append("files", signatureFile);
      formData.append("doctor_id", profileData.user.id);
      console.log("<><>", formData);
      const response = await axios.post(
        `${configData.SERVER_API_URL}/users/add_doctor_signature`,
        formData
      );
      if (response.status === 200) {
        console.log("@", response.data);
        setUploadStatus("Signature uploaded successfully!");
        getapi1();
      } else {
        setUploadStatus("Error uploading signature. Please try again.");
      }
    } catch (error) {
      console.error("Upload error:", error);
      setUploadStatus("Error uploading signature. Please try again.");
    }
  };

  const isScreen3 = useMediaQuery({
    query: "(min-width: 2000px)",
  });
  console.log("%%", sign);
  return (
    <div className="container">
      <div className="row">
        <div className="col-6">
          {uploadedSignatureUrl && (
            <>
              <div
                className="my-3"
                style={{
                  fontSize: isTabScreen ? "17px" : isScreen3 ? "28px" : "22px",
                  fontWeight: "600",
                }}
              >
                Uploaded Signature
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                {sign === "null" ? (
                  <div>Not uploaded yet...</div>
                ) : (
                  <>
                    <img
                      src={uploadedSignatureUrl}
                      alt="Uploaded Signature"
                      style={{ maxWidth: "200px", maxHeight: "200px" }}
                    />
                  </>
                )}
              </div>
            </>
          )}
        </div>
        <div className="col-6">
          <div
            style={{
              fontSize: isTabScreen ? "17px" : isScreen3 ? "28px" : "22px",
              fontWeight: "600",
              marginTop: "4%",
              paddingBottom: "2%",
            }}
          >
            Upload Signature
          </div>
          <div>
            <label htmlFor="fileInput">
              Select Signature to upload on a prescription
            </label>
            <br></br>
            <br></br>
            <input
              type="file"
              id="fileInput"
              accept=".png, .jpg, .jpeg"
              onChange={handleFileChange}
            />
          </div>
          <div className="mt-5 upload" onClick={handleUpload}>
            Upload Signature
          </div>
          {uploadStatus && <p>{uploadStatus}</p>}
        </div>
      </div>
    </div>
  );
}

export default SignatureUpload;
