import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ element: Component, requiredStatus, redirectTo }) => {
  // Check localStorage for user payment status
  const isPaidUser = localStorage.getItem("isPaidUser") === "true"; // Assuming 'true' is used for paid users

  return isPaidUser === requiredStatus ? (
    <Component />
  ) : (
    <Navigate to={redirectTo} />
  );
};

export default ProtectedRoute;
