const API_BASE_URL = "https://api.videosdk.live";
// const VIDEOSDK_TOKEN = process.env.REACT_APP_VIDEOSDK_TOKEN;
const VIDEOSDK_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiI0ODYyYjZjMi04ZGUzLTQwNzItOGQ2ZS1jMDU5YzhhNjllMDQiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTcwMjk4NjkwNSwiZXhwIjoxNzM0NTIyOTA1fQ.yxkzjzNi0owY6E-nKtVHy1TL-u97_jQdE6xedLXNapU";
const API_AUTH_URL = "";
// const API_AUTH_URL = process.env.REACT_APP_AUTH_URL;

export const getToken = async () => {
  if (VIDEOSDK_TOKEN && API_AUTH_URL) {
    console.error(
      "Error: Provide only ONE PARAMETER - either Token or Auth API"
    );
  } else if (VIDEOSDK_TOKEN) {
    return VIDEOSDK_TOKEN;
  } else if (API_AUTH_URL) {
    const res = await fetch(`${API_AUTH_URL}/get-token`, {
      method: "GET",
    });
    const { token } = await res.json();
    return token;
  } else {
    console.error("Error: ", Error("Please add a token or Auth Server URL"));
  }
};

export const getGenratedToken = async (Id) => {
  console.log("getGenratedToken ConsultId>", Id);
  const res = await fetch(
    `https://admin.myprega.com/api/v1/consultation/get_consultation_token`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Specify the content type
      },
      body: JSON.stringify({ consultation_id: Id }),
    }
  );
  const data = await res.json();
  if (data.success) {
    console.log("getGenratedToken Result>", data);
    const token = data.data[0]?.token;
    // const { token } = ctoken.json();
    // console.log("token", token);
    return token;
  } else {
    console.error("Error: ", Error("Please add a token or Auth Server URL"));
  }
};

export const getGenratedYogaToken = async (Id) => {
  const res = await fetch(
    `https://admin.myprega.com/consultation/get_yoga_consultation_token`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Specify the content type
      },
      body: JSON.stringify({ batch_id: Id }),
    }
  );
  const data = await res.json();
  if (data.success) {
    // console.log("data", data.data[0].token);
    const token = data?.token;
    // const { token } = ctoken.json();
    // console.log("token", token);
    return token;
  } else {
    console.error("Error: ", Error("Please add a token or Auth Server URL"));
  }
};

export const createMeeting = async ({ token }) => {
  const url = `${API_BASE_URL}/v2/rooms`;
  const options = {
    method: "POST",
    headers: { Authorization: token, "Content-Type": "application/json" },
  };

  const { roomId } = await fetch(url, options)
    .then((response) => response.json())
    .catch((error) => console.error("error", error));

  return roomId;
};

export const validateMeeting = async ({ roomId, token }) => {
  const url = `${API_BASE_URL}/v2/rooms/validate/${roomId}`;

  const options = {
    method: "GET",
    headers: { Authorization: token, "Content-Type": "application/json" },
  };

  const result = await fetch(url, options)
    .then((response) => response.json()) //result will have meeting id
    .catch((error) => console.error("error", error));

  return result ? result.roomId === roomId : false;
};
