import { Alert } from "@mui/material";
import React from "react";

const Sonostatus = ({ data }) => {
//   console.log("Govind Kumar", data);
  if (data?.is_report_uploaded === 1) {
    return <Alert severity="success">Status: Report Uploaded</Alert>;
  } else if (data?.is_scan_completed == 1) {
    return <Alert severity="success">Status: Scan Completed</Alert>;
  } else if (data?.is_patient_visited == 1) {
    return <Alert severity="success">Status: Patient Visited</Alert>;
  } else {
    return null;
  }
};

export default Sonostatus;
